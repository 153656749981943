import {
  Box,
  Button,
  Checkbox,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  Link,
  MediumText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IClaimPageProps } from './types';

import BumpIcon from '../../assets/BUMPSquare.svg';
import { learnMoreLink } from '../../core/config/links';
import { Routes, subRoutes } from '../../core/config/routes';
import { useChainName } from '../../core/hooks/useChain';
import { VestingService } from '../../core/services/vestingService';
import { useAppSelector } from '../../core/state/hooks';

export const ClaimFlowConfirmPage: React.FC = () => {
  const state = useLocation().state as IClaimPageProps;
  const { isMobile } = useMatchBreakpoints();
  const [isActive, setIsActive] = useState<boolean>(false);
  const vestingData = useAppSelector((state) => state.merkleTree.vesting);
  const navigate = useNavigate();
  const chainName = useChainName();

  const vestingService = VestingService.getInstance();

  const handleBack = async () => {
    navigate(`${Routes.Dashboard}`, {
      replace: true,
    });
  };

  const onConfirmButtonClick = async () => {
    try {
      navigate(`${Routes.Vesting}/${chainName}/${subRoutes.Approve}`, {
        replace: true,
      });

      const tx = await vestingService.claim(vestingData!);

      navigate(`${Routes.Vesting}/${chainName}/${subRoutes.Processing}`, {
        replace: true,
      });
      await tx.wait();

      navigate(`${Routes.Vesting}/${chainName}/${subRoutes.Summary}`, {
        state: {
          ...state,
          txHash: tx.hash,
        },
        replace: true,
      });
    } catch (e) {
      navigate(`${Routes.Dashboard}`, {
        replace: true,
      });
    }
  };

  return (
    <Box maxWidth={isMobile ? '90%' : '575px'}>
      <Flex alignItems="center" justifyContent="center" marginBottom="64px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <img
            src={BumpIcon}
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-50px',
            }}
            width="48px"
            height="48px"
            alt="Icon"
          />
        }
        amount={state.bondAmount}
        subTitle={'BUMP'}
        px={isMobile ? '8px' : ''}
      >
        <MediumText mt="5px" color="secondary.white">
          The above figure is an estimate of the maximum amount of tokens you
          can claim. The actual maximum amount of claimed BUMP will be
          determined at the time the transaction confirms on the blockchain.
        </MediumText>
      </ConfirmCard>
      <Flex alignItems={isMobile ? '' : 'center'} mt="40px">
        <Checkbox onClick={() => setIsActive(!isActive)} />
        <LargeText
          mt={isMobile ? '-10px' : ''}
          ml="20px"
          color="secondary.white"
        >
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={learnMoreLink}>
            Terms and conditions
          </Link>
          ,
          <Link color="secondary.green" href={learnMoreLink}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mt="40px">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={handleBack}
        >
          Back
        </Button>

        <Button
          primary
          onClick={onConfirmButtonClick}
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!isActive}
        >
          Confirm
        </Button>
      </Flex>
    </Box>
  );
};
