import { StakeCard, TotalHubInfoBox } from '@bumper-dao/ui-kit';
import BUMPIcon from '@bumper-dao/ui-kit/dist/images/32px/tokens/bump.svg';
import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { StakeHubCardType } from './types';

import {
  FullScreenWidthContainer,
  HubMainContainer,
} from '../../components/common/MainContainers';
import { SwitchNetworkBanner } from '../../components/common/SwitchNetworkBanner/SwitchNetworkBanner';
import { Routes, subRoutes } from '../../core/config/routes';
import { Slides } from '../../core/config/slides';
import { useChainName } from '../../core/hooks/useChain';
import { StakingService } from '../../core/services/stakingService';
import { useAppSelector } from '../../core/state/hooks';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';
import { SummaryInfoContainer } from '../EarnHub/styles';

export const StakeHub: React.FC = () => {
  const stakingService = StakingService.getInstance();

  const navigate = useNavigate();
  const { chainId } = useWeb3React();
  const chainName = useChainName();

  const tokensDetails = useAppSelector((state) => state.coin.coinDetails);

  const [userStakePositionsSummary, setUserStakePositionsSummary] =
    useState<StakeHubCardType | null>(null);

  useEffect(() => {
    stakingService
      .calculateTotalStakingData(tokensDetails)
      .then((data: StakeHubCardType) => {
        setUserStakePositionsSummary(data);
      })
      .catch((e) => {
        console.error(e);
        setUserStakePositionsSummary(null);
      });
  }, [chainId, tokensDetails]);

  return (
    <FullScreenWidthContainer>
      <SwitchNetworkBanner page="stake" slides={Slides} path={['Stake']} />

      {userStakePositionsSummary ? (
        <HubMainContainer m="64px auto !important" style={{ gap: '0' }}>
          <SummaryInfoContainer>
            <TotalHubInfoBox
              mainTitle={'Value'}
              firstFieldName={'Staked'}
              firstFieldValue={formatStringifyNumberToDot(
                userStakePositionsSummary?.investedAmountInUSDC || '0',
                2,
              )}
              secondFieldName={'Idle'}
              secondFieldValue={formatStringifyNumberToDot(
                userStakePositionsSummary?.assetBalanceInUSDC || '0',
                2,
              )}
              thirdFieldName={'Total'}
              thirdFieldValue={formatStringifyNumberToDot(
                userStakePositionsSummary?.totalAmountInUSDC || '0',
                2,
              )}
            />
            <TotalHubInfoBox
              mainTitle={'Positions'}
              firstFieldName={'Fixed'}
              firstFieldValue={formatStringifyNumberToDot(
                userStakePositionsSummary?.fixedStakingAmountInUSD || '0',
                2,
              )}
              secondFieldName={'Flexible'}
              secondFieldValue={formatStringifyNumberToDot(
                userStakePositionsSummary?.flexibleStakingAmountInUSD || '0',
                2,
              )}
              thirdFieldName={'Total'}
              thirdFieldValue={formatStringifyNumberToDot(
                userStakePositionsSummary?.totalStakingAmountInUSD || '0',
                2,
              )}
            />
          </SummaryInfoContainer>

          <StakeCard
            tokenIcon={BUMPIcon}
            iconBackgroundColor={'#023A42'}
            tokenName={tokensDetails.BUMP.name}
            tokenSymbol={tokensDetails.BUMP.symbol}
            tokenPrice={formatStringifyNumberToDot(
              userStakePositionsSummary?.price,
              2,
            )}
            totalAmount={formatStringifyNumberToDot(
              userStakePositionsSummary.totalBUMP,
            )}
            totalAmountInUSD={formatStringifyNumberToDot(
              userStakePositionsSummary.totalAmountInUSDC,
              2,
            )}
            stakedAmount={formatStringifyNumberToDot(
              userStakePositionsSummary.totalStakingAmount,
            )}
            stakedAmountUSD={formatStringifyNumberToDot(
              userStakePositionsSummary.totalStakingAmountInUSD,
              2,
            )}
            idleAmount={formatStringifyNumberToDot(
              userStakePositionsSummary.idleBUMP,
            )}
            idleAmountUSD={formatStringifyNumberToDot(
              userStakePositionsSummary.assetBalanceInUSDC,
              2,
            )}
            rewardsAmount={formatStringifyNumberToDot(
              userStakePositionsSummary.rewardsBUMP,
            )}
            rewardsAmountUSD={formatStringifyNumberToDot(
              userStakePositionsSummary.rewardsBUMPInUSD,
              2,
            )}
            positionsCount={userStakePositionsSummary.positionsCount}
            onStake={() => {
              navigate(`${Routes.StakeFlow}/${chainName}/${subRoutes.Select}`, {
                state: { ...tokensDetails.BUMP },
              });
            }}
            onView={() => {
              navigate(`${Routes.StakingAssetsDashboard}/${chainName}`);
            }}
          />
        </HubMainContainer>
      ) : null}
    </FullScreenWidthContainer>
  );
};
