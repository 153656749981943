import {
  AssetFooter,
  CardView,
  FlexWithGap,
  HubPages,
  IconSize,
  ListView,
  Pyramid,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react/swiper-react';

import cityBackgound from '../../assets/backgrounds/earn-asset.png';
import noPositionsBg from '../../assets/backgrounds/no-position.png';
import CardViewSlider from '../../components/common/CardView/CardView';
import {
  FullScreenWidthContainer,
  MainContainer,
} from '../../components/common/MainContainers';
import { SwitchNetworkBanner } from '../../components/common/SwitchNetworkBanner/SwitchNetworkBanner';
import { EarnAssetSummaryCard } from '../../components/EarnAssetPage/EarnAssetSummaryCard';
import ListViewBlock from '../../components/EarnAssetPage/ListView';
import NoPositions from '../../components/NoPositions/NoPositions';
import { Routes, subRoutes } from '../../core/config/routes';
import { USDCoin } from '../../core/config/tokenNames';
import { WalletContext } from '../../core/config/walletContext';
import { useChainName } from '../../core/hooks/useChain';
import {
  LargeTotalCardType,
  MakerPositionType,
  PositionExtendedType,
} from '../../core/interfaces';
import { MakerPositionService } from '../../core/services/makerPositionService';
import { useAppSelector } from '../../core/state/hooks';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';
import {
  AssetCardBottomBackground,
  AssetCardTopBackground,
  AssetCardWrapper,
  HubStatusWrapper,
  PositionsWrapper,
  RadiatorBottomStep,
  RadiatorStepperLineWrapper,
  RadiatorStepperWrapper,
  RadiatorTopStep,
} from '../StakingAssetPage/styles';
import {
  RadiatorOrder,
  RadiatorStepperLineProps,
  RadiatorStepperProps,
} from '../StakingAssetPage/types';

const RadiatorStepperLine: React.FC<RadiatorStepperLineProps> = ({
  stepSize,
}) => {
  return (
    <RadiatorStepperLineWrapper stepSize={stepSize}>
      <RadiatorTopStep />
      <RadiatorBottomStep />
    </RadiatorStepperLineWrapper>
  );
};

const RadiatorStepper: React.FC<RadiatorStepperProps> = ({ order }) => {
  return (
    <RadiatorStepperWrapper order={order}>
      <RadiatorStepperLine stepSize="2rem" />
      <RadiatorStepperLine stepSize="1.5rem" />
      <RadiatorStepperLine stepSize="0.75rem" />
      <RadiatorStepperLine />
    </RadiatorStepperWrapper>
  );
};

export const IconWrap = styled.span`
  cursor: pointer;
  z-index: 2;
  opacity: 0.7;

  &.active {
    opacity: 1;
  }
`;

const EarnAsset = () => {
  const makerService = MakerPositionService.getInstance();
  const { chainId } = useWeb3React();
  const { isMobile } = useMatchBreakpoints();
  const tokensDetails = useAppSelector((state) => state.coin.coinDetails);
  const isWalletConnected = useContext(WalletContext).isWalletConnected;

  const navigate = useNavigate();

  const chainName = useChainName();

  const [avgRisk, setAvgRisk] = useState<number>(0);

  const [userPositions, setUserPositions] =
    useState<Map<string, PositionExtendedType<MakerPositionType>[]>>();

  const [
    makerPositionsTotalForLargeCardData,
    setMakerPositionsTotalForLargeCardData,
  ] = useState<LargeTotalCardType | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { resultForLargeCard } =
          await makerService.calculateMakerTotalPositionsData(tokensDetails);

        const positions = await makerService.getOpenPositions();

        setAvgRisk(calculateAvgRisk(positions));

        setUserPositions(positions);
        setMakerPositionsTotalForLargeCardData(resultForLargeCard);
      } catch (e) {
        console.error('error', e);
        setMakerPositionsTotalForLargeCardData(null);
        setUserPositions(undefined);
      }
    })();
  }, [chainId, tokensDetails]);

  useEffect(() => {
    if (!isWalletConnected) navigate(Routes.Dashboard);
  }, [isWalletConnected]);

  const calculateAvgRisk = (
    positions: Map<string, PositionExtendedType<MakerPositionType>[]>,
  ) => {
    const riskArray = Array.from(positions.keys())
      .map((key) => positions?.get(key)?.map((v) => v.tier))
      .flat()
      .map((v) => v ?? 0);
    if (riskArray.length === 0) return 0;
    const sum = riskArray.reduce((prev, curr) => (prev += curr), 0);
    return Math.ceil(sum / riskArray.length);
  };

  /*const onAutoRenewToggle = async (posId: number): Promise<void> => {
    await makerService.toggleAutoRenew(
      posId,
      USDCoin,
      { name: 'WETH', decimals: 18, symbol: 'WETH' }, // todo!
    );
  };*/
  const [cardView, setCardView] = useState<boolean>(false);

  const isPosition = useMemo(() => {
    return userPositions && Array.from(userPositions.keys()).length;
  }, [userPositions]);
  return (
    <FullScreenWidthContainer>
      <SwitchNetworkBanner
        page="assets/earn"
        slides={[]}
        path={['Earn', 'USDC']}
      />
      <AssetCardWrapper bg>
        <AssetCardTopBackground />
        <MainContainer width={!isMobile ? '1100px' : ''}>
          <EarnAssetSummaryCard
            total={{
              amount: formatStringifyNumberToDot(
                makerPositionsTotalForLargeCardData?.totalAmountInUSDC ?? '0',
                2,
              ),
              amountInUSD: formatStringifyNumberToDot(
                makerPositionsTotalForLargeCardData?.totalAmountInUSDC ?? '0',
                2,
              ),
            }}
            averageRisk={{
              amount: formatStringifyNumberToDot(avgRisk.toString(), 1),
              percent: '0', // todo
            }}
            idle={{
              amount: formatStringifyNumberToDot(
                makerPositionsTotalForLargeCardData?.assetBalanceInUSDC ?? '0',
                2,
              ),
              amountInUSD: formatStringifyNumberToDot(
                makerPositionsTotalForLargeCardData?.assetBalanceInUSDC ?? '0',
                2,
              ),
            }}
            earning={{
              amount: formatStringifyNumberToDot(
                makerPositionsTotalForLargeCardData?.investedAmountInUSDC ??
                  '0',
                2,
              ),
              amountInUSD: formatStringifyNumberToDot(
                makerPositionsTotalForLargeCardData?.investedAmountInUSDC ??
                  '0',
                2,
              ),
            }}
            positions={makerPositionsTotalForLargeCardData?.positionsCount ?? 0}
          />
        </MainContainer>

        <AssetCardBottomBackground />
        <HubStatusWrapper>
          <RadiatorStepper order={RadiatorOrder.left} />

          <Pyramid
            isHubConnected={!!(isPosition && userPositions)}
            currentHub={HubPages.earn}
          />

          <RadiatorStepper order={RadiatorOrder.right} />
        </HubStatusWrapper>
      </AssetCardWrapper>
      <PositionsWrapper
        backgroundImage={isPosition ? cityBackgound : noPositionsBg}
        hasPosition={!!isPosition}
      >
        {isPosition && (
          <FlexWithGap
            gap="24px"
            marginBottom={cardView ? '-2.5rem !important' : ''}
            justifyContent="flex-end"
            maxWidth="970px"
            margin="auto"
          >
            <IconWrap
              className={(cardView && 'active') || ''}
              onClick={() => {
                setCardView(true);
              }}
            >
              <CardView variant={IconSize.L} />
            </IconWrap>
            <IconWrap
              className={(!cardView && 'active') || ''}
              onClick={() => setCardView(false)}
            >
              <ListView variant={IconSize.L} />
            </IconWrap>
          </FlexWithGap>
        )}
        <MainContainer maxWidth="948px !important">
          {isPosition && userPositions && !cardView ? (
            Array.from(userPositions.keys()).map((key) => {
              return (userPositions?.get(key ?? '') ?? []).map((pos) => (
                <ListViewBlock
                  key={pos.id}
                  position={pos}
                  token={tokensDetails[pos.tokenSymbol]}
                />
              ));
            })
          ) : isPosition && userPositions && cardView ? (
            <CardViewSlider
              minHeightMobile="1080px"
              posLength={
                makerPositionsTotalForLargeCardData
                  ? makerPositionsTotalForLargeCardData.positionsCount
                  : 1
              }
            >
              {Array.from(userPositions.keys()).map((key) => {
                return (userPositions?.get(key ?? '') ?? []).map((pos, idx) => {
                  return (
                    <SwiperSlide key={idx}>
                      <ListViewBlock
                        cardView={true}
                        key={pos.id}
                        position={pos}
                        token={tokensDetails[pos.tokenSymbol]}
                      />
                    </SwiperSlide>
                  );
                });
              })}
            </CardViewSlider>
          ) : makerPositionsTotalForLargeCardData?.totalAmountInUSDC !== '0' ? (
            <NoPositions
              heading="WANNA PLAY?"
              text="Open some positions to flip the game!"
              btnName="Earn"
              route={() => {
                navigate(`${Routes.Earn}/${chainName}/${subRoutes.Select}`, {
                  state: { token: USDCoin },
                });
              }}
            />
          ) : (
            <NoPositions
              heading="INSERT COINS"
              text="You've got to have some to earn some, dude. Load some coins!"
            />
          )}
        </MainContainer>
      </PositionsWrapper>
      <AssetFooter />
    </FullScreenWidthContainer>
  );
};

export default EarnAsset;
