import {
  Box,
  Button,
  ConfirmationBoxFixed,
  DepositButton,
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  Input,
  InputGroup,
  lightColors,
  MediumText,
  ProtectFloorCard,
  ProtectTermCard,
  Slider,
  SmallText,
  StyledBumperLogo,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import ETHIcon from '@bumper-dao/ui-kit/dist/images/32px/tokens/ETH.svg';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { ConfirmProtectDepositProps, SelectProtectDepositProps } from './types';

import NotificationIcon from '../../assets/32px/notification.png';
import BUMPIcon from '../../assets/32px/tokens/bump.svg';
import ETHIconSmall from '../../assets/32px/tokens/ETH.svg';
import ETHIconBig from '../../assets/48px/tokens/ETH.svg';
import { MainContainer } from '../../components/common/MainContainers';
import { termsAndConditionsLink } from '../../core/config/links';
import { Routes, subRoutes } from '../../core/config/routes';
import { BUMP, ETH, WETH } from '../../core/config/tokenNames';
import { WalletContext } from '../../core/config/walletContext';
import { useChainName } from '../../core/hooks/useChain';
import {
  FixedProtectionPeriodType,
  FloorOptionType,
  IBondPositionData,
} from '../../core/interfaces';
import { BondService } from '../../core/services/bondService';
import { MarketService } from '../../core/services/marketService';
import { ProtocolConfigService } from '../../core/services/protocolConfigService';
import { TakerPositionService } from '../../core/services/takerPositionService';
import { setIsOpenedWalletModal } from '../../core/state/actions/walletModalActions';
import { useAppSelector } from '../../core/state/hooks';
import {
  convertBumpToDollars,
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../core/utils/helpers';
import {
  DepositLiquditySwitchBlock,
  DepositLiquidityBottomInfoCard,
} from '../EarnDepositFlow/AddLiqudityDeposit';
import {
  DepositLiqudityBalanceCard,
  ProtectBottomBalanceGrid,
} from '../EarnDepositFlow/styles';
import { DepositLiqudityBalance } from '../EarnDepositFlow/types';
import { useEthQuery } from '../Market/hooks';

export const SelectProtectDeposit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  const locationState = location.state as ConfirmProtectDepositProps;

  const bondService = BondService.getInstance();
  const marketService = MarketService.getInstance();
  const takerPositionService = TakerPositionService.getInstance();
  const protocolService = ProtocolConfigService.getInstance();

  const { chainId } = useWeb3React();
  const chainName = useChainName();

  const tokensDetails = useAppSelector((state) => state.coin.coinDetails);
  const { TOKEN_DETAILS } = useAppSelector((state) => state.contractsAddresses);

  const optionsConfig: DepositLiqudityBalance[] = [
    {
      key: ETH.symbol,
      token: ETH,
      value: `${ETH.symbol} ($${formatStringifyNumberToDot(
        tokensDetails[ETH.symbol].price,
        2,
      )})`,
      balance: tokensDetails[ETH.symbol].balance,
      balanceInNumber: parseFloat(tokensDetails[ETH.symbol].balance),
      icon: ETHIconBig,
      price: tokensDetails[ETH.symbol].price,
    },
    {
      key: WETH.symbol,
      token: WETH,
      value: `${WETH.symbol} ($${formatStringifyNumberToDot(
        tokensDetails[WETH.symbol].price,
        2,
      )})`,
      balance: tokensDetails[WETH.symbol].balance,
      balanceInNumber: parseFloat(tokensDetails[WETH.symbol].balance),
      icon: ETHIconBig,
      price: tokensDetails[WETH.symbol].price,
    },
  ];

  const [ethMarketAddress, setEthMarketAddress] = useState<string>(
    ethers.constants.AddressZero,
  );

  useEffect(() => {
    protocolService
      .getMarket(TOKEN_DETAILS[ETH.symbol].address)
      .then((addr) => setEthMarketAddress(addr))
      .catch((e) => console.log('Market error:', e));
  }, []);

  const [option, setOption] = useState<DepositLiqudityBalance>(
    locationState?.token.symbol === WETH.symbol
      ? optionsConfig[1]
      : optionsConfig[0],
  );

  const [autoRenew, setAutoRenew] = useState<boolean>(
    !!locationState?.autoRenew,
  );
  const [notEnoughBump, setNotEnoughBump] = useState<boolean>(false);
  const [protectAmount, setProtectAmount] = useState<number>(
    parseFloat(locationState?.protectAmount || '0'),
  );

  const [bumpCoordination, setBumpCoordination] = useState<number>(0);
  const [bumpBoost, setBumpBoost] = useState<number>(0);

  const [activeProtectFloorValue, setActiveProtectFloorValue] =
    useState<number>(locationState?.activeProtectFloorIndex || -1);
  const [fixedProtectTermValue, setFixedProtectTermValue] = useState<number>(
    locationState?.activeProtectTermIndex || -1,
  );

  const [protectFloorValuesOptions, setProtectFloorValuesOptions] = useState<
    FloorOptionType[]
  >([]);
  const [fixedProtectTermOptions, setFixedProtectTermOptions] = useState<
    FixedProtectionPeriodType[]
  >([]);

  const [bumpBondValue, setBumpBondValue] = useState<IBondPositionData>({
    toLock: ethers.constants.Zero,
    toTransfer: ethers.constants.Zero,
    toReduce: ethers.constants.Zero,
  });
  const [bumpBondInDollars, setBumpBondInDollars] = useState<string>('0.00');

  const onCancel = () => {
    navigate(`${Routes.Protect}/${chainName}`);
  };

  const onNext = () => {
    const state: ConfirmProtectDepositProps = {
      token: tokensDetails[option.token.symbol],
      icon: ETHIconSmall,
      protectAmount: protectAmount.toString(),
      protectFloor:
        activeProtectFloorValue >= 0
          ? protectFloorValuesOptions[activeProtectFloorValue].floorValue
          : 0,
      activeProtectFloorIndex: activeProtectFloorValue,
      protectTerm:
        fixedProtectTermValue >= 0
          ? fixedProtectTermOptions[fixedProtectTermValue]
              .fixedProtectionPeriodValue
          : 0,
      activeProtectTermIndex: fixedProtectTermValue,
      autoRenew,
      bondAmount: bumpBondValue,
      bondAmountInUSD: bumpBondInDollars,
      incentiveBoost: bumpBoost,
      incentiveNetwork: bumpCoordination,
      linkPath: termsAndConditionsLink,
      protocolFee: 0,
    };

    return navigate(`${Routes.Protect}/${chainName}/${subRoutes.Confirm}`, {
      state,
      replace: true,
    });
  };

  useEffect(() => {
    Promise.all([
      marketService.getProtectFloorOptions(),
      marketService.getFixedProtectionPeriodOptions(),
    ]).then(([protectFloors, fixedProtectPeriods]) => {
      setProtectFloorValuesOptions(protectFloors);
      setFixedProtectTermOptions(fixedProtectPeriods);
    });
  }, []);

  const debouncedBondValue = debounce(async () => {
    const floor =
      activeProtectFloorValue - 1 >= 0 && protectFloorValuesOptions.length !== 0
        ? protectFloorValuesOptions[activeProtectFloorValue - 1].floorValue
        : 0;
    const term =
      fixedProtectTermValue - 1 >= 0 && fixedProtectTermOptions.length !== 0
        ? fixedProtectTermOptions[fixedProtectTermValue - 1]
            .fixedProtectionPeriodValue
        : 0;

    const bondValue = await bondService.calcBondSizeForTakerPosition(
      option.token,
      protectAmount.toFixed(6).toString(),
      floor,
      term,
    );
    setBumpBondValue(bondValue);

    const inDollars = await convertBumpToDollars('1');

    setBumpBondInDollars(
      formatStringifyNumberToDot(
        (
          parseFloat(inDollars) *
          parseFloat(ethers.utils.formatUnits(bondValue.toLock))
        ).toString(),
        2,
      ),
    );
    setNotEnoughBump(
      parseFloat(tokensDetails[BUMP.symbol].balance) <
        parseFloat(ethers.utils.formatUnits(bondValue.toLock)),
    );
  }, 700);

  useEffect(() => {
    debouncedBondValue();
    takerPositionService
      .getIncentiveValus(
        chainId ?? 1,
        TOKEN_DETAILS[WETH.symbol].address,
        BigNumber.from(ethers.utils.parseUnits(protectAmount.toString())),
      )
      .then(({ coordination, boost }) => {
        setBumpBoost(boost);
        setBumpCoordination(coordination);
      });
  }, [protectAmount, activeProtectFloorValue, fixedProtectTermValue]);

  return (
    <SelectProtectDepositContainer
      protectTerms={fixedProtectTermOptions.map(
        (v) => v.fixedProtectionPeriodValue,
      )}
      protectFloors={protectFloorValuesOptions.map((v) => v.floorValue)}
      requiredBumpAmount={formatStringifyNumberToDot(
        ethers.utils.formatUnits(bumpBondValue.toLock),
        4,
      )}
      requiredBumpAmountInUsd={bumpBondInDollars}
      balanceBlock={{
        balances: optionsConfig,
        activeOption: option,
        onValueChange: (newValue) => {
          setOption(newValue);
          setProtectAmount(0);
        },
        onWalletConnectClick: () => {
          dispatch(setIsOpenedWalletModal(true));
        },
        isActive: isWalletConnected,
      }}
      onNextClick={onNext}
      onCancelClick={onCancel}
      outValue={{
        protectAmount: protectAmount,
        protectTerm: fixedProtectTermValue,
        protectFloor: activeProtectFloorValue,
        autoRenew: autoRenew,
      }}
      setProtectAmount={setProtectAmount}
      setActiveProtectFloorValue={setActiveProtectFloorValue}
      setFixedProtectTermValue={setFixedProtectTermValue}
      setAutoRenew={setAutoRenew}
      token={tokensDetails[option.token.symbol]}
      boost={bumpBoost}
      coordination={bumpCoordination}
      marketAddress={ethMarketAddress}
      notEnoughBump={notEnoughBump}
    />
  );
};

const SelectProtectDepositContainer: React.FC<SelectProtectDepositProps> = ({
  outValue,
  ...props
}) => {
  const { isMobile } = useMatchBreakpoints();
  const { ethYearValue } = useEthQuery(props.marketAddress);
  const isNextButtonEnabled = useMemo<boolean>(() => {
    return (
      outValue.protectAmount > 0 &&
      outValue.protectFloor >= 0 &&
      outValue.protectTerm >= 0
    );
  }, [outValue]);

  const min = 0.00001;

  return (
    <MainContainer
      maxWidth={isMobile ? '100%' : '708px !important'}
      style={{ gap: 0 }}
    >
      <Flex width="100%" justifyContent="start">
        <Heading
          as="h1"
          scale="xxl"
          color="secondary.white"
          mb="24px"
          width="60%"
        >
          Protect
        </Heading>
      </Flex>
      <FlexWithGap
        gap="24px"
        flexDirection="column"
        alignItems="center"
        width="100%"
        mb="32px"
      >
        <DepositLiquditySwitchBlock
          balances={props.balanceBlock.balances}
          isActive={props.balanceBlock.isActive}
          activeOption={props.balanceBlock.activeOption}
          onValueChange={(newOption) => {
            props.balanceBlock.onValueChange(newOption);
          }}
          onWalletConnectClick={() => props.balanceBlock.onWalletConnectClick()}
        />
        <ConfirmationBoxFixed
          padding={isMobile ? '24px 20px' : '24px 48px 24px 32px'}
          disabled={!props.balanceBlock.isActive}
        >
          <Flex flexDirection="column">
            <Heading scale="xl" color="secondary.white" mb="8px">
              Protection Amount
            </Heading>
            <MediumText
              fontFamily={isMobile ? 'Roboto' : 'Roboto Mono'}
              color="typography.earnDepositTextDescription"
              mb="40px"
            >
              Move the slider to choose how much of your balance you want to
              protect or enter the amount in the field below.
            </MediumText>
            <Slider
              name="protectAmount"
              min={min}
              max={props.balanceBlock.activeOption.balanceInNumber}
              value={outValue.protectAmount}
              icon={ETHIcon}
              onValueChanged={(newValue) => {
                props.setProtectAmount(newValue);
              }}
              thumbColor={lightColors.secondary.white}
            />
            <FlexWithGap
              gap={'28px'}
              justifyContent="space-between"
              alignItems="center"
            >
              <FlexWithGap gap="20px" alignItems="center">
                <Box maxWidth="240px" ml="8px" mt="5px">
                  <InputGroup
                    startIcon={<img src={ETHIconSmall} alt="ETH" />}
                    units={props.token.symbol}
                  >
                    <Input
                      fontFamily="Roboto Mono"
                      fontWeight={500}
                      type="text"
                      value={outValue.protectAmount}
                      isAllowed={(e) =>
                        e.floatValue
                          ? e.floatValue <=
                            props.balanceBlock.activeOption.balanceInNumber
                          : false
                      }
                      onValueChange={(e) => {
                        if (e.floatValue) props.setProtectAmount(e.floatValue);
                      }}
                    />
                  </InputGroup>
                </Box>
                {!isMobile && (
                  <FlexWithGap gap="4px" alignItems="center">
                    <MediumText color="typography.placeholder">
                      Current Value:
                    </MediumText>
                    <MediumText fontWeight={500} color="typography.placeholder">
                      ${''}
                      {formatStringifyNumberToDot(
                        (
                          parseFloat(props.token.price) * outValue.protectAmount
                        ).toString(),
                        2,
                      )}{' '}
                    </MediumText>
                  </FlexWithGap>
                )}
              </FlexWithGap>

              <DepositButton
                secondary
                selected={
                  outValue.protectAmount ===
                  props.balanceBlock.activeOption.balanceInNumber
                }
                size={IconSize.L}
                style={{
                  height: '32px',
                }}
                onClick={() => {
                  props.setProtectAmount(
                    props.balanceBlock.activeOption.balanceInNumber,
                  );
                }}
              >
                MAX
              </DepositButton>
            </FlexWithGap>
            {isMobile && (
              <FlexWithGap gap="4px" alignItems="center" mt="20px">
                <MediumText color="typography.placeholder">
                  Current Value:
                </MediumText>
                <MediumText fontWeight={500} color="typography.placeholder">
                  ${''}
                  {formatStringifyNumberToDot(
                    (
                      parseFloat(props.token.price) * outValue.protectAmount
                    ).toString(),
                    2,
                  )}{' '}
                </MediumText>
              </FlexWithGap>
            )}
            <DepositLiqudityBalanceCard mr="-5px">
              <FlexWithGap
                gap="14px"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'start' : 'center'}
              >
                <FlexWithGap gap="8px" alignItems="center">
                  <StyledBumperLogo
                    style={{ width: '32px', height: '32px' }}
                    src={BUMPIcon}
                    alt="bump"
                  />
                  <Flex>
                    <MediumText bold color="secondary.white">
                      Required Bond:
                    </MediumText>
                    {isMobile && <Tooltip tooltipValue="BUMP" />}
                  </Flex>
                </FlexWithGap>
                {props.requiredBumpAmount !== '' ? (
                  <FlexWithGap gap="8px" alignItems="center">
                    <MediumText bold color="primary1.vividTangelo">
                      {props.requiredBumpAmount} BUMP
                    </MediumText>
                    <Flex>
                      <SmallText color="primary2.cambridgeBlue">
                        {`(~ $${props.requiredBumpAmountInUsd})`}
                      </SmallText>
                      {!isMobile && <Tooltip tooltipValue="BUMP" />}
                    </Flex>
                  </FlexWithGap>
                ) : null}
              </FlexWithGap>
            </DepositLiqudityBalanceCard>
            {props.notEnoughBump && (
              <FlexWithGap gap="8px" mt="15px">
                <img
                  src={NotificationIcon}
                  width="32px"
                  height="32px"
                  alt="Notification"
                />
                <SmallText
                  color="typography.placeholder"
                  fontWeight={500}
                  fontFamily={isMobile ? 'Roboto' : 'Roboto Mono'}
                >
                  Insufficient BUMP balance. The protocol will automatically
                  purchase your refundable BUMP Bond using deposited funds on
                  your behalf. Confirmation details are on the next screen.
                </SmallText>
              </FlexWithGap>
            )}
          </Flex>
        </ConfirmationBoxFixed>
        <ProtectFloorCard
          protectFloors={props.protectFloors}
          initialIndex={outValue.protectFloor}
          onChange={(floor) => {
            props.setActiveProtectFloorValue(floor);
          }}
        />
        <ProtectTermCard
          protectTerms={props.protectTerms}
          initialIndex={outValue.protectTerm}
          onChange={(index, ar) => {
            props.setFixedProtectTermValue(index);
            props.setAutoRenew(ar);
          }}
          disabled={
            !props.balanceBlock.isActive ||
            outValue.protectFloor === -1 ||
            outValue.protectAmount === 0
          }
        />
        <ConfirmationBoxFixed
          disabled={outValue.protectAmount === 0}
          p={isMobile ? '24px 0' : '0'}
          background="transparent"
        >
          <ProtectBottomBalanceGrid>
            <DepositLiquidityBottomInfoCard
              title="Deposit Value"
              value={`$${formatStringifyNumberToDot(
                (
                  outValue.protectAmount * parseFloat(props.token.price)
                ).toString(),
                2,
              )}`}
              order={1}
            />
            <DepositLiquidityBottomInfoCard
              title="Floor Price"
              value={`$${formatStringifyNumberToDot(
                (
                  (props.protectFloors[
                    outValue.protectFloor < 0 ? 0 : outValue.protectFloor
                  ] /
                    100) *
                  parseFloat(props.token.price)
                ).toString(),
                2,
              )}`}
              order={isMobile ? 3 : 2}
            />
            <DepositLiquidityBottomInfoCard
              title="Protected Value"
              value={`$${formatStringifyNumberToDot(
                (
                  ((outValue.protectAmount *
                    props.protectFloors[
                      outValue.protectFloor < 0 ? 0 : outValue.protectFloor
                    ]) /
                    100) *
                  parseFloat(props.token.price)
                ).toString(),
                2,
              )}`}
              order={isMobile ? 4 : 3}
            />
            <DepositLiquidityBottomInfoCard
              title="Current Price"
              value={`$${formatStringifyNumberToDot(props.token.price, 2)}`}
              order={isMobile ? 2 : 4}
            />
            <DepositLiquidityBottomInfoCard
              title="Current Premium Rate"
              value={`${formatWeiToNormalString(
                BigNumber.from(ethYearValue.ciTakerAsset),
                16,
                2,
              )}%`}
              order={isMobile ? 6 : 5}
            />
            <DepositLiquidityBottomInfoCard
              title={`Premium Rate (12 ${isMobile ? 'M.' : 'Months'})`}
              value={`${formatWeiToNormalString(
                BigNumber.from(ethYearValue.ciTakerAsset),
                16,
                2,
              )}%`}
              order={isMobile ? 8 : 6}
            />
            <DepositLiquidityBottomInfoCard
              title="Protocol Risk Rating"
              value={(outValue.protectFloor === 0
                ? 0
                : Math.abs(
                    70 -
                      props.protectFloors[
                        outValue.protectFloor < 0 ? 0 : outValue.protectFloor
                      ],
                  )
              ).toString()}
              order={isMobile ? 5 : 7}
            />
            <DepositLiquidityBottomInfoCard
              title={`Protocol Risk ${isMobile ? 'Avg.' : 'Average'}`}
              value={BigNumber.from(ethYearValue.openedPositionsTierSum)
                .div(
                  ethYearValue.totalOpenedMakerPositions > 0
                    ? ethYearValue.totalOpenedMakerPositions
                    : 1,
                )
                .toString()}
              order={isMobile ? 7 : 8}
            />
            <DepositLiquidityBottomInfoCard
              title="BUMP Incentive"
              value={formatStringifyNumberToDot(
                (props.boost + props.coordination).toString(),
                4,
              )}
              label="BUMP"
              order={9}
            />
          </ProtectBottomBalanceGrid>
        </ConfirmationBoxFixed>
      </FlexWithGap>
      <Flex justifyContent="space-between" width="100%">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={props.onCancelClick}
        >
          Cancel
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={props.onNextClick}
          disabled={!isNextButtonEnabled}
        >
          Next
        </Button>
      </Flex>
    </MainContainer>
  );
};
