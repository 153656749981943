import {
  Box,
  Button,
  ConfirmationBoxFixed,
  ConfirmHorizontalDivider,
  DepositButton,
  Flex,
  FlexWithGap,
  GridWithGap,
  Heading,
  IconSize,
  Input,
  InputGroup,
  lightColors,
  MediumText,
  RiskTierCard,
  Select,
  Slider,
  SmallText,
  StyledBumperLogo,
  Toggle,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import USDCIconSmall from '@bumper-dao/ui-kit/dist/images/32px/tokens/USDC-croped.svg';
import USDCIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/usdc.png';
import { BigNumber } from 'ethers';
import React from 'react';

import {
  DepositLiqudityBalanceCard,
  DepositLiqudityBottomBalanceCard,
  DepositLiqudityBottomBalanceGrid,
  DepositLiqudityWalletContainer,
  DepositLiquidityWalletText,
} from './styles';
import {
  DepositBalanceBlockWithWallet,
  DepositLiqudityBalance,
  DepositLiqudityEarningPeriodProps,
  DepositLiquidityBottomInfoCardProps,
  DepositLiquidityInProps,
} from './types';

import NotificationIcon from '../../assets/32px/notification.png';
import BUMPIcon from '../../assets/32px/tokens/bump.svg';
import { MainContainer } from '../../components/common/MainContainers';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../core/utils/helpers';
import { useEthQuery } from '../Market/hooks';

export const DepositLiquidityBottomInfoCard: React.FC<
  DepositLiquidityBottomInfoCardProps
> = ({ title, value, label, order }) => (
  <Box style={{ wordWrap: 'normal', order: order ?? '' }}>
    <SmallText
      color="primary2.cambridgeBlue"
      mb="8px"
      fontWeight={500}
      style={{ whiteSpace: 'nowrap' }}
    >
      {' '}
      {title}{' '}
    </SmallText>
    <DepositLiqudityBottomBalanceCard>
      <MediumText color="secondary.white"> {value} </MediumText>
      {label && (
        <MediumText color="primary2.cambridgeBlue" ml="4px">
          {' '}
          {label}
        </MediumText>
      )}
    </DepositLiqudityBottomBalanceCard>
  </Box>
);

const DepositLiqudityEarningPeriod: React.FC<
  DepositLiqudityEarningPeriodProps
> = (props) => {
  const breakpoints = useMatchBreakpoints();

  return (
    <>
      {!breakpoints.isMobile && (
        <Flex flexDirection="column" width="100%">
          <Flex mb="36px" justifyContent="space-between">
            <Flex flexDirection="column">
              <Heading as="h3" scale="xl" color="secondary.white" mb="8px">
                Earning Period
              </Heading>
              <MediumText fontSize="16px" color="typography.earnDepositValue">
                Choose your earning term.
              </MediumText>
            </Flex>
            <FlexWithGap gap="20px" alignItems="center">
              <Flex alignItems="center">
                <MediumText color="typography.earnDepositValue">
                  Auto-renew
                </MediumText>
                <Tooltip tooltipValue="" />
              </Flex>
              <Toggle onToggle={props.handleAutoRenew} />
            </FlexWithGap>
          </Flex>

          <FlexWithGap alignItems="center" gap="27px" ml="8px" mr="8px">
            {props.earningPeriods.map((item, i) => (
              <DepositButton
                key={i}
                secondary
                selected={props.activeEarningPeriodIndex === i}
                size={IconSize.XL}
                p="4px"
                style={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                  props.handleEarningPeriod(i);
                }}
              >
                {`${item} days`}
              </DepositButton>
            ))}
          </FlexWithGap>
        </Flex>
      )}

      {breakpoints.isMobile && (
        <Flex flexDirection="column" width="100%">
          <Flex flexDirection="column" mb="20px">
            <Heading as="h3" scale="lg" color="secondary.white">
              Earning Period
            </Heading>
            <MediumText
              fontFamily="Roboto"
              color="typography.earnDepositValue"
              mt="8px"
            >
              Choose your earning term.
            </MediumText>
          </Flex>
          <GridWithGap
            gridTemplateColumns="repeat(2, 130px)"
            gridTemplateRows="repeat(3, 1fr)"
            gridRowGaps="32px"
            ml="8px"
          >
            {props.earningPeriods.map((item, i) => (
              <DepositButton
                key={i}
                secondary
                selected={props.activeEarningPeriodIndex === i}
                size={IconSize.L}
                width="fit-content"
                onClick={() => {
                  props.handleEarningPeriod(i);
                }}
              >
                {`${item} days`}
              </DepositButton>
            ))}
          </GridWithGap>
          <Box mt="32px" mb="20px">
            <ConfirmHorizontalDivider color="primary2.aqua" />
          </Box>
          <Flex>
            <Tooltip
              tooltipValue=""
              conntainerStyles={{
                marginRight: '20px',
              }}
            >
              <MediumText color="typography.earnDepositValue">
                Auto-renew
              </MediumText>
            </Tooltip>
            <Toggle onToggle={props.handleAutoRenew} />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export const DepositLiquditySwitchBlock: React.FC<
  DepositBalanceBlockWithWallet
> = (props) => {
  const { isMobile } = useMatchBreakpoints();

  return (
    <>
      {!props.isActive && (
        <ConfirmationBoxFixed
          p={isMobile ? '24px 20px' : '24px 32px'}
          justifyContent={isMobile ? 'center' : 'auto'}
        >
          <DepositLiqudityWalletContainer
            alignItems="center"
            justifyContent={isMobile ? 'center' : 'auto'}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <DepositLiquidityWalletText>
              Connect your wallet:
            </DepositLiquidityWalletText>
            <Button
              primary
              size={IconSize.L}
              onClick={props.onWalletConnectClick}
            >
              Connect
            </Button>
          </DepositLiqudityWalletContainer>
        </ConfirmationBoxFixed>
      )}
      {props.isActive && (
        <ConfirmationBoxFixed
          padding={isMobile ? '24px 20px' : '24px 32px'}
          justifyContent={isMobile ? 'center' : 'auto'}
        >
          <Flex
            flexDirection="column"
            width="100%"
            justifyContent={isMobile ? 'center' : 'auto'}
          >
            <Box width="fit-content" m={isMobile ? 'auto' : '0'}>
              <Select
                selectWidth="100%"
                width="100%"
                value={props.activeOption}
                options={props.balances}
                headingScale={isMobile ? 'sm' : 'md'}
                withIcon
                topValue="12px"
                onValueChanged={(newValue) => {
                  props.onValueChange(newValue as DepositLiqudityBalance);
                }}
              />
              <DepositLiqudityBalanceCard flexDirection="column">
                <Heading
                  as="h4"
                  scale="md"
                  color="primary2.cambridgeBlue"
                  mb="8px"
                >
                  Balance
                </Heading>
                <Flex color="primary2.midnightGreen" alignItems="center">
                  <MediumText color="secondary.white">
                    {formatStringifyNumberToDot(
                      (
                        props.activeOption as DepositLiqudityBalance
                      ).balanceInNumber.toString(),
                      4,
                    )}
                  </MediumText>
                  <SmallText color="primary2.cambridgeBlue" ml="4px">
                    {props.activeOption.key}
                  </SmallText>
                  <SmallText ml="6px" color="primary2.cambridgeBlue">
                    (
                    {props.activeOption &&
                      '$' +
                        formatStringifyNumberToDot(
                          (
                            parseFloat(
                              (props.activeOption as DepositLiqudityBalance)
                                ?.price ?? '0',
                            ) *
                            (props.activeOption as DepositLiqudityBalance)
                              .balanceInNumber
                          ).toFixed(2),
                          2,
                        )}
                    )
                  </SmallText>
                </Flex>
              </DepositLiqudityBalanceCard>
            </Box>
          </Flex>
        </ConfirmationBoxFixed>
      )}
    </>
  );
};

const AddLiqudityDeposit: React.FC<DepositLiquidityInProps> = ({
  outValue,
  ...props
}) => {
  const { ethYearValue } = useEthQuery(props.marketAddress);
  const { isMobile } = useMatchBreakpoints();
  const min = 0;

  return (
    <MainContainer
      maxWidth={isMobile ? '100%' : '708px !important'}
      style={{ gap: '0' }}
    >
      <Flex
        width="100%"
        justifyContent="start"
        maxWidth={isMobile ? '200px' : ''}
        marginRight={isMobile ? 'auto' : ''}
      >
        <Heading as="h1" scale="xxl" color="secondary.white" mb="24px">
          Deposit Liquidity
        </Heading>
      </Flex>
      <FlexWithGap
        gap="24px"
        flexDirection="column"
        alignItems="center"
        width="100%"
        mb="32px"
      >
        <DepositLiquditySwitchBlock
          balances={props.balanceBlock.balances}
          isActive={props.balanceBlock.isActive}
          activeOption={props.balanceBlock.activeOption}
          onValueChange={(newOption) => {
            props.balanceBlock.onValueChange(newOption);
          }}
          onWalletConnectClick={() => props.balanceBlock.onWalletConnectClick()}
        />
        <ConfirmationBoxFixed
          padding={isMobile ? '24px 20px' : '24px 48px 24px 32px'}
          disabled={!props.balanceBlock.isActive}
        >
          <Flex flexDirection="column">
            <Heading as="h3" scale="xl" color="secondary.white" mb="8px">
              Deposit Amount
            </Heading>
            <MediumText
              fontSize={isMobile ? '15px' : '16px'}
              width="299px"
              fontFamily={isMobile ? 'Roboto' : 'Roboto Mono'}
              color="typography.earnDepositTextDescription"
              mb="48px"
            >
              Move the slider to choose how much you want to deposit or enter
              the amount in the field below. You must do either to proceed to
              the next step.
            </MediumText>

            <Slider
              name="stable amount"
              min={min}
              max={props.balanceBlock.activeOption.balanceInNumber}
              value={outValue.depositAmount}
              icon={USDCIconSmall}
              thumbColor={lightColors.secondary.darkBlue}
              onValueChanged={(newValue) => {
                props.setProtectAmount(newValue);
              }}
            />
            <Flex justifyContent="space-between">
              <FlexWithGap gap="20px" alignItems="center">
                <Box maxWidth="240px" ml="8px" mt="-4px">
                  <InputGroup
                    minWidth={isMobile ? '220px' : ''}
                    startIcon={
                      <img
                        src={USDCIcon}
                        alt="USDC"
                        style={{
                          height: '32px',
                        }}
                      />
                    }
                    units="USDC"
                  >
                    <Input
                      type="text"
                      value={outValue.depositAmount}
                      isAllowed={(e) =>
                        e.floatValue
                          ? e.floatValue <=
                            props.balanceBlock.activeOption.balanceInNumber
                          : false
                      }
                      onValueChange={(e) => {
                        if (e.floatValue) props.setProtectAmount(e.floatValue);
                      }}
                    />
                  </InputGroup>
                </Box>
                {!isMobile && (
                  <Flex>
                    <MediumText color="typography.placeholder">
                      Current Value:
                    </MediumText>
                    <MediumText
                      ml="4px"
                      fontWeight={500}
                      color="typography.placeholder"
                    >
                      ${''}
                      {formatStringifyNumberToDot(
                        outValue.depositAmount.toFixed(2),
                        2,
                      )}{' '}
                    </MediumText>
                  </Flex>
                )}
              </FlexWithGap>

              <DepositButton
                secondary
                selected={
                  outValue.depositAmount ===
                  props.balanceBlock.activeOption.balanceInNumber
                }
                size={IconSize.L}
                style={{
                  height: '32px',
                }}
                onClick={() => {
                  props.setProtectAmount(
                    props.balanceBlock.activeOption.balanceInNumber,
                  );
                }}
              >
                MAX
              </DepositButton>
            </Flex>
            {isMobile && (
              <Flex alignItems="center" mt="20px">
                <MediumText color="typography.placeholder">
                  Current Value:
                </MediumText>
                <MediumText
                  ml="4px"
                  fontWeight={500}
                  color="typography.placeholder"
                >
                  ${''}
                  {formatStringifyNumberToDot(
                    outValue.depositAmount.toFixed(2),
                    2,
                  )}{' '}
                </MediumText>
              </Flex>
            )}

            <DepositLiqudityBalanceCard ml="4px" mr="-4px">
              <FlexWithGap
                gap="14px"
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'start' : 'center'}
              >
                <FlexWithGap gap="8px" alignItems="center">
                  <StyledBumperLogo
                    style={{ width: '32px', height: '32px' }}
                    src={BUMPIcon}
                    alt="bump"
                  />
                  <Flex>
                    <MediumText bold color="secondary.white">
                      Required Bond:
                    </MediumText>
                    {isMobile && <Tooltip tooltipValue="BUMP" />}
                  </Flex>
                </FlexWithGap>
                {props.requiredBumpAmount !== '' ? (
                  <FlexWithGap gap="8px" alignItems="center">
                    <MediumText bold color="primary1.vividTangelo">
                      {props.requiredBumpAmount} BUMP
                    </MediumText>
                    <Flex alignItems="center">
                      <SmallText color="primary2.cambridgeBlue">
                        {`(~ $${props.requiredBumpAmountInUsd})`}
                      </SmallText>
                      {!isMobile && <Tooltip tooltipValue="BUMP" />}
                    </Flex>
                  </FlexWithGap>
                ) : null}
              </FlexWithGap>
            </DepositLiqudityBalanceCard>
            {props.notEnoughBump && (
              <FlexWithGap gap="8px" mt="15px">
                <img
                  src={NotificationIcon}
                  width="32px"
                  height="32px"
                  alt="Notification"
                />
                <SmallText
                  color="typography.placeholder"
                  fontWeight={500}
                  fontFamily={isMobile ? 'Roboto' : 'Roboto Mono'}
                >
                  Insufficient BUMP balance. The protocol will automatically
                  purchase your refundable BUMP Bond using deposited funds on
                  your behalf. Confirmation details are on the next screen.
                </SmallText>
              </FlexWithGap>
            )}
          </Flex>
        </ConfirmationBoxFixed>
        <ConfirmationBoxFixed
          padding={isMobile ? '24px 20px' : '24px 32px'}
          disabled={!props.balanceBlock.isActive}
        >
          <DepositLiqudityEarningPeriod
            earningPeriods={props.earningPeriods}
            activeEarningPeriodIndex={props.activeEarningPeriodIndex}
            handleAutoRenew={(newValue) => props.setAutoRenew(newValue)}
            handleEarningPeriod={(newValue) =>
              props.setFixedDepositPeriodValue(newValue)
            }
          />
        </ConfirmationBoxFixed>
        <RiskTierCard
          initialIndex={outValue.riskTier}
          onChange={(newValue) => props.setActiveDepositTierValue(newValue)}
          disabled={
            !props.balanceBlock.isActive ||
            props.activeEarningPeriodIndex === -1 ||
            outValue.depositAmount === 0
          }
        />

        <ConfirmationBoxFixed
          disabled={outValue.depositAmount === 0}
          px="0px"
          background="transparent"
        >
          <DepositLiqudityBottomBalanceGrid
            width="100%"
            gridTemplateColumns="repeat(3, 1fr)"
            gridTemplateRows="repeat(3, 1fr)"
          >
            <DepositLiquidityBottomInfoCard
              title="Deposit Value"
              value={`$${formatStringifyNumberToDot(
                outValue.depositAmount.toString(),
                2,
              )}`}
            />
            <DepositLiquidityBottomInfoCard
              title="Current Yield Rate"
              value={`${formatWeiToNormalString(
                BigNumber.from(ethYearValue.ciTakerAsset),
                16,
                2,
              )}%`}
            />
            <DepositLiquidityBottomInfoCard
              title="YTD Yield"
              value={`${formatWeiToNormalString(
                BigNumber.from(ethYearValue.ciMaker),
                16,
                2,
              )}%`}
            />
            <DepositLiquidityBottomInfoCard
              title="Protocol Risk Rating"
              value={(outValue.riskTier + 1).toString()}
            />
            <DepositLiquidityBottomInfoCard
              title={`Protocol Risk ${isMobile ? 'Avg.' : 'Average'}`}
              value={BigNumber.from(ethYearValue.openedPositionsTierSum)
                .div(
                  ethYearValue.totalOpenedMakerPositions > 0
                    ? ethYearValue.totalOpenedMakerPositions
                    : 1,
                )
                .toString()}
            />
            <DepositLiquidityBottomInfoCard
              title="BUMP Incentives"
              value={formatStringifyNumberToDot(
                props.bumpIncentives.toString(),
                4,
              )}
              label="BUMP"
            />
          </DepositLiqudityBottomBalanceGrid>
        </ConfirmationBoxFixed>
      </FlexWithGap>
      <Flex justifyContent="space-between" width="100%">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={props.onCancelClick}
        >
          Cancel
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={props.onNextClick}
          disabled={
            props.activeEarningPeriodIndex === -1 ||
            outValue.riskTier === -1 ||
            outValue.depositAmount === 0
          }
        >
          Next
        </Button>
      </Flex>
    </MainContainer>
  );
};

export default AddLiqudityDeposit;
