import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';

import {
  getNetworkConfigsByEnv,
  SUPPORTED_CHAINS,
} from '../config/supportedChains';
import { NetworkType } from '../interfaces';

export const useChainName = () => {
  const { chainId } = useWeb3React();
  return useMemo(() => {
    return getNetworkConfigsByEnv()[chainId ?? SUPPORTED_CHAINS[0]]
      .nameForDisplay;
  }, [chainId]);
};

export const useChain = (): NetworkType => {
  const { chainId } = useWeb3React();
  return useMemo(() => {
    return getNetworkConfigsByEnv()[chainId ?? SUPPORTED_CHAINS[0]];
  }, [chainId]);
};
