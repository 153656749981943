import {
  EthereumMainnetConfig,
  FantomConfig,
  GoerliConfig,
  BumperAlphaConfig,
  OptimismConfig,
  TimeMachineConfig,
} from './networksConfig';

import { SupportedChainIdsType } from '../interfaces';

const mainnetsSupportedChainIds: SupportedChainIdsType = {
  [1]: EthereumMainnetConfig,
  [10]: OptimismConfig,
  [250]: FantomConfig,
};

const alphaSupportedChainIds: SupportedChainIdsType = {
  [12345]: BumperAlphaConfig,
};

const betaSupportedChainIds: SupportedChainIdsType = {
  [12345]: BumperAlphaConfig,
};

const testnetsSupportedChainIds: SupportedChainIdsType = {
  [5]: GoerliConfig,
};

const timemachineSupportedChainIds: SupportedChainIdsType = {
  [31337]: TimeMachineConfig,
};

export const getNetworkConfigsByEnv = (): SupportedChainIdsType => {
  if ((process.env.REACT_APP_ENVIRONMENT as string) === 'production') {
    return mainnetsSupportedChainIds;
  } else if ((process.env.REACT_APP_ENVIRONMENT as string) === 'timemachine') {
    return timemachineSupportedChainIds;
  } else if ((process.env.REACT_APP_ENVIRONMENT as string) === 'alpha') {
    return alphaSupportedChainIds;
  } else if ((process.env.REACT_APP_ENVIRONMENT as string) === 'beta') {
    return betaSupportedChainIds;
  } else {
    return testnetsSupportedChainIds;
  }
};

export const SUPPORTED_CHAINS: number[] = Object.keys(
  getNetworkConfigsByEnv(),
).map((key) => +key);

export const isProduction = (): boolean =>
  (process.env.REACT_APP_ENVIRONMENT as string) === 'production' ||
  (process.env.REACT_APP_ENVIRONMENT as string) === 'alpha' ||
  (process.env.REACT_APP_ENVIRONMENT as string) === 'beta';
