import {
  IconSize,
  Box,
  Flex,
  Button,
  Checkbox,
  ConfirmCard,
  Heading,
  Link,
  Tooltip,
  SmallText,
  useMatchBreakpoints,
  LargeText,
  FlexWithGap,
  Toggle,
} from '@bumper-dao/ui-kit';
import React, { useState } from 'react';

import { learnMoreLink } from '../../../core/config/links';
import { ConfirmWithdrawCardProps } from '../types';

export const ConfirmWithdrawPage: React.FC<ConfirmWithdrawCardProps> = (
  props,
) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { isMobile } = useMatchBreakpoints();

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>

      <Flex marginTop="40px">
        <ConfirmCard
          iconComponent={props.iconComponent}
          amount={props.withdrawAmount}
          subTitle={props.token.symbol}
          px={isMobile ? '8px' : ''}
        >
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <LargeText
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
            >
              Withdrawal Amount:
            </LargeText>
            <Flex alignItems="center">
              <LargeText
                color="secondary.white"
                mr="2px"
                fontSize={isMobile ? '14px' : '16px'}
              >
                ${props.withdrawAmount}
              </LargeText>
              <SmallText color="secondary.white">
                {props.token.symbol}
              </SmallText>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <LargeText
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
            >
              Yield (Estimated):
            </LargeText>
            <FlexWithGap gap="4px" alignItems="center">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {props.yieldAmount}
              </LargeText>
              <SmallText color="secondary.white">
                {props.token.symbol}
              </SmallText>
            </FlexWithGap>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <Tooltip tooltipValue="123">
              <LargeText
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
              >
                Lock Your Bond:
              </LargeText>
            </Tooltip>
            <Toggle variants={['Yes', 'No']} />
          </Flex>
          <Flex alignItems="start" justifyContent="space-between" mb="28px">
            <LargeText
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
            >
              Bond Return:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <FlexWithGap gap="4px" alignItems="center">
                <LargeText
                  color="secondary.white"
                  fontSize={isMobile ? '14px' : '16px'}
                >
                  {props.bondAmount}
                </LargeText>
                <SmallText color="secondary.white">BUMP</SmallText>
              </FlexWithGap>
              <FlexWithGap gap="6px">
                <Tooltip tooltipValue="123" />
                <SmallText
                  color="primary2.cambridgeBlue"
                  mr="2px"
                >{`(Est. $${props.bondInUsdAmount})`}</SmallText>
              </FlexWithGap>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <LargeText
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
            >
              BUMP Incentives:
            </LargeText>
            <FlexWithGap gap="4px" alignItems="center">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {props.bumpIncentiveAmount}
              </LargeText>
              <SmallText color="secondary.white">BUMP</SmallText>
            </FlexWithGap>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <SmallText color="secondary.white">Boost:</SmallText>
            <FlexWithGap gap="4px" alignItems="center">
              <SmallText color="secondary.white">
                {props.coordinationAmount}
              </SmallText>
              <SmallText color="secondary.white">{'BUMP'}</SmallText>
            </FlexWithGap>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <SmallText color="secondary.white">Coordination:</SmallText>
            <FlexWithGap gap="4px" alignItems="center">
              <SmallText color="secondary.white">{props.boostAmount}</SmallText>
              <SmallText color="secondary.white">{'BUMP'}</SmallText>
            </FlexWithGap>
          </Flex>
        </ConfirmCard>
      </Flex>
      <Flex alignItems={isMobile ? '' : 'center'} mt="30px">
        <Checkbox onClick={() => setIsActive(!isActive)} />
        <LargeText
          mt={isMobile ? '-10px' : ''}
          ml="20px"
          color="secondary.white"
        >
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={learnMoreLink}>
            Terms and conditions
          </Link>
          ,{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb="20px"
        mt="30px"
      >
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => props.onBackClick()}
        >
          Back
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!isActive}
          onClick={() => props.onConfirmClick()}
        >
          Confirm
        </Button>
      </Flex>
    </Box>
  );
};
