import { Flex, FlowHeader, StepStatus } from '@bumper-dao/ui-kit';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ClaimFlowConfirmPage } from './ClaimFlowConfirmPage';
import { SummaryVestingClaim } from './SummaryVestingClaim';

import { Routes, subRoutes } from '../../core/config/routes';
import { useAppDispatch } from '../../core/state/hooks';
import { toggleToolbar } from '../../core/state/reducers/uiStateManagementReducer';
import { TransactionConfirm, TransactionInProgress } from '../Transaction';

export const VestingClaimFlowPage = () => {
  const history = useNavigate();
  const { step } = useParams<{ step?: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());

    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history(Routes.Dashboard);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Confirm: {
        return <ClaimFlowConfirmPage />;
      }
      case subRoutes.Approve: {
        return <TransactionConfirm steps={2} />;
      }
      case subRoutes.Processing: {
        return <TransactionInProgress />;
      }
      case subRoutes.Summary: {
        return <SummaryVestingClaim />;
      }
      default: {
        return <ClaimFlowConfirmPage />;
      }
    }
  };

  const getStatus = (): StepStatus[] => {
    switch (step) {
      case subRoutes.Confirm: {
        return ['current', 'next'];
      }
      case subRoutes.Approve: {
        return ['current', 'next'];
      }
      case subRoutes.Summary: {
        return ['past', 'current'];
      }
      default: {
        return ['current', 'next'];
      }
    }
  };

  return (
    <>
      <FlowHeader
        cancelClick={onCancel}
        steps={step === subRoutes.Processing ? [] : ['Confirm', 'Summary']}
        stepsStatus={getStatus()}
        logoClick={() => history(Routes.Dashboard)}
      />
      <Flex
        py="40px"
        justifyContent="center"
        alignItems="center"
        flex={1}
        width={'100%'}
      >
        {showSection()}
      </Flex>
    </>
  );
};
