const discordLink = 'https://discord.gg/YyzRws4Ujd';
const telegramLink = 'https://t.me/bumperfinance';
const termsAndConditionsLink = 'https://www.bumper.fi/terms-and-conditions';
const privacyPolicyLink = 'https://www.bumper.fi/privacy-policy';
const disclaimerLink = 'https://www.bumper.fi/privacy-policy';
const docsLink = 'https://docs.bumper.fi/';
const supportLink = 'mailto:support@bumper.fi';
const learnMoreLink =
  'https://medium.com/bumper-finance/the-bump-staking-module-quickening-release-alpha-f66bed1a36ba';
const learnMoreStakingLink = 'https://www.bumper.fi/stake-bump';
const bumperGuideLink =
  'https://bumper-finance.webflow.io/learn/depositing-during-lp-program#metamask';
const feeAndLevyLink = 'https://app.gitbook.com/login/bumper/documentation';
const learnMorePost14Oct =
  'https://www.bumper.fi/learn/participate-in-the-pre-sale';
const etherscanLink = process.env.REACT_APP_ETHERSCAN_URL as string;
const feedbackLink =
  'https://docs.google.com/forms/d/e/1FAIpQLScCp2Zcq8ZdrXHkyE17QEjYvOr4FHm0OumfpPMjNwUj_9uz8w/viewform?usp=sf_link';
const reportBugLink =
  'https://docs.google.com/forms/d/e/1FAIpQLSd1kwjtkV9gWLKTzikUly2uXjq0h__p7pAJtkPPUAd-Da91Dg/viewform';

export {
  discordLink,
  telegramLink,
  termsAndConditionsLink,
  docsLink,
  supportLink,
  learnMoreLink,
  bumperGuideLink,
  feeAndLevyLink,
  learnMorePost14Oct,
  etherscanLink,
  learnMoreStakingLink,
  privacyPolicyLink,
  disclaimerLink,
  feedbackLink,
  reportBugLink,
};
