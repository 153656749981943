import {
  Box,
  Button,
  Checkbox,
  ConfirmCard,
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  lightColors,
  Link,
  Toggle,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import stakeIcon from '@bumper-dao/ui-kit/dist/images/stake.svg';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_DECIMAL_VALUE } from '../../../core/config/formulaConstants';
import { learnMoreLink } from '../../../core/config/links';
import { Routes, subRoutes } from '../../../core/config/routes';
import { BUMP } from '../../../core/config/tokenNames';
import { tokensIcons } from '../../../core/config/tokensIcons';
import { getFlowAssetIcon } from '../../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../../core/hooks/useChain';
import { StakingService } from '../../../core/services/stakingService';
import { VestingService } from '../../../core/services/vestingService';
import { useAppSelector } from '../../../core/state/hooks';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../../core/utils/helpers';
import { ConfirmRestakeState } from '../types';

export const ConfirmRestake = () => {
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const { nameForDisplay: chainName } = useChain();
  const {
    amount,
    option,
    stakeIndex,
    rewards,
    rewardsForTransaction,
    isVestingRestake,
  } = useLocation().state as ConfirmRestakeState;
  const amountDecimal = ethers.utils.parseUnits(amount, DEFAULT_DECIMAL_VALUE);
  const rewardsDecimal = ethers.utils.parseUnits(
    rewards,
    DEFAULT_DECIMAL_VALUE,
  );
  const rewardsForTransactionDecimal = ethers.utils.parseUnits(
    rewardsForTransaction || '0',
    DEFAULT_DECIMAL_VALUE,
  );
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isAccumulatingRewards, setAccumulateRewards] =
    useState<boolean>(false);
  const [periodInDays, setPeriodInDays] = useState<number>(0);
  const stakingService = StakingService.getInstance();
  const vestingService = VestingService.getInstance();
  const vestingData = useAppSelector((state) => state.merkleTree.vesting);

  const onBackButtonPress = () => {
    navigate(Routes.Stake);
  };

  useEffect(() => {
    (async () => {
      const stakeOptions = await stakingService.getStakeOptions();
      setPeriodInDays(stakeOptions[option - 1].periodInDays);
    })();
  }, []);

  const onConfirm = async () => {
    try {
      navigate(`${Routes.Restake}/${chainName}/${subRoutes.Approve}`);
      const tx = isVestingRestake
        ? await vestingService.claimToStakeWithPermit(
            vestingData,
            option,
            false,
            BigNumber.from(0),
            amountDecimal.add(rewardsForTransactionDecimal || 0),
            isAccumulatingRewards && rewardsForTransaction
              ? amountDecimal.add(rewardsForTransactionDecimal)
              : amountDecimal,
          )
        : await stakingService.restake(
            stakeIndex,
            option,
            isAccumulatingRewards,
            false,
          );
      navigate(`${Routes.Restake}/${chainName}/${subRoutes.Processing}`);
      await tx.wait();

      navigate(`${Routes.Restake}/${chainName}/${subRoutes.Summary}`, {
        replace: true,
        state: {
          amount,
          option,
          stakeIndex,
          rewards,
          rewardsForTransaction,
          isVestingRestake,
          periods: periodInDays,
          stakedAmount: amount,
          isAccumulateRewards: isAccumulatingRewards,
          txHash: tx.hash,
        },
      });
    } catch (err) {
      console.error(err);
      navigate(Routes.Stake);
    }
  };

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" marginBottom="64px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <Box
            style={{
              position: 'absolute',
              top: '-50px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {getFlowAssetIcon(stakeIcon, tokensIcons[BUMP.symbol])}
          </Box>
        }
        amount={formatWeiToNormalString(
          isAccumulatingRewards
            ? amountDecimal.add(rewardsDecimal)
            : amountDecimal,
        )}
        subTitle={'BUMP'}
        px={isMobile ? '8px' : ''}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Staking Term:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {periodInDays} Days
          </LargeText>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mt="20px">
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Unstake Balance:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {formatStringifyNumberToDot(amount)}
            <MediumText display="inline-block" ml="4px" color="secondary.white">
              BUMP
            </MediumText>
          </LargeText>
        </Flex>
        {rewardsDecimal.gt(0) && (
          <Flex alignItems="center" justifyContent="space-between" mt="20px">
            <LargeText
              fontWeight={500}
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              Claim Rewards:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {formatStringifyNumberToDot(rewards)}
              <MediumText
                display="inline-block"
                ml="4px"
                color="secondary.white"
              >
                BUMP
              </MediumText>
            </LargeText>
          </Flex>
        )}
        <Box
          width="100%"
          height="4px"
          background={lightColors.primary2.aqua}
          my="20px"
        />
        <Flex alignItems="center" justifyContent="space-between" mt="20px">
          <Flex alignItems="center" justifyContent="space-between">
            <Tooltip tooltipValue={'fdsf'}>
              <LargeText
                fontWeight={500}
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
                maxWidth={isMobile ? '100px' : ''}
              >
                Stake your accumulated rewards:
              </LargeText>
            </Tooltip>
          </Flex>
          <Box pr="8px">
            <Toggle
              checked={isAccumulatingRewards}
              onToggle={() => setAccumulateRewards((prev) => !prev)}
            />
          </Box>
        </Flex>
        <FlexWithGap
          gap="15px"
          alignItems="start"
          justifyContent="space-between"
          mt="20px"
        >
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Conditions:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            You will be able to unstake at the end of your staking term,
            starting from the time the transaction executes on the blockchain.
          </LargeText>
        </FlexWithGap>
      </ConfirmCard>
      <Flex alignItems={isMobile ? '' : 'center'} mt="40px">
        <Checkbox onClick={() => setTermsAccepted((prev) => !prev)} />
        <LargeText
          mt={isMobile ? '-10px' : ''}
          ml="20px"
          color="secondary.white"
        >
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={learnMoreLink}>
            Terms and conditions
          </Link>
          ,{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex justifyContent="space-between" mt="40px">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={onBackButtonPress}
        >
          Cancel
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!termsAccepted}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Flex>
    </Box>
  );
};
