import { IBond, IBond__factory } from '@bumper-dao/contract-interfaces';
import { BigNumber, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';
import { MarketService } from './marketService';
import { ProtocolConfigService } from './protocolConfigService';
import { getContractsAddresses } from './servicesUtils';

import { marketsTokensConfig } from '../config/constants/tokens';
import { DEFAULT_DECIMAL_VALUE } from '../config/formulaConstants';
import { IBondPositionData, ICoin } from '../interfaces';

export class BondService {
  private static instance: BondService;
  private ethersServiceProvider: EthersServiceProvider;
  private protocolConfigServices: ProtocolConfigService;
  private marketServices: MarketService;

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
    this.marketServices = MarketService.getInstance();
    this.protocolConfigServices = ProtocolConfigService.getInstance();
  }

  public static getInstance(): BondService {
    if (!BondService.instance) {
      BondService.instance = new BondService();
    }
    return BondService.instance;
  }

  private async getAccount(address?: string): Promise<string> {
    return address ?? (await this.ethersServiceProvider.getUserAddress());
  }

  private async getBondContractByToken(tokenAddress: string): Promise<IBond> {
    const marketAddress = await this.protocolConfigServices.getMarket(
      tokenAddress,
    );
    const bondAddress = await this.marketServices
      .getMarketContract(marketAddress)
      .BOND_ADDRESS();
    return IBond__factory.connect(
      bondAddress,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
  }

  private async getBondContractByMarket(marketAddress: string): Promise<IBond> {
    const bondAddress = await this.marketServices
      .getMarketContract(marketAddress)
      .BOND_ADDRESS();
    return IBond__factory.connect(
      bondAddress,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
  }

  public async getBondBalance(
    tokenAddress: string,
    account?: string,
  ): Promise<BigNumber> {
    const bond = await this.getBondContractByToken(tokenAddress);
    return await bond.balanceOf(await this.getAccount(account));
  }

  public async calcBondSizeForTakerPosition(
    token: ICoin,
    amount: string,
    risk: number,
    term: number,
  ): Promise<IBondPositionData> {
    const { TOKEN_DETAILS } = await getContractsAddresses();
    const amountDec = ethers.utils.parseUnits(
      amount,
      token.decimals || DEFAULT_DECIMAL_VALUE,
    );
    const bond = await this.getBondContractByToken(
      TOKEN_DETAILS[token.symbol].address,
    );
    return await bond.calcBondSizeForTakerPosition(
      await this.getAccount(),
      TOKEN_DETAILS[token.symbol].address,
      amountDec,
      risk,
      term,
    );
  }

  public async calcBondSizeForMakerPosition(
    token: ICoin,
    amount: string,
    risk: number,
    term: number,
  ): Promise<IBondPositionData> {
    const { TOKEN_DETAILS } = await getContractsAddresses();
    const amountDec = ethers.utils.parseUnits(amount, DEFAULT_DECIMAL_VALUE);
    const tokenForGetMarket = marketsTokensConfig.find(
      (config) =>
        !!config.stable.find(
          (item) =>
            TOKEN_DETAILS[item.symbol].address.toLowerCase() ===
            TOKEN_DETAILS[token.symbol].address.toLowerCase(),
        ),
    )?.token;
    const marketAddress = await this.protocolConfigServices.getMarket(
      TOKEN_DETAILS[tokenForGetMarket?.symbol || ''].address || '',
    );
    const bond = await this.getBondContractByMarket(marketAddress);
    return await bond.calcBondSizeForMakerPosition(
      await this.getAccount(),
      TOKEN_DETAILS[tokenForGetMarket?.symbol || ''].address || '',
      amountDec,
      risk,
      term,
    );
  }
}
