import {
  Button,
  ConfirmCard,
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  SmallText,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { ethers } from 'ethers';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ConfirmProtectDepositProps } from './types';

import ProtectIco from '../../assets/confirm-protect-logo.svg';
import homeIcon from '../../assets/home.svg';
import { MainContainer } from '../../components/common/MainContainers';
import { feedbackLink } from '../../core/config/links';
import { Routes } from '../../core/config/routes';
import { getEtherscanLink } from '../../core/funcs/getEtherscanLink';
import { useChain } from '../../core/hooks/useChain';
import { isFeatureEnabled } from '../../core/utils/features';
import {
  formatStringifyNumberToDot,
  shortenTransactionHash,
} from '../../core/utils/helpers';
import { ConfirmDepositInnerContainer } from '../EarnDepositFlow/styles';

export const PolicyProtectDeposit: React.FC = () => {
  const locationState = useLocation().state as ConfirmProtectDepositProps;
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const chain = useChain();
  const shortHash = useMemo(
    () => shortenTransactionHash(locationState.txHash ?? ''),
    [locationState],
  );

  return (
    <MainContainer
      maxWidth={isMobile ? '90% !important' : '576px !important'}
      style={{ gap: 0 }}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        mt={isMobile ? '0' : '25px'}
      >
        <Heading as="h1" scale="xxl" color="secondary.white">
          Protection Policy
        </Heading>
      </Flex>
      <Flex mt="64px" flexDirection="column" width="100%">
        <ConfirmCard
          iconComponent={
            <img
              src={ProtectIco}
              style={{
                position: 'absolute',
                top: '-50px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
              width="56px"
              height="48px"
              alt="Icon"
            />
          }
          linkUrl={getEtherscanLink(locationState.txHash ?? '', chain.config)}
          linkText={shortHash}
          px={isMobile ? '8px' : ''}
        >
          <ConfirmDepositInnerContainer mb="20px" mx="4px">
            <Flex alignItems="center" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
              >
                Protect Term:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {locationState.protectTerm} Days
              </LargeText>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
              >
                Protect Floor:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {locationState.protectFloor}%
              </LargeText>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
              >
                Auto-renew:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {locationState.autoRenew ? 'On' : 'Off'}
              </LargeText>
            </Flex>
          </ConfirmDepositInnerContainer>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={500}
            >
              Protocol Fee:
            </LargeText>
            <Flex justifyContent="end">
              <LargeText
                ml="5px"
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                $
                {formatStringifyNumberToDot(
                  locationState.protocolFee.toString(),
                  2,
                )}
              </LargeText>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={500}
            >
              BUMP Bond:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                <FlexWithGap gap="4px" alignItems="center">
                  {formatStringifyNumberToDot(
                    ethers.utils.formatUnits(locationState.bondAmount.toLock),
                    4,
                  )}{' '}
                  <SmallText color="secondary.white">BUMP</SmallText>
                </FlexWithGap>
              </LargeText>
              <Flex>
                <Tooltip tooltipValue="Bond" />
                <SmallText color="primary2.cambridgeBlue">
                  <span style={{ whiteSpace: 'nowrap' }}>
                    (Estimated $
                    {formatStringifyNumberToDot(
                      locationState.bondAmountInUSD,
                      2,
                    )}
                    )
                  </span>
                </SmallText>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" mb="5px">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={500}
            >
              BUMP Incentives:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {formatStringifyNumberToDot(
                (
                  locationState.incentiveBoost + locationState.incentiveNetwork
                ).toString(),
                4,
              )}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </LargeText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="5px">
            <MediumText fontFamily="Roboto Mono" color="secondary.white">
              Boost:
            </MediumText>
            <MediumText color="secondary.white">
              {formatStringifyNumberToDot(
                locationState.incentiveBoost.toString(),
                4,
              )}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </MediumText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <MediumText fontFamily="Roboto Mono" color="secondary.white">
              Coordination:
            </MediumText>
            <MediumText color="secondary.white">
              {formatStringifyNumberToDot(
                locationState.incentiveNetwork.toString(),
                4,
              )}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </MediumText>
          </Flex>
          <Flex justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={500}
            >
              {locationState.token.symbol} Amount Protected:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatStringifyNumberToDot(locationState.protectAmount, 4)}{' '}
                <SmallText
                  color="secondary.white"
                  fontSize="12px"
                  display="inline-block"
                >
                  {locationState.token.symbol}
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="Protected" />}
                <SmallText
                  ml="5px"
                  color="primary2.cambridgeBlue"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  (Estimated $
                  {formatStringifyNumberToDot(
                    (
                      parseFloat(locationState.protectAmount) *
                      parseFloat(locationState.token.price)
                    ).toString(),
                    2,
                  )}
                  )
                </SmallText>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="5px">
            <Flex alignItems="center">
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
                fontWeight={500}
              >
                {locationState.token.symbol} Protected Floor Price:
              </LargeText>
              <Tooltip tooltipValue="Floor price" />
            </Flex>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              $
              {formatStringifyNumberToDot(
                (
                  (parseFloat(locationState.token.price) *
                    locationState.protectFloor) /
                  100
                ).toString(),
                2,
              )}
            </LargeText>
          </Flex>
        </ConfirmCard>
        <Flex
          alignItems="center"
          justifyContent={
            isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
          }
          mt="40px"
        >
          <Button
            secondary
            size={isMobile ? IconSize.L : IconSize.XL}
            onClick={() => {
              navigate(`${Routes.Dashboard}`);
            }}
            height="48px"
          >
            <img src={homeIcon} alt="" />
            Dashboard
          </Button>
          {isFeatureEnabled('SUPPORT') && (
            <Button
              secondary
              size={isMobile ? IconSize.L : IconSize.XL}
              onClick={() => {
                window.open(feedbackLink, '_blank');
              }}
              height="48px"
            >
              Feedback
            </Button>
          )}
        </Flex>
      </Flex>
    </MainContainer>
  );
};
