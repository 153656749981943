import {
  Box,
  Button,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  SmallText,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import usdcIcon from '@bumper-dao/ui-kit/dist/images/usdc.png';
import { ethers } from 'ethers';
import React from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { ConfirmDepositInnerContainer } from './styles';
import { ConfirmDepositCardProps } from './types';

import earnIcon from '../../assets/earn1.png';
import { MainContainer } from '../../components/common/MainContainers';
import { feedbackLink } from '../../core/config/links';
import { Routes } from '../../core/config/routes';
import { getEtherscanLink } from '../../core/funcs/getEtherscanLink';
import { getFlowAssetIcon } from '../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../core/hooks/useChain';
import { isFeatureEnabled } from '../../core/utils/features';
import {
  formatStringifyNumberToDot,
  shortenTransactionHash,
} from '../../core/utils/helpers';

export const PolicyDepositContainer: React.FC = () => {
  const navigate = useNavigate();
  const chain = useChain();
  const props = useLocation().state as ConfirmDepositCardProps;
  const { isMobile } = useMatchBreakpoints();

  return (
    <MainContainer
      maxWidth={isMobile ? '100%' : '575px !important'}
      style={{ gap: '32px' }}
    >
      <Flex alignItems="center" justifyContent="center">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Earning Policy
        </Heading>
      </Flex>
      <Flex mt="10px" width="100%">
        <ConfirmCard
          iconComponent={
            <Box
              style={{
                position: 'absolute',
                top: '-50px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {getFlowAssetIcon(earnIcon, usdcIcon)}
            </Box>
          }
          linkUrl={getEtherscanLink(props.txHash ?? '', chain.config)}
          linkText={shortenTransactionHash(props.txHash ?? '')}
          px={isMobile ? '8px' : ''}
        >
          <ConfirmDepositInnerContainer mb="20px" mx="4px">
            <Flex alignItems="center" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
              >
                Deposit Amount:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatStringifyNumberToDot(props.stableAmount, 4)}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {props.stableSymbol}
                </SmallText>
              </LargeText>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
              >
                Term:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {props.earningPeriod} Days
              </LargeText>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
              >
                Tier:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {props.riskTier}
              </LargeText>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
              >
                Auto-renew:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {props.autoRenew ? 'On' : 'Off'}
              </LargeText>
            </Flex>
          </ConfirmDepositInnerContainer>
          <Flex alignItems="start" justifyContent="space-between" mb="20px">
            <Flex>
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                style={{ whiteSpace: 'nowrap' }}
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
              >
                BUMP Bond:
              </LargeText>
              {isMobile && <Tooltip tooltipValue="123" />}
            </Flex>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatStringifyNumberToDot(
                  ethers.utils.formatUnits(props.bondAmount.toLock),
                  4,
                )}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  BUMP
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="123" />}
                <MediumText
                  ml="5px"
                  color="primary2.cambridgeBlue"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  (Estimated $
                  {formatStringifyNumberToDot(props.bondAmountInUSD, 2)})
                </MediumText>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="5px">
            <LargeText
              fontFamily="Roboto Mono"
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
            >
              BUMP Incentives:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {formatStringifyNumberToDot(
                (props.incentiveBoost + props.incentiveNetwork).toString(),
                4,
              )}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </LargeText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <SmallText
              fontFamily="Roboto Mono"
              fontWeight={isMobile ? 500 : 400}
              color="secondary.white"
            >
              Boost:
            </SmallText>
            <MediumText color="secondary.white">
              {formatStringifyNumberToDot(props.incentiveBoost.toString(), 4)}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </MediumText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="10px">
            <SmallText
              fontFamily="Roboto Mono"
              fontWeight={isMobile ? 500 : 400}
              color="secondary.white"
            >
              Coordination:
            </SmallText>
            <MediumText color="secondary.white">
              {formatStringifyNumberToDot(props.incentiveNetwork.toString(), 4)}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </MediumText>
          </Flex>
        </ConfirmCard>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent={
          isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
        }
        width="100%"
      >
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => {
            navigate(`${Routes.EarnAssetsDashboard}/${chain.nameForDisplay}`);
          }}
          height="48px"
        >
          Go to Asset Page
        </Button>
        {isFeatureEnabled('SUPPORT') && (
          <Button
            secondary
            size={isMobile ? IconSize.L : IconSize.XL}
            onClick={() => {
              window.open(feedbackLink, '_blank');
            }}
            height="48px"
          >
            Feedback
          </Button>
        )}
      </Flex>
    </MainContainer>
  );
};
