import {
  IERC20__factory,
  IProtocolConfig,
  IProtocolConfig__factory,
} from '@bumper-dao/contract-interfaces';
import { ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';
import { getContractsAddresses } from './servicesUtils';

import {
  getNetworkConfigsByEnv,
  SUPPORTED_CHAINS,
} from '../config/supportedChains';

export class ProtocolConfigService {
  private static instance: ProtocolConfigService;
  private ethersServiceProvider: EthersServiceProvider;

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
  }

  public static getInstance(): ProtocolConfigService {
    if (!ProtocolConfigService.instance) {
      ProtocolConfigService.instance = new ProtocolConfigService();
    }
    return ProtocolConfigService.instance;
  }

  private getProtocolConfig(contractAddress: string): IProtocolConfig {
    return IProtocolConfig__factory.connect(
      contractAddress,
      this.ethersServiceProvider.currentAccount
        ? (this.ethersServiceProvider.provider?.getSigner(
            0,
          ) as ethers.providers.JsonRpcSigner)
        : new ethers.providers.JsonRpcProvider(
            getNetworkConfigsByEnv()[SUPPORTED_CHAINS[0]].config.rpcUrls[0],
          ),
    );
  }
  public async getMarket(tokenAddress: string): Promise<string> {
    const protocolConfigAddress = (await getContractsAddresses())
      .CONTRACT_ADDRESS.ProtocolConfig;
    return await this.getProtocolConfig(protocolConfigAddress).getMarket(
      tokenAddress,
    );
  }

  public async getNFTMaker(tokenAddress: string): Promise<string> {
    const protocolConfigAddress = (await getContractsAddresses())
      .CONTRACT_ADDRESS.ProtocolConfig;
    return await this.getProtocolConfig(protocolConfigAddress).getNFTMaker(
      tokenAddress,
    );
  }

  public async getNFTTaker(tokenAddress: string): Promise<string> {
    const protocolConfigAddress = (await getContractsAddresses())
      .CONTRACT_ADDRESS.ProtocolConfig;
    return await this.getProtocolConfig(protocolConfigAddress).getNFTTaker(
      tokenAddress,
    );
  }

  public async getStableTokenAddress(): Promise<string> {
    const protocolConfigAddress = (await getContractsAddresses())
      .CONTRACT_ADDRESS.ProtocolConfig;
    return await this.getProtocolConfig(protocolConfigAddress).getStable();
  }

  public async getStableTokenSymbol(
    stableTokenAddress?: string,
  ): Promise<string> {
    if (!stableTokenAddress) {
      stableTokenAddress = await this.getStableTokenAddress();
    }

    return await IERC20__factory.connect(
      stableTokenAddress,
      this.ethersServiceProvider.provider as ethers.providers.JsonRpcProvider,
    ).symbol();
  }
}
