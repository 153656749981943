import {
  BumpToken__factory,
  BUSDC__factory,
  IERC20__factory,
} from '@bumper-dao/contract-interfaces';
import { BigNumber, ethers, FixedNumber } from 'ethers';

import { getContractsAddresses } from './servicesUtils';

import {
  getNetworkConfigsByEnv,
  SUPPORTED_CHAINS,
} from '../config/supportedChains';
import { ETH } from '../config/tokenNames';
import { ICoin, ICoinDetails, CustomProvider } from '../interfaces';
import { convertBumpToDollars, getTokenPrice } from '../utils/helpers';

export class EthersServiceProvider {
  private static instance: EthersServiceProvider;

  private _provider: CustomProvider;
  public defaultProvider: ethers.providers.JsonRpcProvider | undefined;

  public currentAccount: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
    this.currentAccount = '';
  }

  get provider() {
    const defaultRpcURL =
      getNetworkConfigsByEnv()[SUPPORTED_CHAINS[0]].config.rpcUrls[0];
    return (
      this._provider || new ethers.providers.JsonRpcProvider(defaultRpcURL)
    );
  }

  set provider(prov: ethers.providers.JsonRpcProvider | undefined) {
    this._provider = prov;
  }

  /**
   * Configures and returns singleton instance of EthersServiceProvider.
   * @returns Returns EthersServiceProvider singleton instance
   */
  static getInstance(): EthersServiceProvider {
    if (!this.instance) {
      this.instance = new EthersServiceProvider();
      this.instance.getProvider();
    }
    return this.instance;
  }

  async getProvider() {
    return this.provider;
  }

  setCurrentAccount(address: string) {
    this.currentAccount = address;
  }

  /**
   * Will load and return contract instances.
   * @param abi - ABI of contract that you want to create instance of
   * @param address - Address at which that contract is deployed on chain
   * @returns Returns contract instance if successfull or Error if failed
   */
  async loadContractInstance(
    abi: ethers.ContractInterface,
    address: string,
  ): Promise<ethers.Contract> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    if (abi === undefined) {
      throw new Error('INDX: ABI is not passed as argument');
    }
    if (address === undefined) {
      throw new Error('INDX: address is not passed as argument');
    }
    const contract: ethers.Contract = new ethers.Contract(
      address,
      abi,
      this.provider.getSigner(0),
    );
    await contract.deployed();
    return contract;
  }

  /**
   * This method gives you current user account address.
   * @returns Returns user's current metamask aacount
   */
  async getUserAddress(): Promise<string> {
    return this.currentAccount;
  }

  /**
   * This method returns user's balance
   * @returns Returns user balance in  string format
   */
  async getUserBalance(account?: string): Promise<string> {
    return this.convertWeiIntoEther(await this.getUserBalanceRaw(account));
  }

  async getUserBalanceRaw(account?: string): Promise<BigNumber> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const address = account || (await this.getUserAddress());
    return await this.provider.getBalance(address);
  }

  async getUserBalanceTokenRaw(
    token: string,
    account?: string,
  ): Promise<BigNumber> {
    const tokensDetails = (await getContractsAddresses()).TOKEN_DETAILS;
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const address = account || (await this.getUserAddress());
    const erc20 =
      token.toLowerCase() === tokensDetails.bUSDC.address.toLowerCase()
        ? BUSDC__factory.connect(token, this.provider.getSigner(0))
        : IERC20__factory.connect(token, this.provider.getSigner(0));
    const balance = await erc20.balanceOf(address);
    return balance;
  }

  async getUserBalanceToken(
    token: string,
    decimals = 18,
    account?: string,
  ): Promise<string> {
    return ethers.utils.formatUnits(
      await this.getUserBalanceTokenRaw(token, account),
      decimals,
    );
  }

  /**
   * Get network info you are connected too
   * @returns Returns current metamask chain information
   */
  async getCurrentNetworkInfo(): Promise<ethers.providers.Network> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const networkInfo: ethers.providers.Network =
      await this.provider.getNetwork();
    return networkInfo;
  }

  /**
   * This method returns all the info regarding blocknumber passed as param
   * @param blockNumber - Block number in number format
   * @returns Returns block info of provided block number
   */
  async getBlockInfo(blockNumber: number): Promise<ethers.providers.Block> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const block: ethers.providers.Block = await this.provider.getBlock(
      blockNumber,
    );
    return block;
  }

  /**
   * This method approves certain amount of erc20 tokens to an address.
   * @param amount - Amount that needs to be approved.
   * @param to - Address to which tokens need to be approved.
   * @param contractAddress - Address at which ERC20 token is deployed.
   * @returns Returns transaction object that represents the Tx we sent to ERC20 contract.
   */
  async approveTokenAmount(
    amount: string,
    to: string,
    contractAddress: string,
  ): Promise<ethers.ContractTransaction> {
    const erc20Instance = IERC20__factory.connect(
      contractAddress,
      this.provider?.getSigner(0) as ethers.providers.JsonRpcSigner,
    );
    const tx: ethers.ContractTransaction =
      await erc20Instance.functions.approve(to, amount);

    await tx.wait();
    return tx;
  }

  async approveAmount(
    from: string,
    to: string,
    contractAddress: string,
  ): Promise<BigNumber> {
    const erc20Instance = IERC20__factory.connect(
      contractAddress,
      this.provider?.getSigner(0) as ethers.providers.JsonRpcSigner,
    );
    return await erc20Instance.allowance(from, to);
  }

  /**
   * This convert Wei(As Big Number) into Ether format
   * @param value - Big number format value
   * @returns Returns string representation of ether
   */
  convertWeiIntoEther(value: ethers.BigNumberish): string {
    return ethers.utils.formatEther(value);
  }

  /**
   * Reload page when chain id is changes in metamask
   */
  handleChainIdChange(): void {
    window.location.reload();
  }

  /**
   * Reload page when accounts changes.
   * We can modify this function later depending on how we want to handle this event.
   */
  handleAccountsChange(): void {
    window.location.reload();
  }

  /**
   * Is used to convert a big number into fixed number in string format.
   * @param usdc Amount in big number that will be converted to Fixed number format in string.
   * @param decimal Places upto which you want a decimal point.
   * @returns Returns a decimal number in fixed format in form of string.
   */
  toDecimal(usdc: BigNumber, decimal: number): string {
    const formatted = ethers.utils.formatUnits(usdc, decimal);
    return FixedNumber.fromString(formatted).round(6).toString();
  }

  /**
   * It returns details regarding token passed in argument.
   * @param token Token details in ICoin format, for which you want balance , value and price
   * @returns It returns balance , price and value of this token w.r.t current user.
   */
  async fetchTokenDetails(token: ICoin): Promise<ICoinDetails> {
    if (!this.currentAccount) {
      return {
        ...token,
        balance: '0',
        price: '0',
        value: '0',
        balanceDecimal: ethers.constants.Zero,
      };
    }
    const tokensDetails = (await getContractsAddresses()).TOKEN_DETAILS;
    const currentToken = tokensDetails[token.symbol];
    let balanceRes;
    if (token.symbol === ETH.symbol) {
      balanceRes = await this.getUserBalanceRaw();
    } else if (
      currentToken.address.toLowerCase() ===
      tokensDetails.BUMP.address.toLowerCase()
    ) {
      const bumpToken = BumpToken__factory.connect(
        currentToken.address,
        this.provider as ethers.providers.JsonRpcProvider,
      );
      balanceRes = await bumpToken.balanceOf(this.currentAccount);
    } else {
      balanceRes = await this.getUserBalanceTokenRaw(currentToken.address);
    }

    if (
      currentToken.address.toLowerCase() ===
      tokensDetails.BUMP.address.toLowerCase()
    ) {
      const bumpBalanceParsed = ethers.utils.formatUnits(
        balanceRes,
        tokensDetails.BUMP.decimal,
      );
      const singleBumpUsdcPrice = await convertBumpToDollars('1');
      const bumpBalanceInUsdc =
        parseFloat(bumpBalanceParsed) * parseFloat(singleBumpUsdcPrice);
      return {
        ...token,
        balance: ethers.utils.formatUnits(
          balanceRes,
          currentToken.decimal || 18,
        ),
        balanceDecimal: balanceRes,
        price: singleBumpUsdcPrice,
        value: bumpBalanceInUsdc.toString(),
      };
    } else {
      const currentPrice = await getTokenPrice(
        currentToken.address,
        balanceRes,
      );

      return {
        ...token,
        balance: ethers.utils.formatUnits(
          balanceRes,
          currentToken.decimal || 18,
        ),
        balanceDecimal: balanceRes,
        price: ethers.utils.formatUnits(currentPrice, 6),
        value: ethers.utils.formatUnits(
          balanceRes.gt(0) ? balanceRes.mul(currentPrice) : BigNumber.from(0),
          (currentToken.decimal || 18) + 6,
        ),
      };
    }
  }

  async subscribeToEvent(
    eventName: string,
    contractAddress: string,
    listener: ethers.providers.Listener,
    contractABI: ethers.ContractInterface,
  ): Promise<void> {
    if (!this.provider) {
      throw new Error('INDX: Metamask is not connected');
    }
    const contract = await this.loadContractInstance(
      contractABI,
      contractAddress,
    );
    contract.on(
      { address: contractAddress, topics: [ethers.utils.id(eventName)] },
      listener,
    );
  }
}
