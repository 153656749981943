import {
  ClientMarketAdapter,
  ClientMarketAdapter__factory,
  Market,
  Market__factory,
  CIState__factory,
} from '@bumper-dao/contract-interfaces';
import { BigNumber, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';
import { ProtocolConfigService } from './protocolConfigService';
import { getContractsAddresses } from './servicesUtils';
import { StakingService } from './stakingService';

import { marketsTokensConfig } from '../config/constants/tokens';
import { DEFAULT_DECIMAL_VALUE } from '../config/formulaConstants';
import {
  getNetworkConfigsByEnv,
  SUPPORTED_CHAINS,
} from '../config/supportedChains';
import { ETH, USDCoin } from '../config/tokenNames';
import {
  SummaryMarketDataType,
  FixedProtectionPeriodType,
  FloorOptionType,
} from '../interfaces';
import {
  convertBumpToDollars,
  formatStringifyNumberToDot,
  getTokenPrice,
} from '../utils/helpers';

export type MarketDataType = {
  AssetPool: BigNumber;
  AssetReserve: BigNumber;
  CapitalPool: BigNumber;
  CapitalReserve: BigNumber;
};

export class MarketService {
  private static instance: MarketService;
  private ethersServiceProvider: EthersServiceProvider;
  private protocolConfigServices: ProtocolConfigService;

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
    this.protocolConfigServices = ProtocolConfigService.getInstance();
  }

  public static getInstance(): MarketService {
    if (!MarketService.instance) {
      MarketService.instance = new MarketService();
    }
    return MarketService.instance;
  }

  public getMarketContract(marketAddress: string): Market {
    return Market__factory.connect(
      marketAddress,
      this.ethersServiceProvider.currentAccount
        ? (this.ethersServiceProvider.provider?.getSigner(
            0,
          ) as ethers.providers.JsonRpcSigner)
        : new ethers.providers.JsonRpcProvider(
            getNetworkConfigsByEnv()[SUPPORTED_CHAINS[0]].config.rpcUrls[0],
          ),
    );
  }

  public getAdapterContract(adapterAddress: string): ClientMarketAdapter {
    return ClientMarketAdapter__factory.connect(
      adapterAddress,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
  }

  public async getPoolsTVL(): Promise<{
    AP: BigNumber;
    CP: BigNumber;
    B: BigNumber;
    D: BigNumber;
  }> {
    const { TOKEN_DETAILS } = await getContractsAddresses();
    const assetsLibrary = marketsTokensConfig.map(({ token }) => token);

    const markets = await Promise.all(
      assetsLibrary.map((token) =>
        this.protocolConfigServices.getMarket(
          TOKEN_DETAILS[token.symbol].address,
        ),
      ),
    );
    const marketsStates = await Promise.allSettled(
      markets.map<
        Promise<{ AP: BigNumber; CP: BigNumber; B: BigNumber; D: BigNumber }>
      >(async (market) => {
        const stateAddress = await this.getMarketContract(market).getState();
        const stateContractInstance = CIState__factory.connect(
          stateAddress,
          this.ethersServiceProvider.currentAccount
            ? (this.ethersServiceProvider.provider?.getSigner(
                0,
              ) as ethers.providers.JsonRpcSigner)
            : new ethers.providers.JsonRpcProvider(
                getNetworkConfigsByEnv()[SUPPORTED_CHAINS[0]].config.rpcUrls[0],
              ),
        );
        const AP = await stateContractInstance.AP();
        const CP = await stateContractInstance.CP();
        const B = await stateContractInstance.B();
        const D = await stateContractInstance.D();
        return { AP, CP, B, D };
      }),
    );
    return marketsStates.reduce(
      (prev, state) => ({
        AP: prev.AP.add(
          state.status === 'fulfilled' ? state.value.AP : ethers.constants.Zero,
        ),
        CP: prev.CP.add(
          state.status === 'fulfilled' ? state.value.CP : ethers.constants.Zero,
        ),
        B: prev.B.add(
          state.status === 'fulfilled' ? state.value.B : ethers.constants.Zero,
        ),
        D: prev.D.add(
          state.status === 'fulfilled' ? state.value.D : ethers.constants.Zero,
        ),
      }),
      {
        AP: ethers.constants.Zero,
        CP: ethers.constants.Zero,
        B: ethers.constants.Zero,
        D: ethers.constants.Zero,
      },
    );
  }

  public async getState(): Promise<SummaryMarketDataType> {
    const { TOKEN_DETAILS } = await getContractsAddresses();
    const { AP, CP } = await this.getPoolsTVL();
    const stakerSummaryTVL = await StakingService.getInstance().getStakerTVL();

    const assetPrice = await getTokenPrice(
      TOKEN_DETAILS[ETH.symbol].address,
      AP,
    );
    const bumpPrice = await convertBumpToDollars('1');
    const capitalPoolInUSD = ethers.utils.formatUnits(
      CP,
      DEFAULT_DECIMAL_VALUE,
    );
    const assetPoolInUSD = ethers.utils.formatUnits(
      AP.mul(assetPrice),
      DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
    );

    const stakerTVLNumberings = parseFloat(
      ethers.utils.formatUnits(stakerSummaryTVL, DEFAULT_DECIMAL_VALUE),
    );
    const stakerTVLInUSD = stakerTVLNumberings * parseFloat(bumpPrice);
    return {
      summaryTVL: (
        parseFloat(assetPoolInUSD) +
        parseFloat(capitalPoolInUSD) +
        stakerTVLInUSD
      ).toString(),
      takerTVL: assetPoolInUSD,
      makerTVL: capitalPoolInUSD,
      stakerTVL: stakerTVLInUSD.toString(),
    };
  }

  public async getMarketInfoByToken(token: string): Promise<{
    averageProtocolFloor: string;
  }> {
    const market = await this.protocolConfigServices.getMarket(token);
    const stateAddress = await this.getMarketContract(market).getState();
    const stateContractInstance = CIState__factory.connect(
      stateAddress,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
    const L = await stateContractInstance.L();
    const B = await stateContractInstance.B();

    const averageProtocolFloor = `$${formatStringifyNumberToDot(
      L.div(B).toString(),
      2,
    )}`;
    return {
      averageProtocolFloor,
    };
  }

  public async getStateByToken(): // tokenAddress: string
  Promise<MarketDataType> {
    // const market = await this.protocolConfigServices.getMarket(tokenAddress);
    // const marketState = await this.getMarketContract(market).getState();
    return {
      AssetPool: BigNumber.from(0), //marketState.AP,
      AssetReserve: BigNumber.from(0), //marketState._AR,
      CapitalPool: BigNumber.from(0), //marketState._CP,
      CapitalReserve: BigNumber.from(0), //marketState._CR,
    };
  }

  public async getProtectFloorOptions(): Promise<FloorOptionType[]> {
    const protectFloorValues = [70, 80, 85, 90, 95];
    return protectFloorValues.map((protectFloorValue) => ({
      floorValue: protectFloorValue,
    }));
  }

  public async getDepositTierOptions(): Promise<FloorOptionType[]> {
    const protectFloorValues = [1, 2, 3, 4, 5];
    return protectFloorValues.map((protectFloorValue) => ({
      floorValue: protectFloorValue,
    }));
  }

  public async getFixedProtectionPeriodOptions(): Promise<
    FixedProtectionPeriodType[]
  > {
    const fixedProtectionPeriodValues = (
      process.env.REACT_APP_PROTECT_PERIODS || '5,10'
    )
      .split(',')
      .map((v) => parseInt(v));
    return fixedProtectionPeriodValues.map((fixedProtectionPeriodValue) => ({
      fixedProtectionPeriodValue,
    }));
  }

  public async getFixedEarningPeriodOptions(): Promise<
    FixedProtectionPeriodType[]
  > {
    const fixedProtectionPeriodValues = (
      process.env.REACT_APP_EARN_PERIODS || '5,10'
    )
      .split(',')
      .map((v) => parseInt(v));
    return fixedProtectionPeriodValues.map((fixedProtectionPeriodValue) => ({
      fixedProtectionPeriodValue,
    }));
  }
}
