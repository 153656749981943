import { Box, Heading } from '@bumper-dao/ui-kit';
import React, { FC } from 'react';
import { ToastProps } from 'react-toast-notifications';

import ToastCross from './img/toast-cross.svg';
import ToastTick from './img/toast-tick.svg';
import ToastWarning from './img/toast-warning.svg';
import { ToastContainer } from './styles';

export const Toast: FC<ToastProps> = (props) => {
  return (
    <ToastContainer
      appear={props.appearance}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <Box ml="8px" mt="12px">
        {props.appearance === 'success' ? (
          <img src={ToastTick} alt="tick icon" />
        ) : (
          <img src={ToastWarning} alt="warning icon" />
        )}
      </Box>
      <Box style={{ marginTop: '12px', marginLeft: '16px', width: '194px' }}>
        <Heading scale={'md'} color="typography.input">
          {props.children}
        </Heading>
      </Box>
      <Box
        style={{ marginLeft: '16px', marginTop: '12px', cursor: 'pointer' }}
        onClick={() => props.onDismiss()}
      >
        <img src={ToastCross} alt="cross icon" />
      </Box>
    </ToastContainer>
  );
};
