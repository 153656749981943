import {
  Box,
  Button,
  Caret,
  Flex,
  FlexWithGap,
  FlexWithGapProps,
  IconSize,
  LargeText,
  lightColors,
  MediumText,
  Select,
  SmallText,
  TextProps,
  Tooltip,
  VerticalOrientation,
} from '@bumper-dao/ui-kit';
import React, { useMemo, useState } from 'react';
import { Line, LineChart } from 'recharts';
import styled from 'styled-components';

import { MarketRowMobileWrapper, MarketRowWrapper } from './styles';
import {
  MarketChartProps,
  MarketDesktopSortProps,
  MarketDetail,
  PremiumHistoryPeriods,
  PremiumHistoryPeriodsProps,
} from './types';

export const MarketCell = styled((props) => (
  <FlexWithGap
    alignItems="center"
    justifyContent="center"
    gap="4px"
    {...props}
  />
))<Partial<FlexWithGapProps>>``;

export const MarketRowMobileText: React.FC<
  TextProps & { children: React.ReactNode }
> = (props) => <SmallText fontFamily="Roboto" {...props} />;

export const MarketRowMobile: React.FC<
  MarketDetail & PremiumHistoryPeriodsProps & { borderBottom?: boolean }
> = ({
  premiumHistoryPeriodsOptions,
  premiumHistoryPeriod,
  setPremiumHistoryPeriod,
  ...props
}) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <MarketRowMobileWrapper gap="12px" withBorderBottom={props.borderBottom}>
      <Flex alignItems="center" justifyContent="space-between" mb="5px">
        <FlexWithGap gap="4px" alignItems="center">
          {props.icon}
          <LargeText fontFamily="Share Tech Mono">{props.token}</LargeText>
        </FlexWithGap>
        <Button
          size={IconSize.L}
          primary
          onClick={() => {
            if (props.onClick) {
              props.onClick(props.token);
            }
          }}
        >
          {props.isEarn ? 'Earn' : 'Protect'}
        </Button>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <MarketRowMobileText>Current price</MarketRowMobileText>
        <SmallText>{props.price}</SmallText>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <MarketRowMobileText>
          Average Protocol {props.isEarn ? 'Risk' : 'Floor'}
        </MarketRowMobileText>
        <SmallText>{props.averageProtocolFloor}</SmallText>
      </Flex>
      <Flex alignItems="start" justifyContent="space-between">
        <MarketRowMobileText>
          {props.isEarn ? 'Yield' : 'Premium'} Base Rate
        </MarketRowMobileText>
        <Flex flexDirection="column" alignItems="end" justifyContent="center">
          <FlexWithGap gap="4px">
            <Tooltip tooltipValue="" />
            <SmallText>{props.premiumBaseRateAPY}</SmallText>
            <SmallText
              style={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                '-webkit-text-fill-color': `${lightColors.primary2.cambridgeBlue}`,
              }}
              color="primary2.cambridgeBlue"
            >
              APY
            </SmallText>
          </FlexWithGap>
          <SmallText
            style={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '-webkit-text-fill-color': `${lightColors.primary2.cambridgeBlue}`,
            }}
            color="primary2.cambridgeBlue"
          >
            ({props.premiumBaseRateAPR}) APR
          </SmallText>
        </Flex>
      </Flex>
      {showDetails && (
        <>
          <Flex alignItems="center" justifyContent="space-between">
            <FlexWithGap gap="4px" alignItems="center" mb="5px">
              <Select
                value={
                  premiumHistoryPeriodsOptions.find(
                    (v) => v.key === premiumHistoryPeriod,
                  ) || premiumHistoryPeriodsOptions[0]
                }
                options={premiumHistoryPeriodsOptions}
                onValueChanged={(v) =>
                  setPremiumHistoryPeriod(v.key as PremiumHistoryPeriods)
                }
              />
              <MarketRowMobileText ml="5%">
                {props.isEarn ? 'Yield' : 'Premium'} History
              </MarketRowMobileText>
            </FlexWithGap>
            <Flex
              flexDirection="column"
              alignItems="end"
              justifyContent="center"
            >
              <FlexWithGap gap="4px">
                <Tooltip tooltipValue="" />
                <SmallText>{props.premiumHistoryAPY}</SmallText>
                <SmallText
                  style={{
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    '-webkit-text-fill-color': `${lightColors.primary2.cambridgeBlue}`,
                  }}
                  color="primary2.cambridgeBlue"
                >
                  APY
                </SmallText>
              </FlexWithGap>
              <SmallText
                style={{
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  '-webkit-text-fill-color': `${lightColors.primary2.cambridgeBlue}`,
                }}
                color="primary2.cambridgeBlue"
              >
                ({props.premiumHistoryAPR}) APR
              </SmallText>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <MarketRowMobileText>7-day Trend</MarketRowMobileText>
            <MarketChart isMobile prices={props.prices} />
          </Flex>
        </>
      )}
      <FlexWithGap
        gap="4px"
        style={{ cursor: 'pointer' }}
        onClick={() => setShowDetails((prev) => !prev)}
        justifyContent="center"
        alignItems="center"
      >
        <MarketRowMobileText>
          {showDetails ? 'Hide Details' : 'Show details'}
        </MarketRowMobileText>
        <Caret
          variant={IconSize.L}
          orientation={
            showDetails ? VerticalOrientation.UP : VerticalOrientation.DOWN
          }
        />
      </FlexWithGap>
    </MarketRowMobileWrapper>
  );
};

export const MarketRow: React.FC<MarketDetail & { borderBottom?: boolean }> = (
  props,
) => {
  return (
    <MarketRowWrapper borderBottom={props.borderBottom}>
      <MarketCell gap="12px">
        {props.icon}
        <LargeText fontFamily="Share Tech Mono">{props.token}</LargeText>
      </MarketCell>
      <MarketCell>{props.price}</MarketCell>
      <MarketCell>
        <MarketChart prices={props.prices} />
      </MarketCell>
      <MarketCell>{props.averageProtocolFloor}</MarketCell>
      <MarketCell>
        <LargeText>{props.premiumBaseRateAPY}</LargeText>{' '}
        <MediumText
          style={{
            WebkitTextFillColor: `${lightColors.primary2.cambridgeBlue}`,
          }}
          color="primary2.cambridgeBlue"
          fontFamily="Share Tech Mono"
        >
          ({props.premiumBaseRateAPR})
        </MediumText>
      </MarketCell>
      <MarketCell>
        <LargeText>{props.premiumHistoryAPY}</LargeText>{' '}
        <MediumText
          style={{
            WebkitTextFillColor: `${lightColors.primary2.cambridgeBlue}`,
          }}
          fontFamily="Share Tech Mono"
          color="primary2.cambridgeBlue"
        >
          ({props.premiumHistoryAPR})
        </MediumText>
      </MarketCell>
      <MarketCell>
        <Button
          primary
          size={IconSize.L}
          onClick={() => {
            if (props.onClick) {
              props.onClick(props.token);
            }
          }}
        >
          {props.isEarn ? 'Earn' : 'Protect'}
        </Button>
      </MarketCell>
    </MarketRowWrapper>
  );
};

export const MarketChart: React.FC<MarketChartProps> = React.memo(
  ({ prices, isMobile }) => {
    const percent = useMemo(
      () =>
        ((prices[prices.length - 1 > 0 ? 0 : prices.length - 1]?.price || 0) -
          (prices[0]?.price || 0)) *
        100,
      [
        prices[prices.length - 1 > 0 ? 0 : prices.length - 1]?.price,
        prices[0]?.price,
      ],
    );
    const stroke = useMemo(
      () =>
        percent > 0
          ? lightColors.secondary.lightGreen
          : percent < 0
          ? lightColors.secondary.red
          : '#FFB880',
      [percent],
    );

    return (
      <FlexWithGap
        gap="4px"
        flexDirection={isMobile ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
      >
        <LineChart width={60} height={30} data={prices}>
          <Line
            dot={false}
            type="linear"
            dataKey="price"
            stroke={stroke}
            strokeWidth={2}
          />
        </LineChart>
        <MediumText color="secondary.white">
          {percent > 0 && '+'}
          {percent.toFixed(0)}%
        </MediumText>
      </FlexWithGap>
    );
  },
  (prev, curr) =>
    prev.prices
      .map(({ price }, i) => price === (curr.prices[i]?.price || 0))
      .every((v) => v),
);

export const MarketDesktopSort: React.FC<MarketDesktopSortProps> = ({
  initialSortState,
  text,
  sortState,
  sortStateKey,
  setSortState,
  ...props
}) => {
  return (
    <MarketCell {...props}>
      <MediumText fontFamily="Roboto Mono" style={{ whiteSpace: 'nowrap' }}>
        {text}
      </MediumText>{' '}
      <Box
        style={{ cursor: 'pointer' }}
        onClick={() =>
          setSortState({
            ...initialSortState,
            [sortStateKey]:
              sortState[sortStateKey] === VerticalOrientation.DOWN
                ? VerticalOrientation.UP
                : VerticalOrientation.DOWN,
          })
        }
      >
        <Caret variant={IconSize.L} orientation={sortState[sortStateKey]} />
      </Box>
    </MarketCell>
  );
};
