export enum RadiatorOrder {
  right = 'right',
  left = 'left',
}

export interface RadiatorStepperProps {
  order?: RadiatorOrder;
}

export interface RadiatorStepperLineProps {
  stepSize?: string | number;
}

export interface PositionsWrapperProps {
  backgroundImage: string;
  hasPosition: boolean;
}

export interface PolicyBumperLogoProps {
  icon: string;
}
