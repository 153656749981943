import {
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  Link,
  MediumText,
  Notification,
  Select,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { useWeb3React } from '@web3-react/core';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { Breadcrumb } from './Breadcrumb';
import {
  BannerBottomContainer,
  BannerBottomContainerWrapper,
  BannerHeadingYellowBlow,
  BannerTopContainer,
} from './styles';
import { BannerProps, BannerSlideProps } from './types';

import { incorrectChainIDError } from '../../../../core/config/errors';
import { SUPPORTED_CHAINS } from '../../../../core/config/supportedChains';
import { WalletContext } from '../../../../core/config/walletContext';
import { useAppSelector } from '../../../../core/state/hooks';
import { Carusel } from '../../Carousel/Carusel';

const ItemContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    gap: 10px;
  }
`;

const BannerCaruselItem: React.FC<BannerSlideProps> = (props) => {
  const { isMobile } = useMatchBreakpoints();

  return (
    <ItemContainer>
      {isMobile ? (
        <Flex flexDirection="column" marginLeft="32px">
          <Flex justifyContent="space-between" alignItems="center">
            {props.icon}
            <BannerHeadingYellowBlow>
              {props.headerText}
            </BannerHeadingYellowBlow>
          </Flex>
          <MediumText
            color="primary2.cambridgeBlue"
            marginTop="4px"
            lineHeight="24px"
          >
            {props.contentText}
            <Link
              fontFamily="Share Tech Mono"
              color="white"
              href="https://www.bumper.fi/"
              target="_blank"
              ml="4px"
              style={{
                textDecoration: 'underline',
              }}
            >
              {props.linkText}
            </Link>
          </MediumText>
        </Flex>
      ) : (
        <>
          <Flex flexDirection="column">{props.icon}</Flex>
          <Flex flexDirection="column" marginLeft="32px">
            <BannerHeadingYellowBlow>
              {props.headerText}
            </BannerHeadingYellowBlow>
            <MediumText
              color="primary2.cambridgeBlue"
              marginTop="4px"
              lineHeight="24px"
            >
              {props.contentText}
              <Link
                fontFamily="Share Tech Mono"
                color="white"
                href="https://www.bumper.fi/"
                target="_blank"
                ml="4px"
                style={{
                  textDecoration: 'underline',
                }}
              >
                {props.linkText}
              </Link>
            </MediumText>
          </Flex>
        </>
      )}
    </ItemContainer>
  );
};

const Banner: React.FC<BannerProps> = ({
  options,
  slides,
  activeOption,
  setActiveOption,
  isMobile,
  path,
  page,
}) => {
  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  const { chainId } = useWeb3React();
  const error = useAppSelector((state) => state.error.errorMessage);
  return (
    <>
      {slides.length !== 0 && (
        <BannerTopContainer justifyContent="center">
          <Carusel>
            {slides.map((item, index) => (
              <BannerCaruselItem key={index} {...item} />
            ))}
          </Carusel>
        </BannerTopContainer>
      )}
      <BannerBottomContainer
        style={slides.length === 0 ? { boxShadow: 'none' } : {}}
      >
        <BannerBottomContainerWrapper
          justifyContent={
            isMobile
              ? 'center'
              : path || !isWalletConnected
              ? 'space-between'
              : 'end'
          }
        >
          {!isWalletConnected && (
            <FlexWithGap gap="10px" alignItems="center">
              <Notification variant={IconSize.L} />
              <Heading scale={'md'} color="primary2.cambridgeBlue">
                Your wallet is not connected! You have to connect it to proceed.{' '}
              </Heading>
            </FlexWithGap>
          )}
          {isWalletConnected && error === incorrectChainIDError.errorMessage && (
            <FlexWithGap gap="10px" alignItems="center">
              <Notification variant={IconSize.L} />
              <Heading scale={'md'} color="primary2.cambridgeBlue">
                Wrong network! Please choose the supported one{' '}
              </Heading>
            </FlexWithGap>
          )}
          {isWalletConnected && SUPPORTED_CHAINS.includes(chainId ?? 0) && (
            <Breadcrumb path={path} page={page} />
          )}
          {!isMobile && (
            <Select
              zIndex={11}
              value={activeOption}
              options={options}
              onValueChanged={setActiveOption}
              topValue="10px"
              /*padding="0 12px"*/
              selectorPadding="0 12px"
            />
          )}
        </BannerBottomContainerWrapper>
      </BannerBottomContainer>
    </>
  );
};

export default Banner;
