import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IError } from '../../interfaces';

interface IErrorState extends IError {
  error: boolean;
}

const initialState: IErrorState = {
  error: false,
  errorMessage: '',
  errorDescription: '',
  hideBtn: false,
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    createError: (state, action: PayloadAction<IError>) => {
      state.error = true;
      state.errorMessage = action.payload.errorMessage;
      state.errorDescription = action.payload.errorDescription;
      state.hideBtn = action.payload.hideBtn;
    },
    removeError: (state) => {
      state.error = false;
      state.errorMessage = '';
      state.errorDescription = '';
    },
  },
});

export default errorSlice.reducer;

export const { createError, removeError } = errorSlice.actions;
