import { constants } from 'ethers';

import NetworkAddresses from './constants/network-addresses.json';
import { bETH, BUMP, bUSDCCoin, ETH, USDCoin, wBTC, WETH } from './tokenNames';

import { ContractListByChainIdType } from '../interfaces';

export const CONTRACTS: ContractListByChainIdType = {
  //MAINNETS-------------------------------
  [1]: {
    CONTRACT_ADDRESS: {
      Airdrop: NetworkAddresses['1'].CONTRACT_ADDRESS.Airdrop,
      BumpMarket: NetworkAddresses['1'].CONTRACT_ADDRESS.BumpMarket,
      Vesting: NetworkAddresses['1'].CONTRACT_ADDRESS.Vesting,
      Staking: NetworkAddresses['1'].CONTRACT_ADDRESS.Staking,
      Treasury: NetworkAddresses['1'].CONTRACT_ADDRESS.Treasury,
      ProtocolConfig: NetworkAddresses['1'].CONTRACT_ADDRESS.ProtocolConfig,
      Incentives: NetworkAddresses['1'].CONTRACT_ADDRESS.Incentives,
    },
    TOKEN_DETAILS: {
      [USDCoin.symbol]: {
        address: NetworkAddresses['1'].TOKEN_DETAILS.USDC,
        decimal: 6,
        enumId: 0,
      },
      [bUSDCCoin.symbol]: {
        address: NetworkAddresses['1'].TOKEN_DETAILS.BUSDC,
        decimal: 18,
        enumId: 0,
      },
      [BUMP.symbol]: {
        address: NetworkAddresses['1'].TOKEN_DETAILS.BUMP,
        decimal: 18,
        enumId: 0,
      },
      [ETH.symbol]: {
        address: NetworkAddresses['1'].TOKEN_DETAILS.WETH,
        decimal: 18,
        enumId: 0,
      },
      [WETH.symbol]: {
        address: NetworkAddresses['1'].TOKEN_DETAILS.WETH,
        decimal: 18,
        enumId: 0,
      },
      [wBTC.symbol]: {
        address: NetworkAddresses['1'].TOKEN_DETAILS.WBTC,
        decimal: 8,
        enumId: 0,
      },
      [bETH.symbol]: {
        address: NetworkAddresses['1'].TOKEN_DETAILS.BETH,
        decimal: 18,
        enumId: 0,
      },
    },
    ORACLES: {
      [WETH.symbol]: NetworkAddresses['1'].ORACLES.WETH,
      [wBTC.symbol]: NetworkAddresses['1'].ORACLES.WBTC,
    },
    CALCULATIONS: {
      [WETH.symbol]: NetworkAddresses['1'].CALCULATIONS.WETH,
    },
    ADAPTERS: {
      [WETH.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['1'].ADAPTERS.WETH.USDC,
      },
      [wBTC.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['1'].ADAPTERS.WBTC.USDC,
      },
    },
  },
  [10]: {
    CONTRACT_ADDRESS: {
      Airdrop: '',
      BumpMarket: '',
      Vesting: '',
      Staking: '',
      Treasury: '',
      ProtocolConfig: '',
      Incentives: '',
    },
    TOKEN_DETAILS: {
      [USDCoin.symbol]: {
        address: '',
        decimal: 6,
        enumId: 0,
      },
      [bUSDCCoin.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
      [BUMP.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
      [ETH.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
      [WETH.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
      [wBTC.symbol]: {
        address: '',
        decimal: 8,
        enumId: 0,
      },
      [bETH.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
    },
    ORACLES: {
      [WETH.symbol]: '',
      [wBTC.symbol]: '',
    },
    CALCULATIONS: {
      [WETH.symbol]: '',
    },
    ADAPTERS: {
      [WETH.symbol]: {
        [USDCoin.symbol]: '',
      },
      [wBTC.symbol]: {
        [USDCoin.symbol]: '',
      },
    },
  },
  [250]: {
    CONTRACT_ADDRESS: {
      Airdrop: '',
      BumpMarket: '',
      Vesting: '',
      Staking: '',
      Treasury: '',
      ProtocolConfig: '',
      Incentives: '',
    },
    TOKEN_DETAILS: {
      [USDCoin.symbol]: {
        address: '',
        decimal: 6,
        enumId: 0,
      },
      [bUSDCCoin.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
      [BUMP.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
      [ETH.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
      [WETH.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
      [wBTC.symbol]: {
        address: '',
        decimal: 8,
        enumId: 0,
      },
      [bETH.symbol]: {
        address: '',
        decimal: 18,
        enumId: 0,
      },
    },
    ORACLES: {
      [WETH.symbol]: '',
      [wBTC.symbol]: '',
    },
    CALCULATIONS: {
      [WETH.symbol]: '',
    },
    ADAPTERS: {
      [WETH.symbol]: {
        [USDCoin.symbol]: '',
      },
      [wBTC.symbol]: {
        [USDCoin.symbol]: '',
      },
    },
  },

  //TESTNETS--------------------------------
  [42]: {
    CONTRACT_ADDRESS: {
      Airdrop: NetworkAddresses['42'].CONTRACT_ADDRESS.Airdrop,
      BumpMarket: NetworkAddresses['42'].CONTRACT_ADDRESS.BumpMarket,
      Vesting: NetworkAddresses['42'].CONTRACT_ADDRESS.Vesting,
      Staking: NetworkAddresses['42'].CONTRACT_ADDRESS.Staking,
      Treasury: NetworkAddresses['42'].CONTRACT_ADDRESS.Treasury,
      ProtocolConfig: NetworkAddresses['42'].CONTRACT_ADDRESS.ProtocolConfig,
      Incentives: NetworkAddresses['42'].CONTRACT_ADDRESS.Incentives,
    },
    TOKEN_DETAILS: {
      [USDCoin.symbol]: {
        address: NetworkAddresses['42'].TOKEN_DETAILS.USDC,
        decimal: 6,
        enumId: 0,
      },
      [bUSDCCoin.symbol]: {
        address: NetworkAddresses['42'].TOKEN_DETAILS.BUSDC,
        decimal: 18,
        enumId: 0,
      },
      [BUMP.symbol]: {
        address: NetworkAddresses['42'].TOKEN_DETAILS.BUMP,
        decimal: 18,
        enumId: 0,
      },
      [ETH.symbol]: {
        address: NetworkAddresses['42'].TOKEN_DETAILS.WETH,
        decimal: 18,
        enumId: 0,
      },
      [WETH.symbol]: {
        address: NetworkAddresses['42'].TOKEN_DETAILS.WETH,
        decimal: 18,
        enumId: 0,
      },
      [wBTC.symbol]: {
        address: NetworkAddresses['42'].TOKEN_DETAILS.WBTC,
        decimal: 8,
        enumId: 0,
      },
      [bETH.symbol]: {
        address: NetworkAddresses['42'].TOKEN_DETAILS.BETH,
        decimal: 18,
        enumId: 0,
      },
    },
    ORACLES: {
      [WETH.symbol]: NetworkAddresses['42'].ORACLES.WETH,
      [wBTC.symbol]: NetworkAddresses['42'].ORACLES.WBTC,
    },
    CALCULATIONS: {
      [WETH.symbol]: NetworkAddresses['42'].CALCULATIONS.WETH,
    },
    ADAPTERS: {
      [WETH.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['42'].ADAPTERS.WETH.USDC,
      },
      [wBTC.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['42'].ADAPTERS.WBTC.USDC,
      },
    },
  },
  [5]: {
    CONTRACT_ADDRESS: {
      Airdrop: NetworkAddresses['5'].CONTRACT_ADDRESS.Airdrop,
      BumpMarket: NetworkAddresses['5'].CONTRACT_ADDRESS.BumpMarket,
      Vesting: NetworkAddresses['5'].CONTRACT_ADDRESS.Vesting,
      Staking: NetworkAddresses['5'].CONTRACT_ADDRESS.Staking,
      Treasury: NetworkAddresses['5'].CONTRACT_ADDRESS.Treasury,
      ProtocolConfig: NetworkAddresses['5'].CONTRACT_ADDRESS.ProtocolConfig,
      Incentives: NetworkAddresses['5'].CONTRACT_ADDRESS.Incentives,
    },
    TOKEN_DETAILS: {
      [USDCoin.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.USDC,
        decimal: 6,
        enumId: 0,
      },
      [bUSDCCoin.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.BUSDC,
        decimal: 18,
        enumId: 0,
      },
      [BUMP.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.BUMP,
        decimal: 18,
        enumId: 0,
      },
      [ETH.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.WETH,
        decimal: 18,
        enumId: 0,
      },
      [WETH.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.WETH,
        decimal: 18,
        enumId: 0,
      },
      [wBTC.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.WBTC,
        decimal: 8,
        enumId: 0,
      },
      [bETH.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.BETH,
        decimal: 18,
        enumId: 0,
      },
    },
    ORACLES: {
      [WETH.symbol]: NetworkAddresses['5'].ORACLES.WETH,
      [wBTC.symbol]: NetworkAddresses['5'].ORACLES.WBTC,
    },
    CALCULATIONS: {
      [WETH.symbol]: NetworkAddresses['5'].CALCULATIONS.WETH,
    },
    ADAPTERS: {
      [WETH.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['5'].ADAPTERS.WETH.USDC,
      },
      [wBTC.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['5'].ADAPTERS.WBTC.USDC,
      },
    },
  },
  [12345]: {
    CONTRACT_ADDRESS: {
      Airdrop: NetworkAddresses['12345'].CONTRACT_ADDRESS.Airdrop,
      BumpMarket: NetworkAddresses['12345'].CONTRACT_ADDRESS.BumpMarket,
      Vesting: NetworkAddresses['12345'].CONTRACT_ADDRESS.Vesting,
      Staking: NetworkAddresses['12345'].CONTRACT_ADDRESS.Staking,
      Treasury: NetworkAddresses['12345'].CONTRACT_ADDRESS.Treasury,
      ProtocolConfig: NetworkAddresses['12345'].CONTRACT_ADDRESS.ProtocolConfig,
      Incentives: NetworkAddresses['12345'].CONTRACT_ADDRESS.Incentives,
    },
    TOKEN_DETAILS: {
      [USDCoin.symbol]: {
        address:
          NetworkAddresses['12345'].TOKEN_DETAILS.USDC || constants.AddressZero,
        decimal: 6,
        enumId: 0,
      },
      [bUSDCCoin.symbol]: {
        address:
          NetworkAddresses['12345'].TOKEN_DETAILS.BUSDC ||
          constants.AddressZero,
        decimal: 18,
        enumId: 0,
      },
      [BUMP.symbol]: {
        address:
          NetworkAddresses['12345'].TOKEN_DETAILS.BUMP || constants.AddressZero,
        decimal: 18,
        enumId: 0,
      },
      [ETH.symbol]: {
        address:
          NetworkAddresses['12345'].TOKEN_DETAILS.WETH || constants.AddressZero,
        decimal: 18,
        enumId: 0,
      },
      [WETH.symbol]: {
        address:
          NetworkAddresses['12345'].TOKEN_DETAILS.WETH || constants.AddressZero,
        decimal: 18,
        enumId: 0,
      },
      [wBTC.symbol]: {
        address:
          NetworkAddresses['12345'].TOKEN_DETAILS.WBTC ||
          NetworkAddresses['12345'].TOKEN_DETAILS.WETH ||
          constants.AddressZero,
        decimal: 8,
        enumId: 0,
      },
      [bETH.symbol]: {
        address:
          NetworkAddresses['12345'].TOKEN_DETAILS.BETH || constants.AddressZero,
        decimal: 18,
        enumId: 0,
      },
    },
    ORACLES: {
      [WETH.symbol]: NetworkAddresses['12345'].ORACLES.WETH,
      [wBTC.symbol]: NetworkAddresses['12345'].ORACLES.WBTC,
    },
    CALCULATIONS: {
      [WETH.symbol]: NetworkAddresses['12345'].CALCULATIONS.WETH,
    },
    ADAPTERS: {
      [WETH.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['12345'].ADAPTERS.WETH.USDC,
      },
      [wBTC.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['12345'].ADAPTERS.WBTC.USDC,
      },
    },
  },

  //TESTNETS--------------------------------
  [31337]: {
    CONTRACT_ADDRESS: {
      Airdrop: NetworkAddresses['5'].CONTRACT_ADDRESS.Airdrop,
      BumpMarket: NetworkAddresses['5'].CONTRACT_ADDRESS.BumpMarket,
      Vesting: NetworkAddresses['5'].CONTRACT_ADDRESS.Vesting,
      Staking: NetworkAddresses['5'].CONTRACT_ADDRESS.Staking,
      Treasury: NetworkAddresses['5'].CONTRACT_ADDRESS.Treasury,
      ProtocolConfig: NetworkAddresses['5'].CONTRACT_ADDRESS.ProtocolConfig,
      Incentives: NetworkAddresses['5'].CONTRACT_ADDRESS.Incentives,
    },
    TOKEN_DETAILS: {
      [USDCoin.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.USDC,
        decimal: 6,
        enumId: 0,
      },
      [bUSDCCoin.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.BUSDC,
        decimal: 18,
        enumId: 0,
      },
      [BUMP.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.BUMP,
        decimal: 18,
        enumId: 0,
      },
      [ETH.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.WETH,
        decimal: 18,
        enumId: 0,
      },
      [WETH.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.WETH,
        decimal: 18,
        enumId: 0,
      },
      [wBTC.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.WBTC,
        decimal: 8,
        enumId: 0,
      },
      [bETH.symbol]: {
        address: NetworkAddresses['5'].TOKEN_DETAILS.BETH,
        decimal: 18,
        enumId: 0,
      },
    },
    ORACLES: {
      [WETH.symbol]: NetworkAddresses['5'].ORACLES.WETH,
      [wBTC.symbol]: NetworkAddresses['5'].ORACLES.WBTC,
    },
    CALCULATIONS: {
      [WETH.symbol]: NetworkAddresses['5'].CALCULATIONS.WETH,
    },
    ADAPTERS: {
      [WETH.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['5'].ADAPTERS.WETH.USDC,
      },
      [wBTC.symbol]: {
        [USDCoin.symbol]: NetworkAddresses['5'].ADAPTERS.WBTC.USDC,
      },
    },
  },
};
