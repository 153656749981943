import { createAsyncThunk } from '@reduxjs/toolkit';

import { ContractListType } from '../../interfaces';
import { getContractsAddresses } from '../../services/servicesUtils';

export const getContractsAddressesAction = createAsyncThunk<ContractListType>(
  'getContractsAddressesAction',
  async () => {
    return await getContractsAddresses();
  },
);
