import { Box } from '@bumper-dao/ui-kit';
import React, { ReactNode } from 'react';

export const getFlowAssetIcon = (
  flowIcon: string,
  assetIcon: string,
  width?: string,
  height?: string,
  firstIconWidth?: string,
  firstIconHeight?: string,
  secondIconWidth?: string,
  secondIconHeight?: string,
): ReactNode => {
  return (
    <Box position="relative" width={width || '56px'} height={height || '49px'}>
      <img
        src={flowIcon}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: firstIconWidth || '71.5%',
          height: firstIconHeight || '81.6%',
          zIndex: 5,
        }}
        alt="flowIcon"
      />
      <img
        src={assetIcon}
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: secondIconWidth || '57.1%',
          height: secondIconHeight || '65.3%',
          zIndex: 6,
        }}
        alt="flowIcon"
      />
    </Box>
  );
};
