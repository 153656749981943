import { AssetLabel, Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

export const PositionHeaderWrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }
`;

export const PositionHeaderInfoWrapper = styled(Flex)<{
  gap?: string;
  flexDirection?: string;
  alignItems?: string;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
  }
`;

export const CoinName = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
`;

export const PositionHeaderActionWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 34px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    width: 100%;
    align-items: start;
    gap: 24px;
  }
`;

export const ButtonsWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 32px;
`;

export const ButtonsDivider = styled(Flex)`
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
`;

export const AutorenewWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 23px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
`;

export const PositionContentWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: rgba(18, 191, 212, 0.1);
  box-shadow: 0 -6px 0 6px rgba(18, 191, 212, 0.1);

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-bottom: 1.5rem;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
  }
`;

export const ContentProtectedWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 0.75rem;
  max-width: calc(100% - 2rem);

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
  }
`;

export const PositionInfoWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 40px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    align-items: start;
    gap: 28px;
    width: 100%;
  }
`;

export const PositionInfoBlockWrapper = styled(AssetLabel)<{ padd?: string }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: unset;
  min-width: 162px;
  max-height: 70px;
  min-height: 70px;
  justify-content: space-between;
  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: auto;
    max-height: 70px;
  }
  gap: 4px;
  padding: ${({ padd }) => (padd ? padd : '10px 16px')};
`;
export const PositionInfoBlockLarge = styled(AssetLabel)`
  display: flex;
  align-items: center;
  flex-direction: row;
  max-width: unset;
  min-width: unset;
  min-height: 70px;
  justify-content: space-between;
  ${({ theme }) => theme.mediaQueries.sm} {
    min-height: auto;
    max-height: 70px;
  }
  gap: 24px;
  padding: 8px 16px;
`;
export const PeriodLabel = styled(AssetLabel)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: unset;
  min-width: unset;
  max-height: unset;
  padding: 4px 12px;
  background-color: ${({ theme }) => theme.colors.primary2.midnightGreen};
  box-shadow: ${({ theme }) => `
    4px 0 ${theme.colors.primary2.cyprus},
    -4px 0 ${theme.colors.primary2.cyprus},
    0 4px ${theme.colors.primary2.cyprus},
    0 -4px ${theme.colors.primary2.cyprus}
  `};
  ${({ theme }) => theme.mediaQueries.sm} {
    width: fit-content;
    height: 32px;
  }
`;
export const PositionInfoBlockInset = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
`;

export const MobileFieldContainerWrapper = styled(Flex)`
  width: 100%;
  align-items: start;
  flex-direction: column;
  gap: 8px;
`;

export const ContentHeadingWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;

  & > *:first-child {
    margin-right: 0.25rem;
  }
`;

export const ContentValueWrapper = styled(Flex)`
  justify-content: center;

  & > *:first-child {
    margin-right: 0.25rem;
  }
`;

export const CoinKey = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
`;

export const ContentWrapper = styled(Flex)`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 40px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    gap: 32px;
    padding: 10px 0;
  }
`;

export const InfoBoxContent = styled(Flex)`
  flex-direction: column;
  align-items: start;
  gap: 4px;
`;
