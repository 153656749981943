import {
  Bump,
  DarkInfoBox,
  FlowCard,
  Heading,
  IconSize,
  LargeText,
  lightColors,
  Radiator,
  SmallText,
  Tooltip,
  Flex,
  Button,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { FC } from 'react';
import { useNavigate } from 'react-router';

import {
  CoinKey,
  CoinName,
  ContentHeadingWrapper,
  ContentValueWrapper,
  ContentWrapper,
  InfoBoxContent,
} from './styles';
import { AssetSummaryProps } from './types';

import BottomLeftCornerAsset from '../../assets/cardCorners/leftAsset.svg';
import BottomRightCornerAsset from '../../assets/cardCorners/rightAsset.svg';
import { Routes, subRoutes } from '../../core/config/routes';
import { useChainName } from '../../core/hooks/useChain';

export const StakingAssetSummaryCard: FC<AssetSummaryProps> = (props) => {
  const { isMobile } = useMatchBreakpoints();
  return (
    <FlowCard
      header={<Header />}
      content={<Content {...props} />}
      bottomLeftCorner={BottomLeftCornerAsset}
      bottomRightCorner={BottomRightCornerAsset}
      bottomLedgeHeight="38px"
      bottomLedgeCenterWidth={isMobile ? '281px' : '1030px'}
      mt="4rem"
      mb="3rem"
    />
  );
};

const Content: FC<AssetSummaryProps> = ({
  idle,
  idleInUsd,
  staked,
  stakedInUsd,
  total,
  totalInUsd,
  totalRewards,
  positionsCount,
}) => {
  return (
    <ContentWrapper>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Total
          </Heading>
          <ContentValueWrapper>
            <LargeText fontWeight={500} color={lightColors.secondary.white}>
              {total}
            </LargeText>
            <CoinKey>BUMP</CoinKey>
          </ContentValueWrapper>
          <SmallText color={lightColors.primary2.cambridgeBlue}>
            (${totalInUsd})
          </SmallText>
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Idle
          </Heading>
          <ContentValueWrapper>
            <LargeText fontWeight={500} color={lightColors.secondary.red}>
              {idle}
            </LargeText>
            <CoinKey>BUMP</CoinKey>
          </ContentValueWrapper>
          <SmallText color={lightColors.primary2.cambridgeBlue}>
            (${idleInUsd})
          </SmallText>
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <ContentHeadingWrapper>
            <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
              Staked
            </Heading>
            <Tooltip tooltipValue={'fdsfdsg'} place="bottom" />
          </ContentHeadingWrapper>
          <ContentValueWrapper>
            <LargeText
              fontWeight={500}
              color={lightColors.primary1.vividTangelo}
            >
              {staked}
            </LargeText>
            <CoinKey>BUMP</CoinKey>
          </ContentValueWrapper>
          <SmallText color={lightColors.primary2.desaturatedCyan}>
            (${stakedInUsd})
          </SmallText>
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <ContentHeadingWrapper>
            <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
              Total Rewards
            </Heading>
            <Tooltip tooltipValue={'fdsfdsg'} place="bottom" />
          </ContentHeadingWrapper>
          <LargeText fontWeight={500} color={lightColors.typography.input}>
            ${totalRewards}
          </LargeText>
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Positions
          </Heading>
          <LargeText fontWeight={500} color={lightColors.secondary.white}>
            {positionsCount}
          </LargeText>
        </InfoBoxContent>
      </DarkInfoBox>
    </ContentWrapper>
  );
};

const Header = () => {
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const chainName = useChainName();
  return (
    <ContentWrapper justifyContent="space-between">
      <CoinName>
        <Bump variant={IconSize.XL} />
        <LargeText
          fontSize="18px"
          lineHeight="150%"
          letterSpacing="0.03em"
          color={lightColors.secondary.white}
        >
          BUMP
        </LargeText>
      </CoinName>
      {!isMobile && (
        <Flex width="100%" flex={1}>
          <Radiator width="100%" />
        </Flex>
      )}
      <Button
        primary
        size={IconSize.L}
        padding="0.25rem 1rem"
        letterSpacing="0.03em"
        lineHeight="20px"
        minHeight="32px"
        onClick={() => {
          navigate(`${Routes.StakeFlow}/${chainName}/${subRoutes.Select}`);
        }}
      >
        Stake BUMP
      </Button>
    </ContentWrapper>
  );
};
