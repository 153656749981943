import {
  Box,
  Button,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import stakeIcon from '@bumper-dao/ui-kit/dist/images/stake.svg';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SummaryStakingProps } from './types';

import homeIcon from '../../assets/home.svg';
import { feedbackLink } from '../../core/config/links';
import { Routes } from '../../core/config/routes';
import { BUMP } from '../../core/config/tokenNames';
import { tokensIcons } from '../../core/config/tokensIcons';
import { getEtherscanLink } from '../../core/funcs/getEtherscanLink';
import { getFlowAssetIcon } from '../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../core/hooks/useChain';
import { useShortHash } from '../../core/hooks/useShortHash';
import { isFeatureEnabled } from '../../core/utils/features';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';

export const SummaryStaking: React.FC = () => {
  const state = useLocation().state as SummaryStakingProps;
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const chain = useChain();
  const shortHash = useShortHash(state.txHash);

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" mb="50px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Stake
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <Box
            style={{
              position: 'absolute',
              top: '-50px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {getFlowAssetIcon(stakeIcon, tokensIcons[BUMP.symbol])}
          </Box>
        }
        linkUrl={getEtherscanLink(state.txHash || '', chain.config)}
        linkText={shortHash}
        px={isMobile ? '8px' : ''}
      >
        <Flex alignItems="center" justifyContent="space-between" mb="20px">
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Staking Term:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {state.option?.periodInDays && state.activeOptionIndex !== 0
              ? `${state.option.periodInDays} Days`
              : 'Flexible'}
          </LargeText>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mb="20px">
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            BUMP Stake:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {formatStringifyNumberToDot(state.stakeAmount.toString())}
            <MediumText display="inline-block" ml="4px" color="secondary.white">
              BUMP
            </MediumText>
          </LargeText>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Auto-renew:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {state.autorenew ? 'On' : 'Off'}
          </LargeText>
        </Flex>
      </ConfirmCard>
      <Flex
        alignItems="center"
        justifyContent={
          isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
        }
        mt="40px"
      >
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => {
            navigate(`${Routes.Dashboard}`);
          }}
          height="48px"
        >
          <img src={homeIcon} alt="" />
          Dashboard
        </Button>
        {isFeatureEnabled('SUPPORT') && (
          <Button
            secondary
            size={isMobile ? IconSize.L : IconSize.XL}
            onClick={() => {
              window.open(feedbackLink, '_blank');
            }}
            height="48px"
          >
            Feedback
          </Button>
        )}
      </Flex>
    </Box>
  );
};
