import { DashboardCard } from '@bumper-dao/ui-kit';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { StakeHubCardType } from './StakeHub/types';

import {
  FullScreenWidthContainer,
  MainContainer,
} from '../components/common/MainContainers';
import { SwitchNetworkBanner } from '../components/common/SwitchNetworkBanner/SwitchNetworkBanner';
import { BumpBalanceCard } from '../components/Dashboard/BumpBalanceCard';
import { Routes, subRoutes } from '../core/config/routes';
import { Slides } from '../core/config/slides';
import { WalletContext } from '../core/config/walletContext';
import { useChainName } from '../core/hooks/useChain';
import { LargeTotalCardType } from '../core/interfaces';
import { MakerPositionService } from '../core/services/makerPositionService';
import { StakingService } from '../core/services/stakingService';
import { TakerPositionService } from '../core/services/takerPositionService';
import { useAppSelector } from '../core/state/hooks';
import { formatStringifyNumberToDot } from '../core/utils/helpers';

export const Dashboard: FC = () => {
  const navigate = useNavigate();
  const chainName = useChainName();
  const { isWalletConnected } = useContext(WalletContext);
  const tokensDetails = useAppSelector((state) => state.coin.coinDetails);

  const [takerPositionsData, setTakerPositionsData] =
    useState<LargeTotalCardType | null>(null);
  const takerService = TakerPositionService.getInstance();

  const [makerPositionsData, setMakerPositionsData] =
    useState<LargeTotalCardType | null>(null);
  const makerService = MakerPositionService.getInstance();

  const [userStakePositions, setUserStakePositions] =
    useState<StakeHubCardType | null>(null);
  const stakeService = StakingService.getInstance();

  useEffect(() => {
    Promise.allSettled([
      takerService.calculateTakerTotalPositionsData(tokensDetails),
      makerService.calculateMakerTotalPositionsData(tokensDetails),
      stakeService.calculateTotalStakingData(tokensDetails),
    ]).then(([takerData, makerData, stakeData]) => {
      if (takerData.status === 'fulfilled')
        setTakerPositionsData(takerData.value.resultForLargeCard);
      if (makerData.status === 'fulfilled')
        setMakerPositionsData(makerData.value.resultForLargeCard);
      if (stakeData.status === 'fulfilled')
        setUserStakePositions(stakeData.value);
    });
  }, [tokensDetails]);

  const calcRatio = (first: string | undefined, second: string | undefined) =>
    formatStringifyNumberToDot((+(first || 0) / +(second || 1)).toString(), 2);

  const getLargeTotalCardWalletAmount = (
    largeCard: LargeTotalCardType | null,
  ): number => {
    return largeCard ? parseFloat(largeCard.assetBalanceInUSDC) : 0;
  };

  const getStakeCardWalletAmount = (
    largeCard: StakeHubCardType | null,
  ): number => {
    return largeCard ? parseFloat(largeCard.idleBUMPInUSD) : 0;
  };

  const getDashOrValue = (value: string, withoutUSD?: boolean) => {
    return parseFloat(value) > 0
      ? (withoutUSD ? '' : '$') + formatStringifyNumberToDot(value, 2)
      : '_';
  };

  return (
    <FullScreenWidthContainer>
      <SwitchNetworkBanner page="dashboard" slides={Slides} />
      <MainContainer m="100px auto 56px auto !important">
        <BumpBalanceCard
          socketStates={{
            protect: !!takerPositionsData?.positionsCount,
            earn: !!makerPositionsData?.positionsCount,
            stake: !!userStakePositions?.positionsCount,
          }}
        />
        <DashboardCard
          cardSocketName={isWalletConnected ? 'Protect' : 'Off'}
          cardName={'Protect'}
          detailsIsActive={!isWalletConnected}
          nextIsActive={
            !(
              isWalletConnected &&
              getLargeTotalCardWalletAmount(takerPositionsData) > 0
            )
          }
          detailsClick={() => navigate(`${Routes.Protect}/${chainName}`)}
          nextClick={() =>
            navigate(`${Routes.Protect}/${chainName}/${subRoutes.Select}`)
          }
          total={getDashOrValue(takerPositionsData?.totalAmountInUSDC || '0')}
          secondFieldName={'Protected'}
          secondFieldValue={getDashOrValue(
            takerPositionsData?.investedAmountInUSDC || '0',
          )}
          secondFieldTooltip={'Protected'}
          thirdFieldName={'Unprotected'}
          thirdFieldValue={getDashOrValue(
            takerPositionsData?.assetBalanceInUSDC || '0',
          )}
          thirdFieldTooltip={'Unprotected'}
          fourthFieldName={'Protected Ratio'}
          fourthFieldValue={getDashOrValue(
            calcRatio(
              takerPositionsData?.investedAmountInUSDC,
              takerPositionsData?.totalAmountInUSDC,
            ),
            true,
          )}
          fourthFieldTooltip={'Protected Ratio'}
        />
        <DashboardCard
          cardName={'Earn'}
          cardSocketName={isWalletConnected ? 'Earn' : 'Off'}
          detailsIsActive={!isWalletConnected}
          detailsClick={() => navigate(`${Routes.Earn}/${chainName}`)}
          nextIsActive={
            !(
              isWalletConnected &&
              getLargeTotalCardWalletAmount(makerPositionsData) > 0
            )
          }
          nextClick={() =>
            navigate(`${Routes.Earn}/${chainName}/${subRoutes.Select}`)
          }
          total={getDashOrValue(makerPositionsData?.totalAmountInUSDC || '0')}
          secondFieldName={'Earning'}
          secondFieldValue={getDashOrValue(
            makerPositionsData?.investedAmountInUSDC || '0',
          )}
          secondFieldTooltip={'Earning'}
          thirdFieldName={'Idle'}
          thirdFieldValue={getDashOrValue(
            makerPositionsData?.assetBalanceInUSDC || '0',
          )}
          thirdFieldTooltip={'Idle'}
          fourthFieldName={'Earning Ratio'}
          fourthFieldValue={getDashOrValue(
            calcRatio(
              makerPositionsData?.investedAmountInUSDC,
              makerPositionsData?.totalAmountInUSDC,
            ),
            true,
          )}
          fourthFieldTooltip={'Earning Ratio'}
        />
        <DashboardCard
          cardName={'Stake'}
          cardSocketName={isWalletConnected ? 'Stake' : 'Off'}
          detailsIsActive={!isWalletConnected}
          detailsClick={() =>
            navigate(`${Routes.Stake}/${chainName}`, { replace: true })
          }
          nextIsActive={
            !(
              isWalletConnected &&
              getStakeCardWalletAmount(userStakePositions) > 0
            )
          }
          nextClick={() =>
            navigate(`${Routes.StakeFlow}/${chainName}/${subRoutes.Select}`, {
              replace: true,
            })
          }
          total={getDashOrValue(userStakePositions?.totalAmountInUSDC || '0')}
          secondFieldName={'Staked'}
          secondFieldValue={getDashOrValue(
            userStakePositions?.totalStakingAmountInUSD || '0',
          )}
          secondFieldTooltip={'Total of BUMP tokens'}
          thirdFieldName={'Idle'}
          thirdFieldValue={getDashOrValue(
            userStakePositions?.idleBUMPInUSD || '0',
          )}
          thirdFieldTooltip={'BUMP tokens sitting in your wallet'}
          fourthFieldName={'Staking Ratio'}
          fourthFieldValue={
            userStakePositions
              ? (
                  parseFloat(userStakePositions?.stakedBUMP) /
                  parseFloat(userStakePositions?.totalBUMP)
                ).toFixed(2)
              : '_'
          }
          fourthFieldTooltip="Staking Ratio"
        />
      </MainContainer>
    </FullScreenWidthContainer>
  );
};
