import { createSlice } from '@reduxjs/toolkit';

import {
  bETH,
  BUMP,
  bUSDCCoin,
  ETH,
  USDCoin,
  wBTC,
  WETH,
} from '../../config/tokenNames';
import { ContractListType } from '../../interfaces';
import { getContractsAddressesAction } from '../actions/contractsAddressesActions';

const initialState: ContractListType = {
  CONTRACT_ADDRESS: {
    Airdrop: '',
    BumpMarket: '',
    Vesting: '',
    Staking: '',
    Treasury: '',
    ProtocolConfig: '',
    Incentives: '',
  },
  TOKEN_DETAILS: {
    [USDCoin.symbol]: {
      address: '',
      decimal: 6,
      enumId: 0,
    },
    [bUSDCCoin.symbol]: {
      address: '',
      decimal: 18,
      enumId: 0,
    },
    [BUMP.symbol]: {
      address: '',
      decimal: 18,
      enumId: 0,
    },
    [ETH.symbol]: {
      address: '',
      decimal: 18,
      enumId: 0,
    },
    [WETH.symbol]: {
      address: '',
      decimal: 18,
      enumId: 0,
    },
    [wBTC.symbol]: {
      address: '',
      decimal: 18,
      enumId: 0,
    },
    [bETH.symbol]: {
      address: '',
      decimal: 18,
      enumId: 0,
    },
  },
  ORACLES: {
    [WETH.symbol]: '',
    [wBTC.symbol]: '',
  },
  CALCULATIONS: {
    [WETH.symbol]: '',
  },
  ADAPTERS: {
    [WETH.symbol]: {
      [USDCoin.symbol]: '',
    },
    [wBTC.symbol]: {
      [USDCoin.symbol]: '',
    },
  },
};

export const contractsAddressesSlice = createSlice({
  name: 'contractsAddresses',
  initialState,
  reducers: {
    resetContractsAddresses: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getContractsAddressesAction.fulfilled, (state, action) => {
      state.CONTRACT_ADDRESS = action.payload.CONTRACT_ADDRESS;
      state.TOKEN_DETAILS = action.payload.TOKEN_DETAILS;
      state.CALCULATIONS = action.payload.CALCULATIONS;
      state.ORACLES = action.payload.ORACLES;
      state.ADAPTERS = action.payload.ADAPTERS;
    });
  },
});

export default contractsAddressesSlice.reducer;
export const { resetContractsAddresses } = contractsAddressesSlice.actions;
