import { BannerSlideProps, YellowOrangeDiamond } from '@bumper-dao/ui-kit';
import React from 'react';

export const Slides: BannerSlideProps[] = [
  {
    headerText: 'God-Mode for Crypto',
    contentText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus est pellentesque risus commodo.',
    linkText: 'Learn More',
    linkPath: '',
    icon: <YellowOrangeDiamond />,
  },
  {
    headerText: 'God-Mode for Crypto',
    contentText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus est pellentesque risus commodo.',
    linkText: 'Learn More',
    linkPath: '',
    icon: <YellowOrangeDiamond />,
  },
];
