import { NetworkUniswapV3PoolInfo } from './types';

const uniswapV3PoolFees = {
  [1]: [],
  [10]: [],
  [250]: [],
  [5]: [
    {
      // TEST_WETH-WETH pool
      token0: '0x4e127Cd6d6dc69c8F1B0002da6dCFc7E8916F231',
      token1: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
      pool: '0x23d2FE139392343439603b89a9FBD29B9ac71209',
      fee: 3000,
    },
  ],
} as NetworkUniswapV3PoolInfo;

export default uniswapV3PoolFees;
