import {
  Quoter__factory,
  SwapRouter__factory,
} from '@bumper-dao/contract-interfaces';
import { BigNumber, ethers } from 'ethers';
import { getAddress } from 'ethers/lib/utils';

import { EthersServiceProvider } from './ethersServiceProvider';
import { getContractsAddresses } from './servicesUtils';

import { getUniswapV3WethPoolInfos } from '../config/constants/helpers';
import {
  getNetworkConfigsByEnv,
  SUPPORTED_CHAINS,
} from '../config/supportedChains';
import { BUMP, USDCoin, WETH } from '../config/tokenNames';
import { encodePath } from '../utils/contract-helpers';

export class UniswapService {
  private static instance: UniswapService;

  private ethersServiceProvider: EthersServiceProvider;

  private readonly routerAddress = process.env
    .REACT_APP_SWAP_ROUTER_ADDR as string;
  private readonly quoterAddress = process.env
    .REACT_APP_SWAP_QUOTER_ADDR as string;

  private readonly bumpWethFee = +(process.env
    .REACT_APP_SWAP_BUMP_WETH_FEE as string);
  private readonly wethUsdcFee = +(process.env
    .REACT_APP_SWAP_WETH_USDC_FEE as string);

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
  }

  public static getInstance(): UniswapService {
    if (!UniswapService.instance) {
      UniswapService.instance = new UniswapService();
    }

    return UniswapService.instance;
  }
  /**
   * @param fee is optional (and will be ignored) if tokenIn - WETH
   */
  public async getTokenPriceInUsdcV3(
    amountIn: BigNumber,
    tokenIn: string,
  ): Promise<BigNumber> {
    const currentNetwork =
      await this.ethersServiceProvider.provider?.getNetwork();
    const weth = await this.getWeth();
    const { TOKEN_DETAILS } = await getContractsAddresses();

    if (getAddress(tokenIn) === weth) return this.getWethPriceInUSDC(amountIn);

    const poolInfos = getUniswapV3WethPoolInfos(
      tokenIn,
      currentNetwork?.chainId ?? 1,
    );
    const fee = poolInfos.length !== 0 ? poolInfos[0].fee : undefined;

    if (!fee) throw new Error('Fee was not provided for non-weth pool');

    return this.getAmountsOutV3(
      amountIn,
      [getAddress(tokenIn), weth, TOKEN_DETAILS[USDCoin.symbol].address],
      [fee, this.wethUsdcFee],
    );
  }

  public async getAmountsOutV3(
    amountIn: BigNumber,
    path: readonly string[],
    fees: readonly number[],
  ): Promise<BigNumber> {
    if (amountIn.isZero()) return ethers.constants.Zero;

    const quoter = this.getQuoter();

    const encodedPath = encodePath(path, fees);

    try {
      return await quoter.callStatic.quoteExactInput(encodedPath, amountIn);
    } catch (e) {
      console.error(e);
    }

    return BigNumber.from('0');
  }

  public async getWethPriceInUSDC(wethAmount: BigNumber): Promise<BigNumber> {
    const weth = await this.getWeth();
    const { TOKEN_DETAILS } = await getContractsAddresses();

    return this.getAmountsOutV3(
      wethAmount,
      [weth, TOKEN_DETAILS[USDCoin.symbol].address],
      [this.wethUsdcFee],
    );
  }

  public async getBumpPriceInUSDC(bumpAmount: BigNumber): Promise<BigNumber> {
    const weth = await this.getWeth();
    const { TOKEN_DETAILS } = await getContractsAddresses();

    return this.getAmountsOutV3(
      bumpAmount,
      [
        TOKEN_DETAILS[BUMP.symbol].address,
        weth,
        TOKEN_DETAILS[USDCoin.symbol].address,
      ],
      [this.bumpWethFee, this.wethUsdcFee],
    );
  }

  public async getUSDCinBump(usdcAmount: BigNumber): Promise<BigNumber> {
    const weth = await this.getWeth();
    const { TOKEN_DETAILS } = await getContractsAddresses();

    return this.getAmountsOutV3(
      usdcAmount,
      [
        TOKEN_DETAILS[USDCoin.symbol].address,
        weth,
        TOKEN_DETAILS[BUMP.symbol].address,
      ],
      [this.wethUsdcFee, this.bumpWethFee],
    );
  }
  public getRouter() {
    return SwapRouter__factory.connect(
      this.routerAddress,
      this.ethersServiceProvider.currentAccount
        ? (this.ethersServiceProvider.provider?.getSigner(
            0,
          ) as ethers.providers.JsonRpcSigner)
        : new ethers.providers.JsonRpcProvider(
            getNetworkConfigsByEnv()[SUPPORTED_CHAINS[0]].config.rpcUrls[0],
          ),
    );
  }

  public getQuoter() {
    return Quoter__factory.connect(
      this.quoterAddress,
      this.ethersServiceProvider.currentAccount
        ? (this.ethersServiceProvider.provider?.getSigner(
            0,
          ) as ethers.providers.JsonRpcSigner)
        : new ethers.providers.JsonRpcProvider(
            getNetworkConfigsByEnv()[SUPPORTED_CHAINS[0]].config.rpcUrls[0],
          ),
    );
  }

  public async getWeth() {
    if (
      (process.env.REACT_APP_ENVIRONMENT as string) === 'alpha' ||
      (process.env.REACT_APP_ENVIRONMENT as string) === 'beta'
    ) {
      const { TOKEN_DETAILS } = await getContractsAddresses();
      return TOKEN_DETAILS[WETH.symbol].address;
    }
    return this.getRouter().WETH9();
  }
}
