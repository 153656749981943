import { FlowHeader, StepStatus, Flex } from '@bumper-dao/ui-kit';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AddDepositLiqudityContainer } from './AddDepositLiqudityContainer';
import { ConfirmDepositCardContainer } from './ConfirmDepositCardContainer';
import {
  confirmStatusSteps,
  detailsStatusSteps,
  earnDepositFlowSteps,
  summaryStatusSteps,
} from './contsant';
import { PolicyDepositContainer } from './PolicyDepositContainer';

import { Routes, subRoutes } from '../../core/config/routes';
import { useAppDispatch } from '../../core/state/hooks';
import { toggleToolbar } from '../../core/state/reducers/uiStateManagementReducer';
import { TransactionConfirm, TransactionInProgress } from '../Transaction';

export const EarnDepositFlowPage: React.FC = () => {
  const history = useNavigate();
  const { step } = useParams<{ step?: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());

    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history(Routes.Dashboard);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Select: {
        return <AddDepositLiqudityContainer />;
      }
      case subRoutes.Confirm: {
        return <ConfirmDepositCardContainer />;
      }
      case subRoutes.Approve: {
        return <TransactionConfirm steps={3} />;
      }
      case subRoutes.Processing: {
        return <TransactionInProgress />;
      }
      case subRoutes.Summary: {
        return <PolicyDepositContainer />;
      }
      default: {
        return <AddDepositLiqudityContainer />;
      }
    }
  };

  const getStatus = (): StepStatus[] => {
    switch (step) {
      case subRoutes.Select: {
        return detailsStatusSteps;
      }
      case subRoutes.Confirm: {
        return confirmStatusSteps;
      }
      case subRoutes.Approve: {
        return confirmStatusSteps;
      }
      case subRoutes.Processing: {
        return confirmStatusSteps;
      }
      case subRoutes.Summary: {
        return summaryStatusSteps;
      }
      default: {
        return detailsStatusSteps;
      }
    }
  };

  return (
    <>
      <FlowHeader
        cancelClick={onCancel}
        logoClick={() => history(Routes.Dashboard)}
        steps={step === subRoutes.Processing ? [] : earnDepositFlowSteps}
        stepsStatus={getStatus()}
      />
      <Flex py="64px" justifyContent="center" alignItems="center" flex={1}>
        {showSection()}
      </Flex>
    </>
  );
};
