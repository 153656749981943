import {
  Box,
  Button,
  ConfirmCard,
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ClaimSummaryProps } from './types';

import BumpIcon from '../../assets/BUMPSquare.svg';
import homeIcon from '../../assets/home.svg';
import { feedbackLink } from '../../core/config/links';
import { Routes } from '../../core/config/routes';
import { BUMP } from '../../core/config/tokenNames';
import { getEtherscanLink } from '../../core/funcs/getEtherscanLink';
import { useChain } from '../../core/hooks/useChain';
import { useShortHash } from '../../core/hooks/useShortHash';
import { useAppSelector } from '../../core/state/hooks';
import { isFeatureEnabled } from '../../core/utils/features';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';

export const SummaryVestingClaim: React.FC = () => {
  const state = useLocation().state as ClaimSummaryProps;
  const bumpDetails = useAppSelector(
    (state) => state.coin.coinDetails[BUMP.symbol],
  );
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const chain = useChain();
  const shortHash = useShortHash(state.txHash);

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" marginBottom="64px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Claim
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <img
            src={BumpIcon}
            style={{
              width: '48px',
              height: '48px',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-50px',
            }}
          />
        }
        linkUrl={getEtherscanLink(shortHash, chain.config)}
        linkText={shortHash}
        px={isMobile ? '8px' : ''}
      >
        <Flex alignItems="start" justifyContent="space-between">
          <LargeText
            fontFamily="Share Tech Mono"
            color="secondary.white"
            bold
            fontSize={isMobile ? '14px' : '16px'}
          >
            BUMP Claim Amount:
          </LargeText>
          <FlexWithGap gap={'0'} alignItems="end" flexDirection="column">
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {state.bondAmount} BUMP
            </LargeText>
            <Flex>
              <Tooltip tooltipValue={'fsdf'} />
              <MediumText color="primary2.cambridgeBlue">
                (Estimated $
                {formatStringifyNumberToDot(
                  (
                    parseFloat(state.bondAmount) * parseFloat(bumpDetails.price)
                  ).toString(),
                )}
                )
              </MediumText>
            </Flex>
          </FlexWithGap>
        </Flex>
      </ConfirmCard>
      <Flex
        justifyContent={
          isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
        }
        alignItems="center"
        mt="30px"
      >
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => {
            navigate(`${Routes.Dashboard}`);
          }}
          height="48px"
        >
          <img src={homeIcon} alt="" />
          Dashboard
        </Button>
        {isFeatureEnabled('SUPPORT') && (
          <Button
            secondary
            size={isMobile ? IconSize.L : IconSize.XL}
            onClick={() => {
              window.open(feedbackLink, '_blank');
            }}
            height="48px"
          >
            Feedback
          </Button>
        )}
      </Flex>
    </Box>
  );
};
