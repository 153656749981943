import { BscConnector } from '@binance-chain/bsc-connector';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { FrameConnector } from '@web3-react/frame-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { LatticeConnector } from '@web3-react/lattice-connector';
import { LedgerConnector } from '@web3-react/ledger-connector';
import { PortisConnector } from '@web3-react/portis-connector';
import { TrezorConnector } from '@web3-react/trezor-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { ethers } from 'ethers';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { incorrectChainIDError } from '../config/errors';
import { Routes } from '../config/routes';
import { SUPPORTED_CHAINS } from '../config/supportedChains';
import {
  bETH,
  BUMP,
  bUSDCCoin,
  ETH,
  USDCoin,
  wBTC,
  WETH,
} from '../config/tokenNames';
import { WalletContext } from '../config/walletContext';
import {
  bsc,
  frame,
  getWeb3WalletType,
  injected,
  lattice,
  ledger,
  removeWeb3WalletType,
  trezor,
  walletConnect,
  walletLink,
  Web3WalletType,
} from '../config/wallets';
import { EthersServiceProvider } from '../services/ethersServiceProvider';
import { createDefaultProxyMultiProvider } from '../services/fallbackMultiProvider';
import { getAirdropData } from '../state/actions/airdropActions';
import { fetchCoinDetails } from '../state/actions/coinActions';
import { getContractsAddressesAction } from '../state/actions/contractsAddressesActions';
import {
  getStakingDetails,
  getStakingMerkleInfo,
  getVestingClaimDetails,
} from '../state/actions/merkleTreeActions';
import { getVestingMerkleInfo } from '../state/actions/merkleTreeActions';
import { useAppSelector } from '../state/hooks';
import { resetCoin } from '../state/reducers/coinReducer';
import { createError, removeError } from '../state/reducers/errorReducer';
import { setTried } from '../state/reducers/uiStateManagementReducer';

type useWalletTuple = {
  active: boolean;
  tried: boolean;
  connectWallet: () => Promise<void>;
  disconnectWallet: () => Promise<void>;
};

const useWalletBase = (connector: AbstractConnector): useWalletTuple => {
  const tried = useAppSelector(
    (state) => state.uiStateMgmt.uiStateManagement.tried,
  );
  const merkleState = useAppSelector((state) => state.merkleTree.merkle);
  // const epochsState = useAppSelector((state) => state.epochs);

  const { active, activate, account, chainId } = useWeb3React();
  const { addToast } = useToasts();
  const history = useNavigate();
  const ethersServiceProvider: EthersServiceProvider =
    EthersServiceProvider.getInstance();
  const walletContext = useContext(WalletContext);
  const dispatch = useDispatch();

  const connectWallet = async () => {
    try {
      await activate(connector, undefined, true);
      ethersServiceProvider.provider = new ethers.providers.Web3Provider(
        await connector.getProvider(),
      );

      history(Routes.Dashboard);
      if (!(connector instanceof PortisConnector)) {
        window.location.reload();
      }
    } catch (err) {
      if (err instanceof UnsupportedChainIdError) {
        addToast(incorrectChainIDError.errorMessage, {
          appearance: 'error',
        });
        await activate(connector);
      } else {
        history(Routes.WalletNotFound);
        console.error(err, 'Error in connecting wallet');
        return Promise.reject();
      }
    }
  };
  const disconnectWallet = async () => {
    removeWeb3WalletType();
    ethersServiceProvider.provider = undefined;
    window.location.reload();
  };

  const activateWallet = useCallback(() => {
    activate(connector, undefined, true)
      .then(() => {
        if (ethersServiceProvider && connector) {
          connector
            .getProvider()
            .then((provider) => {
              ethersServiceProvider.provider =
                new ethers.providers.Web3Provider(provider);
            })
            .then(() => {
              dispatch(setTried(true));
              dispatch(getContractsAddressesAction());
            });
        }
      })
      .catch((e) => {
        console.error('Error:', e);
        dispatch(setTried(true));
      });
  }, [activate, connector, dispatch, ethersServiceProvider]);

  useEffect(() => {
    const type = getWeb3WalletType();
    if (
      connector instanceof InjectedConnector &&
      type === Web3WalletType.INJECTED
    ) {
      connector.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activateWallet();
        } else {
          dispatch(setTried(true));
        }
      });
    } else if (
      (connector instanceof BscConnector && type === Web3WalletType.BSC) ||
      (connector instanceof WalletConnectConnector &&
        window.localStorage.walletconnect) ||
      (connector instanceof WalletLinkConnector &&
        window.localStorage.getItem(
          '-walletlink:https://www.walletlink.org:Addresses',
        ) !== null) ||
      (connector instanceof FrameConnector && type === Web3WalletType.FRAME) ||
      (connector instanceof LedgerConnector &&
        type === Web3WalletType.LEDGER) ||
      (connector instanceof TrezorConnector &&
        type === Web3WalletType.TREZOR) ||
      (connector instanceof LatticeConnector && type === Web3WalletType.LATTICE)
    ) {
      activateWallet();
    } else {
      dispatch(setTried(true));
    }
  }, [activate, activateWallet, connector, dispatch]);

  useEffect(() => {
    walletContext.isWalletConnected = active;
    if (active) {
      dispatch(setTried(true));
    } else if (!active) {
      dispatch(resetCoin());
    }
  }, [active, dispatch, walletContext]);

  useEffect(() => {
    if (account) {
      ethersServiceProvider.setCurrentAccount(account);
      if (
        connector instanceof WalletConnectConnector &&
        connector.walletConnectProvider
      ) {
        connector.walletConnectProvider.on('disconnect', () =>
          window.location.reload(),
        );
      }
      dispatch(
        fetchCoinDetails([USDCoin, bUSDCCoin, ETH, BUMP, WETH, bETH, wBTC]),
      );
      dispatch(getVestingMerkleInfo(account));
      dispatch(getStakingMerkleInfo(account));
      // dispatch(getAirdropData(account));
    }
    // dispatch(getEpochsSummary());
  }, [account, chainId, connector, dispatch, ethersServiceProvider]);

  // useEffect(() => {
  //   dispatch(
  //     getEpochsRewards({
  //       address: account,
  //       epochsCount: epochsState.epochsData.length,
  //     }),
  //   );
  // }, [epochsState.epochsData.length, account, dispatch]);

  useEffect(() => {
    dispatch(getStakingDetails(merkleState.staking));
  }, [merkleState.staking, dispatch]);

  useEffect(() => {
    dispatch(getVestingClaimDetails(merkleState.vesting));
  }, [merkleState.vesting, dispatch]);

  useEffect(() => {
    if (chainId) {
      if (SUPPORTED_CHAINS.includes(chainId)) {
        connector.getProvider().then((res) => {
          if (res) {
            ethersServiceProvider.provider = new ethers.providers.Web3Provider(
              res,
            );
          }
          ethersServiceProvider.defaultProvider =
            createDefaultProxyMultiProvider(chainId);
        });
        dispatch(getContractsAddressesAction());
        dispatch(removeError());
        if (account) {
          dispatch(getAirdropData(account));
        }
      } else if (!SUPPORTED_CHAINS.includes(chainId)) {
        dispatch(createError(incorrectChainIDError));
      }
    } else {
      dispatch(createError(incorrectChainIDError));
    }
  }, [account, chainId, connector, dispatch, ethersServiceProvider]);

  return {
    active,
    tried,
    connectWallet,
    disconnectWallet,
  };
};

export const useInjected = (): useWalletTuple => useWalletBase(injected);
export const useBsc = (): useWalletTuple => useWalletBase(bsc);
export const useWalletConnect = (): useWalletTuple =>
  useWalletBase(walletConnect);
export const useLedger = (): useWalletTuple => useWalletBase(ledger);
export const useTrezor = (): useWalletTuple => useWalletBase(trezor);
export const useLattice = (): useWalletTuple => useWalletBase(lattice);
export const useWalletLink = (): useWalletTuple => useWalletBase(walletLink);
export const useFrame = (): useWalletTuple => useWalletBase(frame);

export const useWeb3Wallet = (): useWalletTuple => {
  const defaultWallet = {
    active: false,
    tried: true,
    connectWallet: () => Promise.resolve(),
    disconnectWallet: () => Promise.resolve(),
  };
  switch (getWeb3WalletType()) {
    case Web3WalletType.INJECTED:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useInjected();
    case Web3WalletType.BSC:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useBsc();
    case Web3WalletType.WALLET_CONNECT:
      if (!window.localStorage.walletconnect) {
        removeWeb3WalletType();
        return defaultWallet;
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useWalletConnect();
    case Web3WalletType.LATTICE:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useLattice();
    case Web3WalletType.TREZOR:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useTrezor();
    case Web3WalletType.LEDGER:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useLedger();
    case Web3WalletType.WALLET_LINK:
      if (
        !window.localStorage.getItem(
          '-walletlink:https://www.walletlink.org:Addresses',
        )
      ) {
        removeWeb3WalletType();
        return defaultWallet;
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useWalletLink();
    case Web3WalletType.FRAME:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useFrame();
    default: {
      removeWeb3WalletType();
      return defaultWallet;
    }
  }
};
