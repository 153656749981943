import { Box } from '@bumper-dao/ui-kit';
import earnIcon from '@bumper-dao/ui-kit/dist/images/earn.png';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Routes } from '../../../core/config/routes';
import { USDCoin } from '../../../core/config/tokenNames';
import { tokensIcons } from '../../../core/config/tokensIcons';
import { getFlowAssetIcon } from '../../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../../core/hooks/useChain';
import { useAppSelector } from '../../../core/state/hooks';
import { PolicyWithdrawPage } from '../Presenters/PolicyWithdrawPage';
import { PolicyWithdrawPageProps } from '../types';

export const PolicyWithdrawPageContainer = () => {
  const location = useLocation();

  const coinDetails = useAppSelector((state) => state.coin.coinDetails);
  const getLocationState: () => PolicyWithdrawPageProps = useCallback(() => {
    if (!location.state) {
      return {
        token: coinDetails[USDCoin.symbol],
        txHash: '',
        withdrawAmount: '0',
        yieldEstimated: '0',
        bondAmount: '0',
        bondAmountInUsd: '0',
        bumpIncentivesAmount: '0',
        bumpBoostAmount: '0',
        bumpCoordinationAmount: '0',
      };
    }

    return location.state as PolicyWithdrawPageProps;
  }, [location.state]);

  const locationState = getLocationState();

  const navigate = useNavigate();

  const chain = useChain();

  const onGoToDashboardClick = () => {
    return navigate(`${Routes.EarnAssetsDashboard}/${chain.nameForDisplay}`, {
      replace: true,
    });
  };

  return (
    <PolicyWithdrawPage
      {...locationState}
      iconComponent={
        <Box
          style={{
            position: 'absolute',
            top: '-50px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {getFlowAssetIcon(earnIcon, tokensIcons[USDCoin.symbol])}
        </Box>
      }
      txHash={locationState.txHash ?? ''}
      onGoToDashboard={onGoToDashboardClick}
    />
  );
};
