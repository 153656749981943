import {
  AutoRenewCard,
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  IconSize,
  LargeText,
  Link,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import StakeIcon from '@bumper-dao/ui-kit/dist/images/48px/stake.png';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { learnMoreLink } from '../../../core/config/links';
import { Routes, subRoutes } from '../../../core/config/routes';
import { BUMP } from '../../../core/config/tokenNames';
import { tokensIcons } from '../../../core/config/tokensIcons';
import { getFlowAssetIcon } from '../../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../../core/hooks/useChain';
import { StakingService } from '../../../core/services/stakingService';
import { ConfirmAutoRenewState } from '../types';

export const ConfirmAutorenew = () => {
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const { nameForDisplay: chainName } = useChain();
  const state = useLocation().state as ConfirmAutoRenewState;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const stakingService = StakingService.getInstance();

  const onBackButtonPress = () => {
    navigate(`${Routes.StakingAssetsDashboard}/${chainName}`);
  };

  const onConfirm = async () => {
    try {
      navigate(
        `${Routes.Stake}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Approve}`,
      );
      const tx = await stakingService.switchAutorenew(state.stakeIndex || 0);
      navigate(
        `${Routes.Stake}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Processing}`,
      );
      await tx.wait();
      navigate(
        `${Routes.Stake}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Summary}`,
        {
          replace: true,
          state: {
            ...state,
            txHash: tx.hash,
          },
        },
      );
    } catch (err) {
      console.error(err);
      navigate(Routes.Stake);
    }
  };

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" marginBottom="64px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      <AutoRenewCard
        icon={
          <Box
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-50px',
            }}
          >
            {getFlowAssetIcon(StakeIcon, tokensIcons[BUMP.symbol])}
          </Box>
        }
        autoRenew={!state.autorenew}
      />
      <Flex alignItems={isMobile ? '' : 'center'} mt="40px">
        <Checkbox onClick={() => setTermsAccepted((prev) => !prev)} />
        <LargeText
          mt={isMobile ? '-10px' : ''}
          ml="20px"
          color="secondary.white"
        >
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={learnMoreLink}>
            Terms and conditions
          </Link>
          ,{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex justifyContent="space-between" mt="40px">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={onBackButtonPress}
        >
          Cancel
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!termsAccepted}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Flex>
    </Box>
  );
};
