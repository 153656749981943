import { Heading, HeadingProps, Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

import LinerBackground from '../../../../assets/backgrounds/banner.png';

export const BannerHeadingYellowBlow = styled(Heading).attrs({
  color: 'primary1.vividTangelo',
  as: 'h2',
  scale: 'xxl',
})<HeadingProps>`
  line-height: 48px;
  text-shadow: 0px 2px 12px
    ${({ theme }) => theme.colors.primary2.internationalOrange};
  ${({ theme }) => theme.mediaQueries.sm} {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }
`;

export const BannerTopContainer = styled(Flex)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary2.midnightGreen};
  position: relative;
  /*${({ theme }) => theme.mediaQueries.sm} {
    height: 170px; //todo
  }*/
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary2.greenBlack};
    background-image: url(${LinerBackground});
    background-repeat: repeat;
    background-position: center center;
  }
`;

export const BannerBottomContainer = styled(Flex)`
  width: 100%;
  height: 84px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
  border-top: 4px solid ${({ theme }) => theme.colors.primary2.myrtleGreen};
  box-shadow: 0px 4px 1px ${({ theme }) => theme.colors.primary2.midnightGreen},
    0px 8px 1px ${({ theme }) => theme.colors.primary2.deepTeal},
    0px -4px 1px ${({ theme }) => theme.colors.primary2.myrtleGreen};
`;

export const BannerBottomContainerWrapper = styled(Flex)`
  max-width: 1108px;
  flex: 1;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 90%;
  }
`;
