import { createSlice } from '@reduxjs/toolkit';

import { setIsOpenedWalletModal } from '../actions/walletModalActions';

const initialState: { isOpenedWalletModal: boolean } = {
  isOpenedWalletModal: false,
};

export const walletModalSlice = createSlice({
  name: 'walletModal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setIsOpenedWalletModal.fulfilled, (state, action) => {
      state.isOpenedWalletModal = action.payload;
    });
  },
});

export default walletModalSlice.reducer;
