import { useQuery } from '@apollo/client';
import { BigNumber, ethers } from 'ethers';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import {
  AggregatedDataByMarket,
  MarketChartPosition,
  Timestamps,
} from './types';

import { client } from '../../core/providers/graphql';
import {
  GetAggregatedDataByMarket,
  GetAggregatedDataByMarket_aggregatedMarketDatas,
  GetAggregatedDataByMarket_marketTakerPositions,
} from '../../core/providers/graphql/__generated__/GetAggregatedDataByMarket';
import { GET_AGGREGATED_DATA_BY_MARKET } from '../../core/providers/graphql/queries';

const getTimestamps = (days: number, now = Date.now()): Timestamps => {
  const timestampEnd = parseInt((now / 1000).toString(), 10);
  return {
    timestampEnd: timestampEnd.toString(),
    timestampStart: (timestampEnd - 60 * 60 * 24 * days).toString(),
  };
};

const getAggregatedDataByMarket = (
  market: string,
  days: number,
  now = Date.now(),
): AggregatedDataByMarket => ({
  market,
  ...getTimestamps(days, now),
});

export const useEthQuery = (market: string) => {
  const now = useMemo(() => Date.now(), []);

  const ethQueryYear = useQuery<GetAggregatedDataByMarket>(
    GET_AGGREGATED_DATA_BY_MARKET,
    {
      variables: getAggregatedDataByMarket(market, 365, now),
      client,
      fetchPolicy: 'network-only',
    },
  );

  const ethQuery7Days = useQuery<GetAggregatedDataByMarket>(
    GET_AGGREGATED_DATA_BY_MARKET,
    {
      variables: getAggregatedDataByMarket(market, 7, now),
      client,
      fetchPolicy: 'network-only',
    },
  );

  const ethQuery15Days = useQuery<GetAggregatedDataByMarket>(
    GET_AGGREGATED_DATA_BY_MARKET,
    {
      variables: getAggregatedDataByMarket(market, 15, now),
      client,
      fetchPolicy: 'network-only',
    },
  );

  const ethQuery30Days = useQuery<GetAggregatedDataByMarket>(
    GET_AGGREGATED_DATA_BY_MARKET,
    {
      variables: getAggregatedDataByMarket(market, 30, now),
      client,
      fetchPolicy: 'network-only',
    },
  );

  const ethPrice7Days = useMemo<MarketChartPosition[]>(() => {
    if (ethQuery7Days.data && !ethQuery7Days.error) {
      const data = sortBy(ethQuery7Days.data.aggregatedMarketDatas, [
        (o) => parseInt(o.timestamp),
      ]).reduce((acc, data) => {
        return [
          ...acc,
          { price: parseInt(ethers.utils.formatUnits(data.assetPrice, 8)) },
        ];
      }, [] as MarketChartPosition[]);
      return data;
    }

    return [{ price: 0 }, { price: 0 }];
  }, [ethQuery7Days.error, ethQuery7Days.data]);

  const ethHistory7Days =
    useMemo<GetAggregatedDataByMarket_marketTakerPositions>(() => {
      const premDefault: GetAggregatedDataByMarket_marketTakerPositions = {
        __typename: 'MarketTakerPosition' as const,
        amount: '0',
        premium: '0',
      };

      if (ethQuery7Days.data && !ethQuery7Days.error) {
        const data = ethQuery7Days.data.marketTakerPositions.reduce(
          (acc, data) => {
            return {
              __typename: data.__typename,
              amount: BigNumber.from(acc.amount).add(data.amount).toString(),
              premium: BigNumber.from(acc.premium).add(data.premium).toString(),
            };
          },
          premDefault,
        );
        return data;
      }

      return premDefault;
    }, [ethQuery7Days.error, ethQuery7Days.data]);

  const ethHistory15Days =
    useMemo<GetAggregatedDataByMarket_marketTakerPositions>(() => {
      const premDefault: GetAggregatedDataByMarket_marketTakerPositions = {
        __typename: 'MarketTakerPosition' as const,
        amount: '0',
        premium: '0',
      };

      if (ethQuery15Days.data && !ethQuery15Days.error) {
        const data = ethQuery15Days.data.marketTakerPositions.reduce(
          (acc, data) => {
            return {
              __typename: data.__typename,
              amount: BigNumber.from(acc.amount).add(data.amount).toString(),
              premium: BigNumber.from(acc.premium).add(data.premium).toString(),
            };
          },
          premDefault,
        );
        return data;
      }

      return premDefault;
    }, [ethQuery15Days.error, ethQuery15Days.data]);

  const ethHistory30Days =
    useMemo<GetAggregatedDataByMarket_marketTakerPositions>(() => {
      const premDefault: GetAggregatedDataByMarket_marketTakerPositions = {
        __typename: 'MarketTakerPosition' as const,
        amount: '0',
        premium: '0',
      };

      if (ethQuery30Days.data && !ethQuery30Days.error) {
        const data = ethQuery30Days.data.marketTakerPositions.reduce(
          (acc, data) => {
            return {
              __typename: data.__typename,
              amount: BigNumber.from(acc.amount).add(data.amount).toString(),
              premium: BigNumber.from(acc.premium).add(data.premium).toString(),
            };
          },
          premDefault,
        );
        return data;
      }

      return premDefault;
    }, [ethQuery30Days.error, ethQuery30Days.data]);

  const ethHistoryYear =
    useMemo<GetAggregatedDataByMarket_marketTakerPositions>(() => {
      const premDefault: GetAggregatedDataByMarket_marketTakerPositions = {
        __typename: 'MarketTakerPosition' as const,
        amount: '0',
        premium: '0',
      };

      if (ethQueryYear.data && !ethQueryYear.error) {
        const data = ethQueryYear.data.marketTakerPositions.reduce(
          (acc, data) => {
            return {
              __typename: data.__typename,
              amount: BigNumber.from(acc.amount).add(data.amount).toString(),
              premium: BigNumber.from(acc.premium).add(data.premium).toString(),
            };
          },
          premDefault,
        );
        return data;
      }

      return premDefault;
    }, [ethQueryYear.error, ethQueryYear.data]);

  const ethYearValue =
    useMemo<GetAggregatedDataByMarket_aggregatedMarketDatas>(() => {
      const aggDefault = {
        __typename: 'AggregatedMarketData' as const,
        market,
        timestamp: '0',
        assetPrice: [],
        totalOpenedTakerPositions: '0',
        totalOpenedMakerPositions: '0',
        openedPositionsTierSum: '0',
        openedPositionsFloorSum: '0',
        ciMaker: '0',
        ciTakerAsset: '0',
        ciTakerStable: '0',
      };

      if (ethQueryYear.data && !ethQueryYear.error) {
        const data = sortBy(ethQueryYear.data.aggregatedMarketDatas, [
          (o) => parseInt(o.timestamp),
        ]).reduce(
          (acc, data) => ({
            __typename: data.__typename,
            market: data.market,
            timestamp: data.timestamp,
            assetPrice: [...acc.assetPrice, data.assetPrice],
            totalOpenedTakerPositions: BigNumber.from(
              acc.totalOpenedTakerPositions,
            )
              .add(data.totalOpenedTakerPositions)
              .toString(),
            totalOpenedMakerPositions: BigNumber.from(
              acc.totalOpenedMakerPositions,
            )
              .add(data.totalOpenedMakerPositions)
              .toString(),
            openedPositionsTierSum: BigNumber.from(acc.openedPositionsTierSum)
              .add(data.openedPositionsTierSum)
              .toString(),
            openedPositionsFloorSum: BigNumber.from(acc.openedPositionsFloorSum)
              .add(data.openedPositionsFloorSum)
              .toString(),
            ciMaker: data.ciMaker,
            ciTakerAsset: data.ciTakerAsset,
            ciTakerStable: data.ciTakerAsset,
          }),
          aggDefault,
        );
        return data;
      }

      return aggDefault;
    }, [ethQueryYear.data, ethQueryYear.error]);

  return {
    ethQuery7Days,
    ethQuery15Days,
    ethQuery30Days,
    ethQueryYear,
    ethPrice7Days,
    ethYearValue,
    ethHistory7Days,
    ethHistory15Days,
    ethHistory30Days,
    ethHistoryYear,
  };
};
