import {
  Box,
  Button,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import StakeIcon from '@bumper-dao/ui-kit/dist/images/48px/stake.png';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import homeIcon from '../../../assets/home.svg';
import { feedbackLink } from '../../../core/config/links';
import { Routes } from '../../../core/config/routes';
import { BUMP } from '../../../core/config/tokenNames';
import { tokensIcons } from '../../../core/config/tokensIcons';
import { getEtherscanLink } from '../../../core/funcs/getEtherscanLink';
import { getFlowAssetIcon } from '../../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../../core/hooks/useChain';
import { useShortHash } from '../../../core/hooks/useShortHash';
import { isFeatureEnabled } from '../../../core/utils/features';
import { SummaryAutoRenewStateProps } from '../types';

export const SummaryAutorenew = () => {
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const state = useLocation().state as SummaryAutoRenewStateProps;
  const chain = useChain();
  const shortHash = useShortHash(state.txHash);

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" marginBottom="64px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Auto-renew
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <Box
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-50px',
              width: '48px',
              height: '48px',
            }}
          >
            {getFlowAssetIcon(StakeIcon, tokensIcons[BUMP.symbol])}
          </Box>
        }
        linkUrl={getEtherscanLink(state.txHash || '', chain.config)}
        linkText={shortHash}
        px={isMobile ? '8px' : ''}
      >
        <Flex alignItems="center" justifyContent="space-between" mb="20px">
          <LargeText
            fontFamily="Share Tech Mono"
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Auto-renew:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {!state.autorenew ? `ON` : 'OFF'}
          </LargeText>
        </Flex>
      </ConfirmCard>
      <Flex
        justifyContent={
          isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
        }
        alignItems="center"
        mt="30px"
      >
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => {
            navigate(`${Routes.Dashboard}`);
          }}
          height="48px"
        >
          <img src={homeIcon} alt="" />
          Dashboard
        </Button>
        {isFeatureEnabled('SUPPORT') && (
          <Button
            secondary
            size={isMobile ? IconSize.L : IconSize.XL}
            onClick={() => {
              window.open(feedbackLink, '_blank');
            }}
            height="48px"
          >
            Feedback
          </Button>
        )}
      </Flex>
    </Box>
  );
};
