import { ICoin } from '../interfaces';

export const USDCoin: ICoin = {
  name: 'USD Coin',
  symbol: 'USDC',
  decimals: 6,
};
export const USDT: ICoin = {
  name: 'Tether USD',
  symbol: 'USDT',
  decimals: 6,
};

export const bUSDCCoin: ICoin = {
  name: 'bUSDC Coin',
  symbol: 'bUSDC',
  decimals: 18,
};

export const BUMP: ICoin = {
  name: 'BUMP',
  symbol: 'BUMP',
  decimals: 18,
};

export const ETH: ICoin = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

export const WETH: ICoin = {
  name: 'Wrapped ETH',
  symbol: 'WETH',
  decimals: 18,
};

export const wBTC: ICoin = {
  name: 'Wrapped BTC',
  symbol: 'WBTC',
  decimals: 8,
};

export const bETH: ICoin = {
  name: 'Bumpered ETH',
  symbol: 'bETH',
  decimals: 18,
};

export const CoinAliases = new Map<string, string>([
  ['WETH', 'ETH'],
  ['wBTC', 'BTC'],
]);

export const CoinStables = new Map<string, string>([
  ['ETH', 'WETH'],
  ['BTC', 'wBTC'],
]);

export const CoinProtections = new Map<string, string>([
  ['ETH', 'bETH'],
  ['BTC', 'bBTC'],
]);

// Get symbol from ERC20 alias WETH -> ETH
export const getSymbol = (symbol: string): string => {
  if (symbol === 'TestToken') {
    return CoinAliases.get('WETH') ?? symbol;
  }
  return CoinAliases.get(symbol) ?? symbol;
};

// Get symbol from Asset ETH -> bETH
export const getProtectionSymbol = (symbol: string): string => {
  return CoinProtections.get(symbol) ?? symbol;
};

// Get symbol from asset ETH -> WETH
export const getAssetSymbol = (symbol: string): string => {
  return CoinStables.get(symbol) ?? symbol;
};
