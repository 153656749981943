import {
  Box,
  Button,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import stakeIcon from '@bumper-dao/ui-kit/dist/images/stake.svg';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import homeIcon from '../../../assets/home.svg';
import { feedbackLink } from '../../../core/config/links';
import { Routes } from '../../../core/config/routes';
import { BUMP } from '../../../core/config/tokenNames';
import { tokensIcons } from '../../../core/config/tokensIcons';
import { getEtherscanLink } from '../../../core/funcs/getEtherscanLink';
import { getFlowAssetIcon } from '../../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../../core/hooks/useChain';
import { useShortHash } from '../../../core/hooks/useShortHash';
import { isFeatureEnabled } from '../../../core/utils/features';
import { formatStringifyNumberToDot } from '../../../core/utils/helpers';
import { ConfirmUnstakeState, SummaryConfirmation } from '../types';

export const SummaryUnstake = () => {
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const state = useLocation().state as SummaryConfirmation<ConfirmUnstakeState>;
  const chain = useChain();
  const shortHash = useShortHash(state.txHash);

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" marginBottom="64px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Unstake
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <Box
            style={{
              position: 'absolute',
              top: '-50px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {getFlowAssetIcon(stakeIcon, tokensIcons[BUMP.symbol])}
          </Box>
        }
        linkUrl={getEtherscanLink(state.txHash || '', chain.config)}
        linkText={shortHash}
      >
        <Flex alignItems="center" justifyContent="space-between" mt="20px">
          <LargeText fontWeight={500} color="secondary.white">
            Unstake Balance:
          </LargeText>
          <LargeText color="secondary.white">
            {formatStringifyNumberToDot(state.amount.toString())}
            <MediumText display="inline-block" ml="4px" color="secondary.white">
              BUMP
            </MediumText>
          </LargeText>
        </Flex>
        {state.rewards && (
          <Flex alignItems="center" justifyContent="space-between" mt="20px">
            <LargeText fontWeight={500} color="secondary.white">
              Claim Rewards:
            </LargeText>
            <LargeText color="secondary.white">
              {formatStringifyNumberToDot(state.rewards.toString())}
              <MediumText
                display="inline-block"
                ml="4px"
                color="secondary.white"
              >
                BUMP
              </MediumText>
            </LargeText>
          </Flex>
        )}
      </ConfirmCard>
      <Flex
        justifyContent={
          isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
        }
        alignItems="center"
        mt="30px"
      >
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => {
            navigate(`${Routes.Dashboard}`);
          }}
          height="48px"
        >
          <img src={homeIcon} alt="" />
          Dashboard
        </Button>
        {isFeatureEnabled('SUPPORT') && (
          <Button
            secondary
            size={isMobile ? IconSize.L : IconSize.XL}
            onClick={() => {
              window.open(feedbackLink, '_blank');
            }}
            height="48px"
          >
            Feedback
          </Button>
        )}
      </Flex>
    </Box>
  );
};
