import { createSlice } from '@reduxjs/toolkit';
import { ethers } from 'ethers';

import {
  bUSDCCoin,
  USDCoin,
  BUMP,
  ETH,
  WETH,
  bETH,
} from '../../config/tokenNames';
import { ICoinDetails } from '../../interfaces';
import { fetchCoinDetails } from '../actions/coinActions';

interface IinitialState {
  coinDetails: CoinDetailsReducerType;
  isFirstFetching: boolean;
}
export type CoinDetailsReducerType = {
  [coinName: string]: ICoinDetails;
};

const initialState: IinitialState = {
  isFirstFetching: true,
  coinDetails: {
    [USDCoin.symbol]: {
      name: '',
      symbol: '',
      decimals: 6,
      balance: '',
      balanceDecimal: ethers.constants.Zero,
      price: '1',
      value: '0.0',
    },
    [bUSDCCoin.symbol]: {
      name: '',
      symbol: '',
      decimals: 18,
      balance: '',
      balanceDecimal: ethers.constants.Zero,
      price: '1',
      value: '0.0',
    },
    [BUMP.symbol]: {
      name: '',
      symbol: '',
      decimals: 18,
      balance: '',
      balanceDecimal: ethers.constants.Zero,
      price: '1',
      value: '0.0',
    },
    [ETH.symbol]: {
      name: '',
      symbol: '',
      decimals: 18,
      balance: '',
      balanceDecimal: ethers.constants.Zero,
      price: '1',
      value: '0.0',
    },
    [WETH.symbol]: {
      name: '',
      symbol: '',
      decimals: 18,
      balance: '',
      balanceDecimal: ethers.constants.Zero,
      price: '1',
      value: '0.0',
    },
    [bETH.symbol]: {
      name: 'Bumpered ETH',
      symbol: 'bETH',
      decimals: 18,
      balance: '',
      balanceDecimal: ethers.constants.Zero,
      price: '1',
      value: '0.0',
    },
  },
};

export const tokenSlice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    resetCoin: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoinDetails.fulfilled, (state, action) => {
      action.payload.details.forEach((coin) => {
        state.coinDetails[coin.symbol] = { ...coin };
      });
      state.isFirstFetching = action.payload.isFirstFetching;
    });
  },
});

export default tokenSlice.reducer;
export const { resetCoin } = tokenSlice.actions;
