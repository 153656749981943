import { useQuery } from '@apollo/client';
import { BumpSection } from '@bumper-dao/ui-kit';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BumpBalanceCardProps } from './types';

import { Routes, subRoutes } from '../../core/config/routes';
import { BUMP, WETH } from '../../core/config/tokenNames';
import { WalletContext } from '../../core/config/walletContext';
import { useChainName } from '../../core/hooks/useChain';
import { VestingClaimEvent } from '../../core/interfaces';
import { client } from '../../core/providers/graphql';
import { GetAllVestingClaimsByAddress } from '../../core/providers/graphql/__generated__/GetAllVestingClaimsByAddress';
import { GET_VESTING_CLAIMS_BY_ADDRESS } from '../../core/providers/graphql/queries';
import { BondService } from '../../core/services/bondService';
import { useAppSelector } from '../../core/state/hooks';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../core/utils/helpers';

export const BumpBalanceCard: FC<BumpBalanceCardProps> = ({ socketStates }) => {
  const { account } = useWeb3React();
  const navigate = useNavigate();
  const { isWalletConnected } = useContext(WalletContext);
  const [vestingClaimedEvents, setVestingClaimedEvents] = useState<
    VestingClaimEvent[]
  >([]);

  const bumpDetails = useAppSelector(
    (state) => state.coin.coinDetails[BUMP.symbol],
  );
  const wethDetails = useAppSelector(
    (state) => state.contractsAddresses.TOKEN_DETAILS[WETH.symbol],
  );
  const vestingData = useAppSelector((state) => state.merkleTree.vesting);
  const stakingData = useAppSelector((state) => state.merkleTree.staking);
  const bondService = BondService.getInstance();
  const [availableBond, setAvailableBond] = useState<string>('');
  const chainName = useChainName();

  const vestingQuery = useQuery<GetAllVestingClaimsByAddress>(
    GET_VESTING_CLAIMS_BY_ADDRESS,
    {
      variables: { address: `${account}` },
      client: client,
      fetchPolicy: 'network-only',
      pollInterval: 10000,
    },
  );

  useEffect(() => {
    if (!vestingQuery.loading && !vestingQuery.error && vestingQuery.data)
      setVestingClaimedEvents(
        vestingQuery.data.vestingClaimHistories.map((v) => ({
          claimedAmount: BigNumber.from(v.amount),
          stakedAmount: BigNumber.from(v.index),
          timestamp: BigNumber.from(v.timestamp),
        })),
      );
  }, [vestingQuery?.loading, vestingQuery?.error, vestingQuery?.data]);

  useEffect(() => {
    bondService
      .getBondBalance(wethDetails.address)
      .then((bond) => setAvailableBond(ethers.utils.formatUnits(bond)))
      .catch((e) => console.error('Bond error:', e));
  }, [wethDetails.address]);

  const claimed =
    vestingClaimedEvents?.reduce(
      (sum, curr) => sum.add(curr.claimedAmount),
      ethers.constants.Zero,
    ) ?? vestingData?.claimed;

  const vested = vestingData?.availableToClaim || ethers.constants.Zero;
  const unvested = vestingData?.unvested || ethers.constants.Zero;

  const isDisplayVestingAndStaking = stakingData
    ? vestingData &&
      vestingData.claimed.eq(
        stakingData.amountWithRewards.add(stakingData.amountWithoutStaking),
      )
      ? false
      : !(
          vestingData &&
          vestingData.claimed.eq(stakingData.amountWithoutStaking)
        )
    : true;

  const renderCondition =
    (!(vestingData?.unvested ?? BigNumber.from(0)).isZero() ||
      !vested.isZero()) &&
    isDisplayVestingAndStaking;

  const unvestedDisplay = stakingData
    ? unvested.sub(stakingData.amount).gt(ethers.constants.Zero)
      ? unvested.sub(stakingData.amount)
      : ethers.constants.Zero
    : unvested;

  const vestedDisplay = !stakingData
    ? vested
    : unvestedDisplay.isZero()
    ? stakingData.amountWithoutStaking
    : vested;

  const vestingProps = {
    vesting: {
      claimedBalance: formatWeiToNormalString(claimed),
      unVestedBalance: formatWeiToNormalString(unvestedDisplay),
      vestedBalance: formatWeiToNormalString(vestedDisplay),
      onClaim: () =>
        navigate(`${Routes.Vesting}/${chainName}/${subRoutes.Confirm}`, {
          state: {
            bondAmount: formatWeiToNormalString(vestedDisplay),
            linkPath: '',
          },
        }),
      onClaimAndStake: () =>
        navigate(`${Routes.ClaimStake}/${chainName}/${subRoutes.Claim}`, {
          state: {
            bondAmount: formatWeiToNormalString(vestedDisplay),
            linkPath: '',
          },
        }),
      isVestingEnabled: renderCondition,
    },
  };

  const bumpSectionProps = {
    balance: {
      bumpBalance: formatStringifyNumberToDot(bumpDetails.balance, 4),
      inUSD: formatStringifyNumberToDot(
        (+bumpDetails.balance * +bumpDetails.price).toString(),
        2,
      ),
      bridgeIsActive: !isWalletConnected,
    },
    ...vestingProps,
    socketState: {
      protect: false,
      earn: false,
      stake: false,
    },
    availableBond: formatStringifyNumberToDot(availableBond, 4),
    availableBondInUSD: availableBond
      ? formatStringifyNumberToDot(
          (+availableBond * +bumpDetails.price).toString(),
          2,
        )
      : '',
  };

  return <BumpSection {...bumpSectionProps} socketState={socketStates} />;
};
