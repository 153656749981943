import {
  Box,
  Button,
  ConfirmCard,
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  SmallText,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React from 'react';

import HomeIco from '../../../assets/48px/home.svg';
import { feedbackLink } from '../../../core/config/links';
import { getEtherscanLink } from '../../../core/funcs/getEtherscanLink';
import { useChain } from '../../../core/hooks/useChain';
import { isFeatureEnabled } from '../../../core/utils/features';
import { shortenTransactionHash } from '../../../core/utils/helpers';
import { PolicyWithdrawProps } from '../types';

export const PolicyWithdrawPage: React.FC<PolicyWithdrawProps> = (props) => {
  const { isMobile } = useMatchBreakpoints();
  const chain = useChain();

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Withdraw
        </Heading>
      </Flex>
      <Flex flexDirection="column" mt="40px">
        <ConfirmCard
          iconComponent={props.iconComponent}
          linkUrl={getEtherscanLink(props.txHash || '', chain.config)}
          linkText={shortenTransactionHash(props.txHash || '')}
          px={isMobile ? '8px' : ''}
        >
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={500}
              color="secondary.white"
            >
              Withdrawal Amount:
            </LargeText>
            <FlexWithGap gap="4px" alignItems="center">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                ${props.withdrawAmount}
              </LargeText>
              <SmallText fontWeight={500} color="secondary.white">
                USDC
              </SmallText>
            </FlexWithGap>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={500}
              color="secondary.white"
            >
              Yield (Estimated):
            </LargeText>
            <FlexWithGap gap="4px" alignItems="center">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {props.yieldEstimated}
              </LargeText>
              <SmallText fontWeight={500} color="secondary.white">
                USDC
              </SmallText>
            </FlexWithGap>
          </Flex>
          <Flex alignItems="start" justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={500}
              color="secondary.white"
            >
              Bond Return:
            </LargeText>
            <Flex flexDirection="column" alignItems="end">
              <FlexWithGap gap="4px" alignItems="center">
                <LargeText
                  color="secondary.white"
                  fontSize={isMobile ? '14px' : '16px'}
                >
                  {props.bondAmount}
                </LargeText>
                <SmallText fontWeight={500} color="secondary.white">
                  BUMP
                </SmallText>
              </FlexWithGap>
              <Flex>
                <Tooltip tooltipValue="123" />
                <MediumText ml="5px" color="primary2.cambridgeBlue">
                  (Estimated ${props.bondAmountInUsd})
                </MediumText>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="5px">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight={500}
              color="secondary.white"
            >
              BUMP Incentives:
            </LargeText>
            <FlexWithGap gap="4px" alignItems="center">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {props.bumpIncentivesAmount}
              </LargeText>
              <SmallText fontWeight={500} color="secondary.white">
                BUMP
              </SmallText>
            </FlexWithGap>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="5px">
            <SmallText fontFamily="Roboto Mono" color="secondary.white">
              Boost:
            </SmallText>
            <SmallText color="secondary.white">
              {props.bumpBoostAmount} BUMP
            </SmallText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <SmallText fontFamily="Roboto Mono" color="secondary.white">
              Coordination:
            </SmallText>
            <SmallText color="secondary.white">
              {props.bumpCoordinationAmount} BUMP
            </SmallText>
          </Flex>
        </ConfirmCard>
        <Flex
          justifyContent={
            isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
          }
          alignItems="center"
          mt="30px"
        >
          <Button
            padding="0 24px"
            secondary
            onClick={props.onGoToDashboard}
            size={isMobile ? IconSize.L : IconSize.XL}
          >
            <img src={HomeIco} alt="home-ico" />
            Dashboard
          </Button>
          {isFeatureEnabled('SUPPORT') && (
            <Button
              secondary
              size={isMobile ? IconSize.L : IconSize.XL}
              onClick={() => {
                window.open(feedbackLink, '_blank');
              }}
              height="48px"
            >
              Feedback
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
