import { NetworkTokenInfo } from './types';

import { ICoin } from '../../interfaces';
import { isProduction } from '../supportedChains';
import { USDCoin, wBTC, WETH } from '../tokenNames';

export const WETH_NAME = 'WETH';
type marketTokenConfigType = {
  token: ICoin;
  stable: ICoin[];
};

const tokens = {
  [1]: [],
  [10]: [],
  [250]: [],
  [5]: [
    {
      // PROTOCOL TEST WETH!
      address: '0xbe86fD1d0A4D439aCf0648951Ac57C2b952Ed18a',
      decimals: 18,
      name: 'Wrapped ETH',
    },
    {
      // REAL GOERLI WETH!
      address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
      decimals: 18,
      name: WETH_NAME,
    },
  ],
} as NetworkTokenInfo;

const WethMarketConfig: marketTokenConfigType = {
  token: WETH,
  stable: [USDCoin],
};
const WbtcMarketConfig: marketTokenConfigType = {
  token: wBTC,
  stable: [USDCoin],
};

export const marketsTokensConfig: marketTokenConfigType[] = [
  WethMarketConfig,
  ...(isProduction() ? [] : [WbtcMarketConfig]),
];

export default tokens;
