import {
  BumpToken__factory,
  StakeRewards,
  StakeRewards__factory,
} from '@bumper-dao/contract-interfaces';
import { BigNumber, ContractTransaction, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';
import { getContractsAddresses, tryPermit } from './servicesUtils';

import { StakeHubCardType } from '../../pages/StakeHub/types';
import {
  getNetworkConfigsByEnv,
  SUPPORTED_CHAINS,
} from '../config/supportedChains';
import {
  APRAndAPYType,
  CirculatingSupplyAndPopularityType,
  RewardsType,
  RewardType,
  StakeOptionType,
  StakeWithRewards,
} from '../interfaces';
import { CoinDetailsReducerType } from '../state/reducers/coinReducer';
import { convertBumpToDollars, secondsByDays } from '../utils/helpers';

export class StakingService {
  private static instance: StakingService;
  private ethersServiceProvider: EthersServiceProvider;

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
  }

  public static getInstance(): StakingService {
    if (!StakingService.instance) {
      StakingService.instance = new StakingService();
    }
    return StakingService.instance;
  }

  private getStaking(contractAddress: string): StakeRewards {
    return StakeRewards__factory.connect(
      contractAddress,
      this.ethersServiceProvider.currentAccount
        ? (this.ethersServiceProvider.provider?.getSigner(
            0,
          ) as ethers.providers.JsonRpcSigner)
        : new ethers.providers.JsonRpcProvider(
            getNetworkConfigsByEnv()[SUPPORTED_CHAINS[0]].config.rpcUrls[0],
          ),
    );
  }

  private async getAccount(address?: string): Promise<string> {
    return address ?? (await this.ethersServiceProvider.getUserAddress());
  }

  public async calcRewardsByIndex(stakeIndex: number): Promise<RewardType> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    const { rewards, claimable, withdrawable, endOfLastPeriod } =
      await this.getStaking(contractAddress).calcRewardsByIndex(stakeIndex);
    return {
      rewards: +rewards / 1e18,
      claimable,
      withdrawable,
      endOfLastPeriod: +endOfLastPeriod,
    };
  }

  public async getStakerTVL(): Promise<BigNumber> {
    try {
      const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
        .Staking;
      const stakingOptionsData = await this.getStaking(
        contractAddress,
      ).getStakeOptions();
      return stakingOptionsData.reduce(
        (tvl, { total }) => total.add(tvl),
        ethers.constants.Zero,
      );
    } catch (e) {
      return ethers.constants.Zero;
    }
  }

  public async getUserStakes(address?: string): Promise<StakeWithRewards[]> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    const staking = this.getStaking(contractAddress);

    const [stakes, withdrawWindow, cooldownPeriod] = await Promise.all([
      staking.getUserStakes(await this.getAccount(address)),
      staking.withdrawWindow(),
      staking.cooldownPeriod(),
    ]);

    return Promise.all(
      stakes.map(async (stake, index) => {
        const {
          amount,
          start,
          autorenew,
          claimed,
          end,
          option,
          requestedAt,
          lastCI,
        } = stake;
        const rewards = await this.calcRewardsByIndex(index);
        return {
          amount: +amount / 1e18,
          option,
          start: +start,
          withdrawWindow: +withdrawWindow,
          autorenew,
          end: +end,
          claimed: +claimed / 1e18,
          requestedAt: +requestedAt,
          lastCI: +lastCI,
          rewards,
          cooldownPeriod: +cooldownPeriod,
        };
      }),
    );
  }

  public async getUnlockTimestamp(): Promise<BigNumber> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    return this.getStaking(contractAddress).unlockTimestamp();
  }

  public async getStakeOptions(): Promise<StakeOptionType[]> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    const multipliers = await this.getStaking(contractAddress).multipliers();
    const periods = await this.getStaking(contractAddress).periods();
    return multipliers
      .map((multiplier, optionIndex) => ({
        // get period in days from seconds
        periodInDays: periods[optionIndex] / 86400,
        multiplier,
      }))
      .filter((i, index) => index !== 0);
  }

  public async getPeriods(): Promise<number[]> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    const periods = await this.getStaking(contractAddress).periods();
    return periods.map((period) => period / 86400);
  }

  public async getCooldown(): Promise<number> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    return await this.getStaking(contractAddress).cooldownPeriod();
  }

  public async getCirculatingSupplyAndPopularity(
    periodIndex: number,
  ): Promise<CirculatingSupplyAndPopularityType> {
    const { CONTRACT_ADDRESS, TOKEN_DETAILS } = await getContractsAddresses();
    const bumpTokenAddress = TOKEN_DETAILS.BUMP.address;
    const { Staking, Vesting, Treasury } = CONTRACT_ADDRESS;
    const bumpToken = BumpToken__factory.connect(
      bumpTokenAddress,
      this.ethersServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );
    const stakeOption = (await this.getStaking(Staking).getStakeOptions())[
      periodIndex
    ];
    const [
      vestingBalance,
      stakingBalance,
      treasuryBalance,
      visorBUMPETH,
      gnosis,
    ] = await Promise.all([
      bumpToken.balanceOf(Vesting),
      bumpToken.balanceOf(Staking),
      bumpToken.balanceOf(Treasury),
      bumpToken.balanceOf('0x5D40E4687e36628267854D0B985a9B6e26493b74'),
      bumpToken.balanceOf('0xd6844cF5eF056F93Bb2bC0F577DA04D42ea42519'),
    ]);

    const circulatingSupply = ethers.utils
      .parseUnits(250 * 1e6 + '', 18)
      .sub(vestingBalance)
      .sub(stakingBalance)
      .sub(treasuryBalance)
      .sub(visorBUMPETH)
      .sub(gnosis);
    const stakingPopularity = Math.ceil(
      (parseFloat(ethers.utils.formatUnits(stakeOption.total, 18)) /
        parseFloat(ethers.utils.formatUnits(circulatingSupply, 18))) *
        10,
    );

    return {
      supply: ethers.utils.formatUnits(circulatingSupply, 18),
      popularity: stakingPopularity.toString(),
    };
  }

  public async stake(
    amount: string,
    periodIndex: string,
    autorenew: boolean,
  ): Promise<ContractTransaction> {
    const { CONTRACT_ADDRESS, TOKEN_DETAILS } = await getContractsAddresses();
    const bumpToken = TOKEN_DETAILS.BUMP;
    const { Staking } = CONTRACT_ADDRESS;
    const stakeAmount = ethers.utils.parseUnits(amount, bumpToken.decimal);

    if (
      (
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          (
            await this.getStaking(Staking)
          ).address,
          bumpToken.address,
        )
      ).gte(stakeAmount)
    ) {
      return await this.getStaking(Staking).stake(
        stakeAmount,
        BigNumber.from(periodIndex),
        autorenew,
      );
    }

    const permit = await tryPermit(
      this.ethersServiceProvider.provider,
      bumpToken.address,
      await this.getAccount(),
      stakeAmount,
      1,
      'BUMP',
      Staking,
    );

    if (permit) {
      return this.getStaking(Staking).stakeWithPermit(
        stakeAmount,
        BigNumber.from(periodIndex),
        autorenew,
        permit.deadline,
        permit.v,
        permit.r,
        permit.s,
      );
    }

    const tx = await this.ethersServiceProvider.approveTokenAmount(
      stakeAmount.toString(),
      (
        await this.getStaking(Staking)
      ).address,
      bumpToken.address,
    );
    await tx.wait();

    return await this.getStaking(Staking).stake(
      stakeAmount,
      BigNumber.from(periodIndex),
      autorenew,
    );
  }

  public async requestUnstake(
    stakeIndex: number,
  ): Promise<ContractTransaction> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    return await this.getStaking(contractAddress).requestWithdraw(stakeIndex);
  }

  public async unstake(stakeIndex: number): Promise<ContractTransaction> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    return await this.getStaking(contractAddress).withdraw(stakeIndex);
  }

  public async restake(
    stakeIndex: number,
    option: number,
    withRewards: boolean,
    autorenew: boolean,
  ): Promise<ContractTransaction> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    return this.getStaking(contractAddress).restake(
      BigNumber.from(stakeIndex),
      BigNumber.from(option),
      withRewards,
      autorenew,
    );
  }

  public async switchAutorenew(
    stakeIndex: number,
  ): Promise<ContractTransaction> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    return this.getStaking(contractAddress).switchAutorenew(stakeIndex);
  }

  public async claimRewards(stakeIndex: number): Promise<ContractTransaction> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    return this.getStaking(contractAddress).claimRewards(stakeIndex);
  }

  private async calculateEmission(
    periodIndex: number,
    amount: BigNumber,
  ): Promise<BigNumber> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    const multiplier = (await this.getStaking(contractAddress).multipliers())[
      periodIndex
    ];
    const weigthedAmount = (
      await this.getStaking(contractAddress).totalAmount()
    ).weithedAmountSum.add(amount.mul(multiplier));
    const option = await this.getStaking(contractAddress).stakeOptions(
      periodIndex,
    );
    const totalEmmissionPerSecond = await this.getStaking(
      contractAddress,
    ).totalEmissionPerSecond();
    return totalEmmissionPerSecond
      .mul(option.total.add(amount).mul(multiplier))
      .div(weigthedAmount);
  }

  public async calculateRewards(
    amount: string,
    periodIndex: number,
    daysForFlexible?: number,
  ): Promise<RewardsType> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    const amountDec = ethers.utils.parseUnits(amount, 18);
    const stakeOption = (
      await this.getStaking(contractAddress).getStakeOptions()
    )[periodIndex];
    const periodFromContract = (
      await this.getStaking(contractAddress).periods()
    )[periodIndex];
    const periodInSeconds =
      periodIndex === 0
        ? secondsByDays(daysForFlexible || 0)
        : periodFromContract;
    const currentEmission = await this.calculateEmission(
      periodIndex,
      amountDec,
    );
    const calculatedRewards = amountDec
      .mul(currentEmission)
      .mul(periodInSeconds)
      .div(stakeOption.total.add(amountDec));
    const formatedCalculatedRewards = parseFloat(
      ethers.utils.formatUnits(calculatedRewards, 18),
    );
    const rewardsPerDay =
      periodIndex === 0
        ? formatedCalculatedRewards / (daysForFlexible || 1)
        : formatedCalculatedRewards / (periodInSeconds / 86400);

    return {
      rewards: formatedCalculatedRewards.toString(),
      rewardsPerDay: rewardsPerDay.toString(),
    };
  }

  public async calculateAPRAndAPY(
    amount: string,
    periodIndex: number,
    daysForFlexible?: number,
  ): Promise<APRAndAPYType> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    const amountDec = ethers.utils.parseUnits(amount, 18);
    const stakeOption = (
      await this.getStaking(contractAddress).getStakeOptions()
    )[periodIndex];
    const stakePeriodDelay =
      periodIndex === 0
        ? secondsByDays(daysForFlexible || 0)
        : (await this.getStaking(contractAddress).periods())[periodIndex];

    const compoundingPeriodsCount = BigNumber.from(365 * 86400).div(
      stakePeriodDelay,
    );

    const formattedCompoundingPeriodsCount =
      +compoundingPeriodsCount >= 1 ? Math.floor(+compoundingPeriodsCount) : 1;

    const currentEmission = await this.calculateEmission(
      periodIndex,
      amountDec,
    );

    const periodicRate =
      (+currentEmission * stakePeriodDelay) / (+stakeOption.total + +amountDec);

    const APR = periodicRate * formattedCompoundingPeriodsCount;

    const APY = (1 + +periodicRate) ** formattedCompoundingPeriodsCount - 1;
    return {
      APR: (APR * 100).toFixed(2),
      APY: (APY * 100).toFixed(2),
    };
  }

  public async calculateRewardsForFakeStaking(
    amount: BigNumber,
  ): Promise<BigNumber> {
    const contractAddress = (await getContractsAddresses()).CONTRACT_ADDRESS
      .Staking;
    //March 16 2022 12:00
    const endOfRewardsCalculating = 1647432000;
    const currentTimestamp = Math.ceil(Date.now() / 1000);
    const stakeOption = (
      await this.getStaking(contractAddress).getStakeOptions()
    )[3];

    const timestamp = Math.min(endOfRewardsCalculating, currentTimestamp);
    const lastCumIndexTimestamp = await this.getStaking(
      contractAddress,
    ).lastIndexTimestamp();
    const timestampInterval = BigNumber.from(timestamp).sub(
      lastCumIndexTimestamp,
    );

    const calculatedIndex = timestampInterval
      .mul(stakeOption.emission)
      .mul(BigNumber.from(10).pow(18))
      .div(stakeOption.total);
    const cumulativeIndex = stakeOption.index.add(calculatedIndex);

    return amount.mul(cumulativeIndex).div(BigNumber.from(10).pow(18));
  }

  public async calculateTotalStakingData(
    tokensDetails: CoinDetailsReducerType,
  ): Promise<StakeHubCardType> {
    const stakes = await this.getUserStakes();

    const fixedStakingAmount =
      stakes
        .filter((stake) => stake.option !== 0)
        .reduce((prev, { amount }) => prev + amount, 0) ?? 0;
    const flexibleStakingAmount =
      stakes
        .filter((stake) => stake.option === 0)
        .reduce((prev, { amount }) => prev + amount, 0) ?? 0;
    const stakingAmount = fixedStakingAmount + flexibleStakingAmount;

    const singleBumpUsdcPrice = await convertBumpToDollars('1');

    const stakingRewardsForActivePositions =
      stakes.reduce((prev, { rewards }) => prev + rewards.rewards, 0) ?? 0;

    // get a price of 1 BUMP
    const stakingAmountInUSDC = stakingAmount * parseFloat(singleBumpUsdcPrice);
    const userWalletBump = parseFloat(tokensDetails.BUMP.balance);

    return {
      totalBUMP: (stakingAmount + userWalletBump).toFixed(4),
      idleBUMP: userWalletBump.toFixed(4),
      idleBUMPInUSD: tokensDetails.BUMP.value,
      totalAmountInUSDC: (
        stakingAmountInUSDC + parseFloat(tokensDetails.BUMP.value)
      ).toString(),
      stakedBUMP: stakingAmount.toFixed(4),
      assetBalanceInUSDC: tokensDetails.BUMP.value,
      investedAmountInUSDC: stakingAmountInUSDC.toString(),
      positionsCount: stakes.length,
      // @TODO We need to accumalate records of past events as well.
      rewardsBUMP: stakingRewardsForActivePositions.toFixed(4),
      rewardsBUMPInUSD: (
        stakingRewardsForActivePositions * parseFloat(singleBumpUsdcPrice)
      ).toFixed(4),
      fixedStakingAmountInUSD: (
        fixedStakingAmount * parseFloat(singleBumpUsdcPrice)
      ).toFixed(2),
      flexibleStakingAmountInUSD: (
        flexibleStakingAmount * parseFloat(singleBumpUsdcPrice)
      ).toFixed(2),
      fixedInUSDC: '0',
      flexibleInUSDC: '0',
      fixedStakingAmount: fixedStakingAmount.toFixed(4),
      flexibleStakingAmount: flexibleStakingAmount.toFixed(4),
      totalStakingAmount: stakingAmount.toFixed(4),
      totalStakingAmountInUSD: (
        stakingAmount * parseFloat(singleBumpUsdcPrice)
      ).toFixed(2),
      price: singleBumpUsdcPrice,
    };
  }
}
