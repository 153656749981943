import { useQuery } from '@apollo/client';
import {
  CategoryFilterKeysType,
  History,
  OperationFilterKeysType,
  OperationType,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { useWeb3React } from '@web3-react/core';
import React, { useMemo } from 'react';

import { MainContainer } from '../components/common/MainContainers';
import { DEFAULT_DECIMAL_VALUE } from '../core/config/formulaConstants';
import { client } from '../core/providers/graphql';
import { GetWholeHistoryByAddress } from '../core/providers/graphql/__generated__/GetWholeHistoryByAddress';
import { GET_WHOLE_HISTORY_BY_ADDRESS } from '../core/providers/graphql/queries';
import { formatOperation } from '../core/utils/helpers';

export const HistoryPage: React.FC = () => {
  const { isMobile } = useMatchBreakpoints();

  const { account } = useWeb3React();
  const {
    loading,
    error,
    data: queryData,
  } = useQuery<GetWholeHistoryByAddress>(GET_WHOLE_HISTORY_BY_ADDRESS, {
    variables: { address: `${account}` },
    client: client,
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  });

  const historyData = useMemo<OperationType[]>(() => {
    if (!loading && !error && queryData) {
      return queryData.generalHistories
        .filter(({ eventType }) => !eventType.includes('BUMP'))
        .map((query) => {
          const splitedEventType = query.eventType.split(' ');
          const category = splitedEventType[0] as CategoryFilterKeysType;
          const operation = splitedEventType[1] as OperationFilterKeysType;
          const protectionOperations: OperationFilterKeysType[] = [
            'Protect',
            'Close',
            'Claim',
          ];
          const earningOperations: OperationFilterKeysType[] = [
            'Deposit',
            'Withdraw',
          ];
          if (category === 'Market') {
            if (protectionOperations.includes(operation)) {
              const result = formatOperation(
                query.txHash,
                query.timestamp,
                'Protection',
                operation,
                query.amountAsset,
                'ETH',
                DEFAULT_DECIMAL_VALUE,
              );

              return {
                timestampBlock: result.timestampBlock,
                txHash: result.txHash,
                category: result.category as CategoryFilterKeysType,
                operation: result.operation as OperationFilterKeysType,
                amount: result.amount,
                formattedAmount: result.formattedAmount,
                units: result.units,
              } as OperationType;
            }
            if (earningOperations.includes(operation)) {
              const result = formatOperation(
                query.txHash,
                query.timestamp,
                'Earning',
                operation,
                query.amountStable,
                'USD',
                6,
              );

              return {
                timestampBlock: result.timestampBlock,
                txHash: result.txHash,
                category: result.category as CategoryFilterKeysType,
                operation: result.operation as OperationFilterKeysType,
                amount: result.amount,
                formattedAmount: result.formattedAmount,
                units: result.units,
              } as OperationType;
            }
          }

          const result = formatOperation(
            query.txHash,
            query.timestamp,
            category,
            operation,
            query.amountBump,
            'BUMP',
            DEFAULT_DECIMAL_VALUE,
          );

          return {
            timestampBlock: result.timestampBlock,
            txHash: result.txHash,
            category: result.category as CategoryFilterKeysType,
            operation: result.operation as OperationFilterKeysType,
            amount: result.amount,
            formattedAmount: result.formattedAmount,
            units: result.units,
          } as OperationType;
        });
    } else return [];
  }, [loading, error, isMobile, queryData]);

  return (
    <MainContainer width={isMobile ? '100%' : '1128px'} py="24px">
      <History history={historyData} />
    </MainContainer>
  );
};
