import {
  Box,
  Button,
  Checkbox,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  Link,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import stakeIcon from '@bumper-dao/ui-kit/dist/images/stake.svg';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { learnMoreLink } from '../../../core/config/links';
import { Routes, subRoutes } from '../../../core/config/routes';
import { BUMP } from '../../../core/config/tokenNames';
import { tokensIcons } from '../../../core/config/tokensIcons';
import { getFlowAssetIcon } from '../../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../../core/hooks/useChain';
import { StakingService } from '../../../core/services/stakingService';
import { formatStringifyNumberToDot } from '../../../core/utils/helpers';
import { ConfirmUnstakeState } from '../types';

export const ConfirmUnstake = () => {
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const { nameForDisplay: chainName } = useChain();
  const { stakeIndex, rewards, amount } = useLocation()
    .state as ConfirmUnstakeState;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const stakingService = StakingService.getInstance();

  const onBackButtonPress = () => {
    navigate(Routes.Stake);
  };

  const onConfirm = async () => {
    try {
      navigate(`${Routes.Unstake}/${chainName}/${subRoutes.Approve}`);
      const tx = await stakingService.unstake(stakeIndex || 0);

      navigate(`${Routes.Unstake}/${chainName}/${subRoutes.Processing}`);
      await tx.wait();

      navigate(`${Routes.Unstake}/${chainName}/${subRoutes.Summary}`, {
        replace: true,
        state: {
          stakeIndex,
          rewards,
          amount,
          txHash: tx.hash,
        },
      });
    } catch (err) {
      console.error(err);
      navigate(Routes.Stake);
    }
  };

  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" marginBottom="64px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <Box
            style={{
              position: 'absolute',
              top: '-50px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {getFlowAssetIcon(stakeIcon, tokensIcons[BUMP.symbol])}
          </Box>
        }
        amount={formatStringifyNumberToDot(
          (amount + (rewards || 0)).toString(),
        )}
        subTitle={'BUMP'}
        px={isMobile ? '8px' : ''}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Unstake Balance:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {formatStringifyNumberToDot(amount.toString())}
            <MediumText display="inline-block" ml="4px" color="secondary.white">
              BUMP
            </MediumText>
          </LargeText>
        </Flex>
        {rewards && rewards > 0 && (
          <Flex alignItems="center" justifyContent="space-between" mt="20px">
            <LargeText
              fontWeight={500}
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              Claim Rewards:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {formatStringifyNumberToDot(rewards.toString())}
              <MediumText
                display="inline-block"
                ml="4px"
                color="secondary.white"
              >
                BUMP
              </MediumText>
            </LargeText>
          </Flex>
        )}
      </ConfirmCard>
      <Flex alignItems={isMobile ? '' : 'center'} mt="40px">
        <Checkbox onClick={() => setTermsAccepted((prev) => !prev)} />
        <LargeText
          mt={isMobile ? '-10px' : ''}
          ml="20px"
          color="secondary.white"
        >
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={learnMoreLink}>
            Terms and conditions
          </Link>
          ,{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex justifyContent="space-between" mt="40px">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={onBackButtonPress}
        >
          Cancel
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!termsAccepted}
          onClick={onConfirm}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};
