import { createAsyncThunk } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

import { getNetworkConfigsByEnv } from '../../config/supportedChains';
import { StakingMerkleInfo, VestingMerkleInfo } from '../../interfaces';
import { EthersServiceProvider } from '../../services/ethersServiceProvider';
import {
  getStakingUserData,
  getVestingUserData,
} from '../../services/firebaseStorageService';
import { StakingService } from '../../services/stakingService';
import { VestingService } from '../../services/vestingService';
import { StakingType, VestingMerkleType } from '../reducers/merkleTree';

export const getVestingMerkleInfo = createAsyncThunk<
  VestingMerkleInfo | null,
  string
>('getVestingMerkleInfo', async (address) => {
  try {
    const currentNetwork =
      await EthersServiceProvider.getInstance().provider?.getNetwork();
    const chainName =
      getNetworkConfigsByEnv()[currentNetwork?.chainId ?? 1].nameForDisplay;
    const data = await getVestingUserData(chainName, address);

    return {
      ...data,
      vestingPerSec: BigNumber.from(data.vestingPerSec),
      totalAmount: BigNumber.from(data.totalAmount),
      onStartAmount: BigNumber.from(data.onStartAmount),
      previousAmount: BigNumber.from(data.previousAmount),
      claimedV1: BigNumber.from(data.claimedV1),
    };
  } catch (err) {
    console.error(err);
    return null;
  }
});

export const getVestingClaimDetails = createAsyncThunk<
  VestingMerkleType | null,
  VestingMerkleInfo | null
>('getVestingClaimDetails', async (merkleInfo) => {
  try {
    if (!merkleInfo) return null;
    const address = EthersServiceProvider.getInstance().currentAccount;
    const info = {
      info: merkleInfo,
      account: address,
      proof: merkleInfo.proof,
      availableToClaim: BigNumber.from(0),
      unvested: BigNumber.from(0),
      claimed: BigNumber.from(0),
    };

    info.availableToClaim =
      await VestingService.getInstance().getClaimableAmount(info);
    info.unvested = await VestingService.getInstance().totalLockedOf(info);
    info.claimed = await VestingService.getInstance().totalClaimedOf(address);

    return info;
  } catch (err) {
    console.error(err);
    return null;
  }
});

export const getStakingMerkleInfo = createAsyncThunk<
  StakingMerkleInfo | null,
  string
>('getStakingMerkleInfo', async (address) => {
  try {
    const currentNetwork =
      await EthersServiceProvider.getInstance().provider?.getNetwork();
    const chainName =
      getNetworkConfigsByEnv()[currentNetwork?.chainId ?? 1].nameForDisplay;
    const data = await getStakingUserData(chainName, address);
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
});

export const getStakingDetails = createAsyncThunk<
  StakingType | null,
  StakingMerkleInfo | null
>('getStakingDetails', async (merkleTreeData) => {
  try {
    if (!merkleTreeData) return null;

    const stakingService = StakingService.getInstance();

    const rewards = await stakingService.calculateRewardsForFakeStaking(
      BigNumber.from(merkleTreeData.amount),
    );

    return {
      amount: BigNumber.from(merkleTreeData.amount),
      amountWithoutStaking: BigNumber.from(merkleTreeData.amountWithoutStaking),
      amountWithRewards: BigNumber.from(merkleTreeData.amountWithRewards),
      rewards: rewards,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
});
