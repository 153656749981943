import { ethers } from 'ethers';

import { getNetworkConfigsByEnv } from '../config/supportedChains';

declare global {
  interface ProxyConstructor {
    // eslint-disable-next-line @typescript-eslint/ban-types
    new <TSource extends object, TTarget extends object | undefined>(
      target: TSource,
      handler: ProxyHandler<TSource>,
    ): TTarget;
  }
}

export const createProxyMultiProvider = (
  ...providers: ethers.providers.JsonRpcProvider[]
) => {
  return new Proxy<
    ethers.providers.JsonRpcProvider[],
    ethers.providers.JsonRpcProvider
  >(providers, {
    get: (o, key) => {
      return async (...args: any[]) => {
        let lastErr: any;

        for (const iterator of o) {
          const provider = iterator as any;

          try {
            return await provider[key](args);
          } catch (err) {
            lastErr = err;
            console.error(
              'Provider request failed. Trying with next one. Err: ',
              err,
            );
          }
        }

        throw lastErr;
      };
    },
  });
};

export const createDefaultProxyMultiProvider = (chainId: number) => {
  const networkConfig = getNetworkConfigsByEnv()[chainId];

  const providers = networkConfig.config.rpcUrls.map(
    (url) => new ethers.providers.JsonRpcProvider(url, chainId),
  );

  return createProxyMultiProvider(...providers);
};
