export const Routes = {
  ConnectWallet: '/connect',
  App: '/',
  Dashboard: '/dashboard',
  Epochs: '/farming',
  ProtectAssetsDashboard: '/assets/protect',
  EarnAssetsDashboard: '/assets/earn',
  StakingAssetsDashboard: '/assets/stake',
  Protocol: '/stats',
  Governance: '/governance',
  Vesting: '/vesting',
  WalletNotFound: '/walletNotFound',
  Protect: '/protect',
  ClaimClose: '/claim-close',
  Earn: '/earn',
  Stake: '/stake',
  StakeFlow: '/stake/flow',
  History: '/transaction-history',
  Market: '/market',

  WithdrawLiquidity: '/withdrawliquidity',
  WithdrawDetails: '/withdrawdetails',
  Withdraw: '/withdraw',

  ClaimDetails: '/claimdetails',
  ConfirmAutoRenew: '/autorenew',
  Restake: '/restake',
  ClaimRewardsStaking: '/claim',
  Cooldown: '/cooldown',
  Unstake: '/unstake',
  Airdrop: '/airdrop',
  ClaimStake: '/claim/stake',
};

export const subRoutes = {
  Summary: 'summary',
  Claim: 'claim',
  Select: 'details',
  Confirm: 'confirm',
  Close: 'close',
  Presale: 'presale',
  Processing: 'transaction-pending',
  Approve: 'transaction-approve',
  Error: 'error',
  Stake: 'stake',
};
