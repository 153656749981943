import {
  Pyramid,
  HubPages,
  FlexWithGap,
  CardView,
  IconSize,
  ListView,
  AssetFooter,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { SwiperSlide } from 'swiper/react/swiper-react';

import {
  AssetCardBottomBackground,
  AssetCardTopBackground,
  AssetCardWrapper,
  HubStatusWrapper,
  RadiatorStepperLineWrapper,
  RadiatorTopStep,
  RadiatorBottomStep,
  RadiatorStepperWrapper,
  PositionsWrapper,
} from './styles';
import {
  RadiatorOrder,
  RadiatorStepperProps,
  RadiatorStepperLineProps,
} from './types';

import noPositionsBg from '../../assets/backgrounds/no-position.png';
import cityBackgound from '../../assets/backgrounds/stake-asset.png';
import CardViewSlider from '../../components/common/CardView/CardView';
import {
  FullScreenWidthContainer,
  MainContainer,
} from '../../components/common/MainContainers';
import { SwitchNetworkBanner } from '../../components/common/SwitchNetworkBanner/SwitchNetworkBanner';
import NoPositions from '../../components/NoPositions/NoPositions';
import { FixedStakingCard } from '../../components/StakingAssetPage/FixedCard/FixedCard';
import { FlexibleStakingCard } from '../../components/StakingAssetPage/FlexibleCard/FlexibleCard';
import { StakingAssetSummaryCard } from '../../components/StakingAssetPage/StakingAssetSummaryCard';
import { Routes, subRoutes } from '../../core/config/routes';
import { BUMP } from '../../core/config/tokenNames';
import { WalletContext } from '../../core/config/walletContext';
import { useChainName } from '../../core/hooks/useChain';
import { StakeWithRewards } from '../../core/interfaces';
import { StakingService } from '../../core/services/stakingService';
import { useAppSelector } from '../../core/state/hooks';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';
import { IconWrap } from '../EarnAsset/EarnAsset';
import { StakeHubCardType } from '../StakeHub/types';

const RadiatorStepperLine: React.FC<RadiatorStepperLineProps> = ({
  stepSize,
}) => {
  return (
    <RadiatorStepperLineWrapper stepSize={stepSize}>
      <RadiatorTopStep />
      <RadiatorBottomStep />
    </RadiatorStepperLineWrapper>
  );
};

const RadiatorStepper: React.FC<RadiatorStepperProps> = ({ order }) => {
  return (
    <RadiatorStepperWrapper order={order}>
      <RadiatorStepperLine stepSize="2rem" />
      <RadiatorStepperLine stepSize="1.5rem" />
      <RadiatorStepperLine stepSize="0.75rem" />
      <RadiatorStepperLine />
    </RadiatorStepperWrapper>
  );
};

export const StakingAssetPage: React.FC = () => {
  const { chainId, account } = useWeb3React();
  const chainName = useChainName();
  const { isMobile } = useMatchBreakpoints();
  const stakingService = StakingService.getInstance();
  const navigate = useNavigate();
  const tokensDetails = useAppSelector((state) => state.coin.coinDetails);
  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  const [stakes, setStakes] = useState<StakeWithRewards[]>([]);
  const [periods, setPeriods] = useState<number[]>([]);
  const [
    stakingPositionsTotalForLargeCardData,
    setStakingPositionsTotalForLargeCardData,
  ] = useState<StakeHubCardType | null>(null);
  useEffect(() => {
    (async () => {
      try {
        const result = await stakingService.calculateTotalStakingData(
          tokensDetails,
        );
        setStakingPositionsTotalForLargeCardData(result);
      } catch (e) {
        console.error(e);
        setStakingPositionsTotalForLargeCardData(null);
      }
    })();
  }, [chainId, tokensDetails]);

  useEffect(() => {
    if (!isWalletConnected) navigate(Routes.Dashboard);
  }, [isWalletConnected]);

  useEffect(() => {
    if (isWalletConnected && account) {
      Promise.all([
        stakingService.getUserStakes(),
        stakingService.getPeriods(),
      ]).then(([stakesData, stakePeriods]) => {
        setStakes(stakesData);
        setPeriods(stakePeriods);
      });

      const polling = setInterval(async () => {
        const stakeData = await stakingService.getUserStakes();
        setStakes(stakeData);
      }, 20000);
      return () => clearInterval(polling);
    }
  }, [chainId, account, isWalletConnected]);

  const [cardView, setCardView] = useState<boolean>(false);

  return (
    <FullScreenWidthContainer>
      <SwitchNetworkBanner
        page="assets/stake"
        slides={[]}
        path={['Stake', 'BUMP']}
      />
      <AssetCardWrapper>
        <AssetCardTopBackground />
        <MainContainer width={!isMobile ? '1100px' : '90%'}>
          <StakingAssetSummaryCard
            total={
              stakingPositionsTotalForLargeCardData?.totalBUMP
                ? formatStringifyNumberToDot(
                    stakingPositionsTotalForLargeCardData.totalBUMP,
                  )
                : '0'
            }
            totalInUsd={
              stakingPositionsTotalForLargeCardData?.totalAmountInUSDC
                ? formatStringifyNumberToDot(
                    stakingPositionsTotalForLargeCardData.totalAmountInUSDC,
                    2,
                  )
                : '0'
            }
            idle={
              stakingPositionsTotalForLargeCardData?.idleBUMP
                ? formatStringifyNumberToDot(
                    stakingPositionsTotalForLargeCardData.idleBUMP,
                  )
                : '0'
            }
            idleInUsd={
              stakingPositionsTotalForLargeCardData?.idleBUMPInUSD
                ? formatStringifyNumberToDot(
                    stakingPositionsTotalForLargeCardData.idleBUMPInUSD,
                    2,
                  )
                : '0'
            }
            staked={
              stakingPositionsTotalForLargeCardData?.totalStakingAmount
                ? formatStringifyNumberToDot(
                    stakingPositionsTotalForLargeCardData.totalStakingAmount,
                  )
                : '0'
            }
            stakedInUsd={
              stakingPositionsTotalForLargeCardData?.totalStakingAmountInUSD
                ? formatStringifyNumberToDot(
                    stakingPositionsTotalForLargeCardData.totalStakingAmountInUSD,
                    2,
                  )
                : '0'
            }
            totalRewards={
              stakingPositionsTotalForLargeCardData?.rewardsBUMPInUSD
                ? formatStringifyNumberToDot(
                    stakingPositionsTotalForLargeCardData.rewardsBUMPInUSD,
                  )
                : '0'
            }
            positionsCount={
              stakingPositionsTotalForLargeCardData?.positionsCount
                ? stakingPositionsTotalForLargeCardData.positionsCount
                : 0
            }
          />
        </MainContainer>
        <AssetCardBottomBackground />
        <HubStatusWrapper>
          <RadiatorStepper order={RadiatorOrder.left} />

          <Pyramid
            isHubConnected={stakes.length !== 0}
            currentHub={HubPages.stake}
          />

          <RadiatorStepper order={RadiatorOrder.right} />
        </HubStatusWrapper>
      </AssetCardWrapper>
      <PositionsWrapper
        backgroundImage={stakes.length !== 0 ? cityBackgound : noPositionsBg}
        hasPosition={!!stakes.length}
      >
        {stakes.length && (
          <FlexWithGap
            gap="24px"
            marginBottom={cardView ? '-2.5rem !important' : ''}
            justifyContent="flex-end"
            maxWidth="970px"
            margin="auto"
          >
            <IconWrap
              className={(cardView && 'active') || ''}
              onClick={() => {
                setCardView(true);
              }}
            >
              <CardView variant={IconSize.L} />
            </IconWrap>
            <IconWrap
              className={(!cardView && 'active') || ''}
              onClick={() => setCardView(false)}
            >
              <ListView variant={IconSize.L} />
            </IconWrap>
          </FlexWithGap>
        )}
        <MainContainer maxWidth="948px !important">
          {stakes.length === 0 &&
            tokensDetails[BUMP.symbol].balanceDecimal.gt(0) && (
              <NoPositions
                heading="WANNA PLAY?"
                text="Open some positions to flip the game!"
                route={() => {
                  navigate(
                    `${Routes.StakeFlow}/${chainName}/${subRoutes.Select}`,
                  );
                }}
                btnName="Stake"
              />
            )}
          {stakes.length === 0 &&
            tokensDetails[BUMP.symbol].balanceDecimal.eq(0) && (
              <NoPositions
                heading="INSERT COINS"
                text="You've got to have some to stake some, dude.
              Load some coins!"
              />
            )}
          {!cardView ? (
            stakes.map(
              (
                {
                  amount,
                  autorenew,
                  requestedAt,
                  option,
                  withdrawWindow,
                  rewards,
                  start,
                  cooldownPeriod,
                },
                index,
              ) =>
                option === 0 ? (
                  <FlexibleStakingCard
                    amount={amount}
                    rewards={rewards}
                    unstakeRequestedAt={requestedAt}
                    endWithdrawWindow={withdrawWindow}
                    stakeIndex={index}
                    cooldownPeriod={cooldownPeriod}
                  />
                ) : (
                  <FixedStakingCard
                    key={index}
                    amount={amount}
                    rewards={rewards}
                    autorenew={autorenew}
                    option={option}
                    stakeIndex={index}
                    start={start}
                    withdrawWindow={withdrawWindow}
                    interval={periods[option]}
                  />
                ),
            )
          ) : cardView ? (
            <CardViewSlider
              posLength={stakes.length}
              minHeightMobile="800px !important"
              minHeight="400px"
            >
              {stakes.map(
                (
                  {
                    amount,
                    autorenew,
                    requestedAt,
                    option,
                    withdrawWindow,
                    rewards,
                    start,
                    cooldownPeriod,
                  },
                  index,
                ) => {
                  if (option === 0) {
                    return (
                      <SwiperSlide key={index}>
                        <FlexibleStakingCard
                          cardView={true}
                          amount={amount}
                          rewards={rewards}
                          unstakeRequestedAt={requestedAt}
                          endWithdrawWindow={withdrawWindow}
                          stakeIndex={index}
                          cooldownPeriod={cooldownPeriod}
                        />
                      </SwiperSlide>
                    );
                  } else {
                    return (
                      <SwiperSlide>
                        <FixedStakingCard
                          cardView={true}
                          key={index}
                          amount={amount}
                          rewards={rewards}
                          autorenew={autorenew}
                          option={option}
                          stakeIndex={index}
                          start={start}
                          withdrawWindow={withdrawWindow}
                          interval={periods[option]}
                        />
                      </SwiperSlide>
                    );
                  }
                },
              )}
            </CardViewSlider>
          ) : null}
        </MainContainer>
      </PositionsWrapper>
      <AssetFooter />
    </FullScreenWidthContainer>
  );
};
