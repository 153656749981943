import { ICoinDetails, IProtectionPosition } from '../../core/interfaces';

export enum RadiatorOrder {
  right = 'right',
  left = 'left',
}

export interface RadiatorStepperProps {
  order?: RadiatorOrder;
}

export interface RadiatorStepperLineProps {
  stepSize?: string | number;
}

export interface IAssetBalanceData {
  totalBalance: string;
  totalBalanceInUSD: string;
  avgFloor: string;
  inWalletBalance: string;
  balanceAmount: string;
  balanceAmountInUSD: string;
  protocolAmount: string;
  protocolAmountInUSD: string;
  positionsCount: number;
}

export interface IAutoRenew {
  token: ICoinDetails;
  protectedToken: ICoinDetails;
  position: IProtectionPosition;
  toggleState: boolean;
  hash?: string;
}

export interface IAssetBalanceCardProps {
  data: IAssetBalanceData;
  tokenSymbol: string;
}
