import { Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

export const ContentWrapper = styled(Flex)`
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 40px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    gap: 32px;
    padding: 15px 25px;
  }
`;

export const ContentValueWrapper = styled(Flex)`
  justify-content: center;
  & > *:first-child {
    margin-right: 0.25rem;
  }
`;

export const CoinKey = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
`;

export const CoinName = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
`;

export const InfoBoxContent = styled(Flex)`
  flex-direction: column;
  align-items: start;
  gap: 4px;
`;

export const ContentHeadingWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  & > *:first-child {
    margin-right: 0.25rem;
  }
`;
