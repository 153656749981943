import {
  Box,
  Button,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  SmallText,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import ProtectIcon from '@bumper-dao/ui-kit/dist/images/protect.png';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import React from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { getEtherscanLink } from './func';
import { IPolicyClaimCloseLocationProps } from './types';

import homeIcon from '../../assets/home.svg';
import { feedbackLink } from '../../core/config/links';
import { Routes } from '../../core/config/routes';
import { getNetworkConfigsByEnv } from '../../core/config/supportedChains';
import { BUMP } from '../../core/config/tokenNames';
import { getFlowAssetIcon } from '../../core/funcs/getFlowAssetIcon';
import { isFeatureEnabled } from '../../core/utils/features';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
  shortenTransactionHash,
} from '../../core/utils/helpers';

export const PolicyProtectClaimClose = () => {
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const { chainId } = useWeb3React();
  const state = useLocation().state as IPolicyClaimCloseLocationProps;

  return (
    <Box width={isMobile ? '100%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" mt="20px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          {state.isClaim ? 'Claim' : 'Close'}
        </Heading>
      </Flex>
      <Flex marginTop="40px" flexDirection="column">
        <ConfirmCard
          iconComponent={
            <Box
              style={{
                position: 'absolute',
                top: '-50px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {getFlowAssetIcon(ProtectIcon, state.icon)}
            </Box>
          }
          linkUrl={getEtherscanLink(
            state.txHash ?? '',
            getNetworkConfigsByEnv()[chainId ?? 1].config,
          )}
          linkText={shortenTransactionHash(state.txHash ?? '')}
          px={isMobile ? '8px' : ''}
        >
          <Flex flexDirection="column" justifyContent="space-between" mb="20px">
            <Flex alignItems="start" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
                fontWeight={500}
              >
                {state.token.symbol} {state.isClaim ? 'Claim' : 'Close'} Amount:
              </LargeText>
              <Flex flexDirection="column" alignItems="flex-end">
                <LargeText
                  fontFamily="Roboto Mono"
                  color="secondary.white"
                  fontSize={isMobile ? '14px' : '16px'}
                >
                  {formatWeiToNormalString(
                    state.amount,
                    state.token.decimals,
                    4,
                  )}{' '}
                  <SmallText color="secondary.white" display="inline-block">
                    {state.token.symbol}
                  </SmallText>
                </LargeText>
                <Flex>
                  <Tooltip tooltipValue="Tooltip" />
                  <SmallText ml="5px" color="primary2.cambridgeBlue">
                    (Estimated $
                    {formatWeiToNormalString(
                      state.amountValue,
                      state.token.decimals,
                      2,
                    )}
                    )
                  </SmallText>
                </Flex>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
                fontWeight={500}
              >
                Bond Withdrawal:
              </LargeText>
              <Flex flexDirection="column" alignItems="flex-end">
                <LargeText
                  fontFamily="Roboto Mono"
                  color="secondary.white"
                  fontSize={isMobile ? '14px' : '16px'}
                >
                  {formatWeiToNormalString(
                    BigNumber.from(state.bondValue),
                    BUMP.decimals,
                    4,
                  )}{' '}
                  <SmallText color="secondary.white" display="inline-block">
                    {BUMP.symbol}
                  </SmallText>
                </LargeText>
                <Flex>
                  <Tooltip tooltipValue="Tooltip" />
                  <SmallText ml="5px" color="primary2.cambridgeBlue">
                    (Estimated $
                    {formatWeiToNormalString(
                      BigNumber.from(state.bondValueInUSD),
                      BUMP.decimals,
                      2,
                    )}
                    )
                  </SmallText>
                </Flex>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" mb="5px">
              <LargeText
                fontFamily="Roboto Mono"
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
                fontWeight={500}
              >
                BUMP Incentives:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatStringifyNumberToDot(
                  (
                    parseFloat(state.boost) + parseFloat(state.coordination)
                  ).toString(),
                  4,
                )}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {BUMP.symbol}
                </SmallText>
              </LargeText>
            </Flex>
            <Flex alignItems="start" justifyContent="space-between" mb="5px">
              <MediumText fontFamily="Roboto Mono" color="secondary.white">
                Boost:
              </MediumText>
              <MediumText color="secondary.white">
                {formatStringifyNumberToDot(state.boost, 4)}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {BUMP.symbol}
                </SmallText>
              </MediumText>
            </Flex>
            <Flex alignItems="start" justifyContent="space-between" mb="20px">
              <MediumText fontFamily="Roboto Mono" color="secondary.white">
                Coordination:
              </MediumText>
              <MediumText color="secondary.white">
                {formatStringifyNumberToDot(state.coordination, 4)}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {BUMP.symbol}
                </SmallText>
              </MediumText>
            </Flex>
            <Flex alignItems="start" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
                fontWeight={500}
              >
                Premium:
              </LargeText>
              <Flex flexDirection="column" alignItems="flex-end">
                <LargeText
                  fontFamily="Roboto Mono"
                  color="secondary.white"
                  fontSize={isMobile ? '14px' : '16px'}
                >
                  {formatWeiToNormalString(
                    BigNumber.from(state.premium),
                    state.token.decimals,
                    4,
                  )}{' '}
                  <SmallText color="secondary.white" display="inline-block">
                    {state.token.symbol}
                  </SmallText>
                </LargeText>
                <Flex>
                  <Tooltip tooltipValue="Tooltip" />
                  <SmallText ml="5px" color="primary2.cambridgeBlue">
                    (Estimated $
                    {formatWeiToNormalString(
                      BigNumber.from(state.premiumInUSD),
                      state.token.decimals,
                      2,
                    )}
                    )
                  </SmallText>
                </Flex>
              </Flex>
            </Flex>
            <Flex alignItems="start" justifyContent="space-between">
              <LargeText
                fontFamily="Roboto Mono"
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
                fontWeight={500}
              >
                Protocol Fee:
              </LargeText>
              <Flex flexDirection="column" alignItems="flex-end">
                <LargeText
                  fontFamily="Roboto Mono"
                  color="secondary.white"
                  fontSize={isMobile ? '14px' : '16px'}
                >
                  {formatWeiToNormalString(
                    BigNumber.from(state.fee),
                    state.token.decimals,
                    4,
                  )}{' '}
                  <SmallText color="secondary.white" display="inline-block">
                    {state.token.symbol}
                  </SmallText>
                </LargeText>
                <Flex>
                  <Tooltip tooltipValue="Tooltip" />
                  <SmallText ml="5px" color="primary2.cambridgeBlue">
                    (Estimated $
                    {formatWeiToNormalString(
                      BigNumber.from(state.feeInUSD),
                      state.token.decimals,
                      2,
                    )}
                    )
                  </SmallText>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </ConfirmCard>
        <Flex
          justifyContent={
            isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
          }
          alignItems="center"
          mt="30px"
        >
          <Button
            secondary
            size={isMobile ? IconSize.L : IconSize.XL}
            onClick={() => {
              navigate(`${Routes.Dashboard}`);
            }}
            height="48px"
          >
            <img src={homeIcon} alt="" />
            Dashboard
          </Button>
          {isFeatureEnabled('SUPPORT') && (
            <Button
              secondary
              size={isMobile ? IconSize.L : IconSize.XL}
              onClick={() => {
                window.open(feedbackLink, '_blank');
              }}
              height="48px"
            >
              Feedback
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
