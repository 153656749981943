import { Flex, LargeText } from '@bumper-dao/ui-kit';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BreadcrumbProps } from './types';

import HomeIcon from '../../../../assets/32px/home.svg';
import { useChainName } from '../../../../core/hooks/useChain';

export const LinkWrap = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-color: #99b6b9;
  }
  &:focus,
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
`;

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ path, page }) => {
  const navigate = useNavigate();
  const chainName = useChainName();
  const lastItem = page.substring(page.lastIndexOf('/') + 1);
  return path ? (
    <Flex alignItems="center">
      <LinkWrap
        onClick={() => {
          navigate(`/dashboard/${chainName}`);
        }}
      >
        <img src={HomeIcon} alt="home" />
      </LinkWrap>
      {path.flatMap((v, i) => [
        <LargeText key={`${i}/`} color="primary2.cambridgeBlue" ml="8px">
          /
        </LargeText>,
        <LinkWrap
          key={i}
          onClick={() => {
            if (v !== path.slice(-1)[0]) {
              navigate(`/${lastItem}/${chainName}`);
            }
          }}
        >
          <LargeText key={i} color="primary2.cambridgeBlue" ml="8px">
            {v}
          </LargeText>
        </LinkWrap>,
      ])}
    </Flex>
  ) : null;
};
