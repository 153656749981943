import { getNetworkConfigsByEnv } from './supportedChains';

import { IError } from '../interfaces';

const supportedChains = Object.values(getNetworkConfigsByEnv()).map(
  (config) => config.nameForDisplay,
);

const incorrectChainIDError: IError = {
  errorDescription: `Only ${supportedChains.join(', ')} is supported`,
  errorMessage: `Unsupported network. Please switch to ${supportedChains.join(
    ', ',
  )}`,
  hideBtn: true,
};

export { incorrectChainIDError };
