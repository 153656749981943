import { ApolloProvider } from '@apollo/client';
import { CustomThemeProvider, light, ResetCSS } from '@bumper-dao/ui-kit';
import { Web3ReactProvider } from '@web3-react/core';
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { ThemeProvider } from 'styled-components';

import { Toast } from './components/common/Toast/Toast';
import { BackgroundImageProvider } from './core/providers/BackgroundImageProvider';
import { ErrorHandler } from './core/providers/ErrorHandler';
import { client } from './core/providers/graphql';
import { ReduxProvider } from './core/providers/ReduxProvider';
import { EthersServiceProvider } from './core/services/ethersServiceProvider';
import { ScrollToTop } from './core/utils/scrollToTop';
import { RenderRootRoutes } from './routes/RenderRoutes';
import './App.css';

const App: FC = () => {
  return (
    <CustomThemeProvider>
      <ThemeProvider theme={light}>
        <ToastProvider
          components={{ Toast: Toast }}
          placement="bottom-left"
          autoDismissTimeout={10000}
          autoDismiss={true}
        >
          <Web3ReactProvider
            getLibrary={() => EthersServiceProvider.getInstance().getProvider()}
          >
            <ApolloProvider client={client}>
              <ReduxProvider>
                <ErrorHandler>
                  <BrowserRouter>
                    <ScrollToTop />
                    <ResetCSS />
                    <BackgroundImageProvider>
                      <RenderRootRoutes />
                    </BackgroundImageProvider>
                  </BrowserRouter>
                </ErrorHandler>
              </ReduxProvider>
            </ApolloProvider>
          </Web3ReactProvider>
        </ToastProvider>
      </ThemeProvider>
    </CustomThemeProvider>
  );
};

export default App;
