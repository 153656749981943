import {
  Box,
  Menu,
  Page,
  SubmenuOption,
  TransactionLogo,
} from '@bumper-dao/ui-kit';
import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import timemachine from 'timemachine';

import { defaultSummaryTVLs } from './constants';

import { Routes } from '../core/config/routes';
import {
  bETH,
  BUMP,
  bUSDCCoin,
  ETH,
  USDCoin,
  wBTC,
  WETH,
} from '../core/config/tokenNames';
import { getEtherscanBlockLink } from '../core/funcs/getEtherscanLink';
import { useChain } from '../core/hooks/useChain';
import { useWeb3Wallet } from '../core/hooks/useWallet';
import { SummaryMarketDataType } from '../core/interfaces';
import { EthersServiceProvider } from '../core/services/ethersServiceProvider';
import { MarketService } from '../core/services/marketService';
import { fetchCoinDetails } from '../core/state/actions/coinActions';
import { setIsOpenedWalletModal } from '../core/state/actions/walletModalActions';
import { useAppSelector } from '../core/state/hooks';
import { isFeatureEnabled } from '../core/utils/features';
import {
  convertBumpToDollars,
  formatStringifyNumberToDot,
  timeInMilliseconds,
} from '../core/utils/helpers';
import { ConnectWallet } from '../pages/ConnectWallet';

timemachine.reset();

export const AppRouteComponent: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const ethersServiceProvider = EthersServiceProvider.getInstance();
  const marketService = MarketService.getInstance();
  const [bumpPrice, setBumpPrice] = useState<string>('0.00');
  const coin = useAppSelector((state) => state.coin);

  const [{ summaryTVL, makerTVL, takerTVL, stakerTVL }, setTVLs] =
    useState<SummaryMarketDataType>(defaultSummaryTVLs);

  const isOpenedWalletModal = useAppSelector(
    (state) => state.walletModal.isOpenedWalletModal,
  );
  const uiStateMagmt = useAppSelector(
    (state) => state.uiStateMgmt.uiStateManagement,
  );
  const { active: isConnected, tried, disconnectWallet } = useWeb3Wallet();
  const { chainId } = useWeb3React();
  const { nameForDisplay, config } = useChain();

  const history = useNavigate();
  const location = useLocation();

  const [latestBlockNumber, setLatestBlockNumber] = useState<number>(0);
  useEffect(() => {
    const latestBlockNumberPolling = setInterval(() => {
      (async () => {
        const provider = EthersServiceProvider.getInstance().provider;
        const blockNumber = (await provider?.getBlockNumber()) || 0;
        setLatestBlockNumber(blockNumber);
      })();
    }, 10000);
    return () => {
      clearInterval(latestBlockNumberPolling);
    };
  }, [chainId]);

  useEffect(() => {
    if (location.pathname === Routes.App)
      history(Routes.Dashboard, { replace: true });
  }, [location.pathname]);

  const updateTVLs = async () => {
    setBumpPrice(await convertBumpToDollars('1'));
    const TVLs = await marketService.getState();
    setTVLs(TVLs);
  };

  useEffect(() => {
    updateTVLs();
    const TVLsPolling = setInterval(() => {
      updateTVLs();
    }, 10000);
    return () => {
      clearInterval(TVLsPolling);
    };
  }, [chainId]);

  useEffect(() => {
    const delayBalancePolling = 7000;
    const delayBlockTimeStampPolling = 2000;

    const isTimeMachineEnabled =
      (process.env.REACT_APP_TIME_MACHINE as string) === 'true';

    const poolingBlockTimeStamp = isTimeMachineEnabled
      ? setInterval(() => {
          (async () => {
            const provider = EthersServiceProvider.getInstance().provider;
            if (provider) {
              const lastBlockTimeStamp = timeInMilliseconds(
                (await provider.getBlock('latest')).timestamp,
              );
              if (lastBlockTimeStamp > Date.now()) {
                timemachine.config({
                  timestamp: lastBlockTimeStamp,
                  tick: true,
                });
              }
            }
          })();
        }, delayBlockTimeStampPolling)
      : null;

    const pollingBalanceInterval = setInterval(() => {
      dispatch(
        fetchCoinDetails([USDCoin, bUSDCCoin, ETH, BUMP, WETH, wBTC, bETH]),
      );
    }, delayBalancePolling);

    return () => {
      clearInterval(pollingBalanceInterval);
      if (poolingBlockTimeStamp) {
        timemachine.reset();
        clearInterval(poolingBlockTimeStamp);
      }
    };
  }, [dispatch]);

  const SubMenuOptions: SubmenuOption[] = [
    {
      name: 'History',
      onClick: () => {
        history(Routes.History, { replace: true });
      },
    },
    {
      name: 'Market',
      onClick: () => {
        history(`${Routes.Market}/${nameForDisplay}`, { replace: true });
      },
    },
  ];

  const getActivePage = (): Page => {
    if (location.pathname.includes('assets')) return 'other';
    const regExpProtect = new RegExp(Routes.Protect);
    const regExpEarn = new RegExp(Routes.Earn);
    const regExpStake = new RegExp(Routes.Stake);
    if (regExpProtect.test(location.pathname)) return 'protect';
    if (regExpEarn.test(location.pathname)) return 'earn';
    if (regExpStake.test(location.pathname)) return 'stake';
    return 'other';
  };

  const renderRoutes = () => {
    return (
      <>
        {isOpenedWalletModal && (
          <ConnectWallet
            setIsOpened={(data) => dispatch(setIsOpenedWalletModal(data))}
          />
        )}
        <Menu
          subMenuOptions={SubMenuOptions}
          walletAddress={
            isConnected ? ethersServiceProvider.currentAccount : null
          }
          bumpBalance={
            isConnected
              ? formatStringifyNumberToDot(
                  coin.coinDetails[BUMP.symbol].balance,
                  2,
                )
              : '0.00'
          }
          bumpInUSDBalance={
            isConnected
              ? formatStringifyNumberToDot(
                  coin.coinDetails[BUMP.symbol].value,
                  2,
                )
              : '0.00'
          }
          bumpPrice={formatStringifyNumberToDot(bumpPrice, 2)}
          activePage={getActivePage()}
          protectClick={() => {
            history(`${Routes.Protect}/${nameForDisplay}`);
          }}
          earnClick={() => {
            history(`${Routes.Earn}/${nameForDisplay}`);
          }}
          stakeClick={() => {
            history(`${Routes.Stake}/${nameForDisplay}`);
          }}
          homePageClick={() => {
            history(`${Routes.Dashboard}/${nameForDisplay}`);
          }}
          connectClick={() => {
            dispatch(setIsOpenedWalletModal(true));
          }}
          protocolTVL={formatStringifyNumberToDot(summaryTVL, 2)}
          takerTVL={formatStringifyNumberToDot(takerTVL, 2)}
          makerTVL={formatStringifyNumberToDot(makerTVL, 2)}
          stakerTVL={formatStringifyNumberToDot(stakerTVL, 2)}
          isFlowActive={!uiStateMagmt.showNavToolbar}
          blockNumber={latestBlockNumber}
          logoutClick={() => {
            disconnectWallet();
          }}
          linkUrl={getEtherscanBlockLink(latestBlockNumber, config)}
          isSupportEnabled={isFeatureEnabled('SUPPORT')}
        >
          <Outlet />
        </Menu>
      </>
    );
  };

  if (isConnected && tried) {
    if (
      coin.coinDetails[bUSDCCoin.symbol].balance === '' &&
      coin.coinDetails[USDCoin.symbol].balance === ''
    ) {
      return (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: 'translate(-50%, -50%)' }}
        >
          <TransactionLogo />
        </Box>
      );
    } else {
      return renderRoutes();
    }
  } else if (!isConnected && tried) {
    return renderRoutes();
  } else {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <TransactionLogo />
      </Box>
    );
  }
});
