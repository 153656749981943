import * as Sentry from '@sentry/react';
import { signERC2612Permit } from 'eth-permit';
import { RSV } from 'eth-permit/dist/rpc';
import { BigNumber } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';

import { CONTRACTS } from '../config/contractAddresses';
import { BUMP } from '../config/tokenNames';
import { ContractListType, TokenDetails, CustomProvider } from '../interfaces';

/**
 *
 * @param provider: RPC or Signer for call permit
 * @param tokenAddress: token address who we try to permit
 * @param userAddress: sender address(who permit)
 * @param transferAmount: sum of tokens to permit
 * @param version: token version for token domain generating
 * @param name: token name
 * @param contractAddress: contract which we authorize
 */
export const tryPermit = async (
  provider: CustomProvider,
  tokenAddress: string,
  userAddress: string,
  transferAmount: BigNumber,
  version: number,
  name: string,
  contractAddress: string,
): Promise<({ deadline: number | string } & RSV) | undefined> => {
  const { TOKEN_DETAILS } = await getContractsAddresses();
  if (
    tokenAddress.toLowerCase() !==
    TOKEN_DETAILS[BUMP.symbol].address.toLowerCase()
  ) {
    return undefined;
  }
  const currentNetwork =
    await EthersServiceProvider.getInstance().provider?.getNetwork();
  const domain = {
    name,
    version: version.toString(),
    chainId: currentNetwork?.chainId ?? 1,
    verifyingContract: tokenAddress,
  };
  const deadline = Math.floor(new Date().getTime() / 1000) + 1000000;
  let permit;
  try {
    permit = await signERC2612Permit(
      provider,
      domain,
      userAddress,
      contractAddress,
      transferAmount.toString(),
      deadline,
    );
  } catch (err) {
    Sentry.captureException(err);
    return undefined;
  }

  if (permit.v !== 27 && permit.v !== 28) {
    return undefined;
  }

  return permit;
};

export const getChainId = async (): Promise<number> => {
  const currentNetwork =
    await EthersServiceProvider.getInstance().provider?.getNetwork();
  return currentNetwork?.chainId ?? 1;
};
export const getContractsAddresses = async (): Promise<ContractListType> => {
  const chainId = await getChainId();
  const contracts = CONTRACTS[chainId];
  return contracts;
};
export const getCurrentTokenDataBySymbol = async (
  symbol: string,
): Promise<TokenDetails> => {
  return (await getContractsAddresses()).TOKEN_DETAILS[symbol];
};
