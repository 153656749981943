export enum Chains {
  MAINNET = 1,
  OPTIMISM = 10,
  FANTOM = 250,
  GOERLI = 5,
}

export const SUPPORTED_CHAINS = [
  Chains.MAINNET as number,
  Chains.OPTIMISM as number,
  Chains.FANTOM as number,
  Chains.GOERLI as number,
];
