import {
  Box,
  Button,
  Checkbox,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  Link,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { BigNumber, ethers } from 'ethers';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import HorizontalLine from '../../assets/backgrounds/claimStakeLine.svg';
import BumpIcon from '../../assets/BUMPSquare.svg';
import { MainContainer } from '../../components/common/MainContainers';
import { learnMoreLink } from '../../core/config/links';
import { Routes, subRoutes } from '../../core/config/routes';
import { BUMP } from '../../core/config/tokenNames';
import { useChain } from '../../core/hooks/useChain';
import { VestingService } from '../../core/services/vestingService';
import { fetchCoinDetails } from '../../core/state/actions/coinActions';
import { getVestingClaimDetails } from '../../core/state/actions/merkleTreeActions';
import { useAppSelector } from '../../core/state/hooks';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';
import { ConfirmStakingProps } from '../Staking/types';

export const ClaimStakeConfirmPage = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const location = useLocation();
  const { nameForDisplay: chainName } = useChain();
  const locationState = location.state as ConfirmStakingProps;

  const {
    stakingType,
    stakeAmount,
    option,
    activeOptionIndex,
    claimedAmount,
    autorenew,
    walletAmount,
  } = locationState;
  const dispatch = useDispatch();
  const vestingService = VestingService.getInstance();

  const vestingData = useAppSelector((state) => state.merkleTree.vesting);
  const merkleState = useAppSelector((state) => state.merkleTree.merkle);

  const handleBack = async () => {
    navigate(`${Routes.ClaimStake}/${chainName}/${subRoutes.Select}`, {
      replace: true,
      state: locationState,
    });
  };

  const onConfirmButtonClick = async () => {
    try {
      navigate(`${Routes.ClaimStake}/${chainName}/${subRoutes.Approve}`, {
        replace: true,
      });

      const tx = await vestingService.claimToStakeWithPermit(
        vestingData,
        activeOptionIndex,
        autorenew,
        BigNumber.from(walletAmount),
        ethers.utils.parseUnits(claimedAmount?.toString() ?? '0', 18),
        ethers.utils.parseUnits(stakeAmount.toString(), 18),
      );

      navigate(`${Routes.ClaimStake}/${chainName}/${subRoutes.Processing}`, {
        replace: true,
      });
      await tx.wait();
      navigate(`${Routes.ClaimStake}/${chainName}/${subRoutes.Summary}`, {
        state: {
          ...locationState,
          txHash: tx.hash,
        },
        replace: true,
      });

      dispatch(fetchCoinDetails([BUMP]));
      dispatch(getVestingClaimDetails(merkleState.vesting));
    } catch (err) {
      console.error(err);
      navigate(`${Routes.Dashboard}`);
    }
  };

  return (
    <MainContainer
      maxWidth={isMobile ? '90%' : '575px !important'}
      style={{ gap: 0 }}
    >
      <Flex alignItems="center" justifyContent="center" marginBottom="50px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <img
            src={BumpIcon}
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-50px',
            }}
            width="48px"
            height="48px"
            alt="Icon"
          />
        }
        amount={(locationState.claimedAmount || 0).toString()}
        subTitle={'BUMP'}
        px={isMobile ? '8px' : ''}
      >
        <Flex alignItems="center" justifyContent="space-between" mb="20px">
          <LargeText
            fontFamily="Share Tech Mono"
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            BUMP Claim:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {formatStringifyNumberToDot(
              '' +
                ((claimedAmount ?? 0) - stakeAmount > 0
                  ? (claimedAmount ?? 0) - stakeAmount
                  : 0),
              4,
            )}{' '}
            BUMP
          </LargeText>
        </Flex>
        <Box width="100%" overflow="hidden">
          <img src={HorizontalLine} />
        </Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mt="20px"
          mb="20px"
        >
          <LargeText
            fontFamily="Share Tech Mono"
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Staking Term:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {stakingType === 1 ? 'Flexible' : `${option.periodInDays} days`}
          </LargeText>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between" mb="20px">
          <LargeText
            fontFamily="Share Tech Mono"
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            BUMP Stake:
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {stakeAmount} BUMP
          </LargeText>
        </Flex>
      </ConfirmCard>
      <Flex alignItems="center" mt="40px" width="100%">
        <Checkbox onClick={() => setIsActive(!isActive)} />
        <LargeText ml="20px" color="secondary.white">
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={learnMoreLink}>
            Terms and conditions
          </Link>
          ,
          <Link color="secondary.green" href={learnMoreLink}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="40px"
        width="100%"
      >
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={handleBack}
        >
          Back
        </Button>

        <Button
          primary
          onClick={onConfirmButtonClick}
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!isActive}
        >
          Confirm
        </Button>
      </Flex>
    </MainContainer>
  );
};
