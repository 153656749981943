import { StepStatus } from '@bumper-dao/ui-kit';

import { DepositLiquidityOutProps } from './types';

export const earningPeriodMultiply = 30;
export const earningPeriods = Array.from(
  { length: 5 },
  (_, i) => (i + 1) * earningPeriodMultiply,
);

export const defaultEmptyOutProps: DepositLiquidityOutProps = {
  depositAmount: 0,
  earningPeriod: -1,
  autoRenew: false,
  riskTier: -1,
};

export const earnDepositFlowSteps = ['Details', 'Confirm', 'Summary'];

export const detailsStatusSteps: StepStatus[] = ['current', 'next', 'next'];
export const confirmStatusSteps: StepStatus[] = ['past', 'current', 'next'];
export const summaryStatusSteps: StepStatus[] = ['past', 'past', 'current'];
