import {
  LargeText,
  SmallText,
  Heading,
  ConfirmationBoxFixed,
  Flex,
  TransactionLogo,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React from 'react';

interface TransactionProps {
  steps?: number;
}

export const TransactionConfirm: React.FC<TransactionProps> = ({ steps }) => {
  const { isMobile } = useMatchBreakpoints();
  return (
    <Flex maxWidth={isMobile ? '90%' : '415px'} flexDirection="column">
      <Flex justifyContent="center" mb="40px">
        <TransactionLogo />
      </Flex>
      <Flex justifyContent="center">
        <Heading
          color="secondary.white"
          as="h3"
          scale="xl"
          lineHeight={'150%'}
          letterSpacing={'0.03em'}
          mb="20px"
          maxWidth={305}
          textAlign={'center'}
        >
          Confirm transactions in your wallet
        </Heading>
      </Flex>

      <ConfirmationBoxFixed p={isMobile ? '16px 50px' : undefined}>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          maxWidth={isMobile ? '230px' : 'auto'}
        >
          <SmallText color="typography.earnDepositTextDescription">
            *** IMPORTANT ***
          </SmallText>
          {steps === 1 && (
            <SmallText
              color="typography.earnDepositTextDescription"
              textAlign="center"
            >
              To complete your transaction, you need to confirm the transaction
              in your wallet. A network gas fee will be charged.
            </SmallText>
          )}
          {(!steps || steps === 2) && (
            <>
              <SmallText
                color="typography.earnDepositTextDescription"
                textAlign="center"
              >
                To complete your transaction, you need to complete two (2)
                steps:
              </SmallText>
              <br />
              <SmallText
                color="typography.earnDepositTextDescription"
                textAlign="center"
              >
                Step 1: Signature Request - no gas fee required
              </SmallText>
              <br />
              <SmallText
                color="typography.earnDepositTextDescription"
                textAlign="center"
              >
                Step 2: Confirm Transaction - gas fee required
              </SmallText>
            </>
          )}
          {steps === 3 && (
            <>
              <SmallText
                color="typography.earnDepositTextDescription"
                textAlign="center"
              >
                To complete your transaction, you need to complete three (3)
                steps:
              </SmallText>
              <br />
              <SmallText
                color="typography.earnDepositTextDescription"
                textAlign="center"
              >
                Step 1: Signature Request - no gas fee required
              </SmallText>
              <br />
              <SmallText
                color="typography.earnDepositTextDescription"
                textAlign="center"
              >
                Step 2: Signature Request - no gas fee required
              </SmallText>
              <br />
              <SmallText
                color="typography.earnDepositTextDescription"
                textAlign="center"
              >
                Step 3: Confirm Transaction - gas fee required
              </SmallText>
            </>
          )}
          <br />
          <SmallText color="typography.earnDepositTextDescription">
            GAS FEES
          </SmallText>
          <SmallText
            color="typography.earnDepositTextDescription"
            textAlign="center"
          >
            {
              "Setting a higher or 'Fast' gas fee will complete your transaction quickly."
            }
          </SmallText>
        </Flex>
      </ConfirmationBoxFixed>
    </Flex>
  );
};

export const TransactionInProgress: React.FC = () => {
  return (
    <Flex flexDirection="column" margin="auto" justifyContent="center">
      <Flex justifyContent="center" mb="50px">
        <TransactionLogo />
      </Flex>
      <LargeText
        color="secondary.white"
        textAlign="center"
        mb="16px"
        fontWeight={700}
      >
        Your transaction is now in progress.
      </LargeText>
      <SmallText
        color="typography.earnDepositTextDescription"
        textAlign="center"
      >
        This may take a few minutes.
      </SmallText>
    </Flex>
  );
};
