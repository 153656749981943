import {
  IconSize,
  Flex,
  Button,
  Checkbox,
  ConfirmCard,
  Heading,
  Link,
  MediumText,
  LargeText,
  SmallText,
  Tooltip,
  useMatchBreakpoints,
  Box,
} from '@bumper-dao/ui-kit';
import { BigNumber } from 'ethers';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ConfirmDepositInnerContainer } from './styles';
import { ConfirmDepositCardProps } from './types';

import earnIcon from '../../assets/earn1.png';
import { MainContainer } from '../../components/common/MainContainers';
import { DEFAULT_DECIMAL_VALUE } from '../../core/config/formulaConstants';
import { Routes, subRoutes } from '../../core/config/routes';
import { USDCoin, WETH } from '../../core/config/tokenNames';
import { tokensIcons } from '../../core/config/tokensIcons';
import { getFlowAssetIcon } from '../../core/funcs/getFlowAssetIcon';
import { useChainName } from '../../core/hooks/useChain';
import { MakerPositionService } from '../../core/services/makerPositionService';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../core/utils/helpers';

const ConfirmDepositCard: React.FC<ConfirmDepositCardProps> = (props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { isMobile } = useMatchBreakpoints();
  const navigate = useNavigate();
  const chainName = useChainName();

  const makerService = MakerPositionService.getInstance();

  const onBackClick = () => {
    navigate(`${Routes.Earn}/${chainName}/${subRoutes.Select}`, {
      replace: true,
      state: { ...props },
    });
  };

  const onConfirmButtonClick = async () => {
    try {
      navigate(`${Routes.Earn}/${chainName}/${subRoutes.Approve}`, {
        replace: true,
      });

      const tx = await makerService.deposit(
        props.stableAmount,
        BigNumber.from(props.bondAmount.toTransfer),
        props.earningPeriod,
        props.riskTier,
        props.autoRenew,
        props.token,
        WETH,
      );

      navigate(`${Routes.Earn}/${chainName}/${subRoutes.Processing}`, {
        replace: true,
      });
      await tx.wait();

      navigate(`${Routes.Earn}/${chainName}/${subRoutes.Summary}`, {
        replace: true,
        state: {
          ...props,
          txHash: tx.hash,
        },
      });
    } catch (e) {
      navigate(`${Routes.Earn}/${chainName}`, {
        replace: true,
      });
    }
  };

  return (
    <MainContainer
      maxWidth={isMobile ? '100%' : '575px !important'}
      style={{ gap: '40px' }}
    >
      <Flex alignItems="center" justifyContent="center">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      <Flex marginTop="10px" width="100%">
        <ConfirmCard
          iconComponent={
            <Box
              style={{
                position: 'absolute',
                top: '-50px',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              {getFlowAssetIcon(earnIcon, tokensIcons[USDCoin.symbol])}
            </Box>
          }
          amount={formatStringifyNumberToDot(props.stableAmount, 4)}
          subTitle={props.stableSymbol}
          px={isMobile ? '8px' : ''}
        >
          <ConfirmDepositInnerContainer mb="20px" mx="4px">
            <Flex alignItems="center" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
              >
                Earning Period:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
                fontFamily="Roboto Mono"
              >
                {props.earningPeriod} Days
              </LargeText>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mb="20px">
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
              >
                Risk Tier:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
                fontFamily="Roboto Mono"
              >
                {props.riskTier}
              </LargeText>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between">
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
              >
                Auto-renew:
              </LargeText>
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
                fontFamily="Roboto Mono"
              >
                {props.autoRenew ? 'On' : 'Off'}
              </LargeText>
            </Flex>
          </ConfirmDepositInnerContainer>
          <Flex alignItems="start" justifyContent="space-between" mb="20px">
            <Flex>
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
                style={{ whiteSpace: 'nowrap' }}
              >
                Deposit Amount:
              </LargeText>
              {isMobile && <Tooltip tooltipValue="" />}
            </Flex>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatStringifyNumberToDot(props.stableAmount.toString(), 4)}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  USDC
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="" />}
                <MediumText
                  ml="5px"
                  color="primary2.cambridgeBlue"
                  style={{ whiteSpace: 'nowrap' }}
                  fontSize={'12px'}
                >
                  (Estimated $
                  {formatStringifyNumberToDot(props.stableAmount.toString(), 2)}
                  )
                </MediumText>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="start" justifyContent="space-between" mb="20px">
            <Flex>
              <LargeText
                fontFamily="Roboto Mono"
                fontWeight={500}
                fontSize={isMobile ? '14px' : '16px'}
                color="secondary.white"
                style={{ whiteSpace: 'nowrap' }}
              >
                BUMP Bond:
              </LargeText>
              {isMobile && <Tooltip tooltipValue="lalala" />}
            </Flex>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatWeiToNormalString(
                  props.bondAmount.toTransfer,
                  DEFAULT_DECIMAL_VALUE,
                  4,
                )}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  BUMP
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="" />}
                <MediumText
                  ml="5px"
                  color="primary2.cambridgeBlue"
                  style={{ whiteSpace: 'nowrap' }}
                  fontSize={'12px'}
                >
                  (Estimated ${props.bondAmountInUSD})
                </MediumText>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="5px">
            <LargeText
              fontFamily="Roboto Mono"
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
            >
              BUMP Incentives:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {formatStringifyNumberToDot(
                (props.incentiveBoost + props.incentiveNetwork).toString(),
                4,
              )}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </LargeText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <MediumText
              fontFamily="Roboto Mono"
              fontWeight={400}
              color="secondary.white"
            >
              Boost:
            </MediumText>
            <MediumText color="secondary.white">
              {formatStringifyNumberToDot(props.incentiveBoost.toString(), 4)}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </MediumText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="10px">
            <MediumText
              fontFamily="Roboto Mono"
              fontWeight={400}
              color="secondary.white"
            >
              Coordination:
            </MediumText>
            <MediumText color="secondary.white">
              {formatStringifyNumberToDot(props.incentiveNetwork.toString(), 4)}{' '}
              <SmallText color="secondary.white" display="inline-block">
                BUMP
              </SmallText>
            </MediumText>
          </Flex>
        </ConfirmCard>
      </Flex>
      <Flex alignItems={isMobile ? 'start' : 'center'} width="100%">
        <Checkbox onClick={() => setIsActive(!isActive)} />
        <LargeText
          mt={isMobile ? '-10px' : ''}
          ml="20px"
          color="secondary.white"
        >
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={props.linkPath}>
            Terms and conditions
          </Link>
          ,{' '}
          <Link color="secondary.green" href={props.linkPath}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={props.linkPath}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={onBackClick}
        >
          Back
        </Button>

        <Button
          primary
          onClick={onConfirmButtonClick}
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!isActive}
        >
          Confirm
        </Button>
      </Flex>
    </MainContainer>
  );
};

export default ConfirmDepositCard;
