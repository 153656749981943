import {
  Box,
  Button,
  Checkbox,
  ConfirmationBoxFixed,
  ConfirmCard,
  Flex,
  FlexWithGap,
  Heading,
  IconSize,
  LargeText,
  Link,
  MediumText,
  SmallText,
  Toggle,
  ToggleParams,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import ProtectIcon from '@bumper-dao/ui-kit/dist/images/protect.svg';
import { BigNumber, ethers } from 'ethers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import {
  ConfirmCloseLocationProps,
  IConfirmClaimCloseData,
  IPolicyClaimCloseLocationProps,
} from './types';

import ETHIconSmall from '../../assets/32px/tokens/ETH.svg';
import { MainContainer } from '../../components/common/MainContainers';
import {
  disclaimerLink,
  privacyPolicyLink,
  termsAndConditionsLink,
} from '../../core/config/links';
import { Routes, subRoutes } from '../../core/config/routes';
import { BUMP, ETH, getSymbol, WETH } from '../../core/config/tokenNames';
import { getFlowAssetIcon } from '../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../core/hooks/useChain';
import { ICoin } from '../../core/interfaces';
import { TakerPositionService } from '../../core/services/takerPositionService';
import { useAppSelector } from '../../core/state/hooks';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../core/utils/helpers';

export const ConfirmProtectClaimClose = () => {
  const [closeData, setCloseData] = useState<IConfirmClaimCloseData>({
    amount: ethers.constants.Zero,
    amountValue: ethers.constants.Zero,
    price: ethers.constants.Zero,
    premium: ethers.constants.Zero,
    fee: ethers.constants.Zero,
    bondValue: '0',
    bondValueInUSD: '0',
    boost: '0',
    coordination: '0',
    premiumInUSD: '0',
    feeInUSD: '0',
  });
  const [ethClose, setEthClose] = useState<boolean>(false);
  const [withdrawBond, setWithdrawBond] = useState<boolean>(false);

  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const params = useParams<{ symbol: string }>();
  const symbol = useMemo(
    () => getSymbol(params.symbol ?? ETH.symbol),
    [params],
  );
  const location = useLocation();
  const { nameForDisplay: chainName, config } = useChain();
  const coinDetails = useAppSelector((state) => state.coin.coinDetails);
  const TOKEN_DETAILS = useAppSelector(
    (state) => state.contractsAddresses.TOKEN_DETAILS,
  );
  const takerService = TakerPositionService.getInstance();

  const getLocationState: () => ConfirmCloseLocationProps = useCallback(() => {
    if (!location.state) {
      return {
        amount: '0',
        token: coinDetails[symbol],
        id: 0,
        isClaim: false,
        icon: ETHIconSmall,
      };
    }

    return location.state as ConfirmCloseLocationProps;
  }, [location.state]);

  const { amount, icon, id, isClaim, token } = getLocationState();

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const onBack = () =>
    navigate(`${Routes.ProtectAssetsDashboard}/${chainName}`);

  const displayToken = useMemo<ICoin>(() => {
    const currentTokenSymbol = token.symbol;
    if (
      currentTokenSymbol === ETH.symbol ||
      currentTokenSymbol === WETH.symbol
    ) {
      if (ethClose) return ETH;
      return WETH;
    }
    return token;
  }, [token, ethClose]);

  const onConfirm = async () => {
    try {
      navigate(
        `${Routes.ClaimClose}/${chainName}/${displayToken.symbol}/${subRoutes.Approve}`,
        { replace: true },
      );
      const tx = await (isClaim
        ? takerService.claim(id, displayToken, withdrawBond)
        : takerService.closeTakerPosition(
            id,
            displayToken,
            ethClose,
            withdrawBond,
          ));
      navigate(
        `${Routes.ClaimClose}/${chainName}/${displayToken.symbol}/${subRoutes.Processing}`,
        { replace: true },
      );
      await tx.wait();
      const policyState: IPolicyClaimCloseLocationProps = {
        ...closeData,
        token: displayToken,
        isClaim,
        icon,
        txHash: tx.hash,
      };
      navigate(
        `${Routes.ClaimClose}/${chainName}/${displayToken.symbol}/${subRoutes.Summary}`,
        { state: policyState, replace: true },
      );
    } catch (e) {
      console.log(e);
      navigate(`${Routes.Protect}/${chainName}`, { replace: true });
    }
  };

  const handleCheckbox = () => setTermsAccepted((prev) => !prev);

  const getCloseData = () =>
    takerService.getCloseData(
      +config.chainId,
      id,
      TOKEN_DETAILS[token.symbol].address,
      token,
      BigNumber.from(amount),
    );

  useEffect(() => {
    getCloseData().then((data) => setCloseData(data));
  }, []);

  return (
    <MainContainer
      maxWidth={isMobile ? '100% !important' : '575px !important'}
      style={{ gap: '40px' }}
    >
      <Flex mt="20px" alignItems="center" justifyContent="center">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      {!isClaim && (
        <ConfirmationBoxFixed mt="20px" position="relative">
          <Box
            style={{
              position: 'absolute',
              left: '50%',
              top: '-50px',
              transform: 'translateX(-50%)',
            }}
          >
            {getFlowAssetIcon(ProtectIcon, icon)}
          </Box>
          <FlexWithGap gap="36px" flexDirection="column" width="100%">
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex alignItems="center" width={isMobile ? '70px' : 'unset'}>
                <LargeText
                  color="secondary.white"
                  fontSize={isMobile ? '14px' : '16px'}
                  width={isMobile ? '70px !important' : 'unset'}
                  fontWeight={500}
                >
                  Position Close Currency:
                </LargeText>
              </Flex>
              <Box width="120px">
                <ToggleParams
                  checked={false}
                  onToggle={() => setEthClose((prev) => !prev)}
                  variants={['ETH', 'wETH']}
                />
              </Box>
            </Flex>
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Flex alignItems="center" width={isMobile ? '70px' : 'unset'}>
                <LargeText
                  color="secondary.white"
                  fontSize={isMobile ? '14px' : '16px'}
                  fontWeight={500}
                >
                  Withdraw Your Bond:
                </LargeText>
                <Tooltip tooltipValue="Bond" />
              </Flex>
              <Box width="120px">
                <Toggle
                  checked={withdrawBond}
                  onToggle={() => setWithdrawBond((prev) => !prev)}
                  variants={['yes', 'no']}
                />
              </Box>
            </Flex>
          </FlexWithGap>
        </ConfirmationBoxFixed>
      )}
      <ConfirmCard
        iconComponent={
          isClaim ? (
            <img
              src={icon}
              style={{
                position: 'absolute',
                left: '50%',
                top: '-50px',
                transform: 'translateX(-50%)',
              }}
              width="48px"
              height="48px"
              alt="Icon"
            />
          ) : (
            <span />
          )
        }
        amount={formatWeiToNormalString(closeData.amount, token.decimals, 4)}
        subTitle={displayToken.name.toUpperCase()}
        px={isMobile ? '8px' : ''}
      >
        <Flex flexDirection="column" justifyContent="space-between" mb="20px">
          <Flex justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
              fontWeight={500}
            >
              {displayToken.symbol} {isClaim ? 'Claim' : 'Close'} Amount:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatWeiToNormalString(
                  closeData.amount,
                  displayToken.decimals,
                  4,
                )}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {displayToken.symbol}
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="Tooltip" />}
                <SmallText ml="5px" color="primary2.cambridgeBlue">
                  (Estimated $
                  {formatWeiToNormalString(
                    closeData.amountValue,
                    token.decimals,
                    2,
                  )}
                  )
                </SmallText>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
              fontWeight={500}
            >
              Bond return:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatWeiToNormalString(
                  BigNumber.from(closeData.bondValue),
                  BUMP.decimals,
                  4,
                )}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {BUMP.symbol}
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="Tooltip" />}
                <SmallText ml="5px" color="primary2.cambridgeBlue">
                  (Estimated $
                  {formatWeiToNormalString(
                    BigNumber.from(closeData.bondValueInUSD),
                    BUMP.decimals,
                    2,
                  )}
                  )
                </SmallText>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="5px">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
              fontWeight={500}
            >
              BUMP Incentives:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {formatStringifyNumberToDot(
                (
                  parseFloat(closeData.boost) +
                  parseFloat(closeData.coordination)
                ).toString(),
                4,
              )}{' '}
              <SmallText color="secondary.white" display="inline-block">
                {BUMP.symbol}
              </SmallText>
            </LargeText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="5px">
            <MediumText fontFamily="Roboto Mono" color="secondary.white">
              Boost:
            </MediumText>
            <MediumText color="secondary.white">
              {formatStringifyNumberToDot(closeData.boost, 4)}{' '}
              <SmallText color="secondary.white" display="inline-block">
                {BUMP.symbol}
              </SmallText>
            </MediumText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <MediumText fontFamily="Roboto Mono" color="secondary.white">
              Coordination:
            </MediumText>
            <MediumText color="secondary.white">
              {formatStringifyNumberToDot(closeData.coordination, 4)}{' '}
              <SmallText color="secondary.white" display="inline-block">
                {BUMP.symbol}
              </SmallText>
            </MediumText>
          </Flex>
          <Flex justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
              fontWeight={500}
            >
              Premium:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatWeiToNormalString(
                  BigNumber.from(closeData.premium),
                  displayToken.decimals,
                  4,
                )}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {displayToken.symbol}
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="Tooltip" />}
                <SmallText ml="5px" color="primary2.cambridgeBlue">
                  (Estimated $
                  {formatWeiToNormalString(
                    BigNumber.from(closeData.premiumInUSD),
                    displayToken.decimals,
                    2,
                  )}
                  )
                </SmallText>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <LargeText
              fontFamily="Roboto Mono"
              fontSize={isMobile ? '14px' : '16px'}
              color="secondary.white"
              fontWeight={500}
            >
              Protocol Fee:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                fontFamily="Roboto Mono"
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatWeiToNormalString(
                  BigNumber.from(closeData.fee),
                  displayToken.decimals,
                  4,
                )}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {displayToken.symbol}
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="Tooltip" />}
                <SmallText ml="5px" color="primary2.cambridgeBlue">
                  (Estimated $
                  {formatWeiToNormalString(
                    BigNumber.from(closeData.feeInUSD),
                    displayToken.decimals,
                    2,
                  )}
                  )
                </SmallText>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ConfirmCard>
      <Flex alignItems="center" width="100%">
        <Box width="32px" height="32px">
          <Checkbox onClick={handleCheckbox} />
        </Box>
        <LargeText ml="20px" color="secondary.white">
          By checking this box you acknowledge and agree to our
          <br />
          <Link color="secondary.green" href={termsAndConditionsLink}>
            <span style={{ fontFamily: 'Roboto Mono' }}>
              Terms and Conditions
            </span>
          </Link>
          ,{' '}
          <Link color="secondary.green" href={privacyPolicyLink}>
            <span style={{ fontFamily: 'Roboto Mono' }}>Privacy Policy</span>
          </Link>
          , and{' '}
          <Link color="secondary.green" href={disclaimerLink}>
            <span style={{ fontFamily: 'Roboto Mono' }}>Disclaimer</span>
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={onBack}
        >
          Back
        </Button>

        <Button
          primary
          onClick={onConfirm}
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!termsAccepted}
        >
          Confirm
        </Button>
      </Flex>
    </MainContainer>
  );
};
