import {
  Box,
  Button,
  Checkbox,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  MediumText,
  Link,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import stakeIcon from '@bumper-dao/ui-kit/dist/images/stake.png';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ConfirmStakingProps } from './types';

import { learnMoreLink } from '../../core/config/links';
import { Routes, subRoutes } from '../../core/config/routes';
import { BUMP } from '../../core/config/tokenNames';
import { tokensIcons } from '../../core/config/tokensIcons';
import { getFlowAssetIcon } from '../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../core/hooks/useChain';
import { StakingService } from '../../core/services/stakingService';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';

export const ConfirmStaking: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const state = useLocation().state as ConfirmStakingProps;

  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const { nameForDisplay: chainName } = useChain();
  const stakingService = StakingService.getInstance();
  const onBackButtonPress = () => {
    navigate(`${Routes.StakeFlow}/${chainName}/${subRoutes.Select}`, {
      state: {
        ...state,
        shouldDisable: false,
      },
      replace: true,
    });
  };
  const onConfirmStaking = async () => {
    try {
      navigate(`${Routes.StakeFlow}/${chainName}/${subRoutes.Approve}`, {
        replace: true,
      });

      const tx = await stakingService.stake(
        state.stakeAmount.toString(),
        state.activeOptionIndex.toString(),
        state.autorenew,
      );

      navigate(`${Routes.StakeFlow}/${chainName}/${subRoutes.Processing}`, {
        replace: true,
      });
      await tx.wait();
      navigate(`${Routes.StakeFlow}/${chainName}/${subRoutes.Summary}`, {
        replace: true,
        state: {
          ...state,
          txHash: tx.hash,
        },
      });
    } catch (err) {
      console.error(err);
      // Sentry.captureException(err);
      navigate(`${Routes.Stake}/${chainName}`, { replace: true });
      // addToast(
      //   'An unexpected error occurred while trying to stake BUMP. Please try again.',
      //   {
      //     appearance: 'warning',
      //   },
      // );
    }
  };
  return (
    <Box width={isMobile ? '90%' : '576px'}>
      <Flex alignItems="center" justifyContent="center" mb="50px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <Box
            style={{
              position: 'absolute',
              top: '-50px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {getFlowAssetIcon(stakeIcon, tokensIcons[BUMP.symbol])}
          </Box>
        }
        amount={formatStringifyNumberToDot(state.stakeAmount.toString())}
        subTitle={'STAKED BUMP'}
        px={isMobile ? '8px' : ''}
      >
        <Flex alignItems="center" justifyContent="space-between" mb="20px">
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            Staking Term
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {state.option?.periodInDays && state.activeOptionIndex !== 0
              ? `${state.option.periodInDays} Days`
              : 'Flexible'}
          </LargeText>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb={
            state.option?.periodInDays && state.activeOptionIndex !== 0
              ? '20px'
              : ''
          }
        >
          <LargeText
            fontWeight={500}
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            BUMP Stake
          </LargeText>
          <LargeText
            color="secondary.white"
            fontSize={isMobile ? '14px' : '16px'}
          >
            {formatStringifyNumberToDot(state.stakeAmount.toString())}
            <MediumText ml="5px" display="inline-block" color="secondary.white">
              BUMP
            </MediumText>
          </LargeText>
        </Flex>
        {state.option?.periodInDays && state.activeOptionIndex !== 0 && (
          <Flex alignItems="center" justifyContent="space-between">
            <LargeText
              fontWeight={500}
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              Auto-renew
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {state.autorenew ? 'On' : 'Off'}
            </LargeText>
          </Flex>
        )}
      </ConfirmCard>
      <Flex alignItems={isMobile ? '' : 'center'} mt="40px">
        <Checkbox onClick={() => setIsActive(!isActive)} />
        <LargeText
          mt={isMobile ? '-10px' : ''}
          ml="20px"
          color="secondary.white"
        >
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={learnMoreLink}>
            Terms and conditions
          </Link>
          ,{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={learnMoreLink}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex justifyContent="space-between" mt="40px">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={onBackButtonPress}
        >
          Cancel
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!isActive}
          onClick={onConfirmStaking}
        >
          Confirm
        </Button>
      </Flex>
    </Box>
  );
};
