import {
  Bump,
  Button,
  Flex,
  Heading,
  IconSize,
  lightColors,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';

import { Routes, subRoutes } from '../../../core/config/routes';
import { useChain } from '../../../core/hooks/useChain';
import { timeInMilliseconds } from '../../../core/utils/helpers';
import {
  ConfirmCooldownState,
  ConfirmUnstakeState,
} from '../../../pages/Staking/types';
import { CountDownComponent } from '../../common/CountDown';
import {
  ButtonsDivider,
  ButtonsWrapper,
  CoinName,
  PeriodLabel,
  PositionHeaderActionWrapper,
  PositionHeaderInfoWrapper,
  PositionHeaderWrapper,
} from '../styles';
import { HeaderFlexibleProps } from '../types';

export const FlexibleCardHeader: React.FC<HeaderFlexibleProps> = ({
  amount,
  stakeIndex,
  rewards,
  endRequestTimestamp,
  endWithdrawWindow,
}) => {
  const navigate = useNavigate();
  const { rewards: stakingRewards } = rewards;
  const { nameForDisplay: chainName } = useChain();

  const endWithdrawWindowTimestamp =
    endRequestTimestamp + timeInMilliseconds(endWithdrawWindow);
  const isWithdrawWindowDontCompleted = Date.now() < endWithdrawWindowTimestamp;
  const isWithdrawWindowPast = Date.now() > endWithdrawWindowTimestamp;
  const isRequestTimestampPast = Date.now() > endRequestTimestamp;

  const startUnstakeFlow = () => {
    const state: ConfirmUnstakeState = {
      stakeIndex,
      amount,
      rewards: stakingRewards,
    };
    navigate(`${Routes.Unstake}/${chainName}/${subRoutes.Confirm}`, {
      state,
    });
  };

  const cooldownClick = () => {
    const state: ConfirmCooldownState = {
      stakeIndex,
    };
    navigate(`${Routes.Cooldown}/${chainName}/${subRoutes.Confirm}`, {
      state,
    });
  };

  const { isMobile } = useMatchBreakpoints();
  return (
    <PositionHeaderWrapper>
      <PositionHeaderInfoWrapper>
        <CoinName>
          <Bump variant={IconSize.XL} />
          <Heading
            scale="xl"
            lineHeight="150%"
            letterSpacing="0.03em"
            color={lightColors.secondary.white}
          >
            BUMP
          </Heading>
        </CoinName>
        <PeriodLabel>Flexible</PeriodLabel>
      </PositionHeaderInfoWrapper>
      {isMobile && <ButtonsDivider />}
      <PositionHeaderActionWrapper>
        {isRequestTimestampPast && isWithdrawWindowDontCompleted && (
          <Flex alignItems="center">
            <Tooltip tooltipValue={'Time in which you can unstake your BUMP'} />
            <Countdown
              date={endWithdrawWindowTimestamp}
              renderer={(properties) => <CountDownComponent {...properties} />}
            />
          </Flex>
        )}
        <ButtonsWrapper>
          <Button
            secondary
            size={IconSize.L}
            padding="0.25rem 1rem"
            letterSpacing="0.03em"
            lineHeight="20px"
            minHeight="32px"
            disabled={isWithdrawWindowDontCompleted}
            onClick={cooldownClick}
          >
            Cooldown
          </Button>
          <Countdown
            date={endRequestTimestamp}
            renderer={(properties) => {
              if (!properties.completed)
                return (
                  <Button
                    secondary
                    size={IconSize.L}
                    padding="0.25rem 1rem"
                    letterSpacing="0.03em"
                    lineHeight="20px"
                    minHeight="32px"
                    disabled
                  >
                    <CountDownComponent {...properties} />
                  </Button>
                );
            }}
          />
          {(isWithdrawWindowPast ||
            (isRequestTimestampPast && isWithdrawWindowDontCompleted)) && (
            <Button
              secondary
              size={IconSize.L}
              padding="0.25rem 1rem"
              letterSpacing="0.03em"
              lineHeight="20px"
              minHeight="32px"
              disabled={isWithdrawWindowPast}
              onClick={startUnstakeFlow}
            >
              Unstake
            </Button>
          )}
        </ButtonsWrapper>
      </PositionHeaderActionWrapper>
    </PositionHeaderWrapper>
  );
};
