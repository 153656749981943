import {
  FlexWithGap,
  FlexWithGapProps,
  Grid,
  GridProps,
  Box,
} from '@bumper-dao/ui-kit';
import React from 'react';
import styled from 'styled-components';

import {
  createMarketSwitcherItemBorder,
  createMarketWrapperBorder,
} from './funcs';
import { MarketSwitcherItemProps } from './types';

export const MarketSwitcherItem = styled(Box)<MarketSwitcherItemProps>`
  max-width: 110px;
  width: 100%;
  text-align: center;
  box-shadow: ${createMarketSwitcherItemBorder};
  background-color: ${({ theme, active }) =>
    active
      ? theme.colors.primary2.darkSlateGray
      : theme.colors.primary2.midnightGreen};
  padding: ${({ active }) => (active ? '10px 20px' : '8px 20px')};
  cursor: pointer;
  color: ${({ theme, active }) =>
    active
      ? theme.colors.secondary.white
      : theme.colors.primary2.cambridgeBlue};
  z-index: ${({ active }) => (active ? 3 : 1)};
  font-family: 'Roboto Mono', sans-serif;
`;

export const MarketWrapperMobile = styled(FlexWithGap)<
  Partial<FlexWithGapProps>
>`
  box-shadow: ${createMarketWrapperBorder};
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
  padding: 24px 16px;
  margin: 48px 18px;
  color: ${({ theme }) => theme.colors.secondary.white} !important;
  font-family: 'Share Tech Mono', sans-serif;
  flex-direction: column;
  -webkit-text-fill-color: ${({ theme }) =>
    theme.colors.secondary.white} !important;
  z-index: 2 !important;
`;

export const MarketWrapper = styled(Grid)<GridProps>`
  box-shadow: ${createMarketWrapperBorder};
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
  padding: 24px 32px;
  margin: 72px 98px;
  color: ${({ theme }) => theme.colors.secondary.white};
  font-family: 'Share Tech Mono', sans-serif;
  -webkit-text-fill-color: ${({ theme }) =>
    theme.colors.secondary.white} !important;
  opacity: 1 !important;
  z-index: 2 !important;
`;

export const MarketRowMobileWrapper = styled(FlexWithGap)<
  FlexWithGapProps & { withBorderBottom?: boolean }
>`
  padding: 24px 16px;
  flex-direction: column;
  border-bottom: ${({ theme, withBorderBottom }) =>
    withBorderBottom && `4px solid ${theme.colors.primary2.myrtleGreen}`};
`;

export const MarketRowWrapper = styled((props) => (
  <Grid
    gridTemplateRows="1fr"
    gridTemplateColumns="1fr 2fr 2fr 2fr 2fr 2fr 1fr"
    {...props}
  />
))<GridProps & { borderBottom?: boolean }>`
  border-bottom: ${({ theme, borderBottom }) =>
    borderBottom && `4px solid ${theme.colors.primary2.midnightGreen}`};
  padding: ${({ borderBottom }) => borderBottom && '12px 0'};
`;
