import React from 'react';

interface IWalletContext {
  isWalletConnected: boolean;
  walletBalance: string;
}

export const WalletContext = React.createContext<IWalletContext>({
  isWalletConnected: false,
  walletBalance: '',
});
