import { MediumText } from '@bumper-dao/ui-kit';
import React from 'react';
import { CountdownRenderProps } from 'react-countdown';
import styled from 'styled-components';

export const CountDownComponent = React.memo<CountdownRenderProps>((props) => {
  return (
    <>
      {props.completed ? (
        <Timer>
          00
          <span>:</span>
          00
          <span>:</span>
          00
          <span>:</span>
          00
        </Timer>
      ) : (
        <Timer>
          {props.formatted.days}
          <span>:</span>
          {props.formatted.hours}
          <span>:</span>
          {props.formatted.minutes}
          <span>:</span>
          {props.formatted.seconds}
        </Timer>
      )}
    </>
  );
});

const Timer = styled(MediumText)`
  color: ${({ theme }) => theme.colors.primary1.vividTangelo};
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 16px;
    line-height: 19.2px;
  },
`;
