import { getAddress } from 'ethers/lib/utils';

import { Chains } from './index';
import { SUPPORTED_CHAINS } from './index';

import tokens, { WETH_NAME } from './tokens';
import fees from './uniswapV3Fees';

// for multi-chain solution we probably need to retrieve current network in some another way

export const getUniswapV3StaticPoolInfos = (
  tokenA: string,
  tokenB: string,
  chainID: Chains,
) => {
  if (!SUPPORTED_CHAINS.includes(chainID)) return [];
  return _getUniswapV3StaticPoolInfos(tokenA, tokenB, chainID);
};

const _getUniswapV3StaticPoolInfos = (
  tokenA: string,
  tokenB: string,
  chainId: Chains,
) => {
  const res = fees[chainId].filter((obj) => {
    return (
      (getAddress(obj.token0) === getAddress(tokenA) &&
        getAddress(obj.token1) === getAddress(tokenB)) ||
      (getAddress(obj.token0) === getAddress(tokenB) &&
        getAddress(obj.token1) === getAddress(tokenA))
    );
  });

  return res.map((obj) => {
    return {
      tokenA,
      tokenB,
      fee: obj.fee,
      pool: obj.pool,
    };
  });
};

export const getUniswapV3WethPoolInfos = (tokenA: string, chainID: number) => {
  if (!SUPPORTED_CHAINS.includes(chainID)) return [];
  const chainId = chainID as Chains;
  return getUniswapV3StaticPoolInfos(
    tokenA,
    tokens[chainId].find((v) => v.name === WETH_NAME)?.address ?? '',
    chainId,
  );
};
