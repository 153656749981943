import { NetworkConfigType } from '../interfaces';

export const getEtherscanLink = (txHash: string, config: NetworkConfigType) => {
  return `${config.blockExplorerUrls[0]}tx/${txHash}`;
};

export const getEtherscanBlockLink = (
  block: number,
  config: NetworkConfigType,
) => {
  return `${config.blockExplorerUrls[0]}block/${block}`;
};
