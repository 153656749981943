import { Box, Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

export const PositionHeaderWrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }
`;

export const PositionHeaderInfoWrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const AutorenewWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 23px;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
`;

export const CoinWrapper = styled(Flex)`
  align-items: center;
  flex-direction: row;

  margin-right: 0.5rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 0;
  }
`;

export const CoinName = styled(Flex)`
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const InfoPositionBalanceWrapper = styled(Flex)`
  align-items: center;
  flex-direction: row;
  gap: 12px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    gap: 8px;
  }
`;

export const InfoPositionLine = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 0;
  border-bottom: 4px solid ${({ theme }) => theme.colors.secondary.greennyGrey};
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 44px;
    align-items: flex-start;
    margin-left: -8px;
    margin-right: 0;
  }
`;

export const InfoContentPositionLine = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 0;
  border-bottom: 4px solid ${({ theme }) => theme.colors.secondary.greennyGrey};
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 40px;
    margin-right: 0;
  }
`;

export const InfoPositionBalance = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const InfoPositionValue = styled(Flex)`
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  margin-left: 0.75rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: -8px;
  }
`;
export const FloorStatusWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const PositionHeaderActionWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const PositionContentWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: calc(100% - 2rem);
  padding-bottom: 1.5rem;
`;

export const ContentProtectedWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  width: 100%;
  background-color: rgba(18, 191, 212, 0.1);
  box-shadow: 60px 0 rgba(18, 191, 212, 0.1), -60px 0 rgba(18, 191, 212, 0.1);

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    box-shadow: 62px 0 rgba(18, 191, 212, 0.1), -62px 0 rgba(18, 191, 212, 0.1);
  }
`;

export const ContentProtectedBalanceWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 0;
    margin-bottom: 25px;
  }
`;

export const ContentProtectedBalance = styled(Flex)`
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 0;
  }

  margin-left: 0.75rem;
`;

export const ProtectedBalanceWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 15px;
  }
`;

export const ProtectedValueWrapper = styled(Flex)`
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
`;

export const ProtectedCoinKey = styled('span')`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
`;

export const PositionInfoWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 144px);
  grid-gap: 1.5rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: 160px;
  }
`;

export const PositionInfoBlockWrapper = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
`;

export const ContentPolicyWrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  padding-top: 1.5rem;
  padding-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
  }
`;

export const PolicyDeatilsHeaderWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 5.5rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-right: 0;
    margin-bottom: 25px;
    margin-top: 25px;
  }
`;

export const PolicyDeatilsHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsDivider = styled(Flex)`
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
`;

export const ConfirmTitle = styled.h1`
  font-family: 'Share Tech Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.secondary.white};
  text-align: center;
`;

export const SummaryBottom = styled(Flex)`
  justify-content: space-between;
  padding-top: 24px;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary.white};
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 18px;
  }
`;
