import {
  MediumText,
  TextProps,
  Flex,
  FlexProps,
  Grid,
  GridProps,
} from '@bumper-dao/ui-kit';
import styled from 'styled-components';

import {
  createPixelInsetBorder,
  createSimpleShadowBorder,
} from '../../core/utils/createPixelInsetBorder';

export const DepositLiqudityBalanceCard = styled(Flex)<FlexProps>`
  margin-top: 36px;
  flex-direction: column;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.primary2.midnightGreen};
  box-shadow: ${({ theme }) =>
    createSimpleShadowBorder(theme.colors.primary2.midnightGreen, 4)};
`;

export const DepositLiquidityWalletText = styled(MediumText)<TextProps>`
  margin-right: 24px;
  color: ${({ theme }) => theme.colors.typography.earnDepositTextDescription};
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-bottom: 24px;
    margin-right: 0;
  }
`;

export const DepositLiqudityBottomBalanceCard = styled(Flex)<FlexProps>`
  max-width: 164px;
  max-height: 28px;
  margin-left: 4px;
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};
  box-shadow: ${({ theme }) =>
    createSimpleShadowBorder(
      theme.colors.primary2.midnightGreen,
      4,
      theme.colors.primary2.myrtleGreen,
    )};
  padding: 4px 8px;

  ${({ theme }) => theme.mediaQueries.xs} {
    max-width: 144px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 144px;
  }
`;

export const DepositLiqudityBottomBalanceGrid = styled(Grid)<GridProps>`
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 24px 60px;
  grid-gap: 1vw;

  ${({ theme }) => theme.mediaQueries.xs} {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5vw;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: 1fr 1fr;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    grid-gap: 4vw;
  }
`;

export const ProtectBottomBalanceGrid = styled(
  DepositLiqudityBottomBalanceGrid,
)<GridProps>`
  width: 100%;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  ${({ theme }) => theme.mediaQueries.sm} {
    grid-gap: 4vw;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const DepositLiqudityWalletContainer = styled(Flex)<FlexProps>`
  align-items: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const ConfirmDepositInnerContainer = styled(Flex)<FlexProps>`
  background-color: ${({ theme }) => theme.colors.background.confirmationBox};
  padding: 12px;
  box-shadow: ${({ theme }) =>
    createPixelInsetBorder(
      'transparent',
      theme.colors.background.confirmationBox,
      theme.colors.background.confirmationBox,
      4,
    )};
  flex-direction: column;
`;
