import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import moonBg from '../../assets/backgrounds/bg.png';
import earnBG from '../../assets/backgrounds/earn-hub.png';
import protectBg from '../../assets/backgrounds/protect-hub.png';
import jupiterBg from '../../assets/backgrounds/saturn-bg.png';
import stakeBg from '../../assets/backgrounds/stake-hub.png';
import { Routes, subRoutes } from '../config/routes';
import { useChainName } from '../hooks/useChain';

export const BackgroundImageProvider: React.FC<{ children: ReactNode }> = (
  props,
) => {
  return <BackgroundSelector>{props.children}</BackgroundSelector>;
};

const BackgroundSelector: React.FC<{ children: ReactNode }> = (props) => {
  const location = useLocation();

  const chainName = useChainName();
  const changeBackground = () => {
    if (
      !location.pathname.includes(Routes.Protect) &&
      location.pathname.includes(Routes.Protect)
    ) {
      return <MoonBackground {...props}></MoonBackground>;
    }
    if (
      location.pathname.includes(
        `${Routes.Vesting}/${chainName}/${subRoutes.Approve}`,
      ) ||
      location.pathname.includes(
        `${Routes.Vesting}/${chainName}/${subRoutes.Processing}`,
      ) ||
      location.pathname.includes(
        `${Routes.ClaimStake}/${chainName}/${subRoutes.Approve}`,
      ) ||
      location.pathname.includes(
        `${Routes.ClaimStake}/${chainName}/${subRoutes.Processing}`,
      )
    ) {
      return <ProtectHub {...props}></ProtectHub>;
    }
    if (
      location.pathname.includes(Routes.ClaimDetails) ||
      location.pathname.includes(Routes.Vesting) ||
      location.pathname.includes(Routes.ClaimRewardsStaking) ||
      location.pathname.includes(Routes.Airdrop) ||
      location.pathname.includes(Routes.ClaimStake) ||
      location.pathname.includes(Routes.History)
    ) {
      return <JupiterFullBackground {...props}></JupiterFullBackground>;
    }
    if (
      location.pathname.includes(Routes.Stake) ||
      location.pathname.includes(Routes.Restake) ||
      location.pathname.includes(Routes.Unstake) ||
      location.pathname.includes(Routes.Cooldown)
    ) {
      return <StakeHub {...props}></StakeHub>;
    }
    if (location.pathname.includes(Routes.Earn)) {
      return <EarnHub {...props}></EarnHub>;
    }
    if (location.pathname.includes(Routes.Protect)) {
      return <ProtectHub {...props}></ProtectHub>;
    } else {
      return <JupiterFullBackground {...props}></JupiterFullBackground>;
    }
  };

  return changeBackground();
};

export const JupiterBackground = styled('div')`
  background-image: url(${jupiterBg});
  background-size: 200%;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100vw;

  ${({ theme }) => theme.mediaQueries.sm} {
    background-size: auto;
  }
`;

export const JupiterFullBackground = styled('div')`
  background-image: url(${jupiterBg});
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100vw;
`;

export const MoonBackground = styled('div')`
  background-image: url(${moonBg});
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100vw;
`;

export const EarnHub = styled('div')`
  background-image: url(${earnBG});
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100vw;
`;

export const ProtectHub = styled('div')`
  background-image: url(${protectBg});
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100vw;
`;

export const StakeHub = styled('div')`
  background-image: url(${stakeBg});
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100vw;
`;
