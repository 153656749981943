import { createSlice } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

import {
  StakingMerkleInfo,
  VestingMerkleInfo,
  VestingInfo,
} from '../../interfaces';
import { getAirdropData } from '../actions/airdropActions';
import {
  getStakingMerkleInfo,
  getVestingMerkleInfo,
} from '../actions/merkleTreeActions';
import {
  getStakingDetails,
  getVestingClaimDetails,
} from '../actions/merkleTreeActions';

export type VestingMerkleType = {
  claimed: BigNumber;
  availableToClaim: BigNumber;
  unvested: BigNumber;
  info: VestingInfo;
  account: string;
  proof: string[];
};

export type AirdropMerkleNodeType = Array<{
  index: number;
  amountUSDC: string;
  amountBUMP: string;
  account: string;
  proof: string[];
}>;

export type AirdropMerkleType = {
  available: AirdropMerkleNodeType | null;
};

export type StakingType = {
  amount: BigNumber;
  amountWithoutStaking: BigNumber;
  amountWithRewards: BigNumber;
  rewards: BigNumber;
};

export interface InitialStateTypeMerkle {
  vesting: VestingMerkleType | null;
  airdrop: AirdropMerkleType | null;
  staking: StakingType | null;
  merkle: {
    staking: StakingMerkleInfo | null;
    vesting: VestingMerkleInfo | null;
  };
}

const initialState: InitialStateTypeMerkle = {
  vesting: null,
  airdrop: null,
  staking: null,
  merkle: {
    staking: null,
    vesting: null,
  },
};

export const merkleTreeSlice = createSlice({
  name: 'merkleTree',
  initialState,
  reducers: {
    resetMerkle: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getVestingClaimDetails.fulfilled, (state, action) => {
      state.vesting = action.payload;
    });
    builder.addCase(getAirdropData.fulfilled, (state, action) => {
      state.airdrop = action.payload;
    });
    builder.addCase(getStakingDetails.fulfilled, (state, action) => {
      state.staking = action.payload;
    });
    builder.addCase(getStakingMerkleInfo.fulfilled, (state, action) => {
      state.merkle.staking = action.payload;
    });
    builder.addCase(getVestingMerkleInfo.fulfilled, (state, action) => {
      state.merkle.vesting = action.payload;
    });
  },
});

export default merkleTreeSlice.reducer;
export const { resetMerkle } = merkleTreeSlice.actions;
