import { FixedNumber } from 'ethers';

export const FORMULAE_CONSTANTS = {
  estimatedBumpPriceA: FixedNumber.from('0.000000008'),
  estimatedBumpPriceB: FixedNumber.from('0.6'),
};

export const DECIMAL_MULTIPLIER = 10 ** 18;

export const DEFAULT_DECIMAL_VALUE = 18;

export const NULLABLE_ADDRESS = '0x0000000000000000000000000000000000000000';
