import {
  Bump,
  Button,
  Flex,
  Heading,
  IconSize,
  lightColors,
  MediumText,
  Toggle,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Routes, subRoutes } from '../../../core/config/routes';
import { DEFAULT_TOOLTIP } from '../../../core/config/tooltips';
import { useChain } from '../../../core/hooks/useChain';
import {
  ConfirmAutoRenewState,
  ConfirmRestakeState,
  ConfirmUnstakeState,
} from '../../../pages/Staking/types';
import {
  AutorenewWrapper,
  ButtonsDivider,
  ButtonsWrapper,
  CoinName,
  PeriodLabel,
  PositionHeaderActionWrapper,
  PositionHeaderInfoWrapper,
  PositionHeaderWrapper,
} from '../styles';
import { HeaderFixedProps } from '../types';

export const FixedCardHeader: React.FC<HeaderFixedProps> = ({
  autorenew,
  endDate,
  amount,
  stakeIndex,
  option,
  interval,
  rewards,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const { rewards: stakingRewards } = rewards;
  const { nameForDisplay: chainName } = useChain();

  const startUnstakeFlow = () => {
    const state: ConfirmUnstakeState = {
      stakeIndex,
      amount,
      rewards: stakingRewards,
    };
    navigate(`${Routes.Unstake}/${chainName}/${subRoutes.Confirm}`, {
      state,
    });
  };
  const restakeClick = () => {
    const state: ConfirmRestakeState = {
      stakeIndex,
      amount: amount.toString(),
      option,
      rewards: stakingRewards.toString(),
    };
    navigate(`${Routes.Restake}/${chainName}/${subRoutes.Confirm}`, {
      state,
    });
  };
  const autorenewClick = () => {
    const state: ConfirmAutoRenewState = {
      autorenew,
      stakeIndex,
    };
    navigate(
      `${Routes.Stake}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Confirm}`,
      {
        state,
      },
    );
  };

  return (
    <PositionHeaderWrapper>
      <PositionHeaderInfoWrapper>
        <CoinName>
          <Bump variant={IconSize.XL} />
          <Heading
            scale="xl"
            lineHeight="150%"
            letterSpacing="0.03em"
            color={lightColors.secondary.white}
          >
            BUMP
          </Heading>
        </CoinName>
        <PeriodLabel>{interval} days</PeriodLabel>
      </PositionHeaderInfoWrapper>
      <PositionHeaderActionWrapper>
        <AutorenewWrapper>
          <Flex>
            <MediumText color={lightColors.typography.placeholder} mr={'6.5px'}>
              Auto-renew
            </MediumText>
            <Tooltip tooltipValue={DEFAULT_TOOLTIP} />
          </Flex>
          <Toggle
            checked={autorenew}
            disabled={endDate <= Date.now()}
            onToggle={autorenewClick}
          />
        </AutorenewWrapper>
        {isMobile && <ButtonsDivider />}
        <ButtonsWrapper>
          {!(autorenew && Date.now() > endDate) && (
            <Button
              secondary
              size={IconSize.L}
              padding="0.25rem 1rem"
              letterSpacing="0.03em"
              lineHeight="20px"
              minHeight="32px"
              disabled={autorenew || endDate > Date.now()}
              onClick={restakeClick}
            >
              Restake
            </Button>
          )}
          <Button
            secondary
            size={IconSize.L}
            padding="0.25rem 1rem"
            letterSpacing="0.03em"
            lineHeight="20px"
            minHeight="32px"
            disabled={endDate > Date.now()}
            onClick={startUnstakeFlow}
          >
            Unstake
          </Button>
        </ButtonsWrapper>
      </PositionHeaderActionWrapper>
    </PositionHeaderWrapper>
  );
};
