export type Feature = 'SUPPORT' | 'STAKING';

export const isFeatureEnabled = (feature: Feature) => {
  switch (feature) {
    case 'SUPPORT':
      return (
        process.env.REACT_APP_ENV === 'alpha' ||
        process.env.REACT_APP_ENV === 'beta'
      );
    case 'STAKING':
      return (
        process.env.REACT_APP_ENV !== 'alpha' &&
        process.env.REACT_APP_ENV !== 'beta'
      );
  }
};
