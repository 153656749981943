import { Button, IconSize, LargeText } from '@bumper-dao/ui-kit';
import React, { FC } from 'react';

import { Heading, NoPositionsWrap, ButtonText } from './styles';
import { NoPositionProps } from './types';

const NoPositions: FC<NoPositionProps> = ({
  heading,
  text,
  route,
  btnName,
}) => {
  return (
    <NoPositionsWrap>
      <Heading>{heading}</Heading>
      <LargeText
        fontSize="24px"
        lineHeight="36px"
        fontFamily="Share Tech Mono"
        letterSpacing="0.03em"
      >
        {text}
      </LargeText>
      {btnName && (
        <Button
          primary
          size={IconSize.M}
          fontWeight={400}
          fontSize="16px !important"
          padding="0.25rem 1rem"
          letterSpacing="0.03em"
          lineHeight="20px"
          minHeight="32px"
          onClick={route}
        >
          <ButtonText>{btnName}</ButtonText>
        </Button>
      )}
    </NoPositionsWrap>
  );
};

export default NoPositions;
