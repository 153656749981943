import { Flex, FlowHeader, StepStatus } from '@bumper-dao/ui-kit';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ConfirmWithdrawPageContainer } from './Containers/ConfirmWithdrawPageContainer';
import { PolicyWithdrawPageContainer } from './Containers/PolicyWithdrawPageContainer';

import { Routes, subRoutes } from '../../core/config/routes';
import { useAppDispatch } from '../../core/state/hooks';
import { toggleToolbar } from '../../core/state/reducers/uiStateManagementReducer';
import { TransactionConfirm, TransactionInProgress } from '../Transaction';

export const EarnWithdrawFlowPage = () => {
  const history = useNavigate();
  const { step } = useParams<{ step?: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());

    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history(Routes.Dashboard);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Confirm: {
        return <ConfirmWithdrawPageContainer />;
      }
      case subRoutes.Approve: {
        return <TransactionConfirm steps={1} />;
      }
      case subRoutes.Processing: {
        return <TransactionInProgress />;
      }
      case subRoutes.Summary: {
        return <PolicyWithdrawPageContainer />;
      }
      default: {
        return <ConfirmWithdrawPageContainer />;
      }
    }
  };

  const getStatus = (): StepStatus[] => {
    switch (step) {
      case subRoutes.Confirm: {
        return ['current', 'next'];
      }
      case subRoutes.Approve: {
        return ['past', 'current'];
      }
      case subRoutes.Processing: {
        return ['past', 'current'];
      }
      case subRoutes.Summary: {
        return ['past', 'current'];
      }
      default: {
        return ['current', 'next'];
      }
    }
  };

  return (
    <>
      <FlowHeader
        cancelClick={onCancel}
        logoClick={() => history(Routes.Dashboard)}
        steps={step === subRoutes.Processing ? [] : ['Confirm', 'Summary']}
        stepsStatus={getStatus()}
      />
      <Flex py="40px" justifyContent="center" flex={1} alignItems="center">
        {showSection()}
      </Flex>
    </>
  );
};
