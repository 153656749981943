import { Flex, FlowHeader, StepStatus } from '@bumper-dao/ui-kit';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ConfirmCooldown } from './ConfirmCooldown';
import { SummaryCooldown } from './SummaryCooldown';

import { Routes, subRoutes } from '../../../core/config/routes';
import { useAppDispatch } from '../../../core/state/hooks';
import { toggleToolbar } from '../../../core/state/reducers/uiStateManagementReducer';
import { TransactionConfirm, TransactionInProgress } from '../../Transaction';

export const stakeDepositFlowSteps = ['Confirm', 'Summary'];

export const CooldownFlowPage: React.FC = () => {
  const history = useNavigate();
  const { step } = useParams<{ step?: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());

    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history(Routes.Dashboard);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Confirm: {
        return <ConfirmCooldown />;
      }
      case subRoutes.Approve: {
        return <TransactionConfirm steps={1} />;
      }
      case subRoutes.Processing: {
        return <TransactionInProgress />;
      }
      case subRoutes.Summary: {
        return <SummaryCooldown />;
      }
      default: {
        return <ConfirmCooldown />;
      }
    }
  };

  const getStatus = (): StepStatus[] => {
    switch (step) {
      case subRoutes.Confirm: {
        return ['current', 'next'];
      }
      case subRoutes.Approve: {
        return ['current', 'next'];
      }
      case subRoutes.Processing: {
        return ['current', 'next'];
      }
      case subRoutes.Summary: {
        return ['past', 'current'];
      }
      default: {
        return ['current', 'next'];
      }
    }
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <FlowHeader
        cancelClick={onCancel}
        logoClick={() => history(Routes.Dashboard)}
        steps={step === subRoutes.Processing ? [] : stakeDepositFlowSteps}
        stepsStatus={getStatus()}
      />
      <Flex py="40px" justifyContent="center" alignItems="center" flex={1}>
        {showSection()}
      </Flex>
    </Flex>
  );
};
