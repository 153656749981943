import {
  FlexWithGap,
  LargeText,
  lightColors,
  MediumText,
  OptionType,
  Select,
  Tooltip,
  useMatchBreakpoints,
  VerticalOrientation,
} from '@bumper-dao/ui-kit';
import ETHIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/eth.png';
import USDCIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/usdc.png';
import { BigNumber, ethers } from 'ethers';
import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  MarketCell,
  MarketRowMobile,
  MarketRow,
  MarketDesktopSort,
} from './commons';
import { premiumPeriods } from './constants';
import { useEthQuery } from './hooks';
import {
  MarketRowWrapper,
  MarketSwitcherItem,
  MarketWrapper,
  MarketWrapperMobile,
} from './styles';
import {
  MarketChartPosition,
  MarketDetail,
  MarketProps,
  MarketSwitcherMode,
  PremiumHistoryPeriods,
  SortState,
} from './types';

import { FullScreenWidthContainer } from '../../components/common/MainContainers';
import { SwitchNetworkBanner } from '../../components/common/SwitchNetworkBanner/SwitchNetworkBanner';
import { Routes } from '../../core/config/routes';
import { Slides } from '../../core/config/slides';
import { ETH, USDCoin } from '../../core/config/tokenNames';
import { getFlowAssetIcon } from '../../core/funcs/getFlowAssetIcon';
import { useChainName } from '../../core/hooks/useChain';
import { ProtocolConfigService } from '../../core/services/protocolConfigService';
import { useAppSelector } from '../../core/state/hooks';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../core/utils/helpers';

export const MarketPage: FC = () => {
  const protocolService = ProtocolConfigService.getInstance();

  const { isMobile } = useMatchBreakpoints();
  const [mode, setMode] = useState<MarketSwitcherMode>('protect');
  const network = useChainName();
  const navigate = useNavigate();

  const [ethMarketAddress, setEthMarketAddress] = useState<string>(
    ethers.constants.AddressZero,
  );

  const {
    ethYearValue,
    ethPrice7Days,
    ethHistory7Days,
    ethHistory15Days,
    ethHistory30Days,
    ethHistoryYear,
  } = useEthQuery(ethMarketAddress);

  const TOKEN_DETAILS = useAppSelector(
    (state) => state.contractsAddresses.TOKEN_DETAILS,
  );
  const coinDetails = useAppSelector((state) => state.coin.coinDetails);

  const tokens = [
    {
      symbol: ETH.symbol,
      icon: <img src={ETHIcon} alt={ETH.symbol} width="32px" height="32px" />,
      address: TOKEN_DETAILS[ETH.symbol].address,
      price: coinDetails[ETH.symbol].price,
    },
  ];

  const earnTokens = [
    {
      symbol: `${USDCoin.symbol}-${ETH.symbol}`,
      icon: getFlowAssetIcon(
        USDCIcon,
        ETHIcon,
        '48px',
        '48px',
        '32px',
        '32px',
        '32px',
        '32px',
      ),
      address: TOKEN_DETAILS[USDCoin.symbol].address,
      price: coinDetails[USDCoin.symbol].price,
    },
  ];

  const [details, setDetails] = useState<MarketDetail[]>([]);
  const [earnDetails, setEarnDetails] = useState<MarketDetail[]>([]);
  const premiumHistoryPeriodsOptions: OptionType[] = premiumPeriods.map(
    (p) => ({ key: p, value: p }),
  );
  const [premiumHistoryPeriod, setPremiumHistoryPeriod] =
    useState<PremiumHistoryPeriods>(
      premiumHistoryPeriodsOptions[0].key as PremiumHistoryPeriods,
    );
  const ethHistory = useMemo(() => {
    switch (premiumHistoryPeriod) {
      case '7 days':
        return ethHistory7Days;
      case '15 days':
        return ethHistory15Days;
      case '30 days':
        return ethHistory30Days;
    }
  }, [
    premiumHistoryPeriod,
    ethHistory7Days,
    ethHistory15Days,
    ethHistory30Days,
  ]);

  useEffect(() => {
    protocolService
      .getMarket(TOKEN_DETAILS[ETH.symbol].address)
      .then((addr) => setEthMarketAddress(addr))
      .catch((e) => console.log('Market error:', e));
  }, []);

  useEffect(() => {
    Promise.all(
      tokens.map(async (token) => {
        return {
          token: token.symbol,
          icon: token.icon,
          price: `$${formatStringifyNumberToDot(token.price, 2)}`,
          prices: ethPrice7Days,
          averageProtocolFloor: `$${formatWeiToNormalString(
            BigNumber.from(ethYearValue.openedPositionsFloorSum).div(
              ethYearValue.totalOpenedTakerPositions > 0
                ? ethYearValue.totalOpenedTakerPositions
                : 1,
            ),
            18,
            2,
          )}`,
          premiumBaseRateAPY: `${formatWeiToNormalString(
            BigNumber.from(ethYearValue.ciTakerAsset),
            16,
            2,
          )}%`,
          premiumBaseRateAPR: `${formatWeiToNormalString(
            BigNumber.from(ethYearValue.ciTakerAsset),
            16,
            2,
          )}%`,
          premiumHistoryAPY: `${BigNumber.from(ethHistoryYear.premium)
            .div(ethHistoryYear.amount > 0 ? ethHistoryYear.amount : 1)
            .mul(100)
            .toNumber()
            .toFixed(2)}%`,
          premiumHistoryAPR: `${BigNumber.from(ethHistory.premium)
            .div(ethHistory.amount > 0 ? ethHistory.amount : 1)
            .mul(100)
            .toNumber()
            .toFixed(2)}%`,
        };
      }),
    ).then((details) => {
      setDetails(details);
    });
    Promise.all(
      earnTokens.map(async (token) => {
        return {
          token: token.symbol,
          icon: token.icon,
          price: `$${formatStringifyNumberToDot(token.price, 2)}`,
          prices: Array.from({ length: ethPrice7Days.length }).map(() => ({
            price: token.price,
          })) as unknown as MarketChartPosition[],
          averageProtocolFloor: BigNumber.from(
            ethYearValue.openedPositionsTierSum,
          )
            .div(
              ethYearValue.totalOpenedMakerPositions > 0
                ? ethYearValue.totalOpenedMakerPositions
                : 1,
            )
            .toString(),
          premiumBaseRateAPY: `${formatWeiToNormalString(
            BigNumber.from(ethYearValue.ciMaker),
            16,
            2,
          )}%`,
          premiumBaseRateAPR: `${formatWeiToNormalString(
            BigNumber.from(ethYearValue.ciMaker),
            16,
            2,
          )}%`,
          premiumHistoryAPY: '0.00%', // @dev need to get
          premiumHistoryAPR: '0.00%', // @dev need to get
        };
      }),
    ).then((earnDetails) => {
      setEarnDetails(earnDetails);
    });
  }, [ethYearValue, ethPrice7Days, ethHistory]);

  const onClick = useCallback(() => {
    navigate(`${mode === 'earn' ? Routes.Earn : Routes.Protect}/${network}`);
  }, [mode]);

  return (
    <>
      <FullScreenWidthContainer>
        <SwitchNetworkBanner page="market" slides={Slides} path={['Market']} />
      </FullScreenWidthContainer>
      <FlexWithGap
        gap="32px"
        mx={isMobile ? '40px' : '120px'}
        mt={isMobile ? '30px' : '100px'}
        mb={isMobile ? '-40px' : '-64px'}
      >
        <MarketSwitcherItem
          active={mode === 'protect'}
          onClick={() => setMode('protect')}
        >
          Protect
        </MarketSwitcherItem>
        <MarketSwitcherItem
          active={mode === 'earn'}
          onClick={() => setMode('earn')}
        >
          Earn
        </MarketSwitcherItem>
      </FlexWithGap>
      {isMobile ? (
        <MarketPageMobile
          details={mode === 'earn' ? earnDetails : details}
          isEarn={mode === 'earn'}
          onClick={onClick}
          premiumHistoryPeriodsOptions={premiumHistoryPeriodsOptions}
          premiumHistoryPeriod={premiumHistoryPeriod}
          setPremiumHistoryPeriod={setPremiumHistoryPeriod}
        />
      ) : (
        <MarketPageDesktop
          details={mode === 'earn' ? earnDetails : details}
          isEarn={mode === 'earn'}
          onClick={onClick}
          premiumHistoryPeriodsOptions={premiumHistoryPeriodsOptions}
          premiumHistoryPeriod={premiumHistoryPeriod}
          setPremiumHistoryPeriod={setPremiumHistoryPeriod}
        />
      )}
    </>
  );
};

export const MarketPageDesktop: FC<MarketProps> = ({
  details,
  isEarn,
  onClick,
  premiumHistoryPeriodsOptions,
  setPremiumHistoryPeriod,
  premiumHistoryPeriod,
}) => {
  const initialSortState: SortState = {
    asset: VerticalOrientation.DOWN,
    currentPrice: VerticalOrientation.DOWN,
    trend: VerticalOrientation.DOWN,
    averageProtocol: VerticalOrientation.DOWN,
    baseRate: VerticalOrientation.DOWN,
  };
  const [sortState, setSortState] = useState<SortState>(initialSortState);

  return (
    <MarketWrapper
      gridTemplateColumns="*"
      gridTemplateRows={`90px 50px repeat(${details.length}, 1fr)`}
    >
      <MarketRowWrapper>
        <MarketDesktopSort
          text="Asset"
          initialSortState={initialSortState}
          sortState={sortState}
          sortStateKey="asset"
          setSortState={setSortState}
        />
        <MarketDesktopSort
          text="Current Price"
          initialSortState={initialSortState}
          sortState={sortState}
          sortStateKey="currentPrice"
          setSortState={setSortState}
        />
        <MarketDesktopSort
          text="7-day Trend"
          initialSortState={initialSortState}
          sortState={sortState}
          sortStateKey="trend"
          setSortState={setSortState}
        />
        <MarketDesktopSort
          text={`Average Protocol ${isEarn ? 'Risk' : 'Floor'}`}
          initialSortState={initialSortState}
          sortState={sortState}
          sortStateKey="averageProtocol"
          setSortState={setSortState}
        />
        <MarketDesktopSort
          text={`${isEarn ? 'Yield' : 'Premium'} Base Rate`}
          initialSortState={initialSortState}
          sortState={sortState}
          sortStateKey="baseRate"
          setSortState={setSortState}
        />
        <MarketCell flexDirection="column" mt="-50px">
          <MediumText fontFamily="Roboto Mono">
            {isEarn ? 'Yield' : 'Premium'} History
          </MediumText>
          <Select
            minWidth="116px"
            selectorPadding="0 12px"
            value={
              premiumHistoryPeriodsOptions.find(
                (v) => v.key === premiumHistoryPeriod,
              ) || premiumHistoryPeriodsOptions[0]
            }
            options={premiumHistoryPeriodsOptions}
            onValueChanged={(v) =>
              setPremiumHistoryPeriod(v.key as PremiumHistoryPeriods)
            }
            mt="10px"
          />
        </MarketCell>
        <MarketCell />
      </MarketRowWrapper>
      <MarketRowWrapper>
        <MarketCell />
        <MarketCell />
        <MarketCell />
        <MarketCell />
        <MarketCell>
          <LargeText
            style={{
              WebkitTextFillColor: `${lightColors.primary2.cambridgeBlue}`,
            }}
            color="primary2.cambridgeBlue"
          >
            APY
          </LargeText>
          <MediumText
            style={{
              WebkitTextFillColor: `${lightColors.primary2.cambridgeBlue}`,
            }}
            color="primary2.cambridgeBlue"
          >
            (APR)
          </MediumText>
          <Tooltip tooltipValue="APR Tooltip" />
        </MarketCell>
        <MarketCell>
          <LargeText
            style={{
              WebkitTextFillColor: `${lightColors.primary2.cambridgeBlue}`,
            }}
            color="primary2.cambridgeBlue"
          >
            APY
          </LargeText>
          <MediumText
            style={{
              WebkitTextFillColor: `${lightColors.primary2.cambridgeBlue}`,
            }}
            color="primary2.cambridgeBlue"
          >
            (APR)
          </MediumText>
          <Tooltip tooltipValue="APR Tooltip" />
        </MarketCell>
        <MarketCell />
      </MarketRowWrapper>
      {details.map((v, i) => (
        <MarketRow
          key={i}
          borderBottom={i !== details.length - 1}
          isEarn={isEarn}
          onClick={onClick}
          {...v}
        />
      ))}
    </MarketWrapper>
  );
};

export const MarketPageMobile: FC<MarketProps> = ({
  details,
  isEarn,
  onClick,
  premiumHistoryPeriodsOptions,
  setPremiumHistoryPeriod,
  premiumHistoryPeriod,
}) => {
  return (
    <MarketWrapperMobile gap="4px">
      {details.map((v, i) => (
        <MarketRowMobile
          key={i}
          borderBottom={i !== details.length - 1}
          isEarn={isEarn}
          onClick={onClick}
          {...v}
          setPremiumHistoryPeriod={setPremiumHistoryPeriod}
          premiumHistoryPeriod={premiumHistoryPeriod}
          premiumHistoryPeriodsOptions={premiumHistoryPeriodsOptions}
        />
      ))}
    </MarketWrapperMobile>
  );
};
