import { lightColors } from '@bumper-dao/ui-kit';
import ETHCoinIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/eth.png';
import USDCCoinIcon from '@bumper-dao/ui-kit/dist/images/48px/tokens/usdc.svg';

import {
  ETH,
  WETH,
  bETH,
  BUMP,
  wBTC,
  USDCoin,
  bUSDCCoin,
  USDT,
} from './tokenNames';

import BUMPCoinIcon from '../../assets/32px/tokens/bump.png';
import BETHIcon from '../../assets/bETHIcon.svg';
import BUSDCoinIcon from '../../assets/busd-coin.svg';
import USDTIcon from '../../assets/USDTIcon.svg';

export const tokensIcons: { [key: string]: string } = {
  [USDCoin.symbol]: USDCCoinIcon,
  [USDT.symbol]: USDTIcon,
  [bUSDCCoin.symbol]: BUSDCoinIcon,
  [BUMP.symbol]: BUMPCoinIcon,
  [ETH.symbol]: ETHCoinIcon,
  [WETH.symbol]: ETHCoinIcon,
  [wBTC.symbol]: ETHCoinIcon,
  [bETH.symbol]: BETHIcon,
};
export const tokensBackground: { [key: string]: string } = {
  [USDCoin.symbol]: lightColors.secondary.darkBlue,
  [USDT.symbol]: lightColors.secondary.green,
  [bUSDCCoin.symbol]: lightColors.primary1.bigFootFeet,
  [BUMP.symbol]: lightColors.primary2.midnightGreen,
  [ETH.symbol]: lightColors.secondary.white,
  [WETH.symbol]: lightColors.secondary.white,
  [wBTC.symbol]: lightColors.secondary.white,
  [bETH.symbol]: lightColors.primary2.midnightGreen,
};
