import { createAsyncThunk } from '@reduxjs/toolkit';

import { ICoin, ICoinDetails } from '../../interfaces';
import { MakerService } from '../../services/makerService';

const makerService = MakerService.getInstance();

type FetchCoinDetailsReturnType = {
  details: ICoinDetails[];
  isFirstFetching: boolean;
};

export const fetchCoinDetails = createAsyncThunk<
  FetchCoinDetailsReturnType,
  ICoin[]
>('getTokenDetails', async (coinList) => {
  const rawResponse = await Promise.allSettled(
    coinList.map((coin) => {
      return makerService.getTokenDetails(coin);
    }),
  );
  const fulfilledResult = rawResponse.filter(
    (res) => res.status === 'fulfilled',
  ) as PromiseFulfilledResult<ICoinDetails>[];
  const result = fulfilledResult.map((res) => res.value);
  return {
    details: result,
    isFirstFetching: false,
  };
});
