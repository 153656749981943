import { Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

export const CoinWrapper = styled(Flex)`
  align-items: center;
  flex-direction: row;
`;

export const LeftBlockWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

export const RightBlockWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    align-items: flex-end;
    gap: 16px;
  }
`;

export const CoinName = styled(Flex)`
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const InfoPositionBalanceWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 1.5rem;
  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: -10px;
  }
`;

export const InfoPositionLine = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 0;
  border-bottom: 4px solid ${({ theme }) => theme.colors.secondary.greennyGrey};

  ${({ theme }) => theme.mediaQueries.sm} {
    transform: rotate(90deg);
    width: 44px;
  }
`;

export const InfoPositionBalance = styled(Flex)`
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 0.75rem;

  div {
    &.word-breaker {
      word-wrap: break-word;
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: -10px;
  }
`;

export const ContentWrap = styled.div`
  width: 100%;
`;

export const DepositWrap = styled.div`
  width: 100%;
  padding: 28px 24px;
  display: flex;
  gap: 14px;
  background-color: rgba(18, 191, 212, 0.1);
  box-shadow: 4px 0 rgba(18, 191, 212, 0.1), -4px 0 rgba(18, 191, 212, 0.1);

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    align-items: center;
  }
`;

export const EarnedWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DetailsWrap = styled.div`
  display: flex;
  padding: 28px 24px;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
  }
`;

export const DepositDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  &.created-date {
    ${({ theme }) => theme.mediaQueries.sm} {
      display: none !important;
    }
  }
`;

export const DepositDetailsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: center;
  }
`;

export const CreatedDateText = styled.span`
  font-family: 'Roboto Mono';
  font-weight: 400;
  color: #99b6b9;
  font-size: 14px;
  line-height: 20px;
  //margin-left: 60px;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: none;
  }
`;

export const BumpIcon = styled.img`
  width: 13%;
`;

export const DetailsInfo = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  list-style: none;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    align-items: center;
  }
`;

export const DetailsElement = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 190px;
  }
`;

export const BumpedCoinWrap = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-bottom: 24px;
  }
`;

export const ToggleWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
    margin-bottom: -5px;
  }
`;

export const LineSeparator = styled.div`
  width: 100%;
  height: 4px;
  display: none;
  background-color: ${({ theme }) => theme.colors.primary2.darkSlateGray};

  ${({ theme }) => theme.mediaQueries.sm} {
    display: inline-block;
  }
`;

export const AssetLabelWrap = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 190px;
  }
`;

export const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
