import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { AppRouteComponent } from './AppRouteComponent';

import { Routes } from '../core/config/routes';
import { Dashboard } from '../pages/Dashboard';
import EarnAsset from '../pages/EarnAsset/EarnAsset';
import EarnAutoRenewFlow from '../pages/EarnAsset/EarnAutoRenewFlow';
import { EarnDepositFlowPage } from '../pages/EarnDepositFlow/EarnDepositFlowPage';
import { EarnHub } from '../pages/EarnHub/EarnHub';
import { EarnWithdrawFlowPage } from '../pages/EarnWithdrawFlow/EarnWithdrawFlowPage';
import { HistoryPage } from '../pages/HistoryPage';
import { MarketPage } from '../pages/Market/MarketPage';
import { ProtectDepositFlowPage } from '../pages/Protect/ProtectDepositFlowPage';
import ProtectAsset from '../pages/ProtectAsset/ProtectAsset';
import ProtectAutoRenewFlow from '../pages/ProtectAsset/ProtectAutoRenewFlow';
import { ProtectClaimCloseFlowPage } from '../pages/ProtectClaimClose/ProtectClaimCloseFlowPage';
import { ProtectHub } from '../pages/ProtectHub/ProtectHub';
import { StakeHub } from '../pages/StakeHub/StakeHub';
import { AutorenewFlowPage } from '../pages/Staking/Autorenew/AutorenewFlowPage';
import { ClaimFlowPage } from '../pages/Staking/Claim/ClaimFlowPage';
import { CooldownFlowPage } from '../pages/Staking/Cooldown/CooldownFlowPage';
import { RestakeFlowPage } from '../pages/Staking/Restake/RestakeFlowPage';
import { StakeDepositFlowPage } from '../pages/Staking/StakeDepositFlowPage';
import { UnstakeFlowPage } from '../pages/Staking/Unstake/UnstakeFlowPage';
import { StakingAssetPage } from '../pages/StakingAssetPage/StakingAssetPage';
import { VestingClaimStakeFlowPage } from '../pages/VestingClaimAndStakeFlowPage/VestingClaimStakeFlowPage';
import { VestingClaimFlowPage } from '../pages/VestingClaimFlowPage/VestingClaimFlowPage';

export const routesConfig: RouteObject[] = [
  {
    path: Routes.App,
    element: <AppRouteComponent />,
    children: [
      {
        path: `${Routes.Market}/:network`,
        caseSensitive: true,
        element: <MarketPage />,
      },
      {
        path: `${Routes.EarnAssetsDashboard}/:network`,
        caseSensitive: true,
        element: <EarnAsset />,
      },
      {
        path: `${Routes.ClaimRewardsStaking}/:network/:step`,
        caseSensitive: true,
        element: <ClaimFlowPage />,
      },
      {
        path: `${Routes.Dashboard}`,
        caseSensitive: false,
        element: <Dashboard />,
      },
      {
        path: `${Routes.Dashboard}/:network`,
        caseSensitive: false,
        element: <Dashboard />,
      },
      {
        path: Routes.History,
        caseSensitive: true,
        element: <HistoryPage />,
      },
      {
        path: `${Routes.Earn}/:network/:step`,
        caseSensitive: true,
        element: <EarnDepositFlowPage />,
      },
      {
        path: `${Routes.Protect}/:network${Routes.ConfirmAutoRenew}/:step`,
        caseSensitive: true,
        element: <ProtectAutoRenewFlow />,
      },
      {
        path: `${Routes.Earn}/:network${Routes.ConfirmAutoRenew}/:step`,
        caseSensitive: true,
        element: <EarnAutoRenewFlow />,
      },
      {
        path: `${Routes.ClaimStake}/:network/:step`,
        caseSensitive: true,
        element: <VestingClaimStakeFlowPage />,
      },
      {
        path: `${Routes.EarnAssetsDashboard}/:network/:step`,
        caseSensitive: true,
        element: <EarnWithdrawFlowPage />,
      },
      {
        path: `${Routes.Protect}/:network/:step`,
        caseSensitive: true,
        element: <ProtectDepositFlowPage />,
      },
      {
        path: `${Routes.Stake}/:network${Routes.ConfirmAutoRenew}/:step`,
        caseSensitive: true,
        element: <AutorenewFlowPage />,
      },
      {
        path: `${Routes.Unstake}/:network/:step`,
        caseSensitive: true,
        element: <UnstakeFlowPage />,
      },
      {
        path: `${Routes.Restake}/:network/:step`,
        caseSensitive: true,
        element: <RestakeFlowPage />,
      },
      {
        path: `${Routes.Cooldown}/:network/:step`,
        caseSensitive: true,
        element: <CooldownFlowPage />,
      },
      {
        path: `${Routes.Earn}/:network`,
        caseSensitive: true,
        element: <EarnHub />,
      },
      {
        path: `${Routes.Vesting}/:network/:step`,
        caseSensitive: true,
        element: <VestingClaimFlowPage />,
      },
      {
        path: `${Routes.StakingAssetsDashboard}/:network`,
        caseSensitive: true,
        element: <StakingAssetPage />,
      },
      {
        path: `${Routes.Protect}/:network`,
        caseSensitive: true,
        element: <ProtectHub />,
      },
      {
        path: `${Routes.Stake}/:network`,
        caseSensitive: true,
        element: <StakeHub />,
      },
      {
        path: `${Routes.ProtectAssetsDashboard}/:network`,
        caseSensitive: true,
        element: <ProtectAsset />,
      },
      {
        path: `${Routes.StakeFlow}/:network/:step`,
        caseSensitive: true,
        element: <StakeDepositFlowPage />,
      },
      {
        path: `${Routes.ClaimClose}/:network/:symbol/:step`,
        caseSensitive: true,
        element: <ProtectClaimCloseFlowPage />,
      },
      {
        path: '*',
        element: <Navigate to={Routes.Dashboard} replace />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={Routes.Dashboard} replace />,
  },
];
