import {
  FlowHeader,
  StepStatus,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ConfirmProtectClaimClose } from './ConfirmProtectClaimClose';
import { PolicyProtectClaimClose } from './PolicyProtectClaimClose';

import { MainContainer } from '../../components/common/MainContainers';
import { Routes, subRoutes } from '../../core/config/routes';
import { useAppDispatch } from '../../core/state/hooks';
import { toggleToolbar } from '../../core/state/reducers/uiStateManagementReducer';
import { TransactionConfirm, TransactionInProgress } from '../Transaction';

export const ProtectClaimCloseFlowPage = () => {
  const history = useNavigate();
  const { step } = useParams<{ step?: string }>();
  const { isMobile } = useMatchBreakpoints();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());

    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history(Routes.Dashboard);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Confirm: {
        return <ConfirmProtectClaimClose />;
      }
      case subRoutes.Approve: {
        return <TransactionConfirm steps={1} />;
      }
      case subRoutes.Processing: {
        return <TransactionInProgress />;
      }
      case subRoutes.Summary: {
        return <PolicyProtectClaimClose />;
      }
      default: {
        return <ConfirmProtectClaimClose />;
      }
    }
  };

  const getStatus = (): StepStatus[] => {
    switch (step) {
      case subRoutes.Confirm: {
        return ['current', 'next'];
      }
      case subRoutes.Summary: {
        return ['past', 'current'];
      }
      default: {
        return ['current', 'next'];
      }
    }
  };

  return (
    <>
      <FlowHeader
        cancelClick={onCancel}
        logoClick={() => history(Routes.Dashboard)}
        steps={
          step === subRoutes.Approve || step === subRoutes.Processing
            ? []
            : ['Confirm', 'Summary']
        }
        stepsStatus={getStatus()}
      />
      <MainContainer p={isMobile ? '40px 0' : '40px'} width="100%">
        {showSection()}
      </MainContainer>
    </>
  );
};
