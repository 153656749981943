import { ProtectCard, TotalHubInfoBox } from '@bumper-dao/ui-kit';
import ETHIcon from '@bumper-dao/ui-kit/dist/images/32px/tokens/ETH.svg';
import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  FullScreenWidthContainer,
  HubMainContainer,
} from '../../components/common/MainContainers';
import { SwitchNetworkBanner } from '../../components/common/SwitchNetworkBanner/SwitchNetworkBanner';
import { Routes, subRoutes } from '../../core/config/routes';
import { Slides } from '../../core/config/slides';
import { getNetworkConfigsByEnv } from '../../core/config/supportedChains';
import { tokensBackground, tokensIcons } from '../../core/config/tokensIcons';
import { useChainName } from '../../core/hooks/useChain';
import {
  LargeTotalCardType,
  PositionExtendedType,
  TakerPositionType,
  TotalCardType,
} from '../../core/interfaces';
import { TakerPositionService } from '../../core/services/takerPositionService';
import { useAppSelector } from '../../core/state/hooks';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../core/utils/helpers';
import { SummaryInfoContainer } from '../EarnHub/styles';

export const ProtectHub: React.FC = () => {
  const navigate = useNavigate();
  const takerService = TakerPositionService.getInstance();
  const { chainId } = useWeb3React();

  const chainName = useChainName();

  const tokensDetails = useAppSelector((state) => state.coin.coinDetails);

  const [takerPositionsTotalData, setTakerPositionsTotalData] = useState<
    TotalCardType[] | null
  >(null);

  const [positions, setPositions] = useState<Map<
    string,
    PositionExtendedType<TakerPositionType>[]
  > | null>(null);

  const [
    takerPositionsTotalForLargeCardData,
    setTakerPositionsTotalForLargeCardData,
  ] = useState<LargeTotalCardType | null>(null);

  useEffect(() => {
    takerService.getOpenPositions().then((v) => setPositions(v));

    takerService
      .calculateTakerTotalPositionsData(tokensDetails)
      .then(({ result, resultForLargeCard }) => {
        setTakerPositionsTotalForLargeCardData(resultForLargeCard);
        setTakerPositionsTotalData(result);
      })
      .catch((e) => {
        console.error(e);
        setTakerPositionsTotalForLargeCardData(null);
        setTakerPositionsTotalData(null);
      });
  }, [chainId, tokensDetails]);

  return (
    <FullScreenWidthContainer>
      <SwitchNetworkBanner page="protect" slides={Slides} path={['Protect']} />
      <HubMainContainer m="64px auto !important" style={{ gap: '0' }}>
        <SummaryInfoContainer>
          <TotalHubInfoBox
            mainTitle={'Value'}
            firstFieldName={'Protected'}
            firstFieldValue={formatStringifyNumberToDot(
              takerPositionsTotalForLargeCardData?.investedAmountInUSDC || '0',
              2,
            )}
            secondFieldName={'Unprotected'}
            secondFieldValue={formatStringifyNumberToDot(
              takerPositionsTotalForLargeCardData?.assetBalanceInUSDC || '0',
              2,
            )}
            thirdFieldName={'Total'}
            thirdFieldValue={formatStringifyNumberToDot(
              takerPositionsTotalForLargeCardData?.totalAmountInUSDC || '0',
              2,
            )}
          />
          <TotalHubInfoBox
            mainTitle={'Positions'}
            firstFieldName={'Fixed'}
            firstFieldValue={formatStringifyNumberToDot(
              takerPositionsTotalForLargeCardData?.fixedInUSDC || '0',
              2,
            )}
            secondFieldName={'Flexible'}
            secondFieldValue={formatStringifyNumberToDot(
              takerPositionsTotalForLargeCardData?.flexibleInUSDC || '0',
              2,
            )}
            thirdFieldName={'Total'}
            thirdFieldValue={formatStringifyNumberToDot(
              takerPositionsTotalForLargeCardData?.investedAmountInUSDC || '0',
              2,
            )}
          />
        </SummaryInfoContainer>
        {takerPositionsTotalData &&
          takerPositionsTotalData.length > 0 &&
          takerPositionsTotalData.map(
            (
              {
                token,
                totalAmount,
                totalAmountInUSDC,
                investedAmount,
                investedAmountInUSDC,
                assetBalance,
                assetBalanceInUSDC,
                price,
                average,
              },
              index,
            ) => (
              <ProtectCard
                key={index}
                tokenIcon={
                  token.symbol === 'ETH' ? ETHIcon : tokensIcons[token.symbol]
                }
                iconBackgroundColor={tokensBackground[token.symbol]}
                tokenName={token.name}
                tokenSymbol={token.symbol}
                tokenPrice={formatStringifyNumberToDot(price, 2)}
                onProtect={() => {
                  navigate(
                    `${Routes.Protect}/${chainName}/${subRoutes.Select}`,
                    {
                      state: { token },
                    },
                  );
                }}
                onView={() => {
                  navigate(
                    `${Routes.ProtectAssetsDashboard}/${
                      getNetworkConfigsByEnv()[chainId ?? 1].nameForDisplay
                    }`,
                  );
                }}
                totalAmount={formatWeiToNormalString(totalAmount)}
                totalAmountInUSD={formatStringifyNumberToDot(
                  totalAmountInUSDC,
                  2,
                )}
                protectedAmount={formatWeiToNormalString(investedAmount)}
                protectedAmountInUsd={formatStringifyNumberToDot(
                  investedAmountInUSDC,
                  2,
                )}
                unprotectedAmount={formatWeiToNormalString(assetBalance)}
                unprotectedAmountInUsd={formatStringifyNumberToDot(
                  assetBalanceInUSDC,
                  2,
                )}
                // @TODO: fix this with correct values
                floor={formatStringifyNumberToDot(average, 2)}
                floorPercents={3.2}
                positionsCount={
                  positions?.get(token.symbol === 'ETH' ? 'WETH' : token.symbol)
                    ?.length ?? 0
                }
              />
            ),
          )}
      </HubMainContainer>
    </FullScreenWidthContainer>
  );
};
