import {
  Button,
  ButtonProps,
  SliderDotActive,
  SliderDotInactive,
  Flex,
  IconSize,
  ArrowLong,
  HorizontalOrientation,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React, { useRef } from 'react';
import AliceCarousel, { DotsItem } from 'react-alice-carousel';
import styled from 'styled-components';
import 'react-alice-carousel/lib/alice-carousel.css';

import { CaruselProps } from './types';

const ButtonCarusel = styled(Button)<ButtonProps>`
  z-index: 1;
  align-self: center;
  cursor: pointer;
`;
const CarouselWrapper = styled(Flex)`
  width: 900px;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
  }
`;
const SlidesWrapper = styled(Flex)`
  width: 510px;
  flex-direction: column;
  padding: 20px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 250px;
    padding: 20px 0;
  }
`;

export const Carusel: React.FC<CaruselProps> = (props) => {
  const caruselRef = useRef<AliceCarousel>(null);
  const renderDotsItem = ({ isActive }: DotsItem) => {
    return isActive ? <SliderDotActive /> : <SliderDotInactive />;
  };
  const { isMobile } = useMatchBreakpoints();

  return (
    <CarouselWrapper {...props}>
      <Flex flexDirection="row" justifyContent="center">
        <ButtonCarusel
          marginRight={isMobile ? '20px' : '160px'}
          onClick={() => caruselRef.current?.slidePrev({})}
          size={IconSize.L}
        >
          <ArrowLong
            variant={IconSize.L}
            orientation={HorizontalOrientation.LEFT}
          />
        </ButtonCarusel>

        <SlidesWrapper>
          <AliceCarousel
            disableButtonsControls
            renderDotsItem={renderDotsItem}
            ref={caruselRef}
            infinite={true}
          >
            {React.Children.map(props.children, (item) => item)}
          </AliceCarousel>
        </SlidesWrapper>

        <ButtonCarusel
          onClick={() => {
            caruselRef.current?.slideNext({});
          }}
          marginLeft={isMobile ? '20px' : '160px'}
          size={IconSize.L}
        >
          <ArrowLong
            variant={IconSize.L}
            orientation={HorizontalOrientation.RIGHT}
          />
        </ButtonCarusel>
      </Flex>
    </CarouselWrapper>
  );
};
