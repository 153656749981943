import { useMatchBreakpoints } from '@bumper-dao/ui-kit';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import './HeroSlides.css';

SwiperCore.use([Navigation, Pagination]);

interface CardViewProps {
  children: React.ReactNode;
  posLength: number;
  minHeight?: string;
  minHeightMobile?: string;
}

export const CardViewWrap = styled(Swiper)<{
  calcLeft?: number;
  mh?: string;
  mhMobile?: string;
  hideArrows?: boolean;
}>`
  &.home-slider {
    .swiper-button-prev {
      visibility: ${({ hideArrows }) => (hideArrows ? 'hidden' : 'visible')};
    }

    .swiper-button-next {
      left: ${({ calcLeft }) => `${calcLeft && 50 + calcLeft}px`};
      visibility: ${({ hideArrows }) => (hideArrows ? 'hidden' : 'visible')};

      ${({ theme }) => theme.mediaQueries.sm} {
        left: 28%;
      }
    }

    .swiper-wrapper {
      min-height: ${({ mh }) => (mh && mh) || '600px'};
      max-width: 948px;

      ${({ theme }) => theme.mediaQueries.sm} {
        min-height: ${({ mhMobile }) => (mhMobile && mhMobile) || '1020px'};
      }
    }
  }
`;

export const CustomPagination = styled.div`
  position: absolute;
  top: 8px;
  left: 50px;
  width: 40px;
  justify-content: center;
  font-family: 'Share Tech Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #ffffff;
  display: none;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
  }
`;

const CardViewSlider: FC<CardViewProps> = ({
  children,
  posLength,
  minHeight,
  minHeightMobile,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const { isMobile } = useMatchBreakpoints();
  return (
    <CardViewWrap
      hideArrows={posLength === 1}
      mh={minHeight}
      mhMobile={minHeightMobile}
      calcLeft={posLength && !isMobile ? 36 * posLength : 1}
      className="home-slider"
      spaceBetween={30}
      slidesPerView={1}
      navigation={true}
      loop={true}
      pagination={!isMobile}
      onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex + 1)}
    >
      {posLength !== 1 && (
        <CustomPagination>
          <span>{currentSlide}</span>/<span>{posLength}</span>
        </CustomPagination>
      )}
      {children}
    </CardViewWrap>
  );
};

export default CardViewSlider;
