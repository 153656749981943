import {
  Box,
  Button,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  MediumText,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IClaimPageProps } from './types';

import HorizontalLine from '../../assets/backgrounds/claimStakeLine.svg';
import BumpIcon from '../../assets/BUMPSquare.svg';
import { Routes, subRoutes } from '../../core/config/routes';
import { useChainName } from '../../core/hooks/useChain';

export const ClaimPage: React.FC = () => {
  const state = useLocation().state as IClaimPageProps;
  const { isMobile } = useMatchBreakpoints();

  const navigate = useNavigate();
  const chainName = useChainName();

  const handleBack = async () => {
    navigate(`${Routes.Dashboard}`, {
      replace: true,
    });
  };

  const onNextButtonClick = async () => {
    navigate(`${Routes.ClaimStake}/${chainName}/${subRoutes.Select}`, {
      replace: true,
      state: {
        stakeAmount: 0,
        autorenew: false,
        claimedAmount: parseFloat(state.bondAmount),
      },
    });
  };

  return (
    <Box maxWidth={isMobile ? '90%' : '575px'}>
      <Flex alignItems="center" justifyContent="center" marginBottom="64px">
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>
      <ConfirmCard
        iconComponent={
          <img
            src={BumpIcon}
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-50px',
            }}
            width="48px"
            height="48px"
            alt="Icon"
          />
        }
        amount={state.bondAmount}
        subTitle={'BUMP'}
        px={isMobile ? '8px' : ''}
      >
        <MediumText mt="5px" color="secondary.white">
          The above figure is an estimate of the maximum amount of tokens you
          can claim. The actual maximum amount of claimed BUMP will be
          determined at the time the transaction confirms on the blockchain.
        </MediumText>
        <Box width="100%" overflow="hidden">
          <img src={HorizontalLine} />
        </Box>
        <MediumText mt="5px" color="secondary.white">
          The next step to stake will include all of your newly claimed BUMP
          tokens as per the above figure.
        </MediumText>
      </ConfirmCard>
      <Flex alignItems="center" justifyContent="space-between" mt="40px">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={handleBack}
        >
          Back
        </Button>

        <Button
          primary
          onClick={onNextButtonClick}
          size={isMobile ? IconSize.L : IconSize.XL}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};
