import {
  AssetCard,
  PositionInfo,
  Pyramid,
  HubPages,
  SmallText,
  MediumText,
  Heading,
  lightColors,
  AssetLabel,
  Label,
  Button,
  IconSize,
  BOTTOM_LEDGE_HEIGHT,
  BOTTOM_LEDGE_WIDTH,
  RADIATOR_WIDTH,
  useMatchBreakpoints,
  OptionType,
  Ethereum,
  Tooltip,
  Toggle,
  CardView,
  ListView,
  FlexWithGap,
  AssetFooter,
  Flex,
  AssetLabelDark,
} from '@bumper-dao/ui-kit';
import { ethers } from 'ethers';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react/swiper-react';

import {
  PositionHeaderWrapper,
  CoinWrapper,
  CoinName,
  InfoPositionBalanceWrapper,
  InfoPositionLine,
  InfoPositionBalance,
  FloorStatusWrapper,
  PositionHeaderInfoWrapper,
  PositionHeaderActionWrapper,
  PositionContentWrapper,
  ContentProtectedWrapper,
  ContentProtectedBalanceWrapper,
  ContentProtectedBalance,
  ProtectedValueWrapper,
  ProtectedCoinKey,
  PositionInfoWrapper,
  PositionInfoBlockWrapper,
  ContentPolicyWrapper,
  PolicyDeatilsHeaderWrapper,
  PolicyDeatilsHeader,
  ButtonsDivider,
  InfoPositionValue,
  ProtectedBalanceWrapper,
  InfoContentPositionLine,
} from './styles';
import {
  RadiatorOrder,
  RadiatorStepperProps,
  RadiatorStepperLineProps,
  IAssetBalanceData,
} from './types';

import ETHIcon from '../../assets/48px/tokens/ETH.svg';
import ETHGreenIcon from '../../assets/48px/tokens/eth_green.svg';
import noPositionsBg from '../../assets/backgrounds/no-position.png';
import cityBackgound from '../../assets/backgrounds/protect-asset.png';
import BumperLogo from '../../assets/bumper-pixel-icon.svg';
import BottomLeftCornerAsset from '../../assets/cardCorners/leftAsset.svg';
import BottomRightCornerAsset from '../../assets/cardCorners/rightAsset.svg';
import CardViewSlider from '../../components/common/CardView/CardView';
import { MainContainer } from '../../components/common/MainContainers';
import { SwitchNetworkBanner } from '../../components/common/SwitchNetworkBanner/SwitchNetworkBanner';
import { ToggleWrap } from '../../components/EarnAssetPage/styles';
import { RightBlockWrap } from '../../components/EarnAssetPage/styles';
import NoPositions from '../../components/NoPositions/NoPositions';
import {
  ProtectAssetCardHeader,
  ProtectAssetCardContent,
} from '../../components/ProtectAssetPage/ProtectAssetSummaryCard';
import { ONE_WETH } from '../../core/config/constants/coins';
import { DEFAULT_DECIMAL_VALUE } from '../../core/config/formulaConstants';
import { Routes, subRoutes } from '../../core/config/routes';
import {
  ETH,
  getAssetSymbol,
  getProtectionSymbol,
  getSymbol,
} from '../../core/config/tokenNames';
import { tokensIcons } from '../../core/config/tokensIcons';
import { WalletContext } from '../../core/config/walletContext';
import { useChainName } from '../../core/hooks/useChain';
import {
  ICoinDetails,
  IProtectionPosition,
  PositionExtendedType,
  TakerPositionType,
} from '../../core/interfaces';
import { TakerPositionService } from '../../core/services/takerPositionService';
import { useAppSelector } from '../../core/state/hooks';
import { CoinDetailsReducerType } from '../../core/state/reducers/coinReducer';
import {
  dateFormatter,
  formatStringifyNumberToDot,
  formatWeiToNormalString,
  getTokenPrice,
  secondsByDays,
  timeInMilliseconds,
} from '../../core/utils/helpers';
import { IconWrap } from '../EarnAsset/EarnAsset';
import {
  AssetCardBottomBackground,
  AssetCardTopBackground,
  AssetCardWrapper,
  HubStatusWrapper,
  RadiatorStepperLineWrapper,
  RadiatorTopStep,
  RadiatorBottomStep,
  RadiatorStepperWrapper,
  PositionsWrapper,
} from '../StakingAssetPage/styles';

export type ProtectionCardPropsType = {
  token: ICoinDetails;
  protectedToken: ICoinDetails;
  position: ITemporaryPosition;
  chainName: string;
  autorenew?: boolean;
};

export const BumperPolicy: React.FC = () => {
  return (
    <img
      width="42px"
      height="42px"
      src={BumperLogo}
      style={{ marginRight: '0.5rem' }}
    />
  );
};
const RadiatorStepperLine: React.FC<RadiatorStepperLineProps> = ({
  stepSize,
}) => {
  return (
    <RadiatorStepperLineWrapper stepSize={stepSize}>
      <RadiatorTopStep />
      <RadiatorBottomStep />
    </RadiatorStepperLineWrapper>
  );
};

const RadiatorStepper: React.FC<RadiatorStepperProps> = ({ order }) => {
  return (
    <RadiatorStepperWrapper order={order}>
      <RadiatorStepperLine stepSize="2rem" />
      <RadiatorStepperLine stepSize="1.5rem" />
      <RadiatorStepperLine stepSize="0.75rem" />
      <RadiatorStepperLine />
    </RadiatorStepperWrapper>
  );
};

const PositionHeader: React.FC<ProtectionCardPropsType> = ({
  token,
  protectedToken,
  position,
  autorenew,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const chainName = useChainName();

  const isClaim =
    parseFloat(position.currentPrice) <= parseFloat(position.protectedPrice);

  const periodsPast = Math.ceil(
    (Date.now() / 1000 - position.start) / secondsByDays(+position.term),
  );
  const liquidateDeadline = autorenew
    ? timeInMilliseconds(
        +position.positionOpenTimestamp +
          secondsByDays(+position.term) * periodsPast,
      )
    : timeInMilliseconds(
        +position.start + secondsByDays(+position.term) * position.pastTerms,
      );

  return (
    <PositionHeaderWrapper>
      <PositionHeaderInfoWrapper>
        <InfoPositionBalanceWrapper>
          <CoinWrapper>
            <Ethereum icon={ETHGreenIcon} />
            <CoinName ml="0.5rem">
              <Heading
                scale={isMobile ? 'lg' : 'xl'}
                lineHeight="150%"
                letterSpacing="0.03em"
                color={lightColors.secondary.white}
              >
                bETH
              </Heading>
              <SmallText
                color="rgba(255, 255, 255, 0.7)"
                fontWeight="500"
                style={{ whiteSpace: 'nowrap' }}
              >
                Bumpered ETH
              </SmallText>
            </CoinName>
          </CoinWrapper>
          <InfoPositionBalance>
            {isMobile ? (
              <InfoPositionLine style={{ transform: 'rotate(90deg)' }} />
            ) : (
              <InfoPositionLine />
            )}
            <InfoPositionValue>
              <SmallText
                color={lightColors.typography.placeholder}
                fontSize="12px"
                lineHeight="16px"
                fontWeight="500"
                style={{ whiteSpace: 'nowrap' }}
              >
                Position Balance
              </SmallText>
              <Flex alignItems="center">
                <SmallText
                  color={lightColors.secondary.white}
                  fontSize="12px"
                  lineHeight="16px"
                  mt="0.25rem"
                >
                  {formatStringifyNumberToDot(
                    ethers.utils.formatUnits(
                      position.amount,
                      token.decimals ?? 18,
                    ),
                  )}
                </SmallText>
                {isMobile && (
                  <SmallText
                    color={lightColors.primary2.cambridgeBlue}
                    fontSize="12px"
                    lineHeight="16px"
                    mt="0.25rem"
                    ml={'8px'}
                  >
                    {` ${protectedToken.symbol}`}
                  </SmallText>
                )}
              </Flex>
            </InfoPositionValue>
          </InfoPositionBalance>
        </InfoPositionBalanceWrapper>
        <FloorStatusWrapper>
          {!isClaim ? (
            <Label
              backgroundColor="transparent"
              borderColor={lightColors.secondary.green}
              color={lightColors.primary2.cambridgeBlue}
              ml="10px"
            >
              Above Floor
            </Label>
          ) : (
            <Label
              backgroundColor="transparent"
              borderColor={lightColors.secondary.red}
              color={lightColors.primary2.cambridgeBlue}
              ml="20px"
            >
              Below Floor
            </Label>
          )}
        </FloorStatusWrapper>
      </PositionHeaderInfoWrapper>
      {isMobile && <ButtonsDivider />}
      {Date.now() > liquidateDeadline ? (
        <PositionHeaderActionWrapper>
          <Button
            primary
            size={IconSize.L}
            padding="0.25rem 1rem"
            letterSpacing="0.03em"
            lineHeight="20px"
            minHeight="32px"
            mr="2.5rem"
            onClick={() =>
              navigate(`${Routes.Protect}/${chainName}/${subRoutes.Select}`)
            }
          >
            New Policy
          </Button>
          <Button
            primary
            size={IconSize.L}
            padding="0.25rem 1rem"
            letterSpacing="0.03em"
            lineHeight="20px"
            minHeight="32px"
            onClick={() =>
              navigate(
                `${Routes.ClaimClose}/${chainName}/${protectedToken.symbol}/${subRoutes.Confirm}`,
                {
                  state: {
                    id: position.id,
                    amount: position.amount.toString(),
                    token: protectedToken,
                    isClaim: isClaim,
                    icon: ETHIcon,
                  },
                },
              )
            }
          >
            {isClaim ? 'Claim' : 'Close'}
          </Button>
        </PositionHeaderActionWrapper>
      ) : (
        <RightBlockWrap>
          <ToggleWrap>
            <Tooltip
              tooltipValue="123"
              conntainerStyles={{
                zIndex: 9000,
              }}
            >
              <MediumText color="#99B3B5" lineHeight="16px">
                Auto-renew
              </MediumText>
            </Tooltip>
            <Toggle
              onToggle={(active) => {
                console.log(
                  `${Routes.Protect}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Confirm}`,
                );
                navigate(
                  `${Routes.Protect}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Confirm}`,
                  {
                    state: {
                      position,
                      protectedToken,
                      toggleState: active,
                    },
                  },
                );
              }}
              checked={autorenew}
            />
          </ToggleWrap>
          <Button
            primary
            size={IconSize.L}
            padding="0.25rem 1rem"
            letterSpacing="0.03em"
            lineHeight="20px"
            minHeight="32px"
            disabled
          >
            <Countdown
              autoStart={true}
              date={liquidateDeadline}
              now={Date.now}
              renderer={({ formatted: f }) => {
                return (
                  <span>
                    {f.days}:{f.hours}:{f.minutes}:{f.seconds}
                  </span>
                );
              }}
            ></Countdown>
          </Button>
        </RightBlockWrap>
      )}
    </PositionHeaderWrapper>
  );
};

const PositionContent: React.FC<ProtectionCardPropsType> = ({
  token,
  protectedToken,
  position,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const tokenDetails = useAppSelector(
    (state) => state.coin.coinDetails[protectedToken.symbol],
  );
  const pricePercents = useMemo(
    () =>
      ((parseFloat(position.depositPrice) - parseFloat(tokenDetails.price)) *
        100) /
      parseFloat(position.depositPrice),
    [tokenDetails.price, position.depositPrice],
  );
  return (
    <PositionContentWrapper>
      <ContentProtectedWrapper>
        <ContentProtectedBalanceWrapper>
          <CoinWrapper>
            <Ethereum icon={ETHIcon} />
            <CoinName ml="0.5rem">
              <Heading
                scale="xl"
                lineHeight="150%"
                letterSpacing="0.03em"
                color={lightColors.secondary.white}
              >
                {protectedToken.symbol}
              </Heading>
              <MediumText color="rgba(255, 255, 255, 0.7)" fontWeight="500">
                {protectedToken.name}
              </MediumText>
            </CoinName>
          </CoinWrapper>
          <ContentProtectedBalance>
            <InfoContentPositionLine mr="1rem" />
            <ProtectedBalanceWrapper>
              <MediumText
                color={lightColors.typography.placeholder}
                lineHeight="16px"
                fontWeight="500"
              >
                Protected
              </MediumText>
              <ProtectedValueWrapper>
                <MediumText
                  color={lightColors.secondary.white}
                  lineHeight="16px"
                  mt="0.25rem"
                  mr="0.25rem"
                >
                  {formatStringifyNumberToDot(
                    ethers.utils.formatUnits(
                      position.amount,
                      token.decimals ?? 18,
                    ),
                    2,
                  )}
                </MediumText>
                <ProtectedCoinKey>ETH</ProtectedCoinKey>
              </ProtectedValueWrapper>
            </ProtectedBalanceWrapper>
          </ContentProtectedBalance>
        </ContentProtectedBalanceWrapper>
        <PositionInfoWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Protected Price
            </SmallText>
            <AssetLabel minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                ${formatStringifyNumberToDot(position.protectedPrice, 2)}
              </MediumText>
            </AssetLabel>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Deposit Price
            </SmallText>
            <AssetLabel minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                ${formatStringifyNumberToDot(position.depositPrice, 2)}
              </MediumText>
            </AssetLabel>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Current Price
            </SmallText>
            <AssetLabel minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                ${formatStringifyNumberToDot(tokenDetails.price, 2)}
              </MediumText>
              <SmallText color={lightColors.typography.placeholder}>
                &nbsp;({pricePercents >= 0 && '+'}
                {pricePercents.toFixed(0)}%)
              </SmallText>
            </AssetLabel>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Protected Value
            </SmallText>
            <AssetLabel minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                ${formatStringifyNumberToDot(position.protectedValue, 2)}
              </MediumText>
            </AssetLabel>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Deposit Value
            </SmallText>

            <AssetLabel minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                $
                {formatStringifyNumberToDot(
                  position.depositValue.toString(),
                  2,
                )}
              </MediumText>
            </AssetLabel>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Estimated Net Claim
            </SmallText>
            <AssetLabel minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                {/*$*/}
                {/*{formatStringifyNumberToDot(*/}
                {/*  position.estimationNetClaim.toString(),*/}
                {/*  2,*/}
                {/*)}*/}
                TBA
              </MediumText>
              {/*<SmallText color={lightColors.typography.placeholder}>*/}
              {/*  &nbsp;(+20%)*/}
              {/*</SmallText>*/}
            </AssetLabel>
          </PositionInfoBlockWrapper>
        </PositionInfoWrapper>
      </ContentProtectedWrapper>
      <ContentPolicyWrapper>
        <PolicyDeatilsHeaderWrapper>
          <PolicyDeatilsHeader>
            <BumperPolicy />

            <Heading
              scale={isMobile ? 'lg' : 'xl'}
              lineHeight="150%"
              letterSpacing="0.03em"
              color={lightColors.secondary.white}
            >
              Policy Details
            </Heading>
          </PolicyDeatilsHeader>
          {!isMobile && (
            <MediumText mt="1rem" color={lightColors.primary2.cambridgeBlue}>
              Created: {dateFormatter(position.positionOpenTimestamp)}
            </MediumText>
          )}
        </PolicyDeatilsHeaderWrapper>
        <PositionInfoWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Protected Floor
            </SmallText>
            <AssetLabelDark minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                {position.floor}%
              </MediumText>
            </AssetLabelDark>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              BUMP Incentives
            </SmallText>
            <AssetLabelDark minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                {position.incentives}
              </MediumText>
              <SmallText ml="4px" color={lightColors.typography.placeholder}>
                BUMP
              </SmallText>
            </AssetLabelDark>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color="primary2.cambridgeBlue"
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Accumulated Fees
            </SmallText>
            <AssetLabelDark minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                fontSize="14px"
                marginRight="2px"
              >
                {formatStringifyNumberToDot(position.accumulatedFee, 6)}
              </MediumText>
              <SmallText
                color={lightColors.typography.placeholder}
                fontSize="14px"
                ml="4px"
              >
                ETH
              </SmallText>
            </AssetLabelDark>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Protection Term
            </SmallText>
            <AssetLabelDark minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                {position.term}
              </MediumText>
              <SmallText ml="4px" color={lightColors.typography.placeholder}>
                Days
              </SmallText>
            </AssetLabelDark>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              BUMP Bond
            </SmallText>
            <AssetLabelDark minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                {position.bumpBondAmount}
              </MediumText>
              <SmallText ml="4px" color={lightColors.typography.placeholder}>
                BUMP
              </SmallText>
            </AssetLabelDark>
          </PositionInfoBlockWrapper>
          <PositionInfoBlockWrapper>
            <SmallText
              color={lightColors.primary2.cambridgeBlue}
              lineHeight="16px"
              fontWeight="500"
              mb="8px"
            >
              Current Fee Rate
            </SmallText>
            <AssetLabelDark minWidth={isMobile ? '190px' : ''}>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                1%
              </MediumText>
              <SmallText ml="4px" color={lightColors.typography.placeholder}>
                p.m.
              </SmallText>
            </AssetLabelDark>
          </PositionInfoBlockWrapper>
        </PositionInfoWrapper>
      </ContentPolicyWrapper>
    </PositionContentWrapper>
  );
};

interface ITemporaryPosition extends IProtectionPosition {
  autorenew: boolean;
  incentives: string;
}

const ProtectAsset: React.FC = () => {
  const { isMobile } = useMatchBreakpoints();
  const history = useNavigate();
  const navigate = useNavigate();
  const takerPositionService = TakerPositionService.getInstance();
  const { TOKEN_DETAILS } = useAppSelector((state) => state.contractsAddresses);
  const [optionSymbol, setOptionSymbol] = useState('ETH');
  const [openPositions, setOpenPositions] = useState<ITemporaryPosition[]>([]);
  const [isBalnce, setIsBalance] = useState<boolean>();
  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  const [assetBalanceData, setAssetBalanceData] = useState<IAssetBalanceData>({
    totalBalance: '',
    totalBalanceInUSD: '',
    avgFloor: '',
    inWalletBalance: '',
    protocolAmount: '',
    protocolAmountInUSD: '',
    balanceAmount: '',
    balanceAmountInUSD: '',
    positionsCount: 0,
  });

  const chainName = useChainName();

  const coinDetails = useAppSelector((state) => state.coin.coinDetails);

  const symbol = useMemo(() => getSymbol(optionSymbol), [optionSymbol]);
  const bSymbol = useMemo(() => getProtectionSymbol(symbol), [symbol]);
  const assetSymbol = useMemo(() => getAssetSymbol(symbol), [symbol]);

  const getUnprotected = (
    optionSymbol: string,
    coins: CoinDetailsReducerType,
  ): string => {
    const tokenBalance = coins[optionSymbol].balance;
    if (optionSymbol !== assetSymbol) {
      const aliasBalance = coins[assetSymbol].balance;
      return `${parseFloat(tokenBalance) + parseFloat(aliasBalance)}`;
    }

    return tokenBalance;
  };
  const getUnprotectedInUSDC = (
    optionSymbol: string,
    coins: CoinDetailsReducerType,
  ): string => {
    const tokenBalance = coins[optionSymbol].value;
    if (optionSymbol !== assetSymbol) {
      const aliasBalance = coins[assetSymbol].value;
      return `${parseFloat(tokenBalance) + parseFloat(aliasBalance)}`;
    }

    return tokenBalance;
  };

  const unprotected = useMemo(
    () => getUnprotected(optionSymbol, coinDetails),
    [optionSymbol, coinDetails],
  );
  const unprotectedInUSDC = useMemo(
    () => getUnprotectedInUSDC(optionSymbol, coinDetails),
    [optionSymbol, coinDetails],
  );

  const loadOpenPositions = async (): Promise<
    Map<string, PositionExtendedType<TakerPositionType>[]>
  > => {
    const takerPositionService = TakerPositionService.getInstance();
    return await takerPositionService.getOpenPositions();
  };

  const getAssetBalance = (
    positions: Map<string, PositionExtendedType<TakerPositionType>[]>,
  ): IAssetBalanceData => {
    if (positions && !positions.has(assetSymbol)) {
      return {
        totalBalance: unprotected,
        totalBalanceInUSD: unprotectedInUSDC,
        inWalletBalance: unprotected,
        avgFloor: '0',
        protocolAmount: '0',
        protocolAmountInUSD: '0',
        balanceAmount: unprotected,
        balanceAmountInUSD: unprotectedInUSDC,
        positionsCount: 0,
      };
    }

    const currentTokenPrice = parseFloat(coinDetails[optionSymbol].price);
    const positionsCount = positions.get(assetSymbol)?.length || 0;

    const summaryFloor = (positions.get(assetSymbol) ?? [])
      .map((pos) => pos.floor)
      .reduce((prev, floor) => prev.add(floor), ethers.constants.Zero);
    const numberingsSummaryFloor = parseFloat(
      ethers.utils.formatUnits(summaryFloor, DEFAULT_DECIMAL_VALUE),
    );
    const averageFloor = numberingsSummaryFloor / positionsCount;

    const unprotectedAmount = unprotected
      ? ethers.utils.parseUnits(unprotected, DEFAULT_DECIMAL_VALUE)
      : ethers.constants.Zero;

    const protectedAmount = (positions.get(assetSymbol) ?? [])
      .map((pos) => pos.assetAmount)
      .reduce((prev, amount) => prev.add(amount), ethers.constants.Zero);
    const numberingsProtectedAmount = parseFloat(
      ethers.utils.formatUnits(protectedAmount, DEFAULT_DECIMAL_VALUE),
    );
    const protectedInUSD = (
      numberingsProtectedAmount * currentTokenPrice
    ).toString();

    const totalAmount = unprotectedAmount.add(protectedAmount);
    const numberingsTotalAmount = parseFloat(
      ethers.utils.formatUnits(totalAmount, DEFAULT_DECIMAL_VALUE),
    );
    const totalAmountInUSD = (
      numberingsTotalAmount * currentTokenPrice
    ).toString();

    return {
      totalBalance: ethers.utils.formatUnits(
        totalAmount,
        DEFAULT_DECIMAL_VALUE,
      ),
      totalBalanceInUSD: totalAmountInUSD,
      inWalletBalance: unprotected,
      protocolAmount: ethers.utils.formatUnits(
        protectedAmount,
        DEFAULT_DECIMAL_VALUE,
      ),
      protocolAmountInUSD: protectedInUSD,
      avgFloor: averageFloor.toString(),
      balanceAmount: unprotected,
      balanceAmountInUSD: unprotectedInUSDC,
      positionsCount,
    };
  };

  const getProtectionPositions = async (
    positions: PositionExtendedType<TakerPositionType>[],
  ): Promise<ITemporaryPosition[]> => {
    const currentPrice = await getTokenPrice(
      TOKEN_DETAILS[symbol].address,
      ONE_WETH,
    );
    const getFormattedPosition = async (
      pos: PositionExtendedType<TakerPositionType>,
    ) => {
      const openPositionPrice = pos.floor.div(pos.risk * 10000);
      const depositValue = ethers.utils.formatUnits(
        pos.assetAmount.mul(openPositionPrice),
        (TOKEN_DETAILS[symbol].decimal ?? 18) + 16,
      );
      const accumulatedFee = await takerPositionService.calculateTakerFee(
        pos.assetAmount,
        pos.start,
        assetSymbol,
      );

      return {
        id: pos.id,
        amount: pos.assetAmount,
        token: pos.tokenAddress,
        depositPrice: ethers.utils.formatUnits(openPositionPrice, 14),
        depositValue: depositValue,
        start: +pos.start,
        pastTerms: pos.pastTerms,
        positionOpenTimestamp: pos.positionOpenTimestamp,
        protectedPrice: ethers.utils.formatUnits(pos.floor, 18),
        protectedValue: Math.ceil(
          parseFloat(depositValue) * pos.risk,
        ).toString(),
        accumulatedFee: ethers.utils.formatUnits(accumulatedFee, 18),
        currentPrice: ethers.utils.formatUnits(currentPrice, 6),
        estimationNetClaim: coinDetails[symbol].price,
        floor: (pos.risk * 100).toString(),
        term: pos.term.toString(),
        bumpBondAmount: formatWeiToNormalString(pos.bumpBondAmount),
        autorenew: pos.autorenew,
        incentives: formatWeiToNormalString(pos.incentives),
      };
    };
    return await Promise.all<ITemporaryPosition>(
      positions.map<Promise<ITemporaryPosition>>((pos) => {
        return getFormattedPosition(pos);
      }),
    );
  };

  useEffect(() => {
    loadOpenPositions().then(async (positions) => {
      const protectionPositions = await getProtectionPositions(
        positions.get(assetSymbol) ?? [],
      );
      setOpenPositions(protectionPositions);
      setAssetBalanceData(getAssetBalance(positions));
    });
  }, [coinDetails]);

  const getOptions = (coinDetail: CoinDetailsReducerType): OptionType[] => {
    const options: OptionType[] = [];

    [ETH].forEach((token) => {
      if (
        coinDetail[token.symbol] &&
        coinDetail[token.symbol].price !== '0.0'
      ) {
        setIsBalance(true);
      } else setIsBalance(false);
      options.push({
        key: token.symbol,
        value: (
          <FlexWithGap gap="5px" alignItems="center">
            <Heading scale={'lg'} color="secondary.white">
              {token.name}
            </Heading>
            <MediumText
              fontWeight={500}
              fontSize="16px"
              color="primary2.cambridgeBlue"
            >
              <span className="cambridgeBlue">({token.symbol})</span>
            </MediumText>
            <SmallText fontWeight={500} color="secondary.white">
              1 {token.symbol} = $
              {formatStringifyNumberToDot(
                coinDetail[token.symbol]?.price ?? '0.00',
                2,
              )}
            </SmallText>
          </FlexWithGap>
        ),
        icon: tokensIcons[token.symbol],
      });
    });

    return options;
  };

  useEffect(() => {
    if (!isWalletConnected) navigate(Routes.Dashboard);
  }, [isWalletConnected]);

  const protectOptions = useMemo(() => getOptions(coinDetails), [coinDetails]);

  const [cardView, setCardView] = useState<boolean>(false);

  return (
    <>
      <SwitchNetworkBanner
        page="assets/protect"
        slides={[]}
        path={['Protect', 'Ether']}
      />
      <AssetCardWrapper>
        <AssetCardTopBackground />
        <MainContainer width={!isMobile ? '1100px' : ''}>
          <AssetCard
            mt="4rem"
            mb="3rem"
            position="relative"
            header={
              <ProtectAssetCardHeader
                options={protectOptions}
                disabled={parseFloat(assetBalanceData.balanceAmount) === 0}
                onProtect={() =>
                  history(
                    `${Routes.Protect}/${chainName}/${subRoutes.Select}`,
                    {
                      state: { token: coinDetails[symbol] },
                    },
                  )
                }
                onChooseOption={(option) => setOptionSymbol(option.key)}
              />
            }
            content={
              <ProtectAssetCardContent
                data={assetBalanceData}
                tokenSymbol={optionSymbol}
              />
            }
            bottomLeftCorner={BottomLeftCornerAsset}
            bottomRightCorner={BottomRightCornerAsset}
            bottomLedgeHeight={BOTTOM_LEDGE_HEIGHT}
            bottomLedgeCenterWidth={isMobile ? '281px' : BOTTOM_LEDGE_WIDTH}
            radiatorWidth={RADIATOR_WIDTH}
            headerPadding={isMobile ? '0' : '16px 10% 16px 2%'}
          />
        </MainContainer>
        <AssetCardBottomBackground />
        <HubStatusWrapper>
          <RadiatorStepper order={RadiatorOrder.left} />

          <Pyramid
            isHubConnected={!!openPositions.length}
            currentHub={HubPages.protect}
          />

          <RadiatorStepper order={RadiatorOrder.right} />
        </HubStatusWrapper>
      </AssetCardWrapper>
      <PositionsWrapper
        backgroundImage={openPositions.length ? cityBackgound : noPositionsBg}
        hasPosition={!!openPositions.length}
      >
        {openPositions.length && (
          <FlexWithGap
            gap="24px"
            marginBottom={cardView ? '-2.5rem !important' : ''}
            justifyContent="flex-end"
            maxWidth="948px"
            margin="auto"
          >
            <IconWrap
              className={(cardView && 'active') || ''}
              onClick={() => {
                setCardView(true);
              }}
            >
              <CardView variant={IconSize.L} />
            </IconWrap>
            <IconWrap
              className={(!cardView && 'active') || ''}
              onClick={() => setCardView(false)}
            >
              <ListView variant={IconSize.L} />
            </IconWrap>
          </FlexWithGap>
        )}
        <MainContainer maxWidth="948px !important">
          {openPositions.length && !cardView ? (
            openPositions.map((pos, i) => (
              <PositionInfo
                key={i}
                header={
                  <PositionHeader
                    token={coinDetails[bSymbol]}
                    protectedToken={coinDetails[symbol]}
                    position={pos}
                    autorenew={pos.autorenew}
                    chainName="goerli"
                  />
                }
                content={
                  <PositionContent
                    token={coinDetails[bSymbol]}
                    protectedToken={coinDetails[symbol]}
                    position={pos}
                    chainName={chainName}
                  />
                }
              />
            ))
          ) : openPositions.length && cardView ? (
            <CardViewSlider
              posLength={openPositions.length}
              minHeightMobile={'1600px'}
              minHeight="650px"
            >
              {openPositions.map((pos, i) => (
                <SwiperSlide key={i}>
                  <PositionInfo
                    key={i}
                    cardView={true}
                    header={
                      <PositionHeader
                        token={coinDetails[bSymbol]}
                        protectedToken={coinDetails[symbol]}
                        position={pos}
                        autorenew={pos.autorenew}
                        chainName="goerli"
                      />
                    }
                    content={
                      <PositionContent
                        token={coinDetails[bSymbol]}
                        protectedToken={coinDetails[symbol]}
                        position={pos}
                        chainName={chainName}
                      />
                    }
                  />
                </SwiperSlide>
              ))}
            </CardViewSlider>
          ) : isBalnce ? (
            <NoPositions
              heading="WANNA PLAY?"
              text="Open some positions, grab a slurpee and chill out!"
              btnName="Protect"
              route={() => {
                history(`${Routes.Protect}/${chainName}/${subRoutes.Select}`, {
                  state: { token: coinDetails[symbol] },
                });
              }}
            />
          ) : (
            <NoPositions
              heading="INSERT COINS"
              text="You've got to have some to earn some, dude. Load some coins!"
            />
          )}
        </MainContainer>
      </PositionsWrapper>
      <AssetFooter />
    </>
  );
};

export default ProtectAsset;
