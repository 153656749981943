import { useWeb3React } from '@web3-react/core';
import React, { ReactNode, useEffect } from 'react';

import { SUPPORTED_CHAINS } from '../config/supportedChains';
import { useAppDispatch } from '../state/hooks';
import { removeError } from '../state/reducers/errorReducer';

export const ErrorHandler: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { chainId } = useWeb3React();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (chainId && SUPPORTED_CHAINS.includes(chainId)) {
      dispatch(removeError());
    }
  }, [chainId, dispatch]);

  // const goToEarnPage = () => {
  //   history.push(Routes.Dashboard);
  // };

  // if (state.error) {
  //   return (
  //     <ErrorState
  //       errorMessage={state.errorMessage}
  //       errorDescription={state.errorDescription}
  //       buttonText="Back to Deposit Page"
  //       buttonAction={goToEarnPage}
  //       hideButton={state.hideBtn}
  //     />
  //   );
  // }

  return <>{children}</>;
};
