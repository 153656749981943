import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';

import coinReducer from './reducers/coinReducer';
import contractsAddressesReducer from './reducers/contractsAddressesReducer';
// import epochsReducer from './reducers/epochsReducer';
import errorReducer from './reducers/errorReducer';
import merkleTreeReducer from './reducers/merkleTree';
import uiStateManagementReducer from './reducers/uiStateManagementReducer';
import vestingReducer from './reducers/vestingReducer';
import walletModalReducer from './reducers/walletModalReducer';

const rootReducer = combineReducers({
  contractsAddresses: contractsAddressesReducer,
  coin: coinReducer,
  error: errorReducer,
  uiStateMgmt: uiStateManagementReducer,
  vesting: vestingReducer,
  merkleTree: merkleTreeReducer,
  walletModal: walletModalReducer,
  // epochs: epochsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
