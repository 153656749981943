import { EarnCard, TotalHubInfoBox } from '@bumper-dao/ui-kit';
import USDCIcon from '@bumper-dao/ui-kit/dist/images/32px/tokens/USDC-croped.svg';
import { useWeb3React } from '@web3-react/core';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SummaryInfoContainer } from './styles';

import {
  FullScreenWidthContainer,
  HubMainContainer,
} from '../../components/common/MainContainers';
import { SwitchNetworkBanner } from '../../components/common/SwitchNetworkBanner/SwitchNetworkBanner';
import { DEFAULT_DECIMAL_VALUE } from '../../core/config/formulaConstants';
import { Routes, subRoutes } from '../../core/config/routes';
import { Slides } from '../../core/config/slides';
import { tokensBackground, tokensIcons } from '../../core/config/tokensIcons';
import { useChainName } from '../../core/hooks/useChain';
import { LargeTotalCardType, TotalCardType } from '../../core/interfaces';
import { MakerPositionService } from '../../core/services/makerPositionService';
import { useAppSelector } from '../../core/state/hooks';
import {
  formatStringifyNumberToDot,
  formatWeiToNormalString,
} from '../../core/utils/helpers';

export const EarnHub: FC = () => {
  const navigate = useNavigate();
  const makerService = MakerPositionService.getInstance();
  const { chainId } = useWeb3React();
  const chainName = useChainName();
  const tokensDetails = useAppSelector((state) => state.coin.coinDetails);
  const [makerPositionsTotalData, setMakerPositionsTotalData] = useState<
    TotalCardType[] | null
  >(null);
  const [
    makerPositionsTotalForLargeCardData,
    setMakerPositionsTotalForLargeCardData,
  ] = useState<LargeTotalCardType | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { result, resultForLargeCard } =
          await makerService.calculateMakerTotalPositionsData(tokensDetails);
        setMakerPositionsTotalForLargeCardData(resultForLargeCard);
        setMakerPositionsTotalData(result);
      } catch (e) {
        console.error(e);
        setMakerPositionsTotalForLargeCardData(null);
        setMakerPositionsTotalData(null);
      }
    })();
  }, [chainId, tokensDetails]);

  return (
    <FullScreenWidthContainer>
      <SwitchNetworkBanner page="earn" slides={Slides} path={['Earn']} />
      <HubMainContainer m="64px auto !important" style={{ gap: '0' }}>
        <SummaryInfoContainer>
          <TotalHubInfoBox
            mainTitle={'Value'}
            firstFieldName={'Earning'}
            firstFieldValue={formatStringifyNumberToDot(
              makerPositionsTotalForLargeCardData?.investedAmountInUSDC || '0',
              2,
            )}
            secondFieldName={'Idle'}
            secondFieldValue={formatStringifyNumberToDot(
              makerPositionsTotalForLargeCardData?.assetBalanceInUSDC || '0',
              2,
            )}
            thirdFieldName={'Total'}
            thirdFieldValue={formatStringifyNumberToDot(
              makerPositionsTotalForLargeCardData?.totalAmountInUSDC || '0',
              2,
            )}
          />
          <TotalHubInfoBox
            mainTitle={'Positions'}
            firstFieldName={'Fixed'}
            firstFieldValue={formatStringifyNumberToDot(
              makerPositionsTotalForLargeCardData?.fixedInUSDC || '0',
              2,
            )}
            secondFieldName={'Flexible'}
            secondFieldValue={formatStringifyNumberToDot(
              makerPositionsTotalForLargeCardData?.flexibleInUSDC || '0',
              2,
            )}
            thirdFieldName={'Total'}
            thirdFieldValue={formatStringifyNumberToDot(
              makerPositionsTotalForLargeCardData?.investedAmountInUSDC || '0',
              2,
            )}
          />
        </SummaryInfoContainer>
        {makerPositionsTotalData &&
          makerPositionsTotalData.length > 0 &&
          makerPositionsTotalData.map(
            (
              {
                token,
                totalAmount,
                totalAmountInUSDC,
                investedAmount,
                investedAmountInUSDC,
                assetBalance,
                assetBalanceInUSDC,
                price,
                average,
                totalYield,
                totalYieldInUSD,
              },
              index,
            ) => (
              <EarnCard
                key={index}
                tokenIcon={
                  token.symbol === 'USDC' ? USDCIcon : tokensIcons[token.symbol]
                }
                iconBackgroundColor={tokensBackground[token.symbol]}
                tokenName={token.name}
                tokenSymbol={token.symbol}
                tokenPrice={formatStringifyNumberToDot(price, 2)}
                onEarn={() => {
                  navigate(`${Routes.Earn}/${chainName}/${subRoutes.Select}`, {
                    state: { token },
                  });
                }}
                onView={() => {
                  navigate(`${Routes.EarnAssetsDashboard}/${chainName}`);
                }}
                totalAmount={formatWeiToNormalString(
                  totalAmount,
                  DEFAULT_DECIMAL_VALUE,
                )}
                totalAmountInUSD={formatStringifyNumberToDot(
                  totalAmountInUSDC,
                  2,
                )}
                idleAmount={formatWeiToNormalString(
                  assetBalance,
                  DEFAULT_DECIMAL_VALUE,
                )}
                idleAmountInUsd={formatStringifyNumberToDot(
                  assetBalanceInUSDC,
                  2,
                )}
                earningAmount={formatWeiToNormalString(
                  investedAmount,
                  DEFAULT_DECIMAL_VALUE,
                )}
                earningAmountInUsd={formatStringifyNumberToDot(
                  investedAmountInUSDC,
                  2,
                )}
                risk={average}
                riskPercents={-4}
                totalYield={formatStringifyNumberToDot(
                  totalYield.toString(),
                  4,
                )}
                totalYieldInUSD={formatStringifyNumberToDot(
                  totalYieldInUSD.toString(),
                  2,
                )}
              />
            ),
          )}
      </HubMainContainer>
    </FullScreenWidthContainer>
  );
};
