import { Box, Flex } from '@bumper-dao/ui-kit';
import styled from 'styled-components';

export const MainContainer = styled(Flex)`
  flex-direction: column;
  max-width: 1108px;
  align-items: center;
  gap: 64px;
  margin: 0 auto;
  flex: 1;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 90%;
  }
`;

export const HubMainContainer = styled(Flex)`
  flex-direction: column;
  max-width: 1108px;
  align-items: center;
  gap: 64px;
  margin: 0 auto;
  flex: 1;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 80%;
  }
`;

export const FullScreenWidthContainer = styled(Box)`
  width: 100vw;
  flex: 1;
`;
