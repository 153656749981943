import { OptionType, useMatchBreakpoints } from '@bumper-dao/ui-kit';
import { useWeb3React } from '@web3-react/core';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { Banner, BannerSlideProps, BreadcrumbProps } from './Banner';

import { getNetworkConfigsByEnv } from '../../../core/config/supportedChains';
import { NetworkConfigType } from '../../../core/interfaces';
import { EthersServiceProvider } from '../../../core/services/ethersServiceProvider';
import { FullScreenWidthContainer } from '../MainContainers';

type SwitchNetworkComponentPropsType = {
  page:
    | 'dashboard'
    | 'protect'
    | 'earn'
    | 'stake'
    | 'market'
    | 'assets/protect'
    | 'assets/earn'
    | 'assets/stake';
  slides: BannerSlideProps[];
} & BreadcrumbProps;

export const SwitchNetworkBanner: FC<SwitchNetworkComponentPropsType> = ({
  page,
  slides,
  path,
}) => {
  const { chainId } = useWeb3React();
  const { network } = useParams<{ network?: string }>();
  const { isMobile } = useMatchBreakpoints();
  const options = Object.values(getNetworkConfigsByEnv()).map<OptionType>(
    (config) => ({
      key: config.nameForDisplay,
      value: config.nameForDisplay.toUpperCase(),
      icon: '',
    }),
  );
  const [activeNetwork, setActiveNetwork] = useState<OptionType>({
    key: network || '',
    value: network || '',
    icon: '',
  });
  const navigate = useNavigate();
  const ethersServiceProvider = EthersServiceProvider.getInstance();

  const handleChangeNetwork = async (config: NetworkConfigType) => {
    try {
      await ethersServiceProvider.provider?.send('wallet_switchEthereumChain', [
        { chainId: config.chainId },
      ]);
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (err.code === 4902) {
        await ethersServiceProvider.provider?.send('wallet_addEthereumChain', [
          config,
        ]);
      }
    }
  };
  useEffect(() => {
    try {
      const networkName = getNetworkConfigsByEnv()[chainId ?? 1].nameForDisplay;
      navigate(`/${page}/${networkName}`);
      const activeOption = options.find((opt) => opt.key === networkName);
      if (activeOption) setActiveNetwork(activeOption);
    } catch (e) {
      setActiveNetwork(options[0]);
    }
  }, [chainId]);

  return (
    <FullScreenWidthContainer>
      <Banner
        slides={slides}
        options={options}
        activeOption={activeNetwork}
        setActiveOption={(value: OptionType) => {
          const config = Object.values(getNetworkConfigsByEnv()).find(
            (conf) => conf.nameForDisplay === value.key,
          ).config;
          handleChangeNetwork(config);
        }}
        isMobile={isMobile}
        path={path}
        page={page}
      />
    </FullScreenWidthContainer>
  );
};
