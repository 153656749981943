import { Flex } from '@bumper-dao/ui-kit';
import { AppearanceTypes } from 'react-toast-notifications';
import styled from 'styled-components';

import { getToastBoxShadow } from './funcs';

export const ToastContainer = styled(Flex)<{ appear: AppearanceTypes }>`
  align-items: flex-start;
  z-index: 99999999;
  width: 291px;
  height: fit-content;
  padding: 0.5em 0 1em 0;
  background-color: ${({ theme }) => theme.colors.primary2.deepTeal};
  box-shadow: ${getToastBoxShadow};
  border-radius: 0;
  margin: 24px;
`;
