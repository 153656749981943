import {
  Box,
  Button,
  ConfirmationBox,
  Flex,
  Heading,
  IconSize,
  PeriodCard,
  SliderCard,
  SummaryCard,
  UnitsCard,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { BigNumber, ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { ConfirmStakingProps, StakeDepositPageProps } from './types';

import BumpSquare from '../../assets/BUMPSquare.svg';
import { MainContainer } from '../../components/common/MainContainers';
import { Routes, subRoutes } from '../../core/config/routes';
import { BUMP } from '../../core/config/tokenNames';
import { WalletContext } from '../../core/config/walletContext';
import { useChainName } from '../../core/hooks/useChain';
import { StakeOptionType } from '../../core/interfaces';
import { StakingService } from '../../core/services/stakingService';
import { setIsOpenedWalletModal } from '../../core/state/actions/walletModalActions';
import { useAppSelector } from '../../core/state/hooks';
import { isFeatureEnabled } from '../../core/utils/features';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';
import {
  DepositLiqudityWalletContainer,
  DepositLiquidityWalletText,
} from '../EarnDepositFlow/styles';

const FLEXIBLE_OPTION_INDEX = 0;

export const StakeDeposit: React.FC<StakeDepositPageProps> = ({
  isVesting,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const history = useNavigate();
  const location = useLocation();
  const chainName = useChainName();
  const dispatch = useDispatch();
  const locationState = location.state as ConfirmStakingProps;
  const isWalletConnected = useContext(WalletContext).isWalletConnected;

  const stakingService = StakingService.getInstance();
  const bumpDetails = useAppSelector((state) => state.coin.coinDetails.BUMP);

  const defaultStakeAmount = locationState?.claimedAmount
    ? locationState.claimedAmount
    : locationState?.stakeAmount || +bumpDetails.balance / 2;

  const [activeTab, setActiveTab] = useState<0 | 1>(
    locationState?.activeOptionIndex === 0 ? 1 : 0,
  );
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [autoRenew, setAutoRenew] = useState<boolean>(
    !!locationState?.autorenew,
  );
  const [stakeAmount, setStakeAmount] = useState<number>(defaultStakeAmount);
  const [isFirstRender, setFirstRender] = useState<boolean>(
    locationState?.shouldDisable === undefined,
  );
  const [areTabsDisabled, disableTabs] = useState<boolean>(
    !!locationState?.shouldDisable,
  );

  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(
    locationState?.activeOptionIndex,
  );
  const [stakeOptions, setStakeOptions] = useState<StakeOptionType[]>([
    { periodInDays: 30, multiplier: 100 },
  ]);

  const stakeOption = stakeOptions[activeOptionIndex];

  const onCancel = () => {
    history(Routes.Stake);
  };

  const onClick = () => {
    const isFlexible = activeTab === 1;
    const props: ConfirmStakingProps = {
      token: bumpDetails,
      stakingType: activeTab,
      stakeAmount: locationState?.claimedAmount
        ? locationState.claimedAmount > stakeAmount
          ? stakeAmount
          : locationState.claimedAmount
        : stakeAmount,
      option: isFlexible ? stakeOptions[FLEXIBLE_OPTION_INDEX] : stakeOption,
      activeOptionIndex: isFlexible
        ? FLEXIBLE_OPTION_INDEX
        : activeOptionIndex + 1,
      autorenew: autoRenew,
      claimedAmount: locationState?.claimedAmount ?? locationState?.stakeAmount,
      walletAmount:
        defaultStakeAmount < stakeAmount
          ? ethers.utils
              .parseUnits((stakeAmount - defaultStakeAmount).toString(), 18)
              .toHexString()
          : BigNumber.from(0).toHexString(),
    };

    const nextPath = isVesting
      ? `${Routes.ClaimStake}/${chainName}/${subRoutes.Confirm}`
      : `${Routes.StakeFlow}/${chainName}/${subRoutes.Confirm}`;

    history(nextPath, {
      state: props,
      replace: true,
    });
  };

  useEffect(() => {
    (async () => {
      const options = await stakingService.getStakeOptions();
      setStakeOptions(options);
    })();
  }, []);
  useEffect(() => {
    setConfirmCheckbox(false);
  }, [activeTab]);
  useEffect(() => {
    if (
      isFirstRender &&
      defaultStakeAmount === (isNaN(stakeAmount) ? 0 : stakeAmount) &&
      !areTabsDisabled
    ) {
      disableTabs(true);
    } else {
      disableTabs(false);
      setFirstRender(false);
    }
  }, [stakeAmount]);

  return (
    <MainContainer
      maxWidth={isMobile ? '100%' : '708px !important'}
      style={{ gap: 0 }}
    >
      <Flex width="100%" justifyContent="start">
        <Heading
          as="h1"
          scale="xxl"
          color="secondary.white"
          mb="24px"
          width="60%"
        >
          Stake
        </Heading>
      </Flex>
      <Box mb="36px" width="100%">
        {!isWalletConnected && (
          <ConfirmationBox
            mb="36px"
            p="24px 32px"
            justifyContent={isMobile ? 'center' : 'auto'}
          >
            <DepositLiqudityWalletContainer alignItems="center">
              <DepositLiquidityWalletText>
                Connect your wallet:
              </DepositLiquidityWalletText>
              <Button
                primary
                size={IconSize.L}
                onClick={() => dispatch(setIsOpenedWalletModal(true))}
              >
                Connect
              </Button>
            </DepositLiqudityWalletContainer>
          </ConfirmationBox>
        )}
        {isWalletConnected && (
          <Flex justifyContent={isMobile ? 'center' : 'auto'}>
            <UnitsCard
              iconComponent={<img src={BumpSquare} />}
              pricePerOneToken={formatStringifyNumberToDot(
                bumpDetails.price,
                2,
              )}
              tokenName={'BUMP'}
              balance={formatStringifyNumberToDot(bumpDetails.balance)}
              balanceInUsd={formatStringifyNumberToDot(bumpDetails.value, 2)}
            />
          </Flex>
        )}
      </Box>
      <Box mb="36px" width="100%">
        <SliderCard
          tokenName={BUMP.symbol}
          icon={BumpSquare}
          onChange={(newValue: number) => {
            if (newValue < 0) return;
            if (
              newValue <=
              parseFloat(
                locationState?.claimedAmount
                  ? (
                      locationState.claimedAmount +
                      parseFloat(bumpDetails.balance)
                    ).toString()
                  : bumpDetails.balance,
              )
            ) {
              setStakeAmount(newValue);
            }
          }}
          value={stakeAmount}
          formattedValue={formatStringifyNumberToDot(stakeAmount.toString(), 4)}
          iconComponent={<img src={BumpSquare} />}
          max={parseFloat(
            locationState?.claimedAmount
              ? (
                  locationState.claimedAmount + parseFloat(bumpDetails.balance)
                ).toString()
              : bumpDetails.balance,
          )}
          min={0.0001}
          subTitle={
            'Move the slider to choose how much you want to stake or enter the amount in the field below. You must do either to proceed to the next step.'
          }
          title={'Stake Amount'}
          toUsd={(value: number) =>
            formatStringifyNumberToDot(
              (value * parseFloat(bumpDetails.price)).toString(),
              2,
            )
          }
          disabled={!isWalletConnected}
        />
      </Box>
      <Box mb="36px" width="100%">
        <PeriodCard
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          autorenew={autoRenew}
          setAutorenew={setAutoRenew}
          optionIndex={activeOptionIndex}
          setOptionIndex={setActiveOptionIndex}
          confirmed={confirmCheckbox}
          setConfirmed={setConfirmCheckbox}
          disabled={!isWalletConnected}
        />
      </Box>
      <Box mb="36px" width="100%">
        <SummaryCard
          currentStakeRate={'6.92'}
          periodType={
            activeTab === 1
              ? 'Flexible'
              : stakeOption
              ? `${stakeOption.periodInDays} days`
              : '0 days'
          }
          stakeAmount={formatStringifyNumberToDot(stakeAmount.toString())}
          stakeValue={formatStringifyNumberToDot(
            (stakeAmount + parseFloat(bumpDetails.price)).toString(),
          )}
          disabled={!isWalletConnected}
        />
      </Box>
      <Flex justifyContent="space-between" width="100%">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={
            (!confirmCheckbox && activeTab === 1) ||
            (activeTab === 0 && activeOptionIndex === undefined) ||
            !isFeatureEnabled('STAKING')
          }
          onClick={() => onClick()}
        >
          Next
        </Button>
      </Flex>
    </MainContainer>
  );
};
