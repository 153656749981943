import { lightColors } from '@bumper-dao/ui-kit';

import { createPixelInsetBorder } from '../../core/utils/createPixelInsetBorder';

export const createMarketWrapperBorder = (): string =>
  createPixelInsetBorder(
    lightColors.primary2.darkGreen,
    lightColors.primary2.darkSlateGray,
    lightColors.primary2.darkSlateGray,
    4,
  );

export const createMarketSwitcherItemBorder = (props: {
  active?: boolean;
}): string =>
  createPixelMarketWrapperBorder(
    lightColors.primary2.darkGreen,
    props.active
      ? lightColors.primary2.darkSlateGray
      : lightColors.primary2.midnightGreen,
    props.active
      ? lightColors.primary2.darkSlateGray
      : lightColors.primary2.midnightGreen,
    4,
    props.active,
  );

export const createPixelMarketWrapperBorder = (
  borderColor: string,
  insetTLColor: string,
  insetBRColor: string,
  borderWidth?: number,
  active?: boolean,
): string => {
  const width = borderWidth ? borderWidth : 2;

  return `${width}px 0px 0px 0px ${insetBRColor}, 
      -2px ${width}px 0px 0px ${insetBRColor}, 
      0px ${width}px 0px 4px ${insetBRColor},   
      -${width}px 0px 0px 0px ${insetTLColor}, 
      0px -${width}px 0px 0px ${insetTLColor}, 
      -${2 * width}px 0px 0px 0px ${borderColor}, 
      ${2 * width}px 0px 0px 0px ${borderColor}, 
      0px ${active ? '0' : 2 * width}px 0px 0px ${borderColor}, 
      0px -${2 * width}px 0px 0px ${borderColor}, 
      0px 0px 0px ${width}px ${borderColor}`;
};
