import {
  DarkInfoBox,
  Heading,
  IconSize,
  LargeText,
  SmallText,
  Tooltip,
  lightColors,
  Button,
  Select,
  OptionType,
  FlexWithGap,
} from '@bumper-dao/ui-kit';
import React, { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import {
  ContentWrapper,
  ContentValueWrapper,
  CoinKey,
  ContentHeadingWrapper,
  InfoBoxContent,
} from './styles';
import { ProtectAssetCardHeaderProps } from './types';

import { formatStringifyNumberToDot } from '../../core/utils/helpers';
import { IAssetBalanceCardProps } from '../../pages/ProtectAsset/types';

export const ProtectAssetCardContent: FC<IAssetBalanceCardProps> = ({
  data,
  tokenSymbol,
}) => {
  return (
    <ContentWrapper justifyContent="center">
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Total
          </Heading>
          <ContentValueWrapper>
            {parseFloat(data.totalBalance) > 0 ? (
              <FlexWithGap gap="4px" alignItems="baseline">
                <LargeText
                  fontWeight={500}
                  color={lightColors.typography.input}
                >
                  {formatStringifyNumberToDot(data.totalBalance)}
                </LargeText>
                <SmallText
                  color={lightColors.primary2.cambridgeBlue}
                  fontWeight={500}
                >
                  {tokenSymbol}
                </SmallText>
              </FlexWithGap>
            ) : (
              <LargeText fontWeight={500} color={lightColors.secondary.white}>
                -
              </LargeText>
            )}
          </ContentValueWrapper>
          {parseFloat(data.totalBalance) > 0 && (
            <SmallText color={lightColors.primary2.cambridgeBlue}>
              (${formatStringifyNumberToDot(data.totalBalanceInUSD, 2)})
            </SmallText>
          )}
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Average Floor
          </Heading>
          {parseFloat(data.avgFloor) > 0 ? (
            <LargeText fontWeight={500} color={lightColors.secondary.white}>
              ${formatStringifyNumberToDot(data.avgFloor)}
            </LargeText>
          ) : (
            <LargeText fontWeight={500} color={lightColors.secondary.white}>
              -
            </LargeText>
          )}
          {parseFloat(data.avgFloor) > 0 && (
            <ContentValueWrapper>
              <SmallText color={lightColors.secondary.green}>+0.0%</SmallText>
              <SmallText color={lightColors.primary2.cambridgeBlue}>
                (24h)
              </SmallText>
            </ContentValueWrapper>
          )}
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <ContentHeadingWrapper>
            <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
              Unprotected
            </Heading>
            <Tooltip tooltipValue={'fdsfdsg'} place="bottom" />
          </ContentHeadingWrapper>
          <ContentValueWrapper>
            {parseFloat(data.totalBalanceInUSD) > 0 ? (
              <FlexWithGap gap="4px" alignItems="baseline">
                <LargeText fontWeight={500} color={lightColors.secondary.red}>
                  {formatStringifyNumberToDot(data.balanceAmount)}
                </LargeText>
                <SmallText
                  color={lightColors.primary2.cambridgeBlue}
                  fontWeight={500}
                >
                  {tokenSymbol}
                </SmallText>
              </FlexWithGap>
            ) : (
              <LargeText fontWeight={500} color={lightColors.secondary.white}>
                -
              </LargeText>
            )}
          </ContentValueWrapper>
          {parseFloat(data.balanceAmount) > 0 && (
            <SmallText color="primary2.desaturatedCyan">
              (${formatStringifyNumberToDot(data.balanceAmountInUSD, 2)})
            </SmallText>
          )}
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <ContentHeadingWrapper>
            <Tooltip tooltipValue={'fdsfdsg'} place="bottom">
              <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
                Protected
              </Heading>
            </Tooltip>
          </ContentHeadingWrapper>
          <ContentValueWrapper>
            {parseFloat(data.protocolAmount) > 0 ? (
              <>
                <LargeText fontWeight={500} color={lightColors.secondary.green}>
                  {formatStringifyNumberToDot(data.protocolAmount)}
                </LargeText>
                <CoinKey>{tokenSymbol}</CoinKey>
              </>
            ) : (
              <LargeText fontWeight={500} color={lightColors.secondary.white}>
                -
              </LargeText>
            )}
          </ContentValueWrapper>
          {parseFloat(data.protocolAmount) > 0 && (
            <SmallText color="primary2.desaturatedCyan">
              (${formatStringifyNumberToDot(data.protocolAmountInUSD, 2)})
            </SmallText>
          )}
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        borderColor="primary2.darkGreen"
        minHeight="90px"
      >
        <InfoBoxContent>
          <Heading scale="md" color="primary2.desaturatedCyan">
            Positions
          </Heading>
          <LargeText fontWeight={500} color="secondary.white">
            {data.positionsCount}
          </LargeText>
        </InfoBoxContent>
      </DarkInfoBox>
    </ContentWrapper>
  );
};

export const ProtectAssetCardHeader: React.FC<ProtectAssetCardHeaderProps> = ({
  options,
  disabled,
  onChooseOption,
  onProtect,
}) => {
  const [activeValue, setActiveValue] = useState<OptionType>(options[0]);

  useEffect(() => {
    onChooseOption(activeValue);
  }, [activeValue]);
  return (
    <ContentWrapper justifyContent="space-between">
      <Select
        value={activeValue}
        options={options}
        withIcon
        topValue="12px"
        onValueChanged={setActiveValue}
        minHeight="48px"
      />
      <Button
        primary
        size={IconSize.L}
        padding="0.25rem 1rem"
        letterSpacing="0.03em"
        lineHeight="20px"
        minHeight="32px"
        disabled={disabled}
        onClick={() => {
          onProtect();
        }}
      >
        Protect
      </Button>
    </ContentWrapper>
  );
};
