import { ToastBoxShadowTypes } from './types';

export const getToastBoxShadow = ({ theme, appear }: ToastBoxShadowTypes) => {
  if (appear === 'error') {
    return `4px 0px 0px 0px ${theme.colors.primary2.deepTeal}, 
      0px 4px 0px 0px ${theme.colors.primary2.deepTeal}, 
      -4px 0px 0px 0px ${theme.colors.primary2.deepTeal}, 
      0px -4px 0px 0px ${theme.colors.primary2.deepTeal}, 
      -8px 0px 0px 0px ${theme.colors.secondary.red}, 
      8px 0px 0px 0px ${theme.colors.secondary.red}, 
      0px 8px 0px 0px ${theme.colors.secondary.red}, 
      0px -8px 0px 0px ${theme.colors.secondary.red}, 
      0px 0px 0px 4px ${theme.colors.secondary.red}`;
  }
  if (appear === 'success') {
    return `4px 0px 0px 0px ${theme.colors.primary2.deepTeal}, 
      0px 4px 0px 0px ${theme.colors.primary2.deepTeal}, 
      -4px 0px 0px 0px ${theme.colors.primary2.deepTeal}, 
      0px -4px 0px 0px ${theme.colors.primary2.deepTeal}, 
      -8px 0px 0px 0px ${theme.colors.secondary.green}, 
      8px 0px 0px 0px ${theme.colors.secondary.green}, 
      0px 8px 0px 0px ${theme.colors.secondary.green}, 
      0px -8px 0px 0px ${theme.colors.secondary.green}, 
      0px 0px 0px 4px ${theme.colors.secondary.green}`;
  }
  return `4px 0px 0px 0px ${theme.colors.primary2.deepTeal}, 
      0px 4px 0px 0px ${theme.colors.primary2.deepTeal}, 
      -4px 0px 0px 0px ${theme.colors.primary2.deepTeal}, 
      0px -4px 0px 0px ${theme.colors.primary2.deepTeal}, 
      -8px 0px 0px 0px ${theme.colors.primary1.vividTangelo}, 
      8px 0px 0px 0px ${theme.colors.primary1.vividTangelo}, 
      0px 8px 0px 0px ${theme.colors.primary1.vividTangelo}, 
      0px -8px 0px 0px ${theme.colors.primary1.vividTangelo}, 
      0px 0px 0px 4px ${theme.colors.primary1.vividTangelo}`;
};
