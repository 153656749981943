import {
  AutoRenewCard,
  Box,
  Button,
  Checkbox,
  ConfirmCard,
  Flex,
  IconSize,
  LargeText,
  Link,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import ProtectIcon from '@bumper-dao/ui-kit/dist/images/protect.png';
import { useWeb3React } from '@web3-react/core';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { ConfirmTitle, SummaryBottom } from './styles';
import { IAutoRenew } from './types';

import HomeIco from '../../assets/48px/home.svg';
import { MainContainer } from '../../components/common/MainContainers';
import {
  learnMoreLink,
  privacyPolicyLink,
  disclaimerLink,
  feedbackLink,
} from '../../core/config/links';
import { Routes, subRoutes } from '../../core/config/routes';
import { getNetworkConfigsByEnv } from '../../core/config/supportedChains';
import { ETH, USDCoin } from '../../core/config/tokenNames';
import { tokensIcons } from '../../core/config/tokensIcons';
import { getFlowAssetIcon } from '../../core/funcs/getFlowAssetIcon';
import { TakerPositionService } from '../../core/services/takerPositionService';
import { isFeatureEnabled } from '../../core/utils/features';
import { shortenTransactionHash } from '../../core/utils/helpers';
import { getEtherscanLink } from '../ProtectClaimClose/func';

export const ConfirmProtectAutoRenew = () => {
  const [confirm, setConfirm] = useState<boolean>(false);

  const takerPositionService = TakerPositionService.getInstance();
  const { isMobile } = useMatchBreakpoints();

  const { chainId } = useWeb3React();
  const chainName = useMemo(() => {
    if (chainId) return getNetworkConfigsByEnv()[chainId ?? 1].nameForDisplay;
  }, [chainId]);

  const { position, protectedToken, toggleState } = useLocation()
    .state as IAutoRenew;

  const navigate = useNavigate();
  const onAutoRenew = async () => {
    try {
      navigate(
        `${Routes.Protect}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Approve}`,
        {
          replace: true,
        },
      );

      const tx = await takerPositionService.toggleAutoRenew(
        position.id,
        USDCoin,
        protectedToken,
      );

      navigate(
        `${Routes.Protect}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Processing}`,
        {
          replace: true,
        },
      );

      await tx.wait();

      navigate(
        `${Routes.Protect}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Summary}`,
        {
          replace: true,
          state: {
            hash: tx.hash,
            toggleState: toggleState,
          },
        },
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <MainContainer
      maxWidth={isMobile ? '100%' : '575px !important'}
      style={{ gap: '40px' }}
    >
      <ConfirmTitle>Confirmation</ConfirmTitle>
      <AutoRenewCard
        icon={
          <Box
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-50px',
            }}
          >
            {getFlowAssetIcon(ProtectIcon, tokensIcons[ETH.symbol])}
          </Box>
        }
        text="By turning on auto-renew now, your protection policy will be renewed for the same term period at the end of your current term."
        autoRenew={toggleState}
      />
      <Flex alignItems="center" width="100%">
        <Checkbox onClick={() => setConfirm((prevState) => !prevState)} />
        <LargeText ml="20px" color="typography.earnDepositTextDescription">
          By checking this box you acknowledge and agree to our {/* <br /> */}
          <Link color="secondary.green" href={learnMoreLink}>
            Terms and Conditions{'  '}
          </Link>
          ,{' '}
          <Link color="secondary.green" href={privacyPolicyLink}>
            {' '}
            Privacy Policy
          </Link>
          , and{' '}
          <Link color="secondary.green" href={disclaimerLink}>
            Disclaimer
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex justifyContent="space-between" width="100%">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => {
            navigate(`${Routes.ProtectAssetsDashboard}/${chainName}`);
          }}
        >
          Cancel
        </Button>

        <Button
          primary
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!confirm}
          onClick={onAutoRenew}
        >
          Confirm
        </Button>
      </Flex>
    </MainContainer>
  );
};

export const AutoRenewSummary = () => {
  const { chainId } = useWeb3React();
  const { isMobile } = useMatchBreakpoints();

  const navigate = useNavigate();

  const chainName = useMemo(() => {
    if (chainId) return getNetworkConfigsByEnv()[chainId ?? 1].nameForDisplay;
  }, [chainId]);

  const { toggleState, hash } = useLocation().state as IAutoRenew;
  console.log(toggleState);
  return (
    <MainContainer
      maxWidth={isMobile ? '100%' : '575px !important'}
      style={{ gap: '40px' }}
    >
      <ConfirmTitle>Auto-renew</ConfirmTitle>
      <ConfirmCard
        iconComponent={
          <Box
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-50px',
            }}
          >
            {getFlowAssetIcon(ProtectIcon, tokensIcons[ETH.symbol])}
          </Box>
        }
        linkUrl={getEtherscanLink(
          hash ?? '',
          getNetworkConfigsByEnv()[chainId ?? 1].config,
        )}
        linkText={shortenTransactionHash(hash ?? '')}
        px={isMobile ? '8px' : ''}
      >
        <SummaryBottom style={{ fontSize: '16px', padding: 0 }}>
          <span>Auto-renew:</span>
          <span>{(toggleState && 'ON') || 'OFF'}</span>
        </SummaryBottom>
      </ConfirmCard>
      <Flex
        width="100%"
        justifyContent={
          isFeatureEnabled('SUPPORT') ? 'space-between' : 'center'
        }
      >
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={() => {
            navigate(`${Routes.Dashboard}/${chainName}`, {
              replace: true,
            });
          }}
          height="48px"
        >
          <img src={HomeIco} alt="home-ico" />
          <span>Dashboard</span>
        </Button>
        {isFeatureEnabled('SUPPORT') && (
          <Button
            secondary
            size={isMobile ? IconSize.L : IconSize.XL}
            onClick={() => {
              window.open(feedbackLink, '_blank');
            }}
            height="48px"
          >
            Feedback
          </Button>
        )}
      </Flex>
    </MainContainer>
  );
};
