import styled from 'styled-components';

export const NoPositionsWrap = styled.div`
  max-width: 686px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Share Tech Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.03em;
  color: ${({ theme }) => theme.colors.secondary.white};
  gap: 24px;
  height: 674px;
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 332px;
    height: auto;
  }
`;

export const Heading = styled.h2`
  font-size: 40px;
  font-weight: 400;
`;

export const ButtonText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
