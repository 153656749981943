import {
  Calculation__factory,
  IERC20__factory,
  Incentives__factory,
} from '@bumper-dao/contract-interfaces';
import { BigNumber, ContractTransaction, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';
import { MarketService } from './marketService';
import { ProtocolConfigService } from './protocolConfigService';
import { getContractsAddresses, tryPermit } from './servicesUtils';
import { UniswapService } from './uniswapService';

import { IConfirmClaimCloseData } from '../../pages/ProtectClaimClose/types';
import { ONE_WETH } from '../config/constants/coins';
import { marketsTokensConfig } from '../config/constants/tokens';
import { CONTRACTS } from '../config/contractAddresses';
import {
  DEFAULT_DECIMAL_VALUE,
  NULLABLE_ADDRESS,
} from '../config/formulaConstants';
import { BUMP, ETH, USDCoin, WETH } from '../config/tokenNames';
import {
  ICoin,
  PositionExtendedType,
  TakerPositionType,
  IPosition,
  LargeTotalCardType,
  TotalCardType,
} from '../interfaces';
import { client } from '../providers/graphql';
import { GetMarketTakerPositionsByDate } from '../providers/graphql/__generated__/GetMarketTakerPositionsByDate';
import { GetMarketTakerPositionsByOwner } from '../providers/graphql/__generated__/GetMarketTakerPositionsByOwner';
import {
  GET_TAKER_POSITIONS_BY_OWNER,
  GET_TAKER_POSITIONS_BY_DATE,
} from '../providers/graphql/queries';
import { CoinDetailsReducerType } from '../state/reducers/coinReducer';
import { convertBumpToDollars, getTokenPrice } from '../utils/helpers';

export class TakerPositionService {
  private static instance: TakerPositionService;
  private ethersServiceProvider: EthersServiceProvider;
  private protocolConfigServices: ProtocolConfigService;
  private marketServices: MarketService;
  private uniswapServices: UniswapService;

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
    this.protocolConfigServices = ProtocolConfigService.getInstance();
    this.marketServices = MarketService.getInstance();
    this.uniswapServices = UniswapService.getInstance();
  }

  public static getInstance(): TakerPositionService {
    if (!TakerPositionService.instance) {
      TakerPositionService.instance = new TakerPositionService();
    }
    return TakerPositionService.instance;
  }

  private async getAccount(address?: string): Promise<string> {
    return address ?? (await this.ethersServiceProvider.getUserAddress());
  }

  public async getTakerPosition(
    takerPositionId: number,
    marketAddress: string,
  ): Promise<Omit<TakerPositionType, 'positionOpenTimestamp'>> {
    const position = await this.marketServices
      .getMarketContract(marketAddress)
      .getTakerPosition(takerPositionId);

    const AUTORENEW_TERMS_OFFSET = 1;
    const AUTORENEW_TERMS_MASK = 0xff << AUTORENEW_TERMS_OFFSET;
    const terms =
      (+position.flags & AUTORENEW_TERMS_MASK) >> AUTORENEW_TERMS_OFFSET;
    const pastTerms = terms > 0 ? terms : 1;

    const hasAutoRenew = (flags: BigNumber): boolean => {
      return flags.and(0x1).gt(0);
    };
    return {
      ...position,
      pastTerms,
      autorenew: hasAutoRenew(position.flags),
      risk: position.risk / 10000,
      bumpBondAmount: position.bumpAmount,
      premium: 0,
    };
  }

  private async getLatestEvents() {
    const { ADAPTERS } = await getContractsAddresses();
    const latestBlock =
      (await this.ethersServiceProvider.provider?.getBlockNumber()) || 1000;
    const adaptersLibrary = marketsTokensConfig
      .map(({ token }) => {
        return ADAPTERS[token.symbol];
      })
      .filter((adapter) => !!adapter)
      .map((adapter) => Object.values(adapter))
      .flat();
    const filteredPositions = await Promise.all(
      adaptersLibrary.map(async (adapterAddress) => {
        try {
          const adapterContract = await this.marketServices.getAdapterContract(
            adapterAddress,
          );
          const tokenAddress = await adapterContract.ASSET_ADDRESS();
          const protectedEventsFilter = await adapterContract.filters.Protect(
            await this.getAccount(),
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          );

          const protectedEvents = await adapterContract
            .queryFilter(protectedEventsFilter, latestBlock - 1000, latestBlock)
            .then((data) => {
              return Promise.all(
                data.map<Promise<IPosition>>(async (event) => ({
                  id: +event.args.id,
                  marketAddress: await adapterContract.market(),
                  tokenAddress,
                  boost: event.args.boost,
                  incentives: event.args.incentives,
                  positionOpenTimestamp: (await event.getBlock()).timestamp,
                })),
              );
            });

          return [...protectedEvents];
        } catch (e) {
          console.error(e);
          return [];
        }
      }),
    );

    return filteredPositions.flat();
  }

  public async toggleAutoRenew(
    takerPositionId: number,
    token: ICoin,
    tokenForGetMarket: ICoin,
  ): Promise<ContractTransaction> {
    const { ADAPTERS } = await getContractsAddresses();
    const tokenFormatted =
      tokenForGetMarket.symbol === ETH.symbol ? WETH : tokenForGetMarket;
    if (!tokenFormatted)
      throw new Error('INDX: token for getting market not found');
    const adapterAddress = ADAPTERS[tokenFormatted.symbol][token.symbol];
    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .toggleTakerAutorenew(takerPositionId);
  }

  public async getPremiumOnClose(
    takerPositionId: number,
    tokenAddress: string,
  ): Promise<BigNumber> {
    const marketAddress = await this.protocolConfigServices.getMarket(
      tokenAddress,
    );
    try {
      return await this.marketServices
        .getMarketContract(marketAddress)
        .premiumOnClose(takerPositionId);
    } catch (e) {
      return ethers.constants.Zero;
    }
  }

  // @dev premium with penalty has been removed from contracts
  // public async getPremiumWithPenalty(
  //   takerPositionId: number,
  //   tokenAddress: string,
  // ): Promise<BigNumber> {
  //   const marketAddress = await this.protocolConfigServices.getMarket(
  //     tokenAddress,
  //   );
  //   return await this.marketServices
  //     .getMarketContract(marketAddress)
  //     .premiumWithPenaulty(takerPositionId);
  // }

  public async getIncentiveValus(
    chainId: number,
    tokenAddress: string,
    tokenAmount: BigNumber,
  ): Promise<{ coordination: number; boost: number }> {
    const incentiveContract = await Incentives__factory.connect(
      CONTRACTS[chainId].CONTRACT_ADDRESS.Incentives,
      this.ethersServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );

    const boost = await incentiveContract.boostForTaker(
      tokenAddress,
      tokenAmount,
    );
    const coordination = await incentiveContract.coordinationForTaker(
      tokenAddress,
      tokenAmount,
    );

    return {
      coordination: parseFloat(ethers.utils.formatUnits(coordination)),
      boost: parseFloat(ethers.utils.formatUnits(boost)),
    };
  }

  //open taker position using native token
  public async protectNative(
    amount: string,
    bumpBondAmount: BigNumber,
    term: number,
    autorenew: boolean,
    risk: number,
    token: ICoin,
  ): Promise<ContractTransaction> {
    const { ADAPTERS, TOKEN_DETAILS } = await getContractsAddresses();
    const marketAddress = await this.protocolConfigServices.getMarket(
      TOKEN_DETAILS[token.symbol === ETH.symbol ? WETH.symbol : token.symbol]
        .address,
    );
    const bondAddress = await this.marketServices
      .getMarketContract(marketAddress)
      .BOND_ADDRESS();
    const adapterAddress =
      ADAPTERS[token.symbol === ETH.symbol ? WETH.symbol : token.symbol][
        USDCoin.symbol
      ];
    const amountDecimal = ethers.utils.parseUnits(amount, token.decimals);
    if (
      (
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          bondAddress,
          TOKEN_DETAILS[BUMP.symbol].address,
        )
      ).gte(bumpBondAmount)
    ) {
      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .protectNative(risk, term, autorenew, { value: amountDecimal });
    }
    const approveBumpTx = await this.ethersServiceProvider.approveTokenAmount(
      bumpBondAmount.toHexString(),
      bondAddress,
      TOKEN_DETAILS[BUMP.symbol].address,
    );
    await approveBumpTx.wait();
    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .protectNative(risk, term, autorenew, { value: amountDecimal });
  }

  //open taker position
  public async protect(
    amount: string,
    bumpBondAmount: BigNumber,
    term: number,
    risk: number,
    autorenew: boolean,
    token: ICoin,
  ): Promise<ContractTransaction> {
    const { TOKEN_DETAILS, ADAPTERS } = await getContractsAddresses();
    const marketAddress = await this.protocolConfigServices.getMarket(
      TOKEN_DETAILS[token.symbol].address,
    );
    const bondAddress = await this.marketServices
      .getMarketContract(marketAddress)
      .BOND_ADDRESS();

    const adapterAddress =
      ADAPTERS[token.symbol === ETH.symbol ? WETH.symbol : token.symbol].USDC;

    const amountDecimal = ethers.utils.parseUnits(amount, token.decimals);
    if (
      (
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          adapterAddress,
          TOKEN_DETAILS[token.symbol].address,
        )
      ).gte(amountDecimal) &&
      (
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          bondAddress,
          TOKEN_DETAILS[BUMP.symbol].address,
        )
      ).gte(bumpBondAmount)
    ) {
      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .protect(amountDecimal, risk, term, autorenew);
    }

    const permitToken = await tryPermit(
      this.ethersServiceProvider.provider,
      TOKEN_DETAILS[token.symbol].address,
      await this.getAccount(),
      amountDecimal,
      2,
      token.name === ETH.name ? WETH.name : token.name,
      adapterAddress,
    );

    const permitBump = await tryPermit(
      this.ethersServiceProvider.provider,
      TOKEN_DETAILS[BUMP.symbol].address,
      await this.getAccount(),
      bumpBondAmount,
      1,
      'BUMP',
      bondAddress,
    );

    if (permitToken && permitBump) {
      const abiCoder = ethers.utils.defaultAbiCoder;
      const encodedPermitToken = abiCoder.encode(
        ['uint', 'uint8', 'bytes32', 'bytes32'],
        [permitToken.deadline, permitToken.v, permitToken.r, permitToken.s],
      );
      const encodedPermitBump = abiCoder.encode(
        ['uint', 'uint8', 'bytes32', 'bytes32'],
        [permitBump.deadline, permitBump.v, permitBump.r, permitBump.s],
      );
      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .protectWithPermitWithAutoBondingPermit(
          amountDecimal,
          risk,
          term,
          autorenew,
          bumpBondAmount,
          encodedPermitToken,
          encodedPermitBump,
        );
    } else if (permitToken && !permitBump) {
      if (
        !(
          await this.ethersServiceProvider.approveAmount(
            await this.getAccount(),
            bondAddress,
            TOKEN_DETAILS[BUMP.symbol].address,
          )
        ).gte(bumpBondAmount)
      ) {
        const approveBumpTx =
          await this.ethersServiceProvider.approveTokenAmount(
            bumpBondAmount.toHexString(),
            bondAddress,
            TOKEN_DETAILS[BUMP.symbol].address,
          );
        await approveBumpTx.wait();
      }
      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .protectWithPermit(
          amountDecimal,
          risk,
          term,
          autorenew,
          permitToken.deadline,
          permitToken.v,
          permitToken.r,
          permitToken.s,
        );
    } else if (permitBump && !permitToken) {
      if (
        !(
          await this.ethersServiceProvider.approveAmount(
            await this.getAccount(),
            adapterAddress,
            TOKEN_DETAILS[token.symbol].address,
          )
        ).gte(amountDecimal)
      ) {
        const approveTokenTx =
          await this.ethersServiceProvider.approveTokenAmount(
            amountDecimal.toHexString(),
            adapterAddress,
            TOKEN_DETAILS[token.symbol].address,
          );
        await approveTokenTx.wait();
      }
      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .protectWithAutoBondingPermit(
          amountDecimal,
          risk,
          term,
          autorenew,
          bumpBondAmount,
          permitBump.deadline,
          permitBump.v,
          permitBump.r,
          permitBump.s,
        );
    }

    if (
      !(
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          bondAddress,
          TOKEN_DETAILS[BUMP.symbol].address,
        )
      ).gte(bumpBondAmount)
    ) {
      const approveBumpTx = await this.ethersServiceProvider.approveTokenAmount(
        bumpBondAmount.toHexString(),
        bondAddress,
        TOKEN_DETAILS[BUMP.symbol].address,
      );
      await approveBumpTx.wait();
    }

    if (
      !(
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          adapterAddress,
          TOKEN_DETAILS[token.symbol].address,
        )
      ).gte(amountDecimal)
    ) {
      const approveTokenTx =
        await this.ethersServiceProvider.approveTokenAmount(
          amountDecimal.toHexString(),
          adapterAddress,
          TOKEN_DETAILS[token.symbol].address,
        );
      await approveTokenTx.wait();
    }

    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .protect(amountDecimal, risk, term, autorenew);
  }

  //close taker position
  public async closeTakerPosition(
    takerPositionId: number,
    token: ICoin,
    unwrap: boolean,
    withdrawBond: boolean,
  ): Promise<ContractTransaction> {
    const { ADAPTERS } = await getContractsAddresses();
    const adapterAddress =
      ADAPTERS[token.symbol === ETH.symbol ? WETH.symbol : token.symbol][
        USDCoin.symbol
      ];
    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .close(takerPositionId, unwrap, withdrawBond);
  }

  //claim part of protected tokens
  public async claim(
    takerPositionId: number,
    token: ICoin,
    withdrawBond: boolean,
  ): Promise<ContractTransaction> {
    const { ADAPTERS } = await getContractsAddresses();
    const adapterAddress =
      ADAPTERS[token.symbol === ETH.symbol ? WETH.symbol : token.symbol][
        USDCoin.symbol
      ];
    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .claim(takerPositionId, withdrawBond);
  }

  //close data
  public async getCloseData(
    chainId: number,
    id: number,
    tokenAddress: string,
    token: ICoin,
    amount: BigNumber,
  ): Promise<IConfirmClaimCloseData> {
    // @todo: need to change to mapping
    // const protocolConfig = await this.protocolConfigServices.getConfigByToken(
    //   tokenAddress,
    // );
    const premium = await this.getPremiumOnClose(id, tokenAddress);
    const price = await getTokenPrice(tokenAddress, ONE_WETH).catch(
      () => ethers.constants.One,
    );
    const bumpPrice = ethers.utils.parseUnits(
      await convertBumpToDollars('1'),
      6,
    );

    const estValue = amount.mul(price).div(10 ** 6);
    const fee = ethers.utils.parseUnits('2.5', 4);

    const { TOKEN_DETAILS } = await getContractsAddresses();
    const bond = (
      await this.marketServices
        .getMarketContract(
          await this.protocolConfigServices.getMarket(
            TOKEN_DETAILS[token.symbol].address,
          ),
        )
        .getTakerPosition(id)
    ).bumpAmount;

    const { boost, coordination } = await this.getIncentiveValus(
      chainId,
      tokenAddress,
      amount,
    );

    return {
      amount: amount,
      fee: fee,
      feeInUSD: fee
        .mul(price)
        .div(10 ** 6)
        .toString(),
      amountValue: estValue,
      premium: premium,
      premiumInUSD: premium
        .mul(price)
        .div(10 ** 6)
        .toString(),
      price: price,
      bondValue: bond.toString(),
      bondValueInUSD: bond
        .mul(bumpPrice)
        .div(10 ** 6)
        .toString(),
      boost: boost.toString(),
      coordination: coordination.toString(),
    };
  }

  //emergency close taker position
  public async cancelTakerPosition(
    takerPositionId: number,
    token: ICoin,
  ): Promise<ContractTransaction> {
    const { ADAPTERS } = await getContractsAddresses();
    const adapterAddress = ADAPTERS[token.symbol][USDCoin.symbol];
    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .cancel(takerPositionId, false);
  }

  public async getOpenPositions(
    account?: string,
  ): Promise<Map<string, PositionExtendedType<TakerPositionType>[]>> {
    const { TOKEN_DETAILS } = await getContractsAddresses();
    const marketsLibrary = (
      await Promise.allSettled(
        marketsTokensConfig.map(({ token }) =>
          this.protocolConfigServices.getMarket(
            TOKEN_DETAILS[token.symbol].address,
          ),
        ),
      )
    )
      .filter(({ status }) => status === 'fulfilled')
      .map((data) => {
        if (data.status === 'fulfilled') {
          return data.value.toLowerCase();
        }
      });
    const combinedData = new Map<
      string,
      PositionExtendedType<TakerPositionType>[]
    >();
    const result = await client.query<GetMarketTakerPositionsByOwner>({
      query: GET_TAKER_POSITIONS_BY_OWNER,
      variables: { address: await this.getAccount(account) },
    });

    const openPositions = await Promise.all(
      result.data.marketTakerPositions
        .filter(
          (pos) =>
            !pos.isCanceled &&
            marketsLibrary.includes(pos.market.toLowerCase()),
        )
        .map(async (pos): Promise<IPosition> => {
          const marketContract = await this.marketServices.getMarketContract(
            pos.market,
          );
          const tokenAddress = await marketContract.ASSET_ADDRESS();
          return {
            id: +pos.positionId,
            marketAddress: pos.market,
            tokenAddress,
            boost: pos.boost,
            incentives: pos.incentives,
            positionOpenTimestamp: pos.positionOpenTimestamp,
          };
        }),
    );
    const positionsFromEvents = await this.getLatestEvents();
    const combinedPositions = [
      ...openPositions,
      ...positionsFromEvents.filter(
        (event) =>
          !openPositions.find(
            (pos) =>
              pos.id === event.id &&
              pos.marketAddress.toLowerCase() ===
                event.marketAddress.toLowerCase(),
          ),
      ),
    ];
    const positionsData: TakerPositionType[] = await Promise.all(
      combinedPositions.map(async (pos) => ({
        ...(await this.getTakerPosition(pos.id, pos.marketAddress)),
        positionOpenTimestamp: pos.positionOpenTimestamp,
      })),
    );
    const tokensSymbols: string[] = await Promise.all(
      combinedPositions.map(async (pos) => {
        const symbol = await IERC20__factory.connect(
          pos.tokenAddress,
          this.ethersServiceProvider
            .provider as ethers.providers.JsonRpcProvider,
        ).symbol();
        return symbol === 'TestToken' ? 'WETH' : symbol;
      }),
    );

    combinedPositions.forEach((pos, index) => {
      const newPosition: PositionExtendedType<TakerPositionType> = {
        id: pos.id,
        marketAddress: pos.marketAddress,
        tokenAddress: pos.tokenAddress,
        tokenSymbol: tokensSymbols[index],
        boost: pos.boost,
        incentives: pos.incentives,
        ...positionsData[index],
      };
      const prev = combinedData.get(tokensSymbols[index]);
      if (
        newPosition.owner !== NULLABLE_ADDRESS &&
        newPosition.assetAmount.gt(0)
      )
        combinedData.set(tokensSymbols[index], [...(prev || []), newPosition]);
    });
    return combinedData;
  }

  public async calculateTakerTotalPositionsData(
    tokensDetails: CoinDetailsReducerType,
  ): Promise<{
    result: TotalCardType[];
    resultForLargeCard: LargeTotalCardType;
  }> {
    const { TOKEN_DETAILS } = await getContractsAddresses();
    const positions = await this.getOpenPositions();
    const tokensSymbols = Array.from(positions.keys());
    const totalPositionsData = tokensSymbols.map((symbol1) => {
      const symbol = symbol1 === 'TestToken' ? WETH.symbol : symbol1;
      const unprotectedSummary =
        symbol === WETH.symbol
          ? ethers.utils.formatUnits(
              tokensDetails[symbol].balanceDecimal.add(
                tokensDetails[ETH.symbol].balanceDecimal,
              ),
            )
          : tokensDetails[symbol]?.balance || '0.00';
      const positionsData = positions.get(symbol) || [];
      const unprotectedAmount = ethers.utils.parseUnits(
        parseFloat(unprotectedSummary).toFixed(
          TOKEN_DETAILS[symbol]?.decimal || 18,
        ),
        DEFAULT_DECIMAL_VALUE,
      );
      const protectedAmount = positionsData.reduce(
        (prev, pos) => prev.add(pos.assetAmount),
        ethers.constants.Zero,
      );
      const totalAmount = unprotectedAmount.add(protectedAmount);
      const averageFloor = positionsData
        .reduce((prev, curr) => prev.add(curr.floor), ethers.constants.Zero)
        .div(positionsData.length > 0 ? positionsData.length : 1);

      const now = Date.now();

      const fixed = positionsData.reduce(
        (prev, curr) =>
          now < (curr.positionOpenTimestamp + curr.term * 86400) * 1000
            ? prev.add(curr.assetAmount)
            : prev,
        ethers.constants.Zero,
      );

      const flexible = positionsData.reduce(
        (prev, curr) =>
          now >= (curr.positionOpenTimestamp + curr.term * 86400) * 1000
            ? prev.add(curr.assetAmount)
            : prev,
        ethers.constants.Zero,
      );

      return {
        unprotectedAmount,
        protectedAmount,
        totalAmount,
        averageFloor,
        fixed,
        flexible,
        token: { ...TOKEN_DETAILS[symbol], ...tokensDetails[symbol] },
      };
    });

    if (totalPositionsData.length === 0)
      return await this.getDefaultTotalData(tokensDetails);

    const result = totalPositionsData.map<TotalCardType>(
      ({
        totalAmount,
        unprotectedAmount,
        protectedAmount,
        token,
        averageFloor,
        fixed,
        flexible,
      }) => {
        const priceDecimal = ethers.utils.parseUnits(
          tokensDetails[token.symbol]?.price || '0.00',
          TOKEN_DETAILS[USDCoin.symbol].decimal,
        );
        console.log();
        return {
          totalAmount,
          assetBalance: unprotectedAmount,
          investedAmount: protectedAmount,
          price: tokensDetails[token.symbol]?.price || '0.00',
          totalAmountInUSDC: ethers.utils.formatUnits(
            totalAmount.gt(0)
              ? totalAmount.mul(priceDecimal)
              : BigNumber.from(0),
            DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
          ),
          average: ethers.utils.formatUnits(
            averageFloor,
            DEFAULT_DECIMAL_VALUE,
          ),
          assetBalanceInUSDC: ethers.utils.formatUnits(
            unprotectedAmount.gt(0)
              ? unprotectedAmount.mul(priceDecimal)
              : BigNumber.from(0),
            DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
          ),
          investedAmountInUSDC: ethers.utils.formatUnits(
            protectedAmount.gt(0)
              ? protectedAmount.mul(priceDecimal)
              : BigNumber.from(0),
            DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
          ),
          fixedInUSDC: ethers.utils.formatUnits(
            fixed.gt(0) ? fixed.mul(priceDecimal) : BigNumber.from(0),
            DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
          ),
          flexibleInUSDC: ethers.utils.formatUnits(
            flexible.gt(0) ? flexible.mul(priceDecimal) : BigNumber.from(0),
            DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
          ),
          totalYield: 0,
          totalYieldInUSD: 0,
          token: {
            symbol: token.symbol === WETH.symbol ? 'ETH' : token.symbol,
            name: token.symbol === WETH.symbol ? 'Ether' : token.name,
            decimals: token.decimal,
          },
        };
      },
    );
    const totalAmountsInUSDCForLargeCard = result.reduce(
      (prev, data) => {
        return {
          unprotectedAmountInUSDC:
            prev.unprotectedAmountInUSDC + parseFloat(data.assetBalanceInUSDC),
          protectedAmountInUSDC:
            prev.protectedAmountInUSDC + parseFloat(data.investedAmountInUSDC),
          totalAmountInUSDC:
            prev.totalAmountInUSDC + parseFloat(data.totalAmountInUSDC),
          fixedInUSDC: prev.fixedInUSDC + parseFloat(data.fixedInUSDC),
          flexibleInUSDC: prev.flexibleInUSDC + parseFloat(data.flexibleInUSDC),
        };
      },
      {
        unprotectedAmountInUSDC: 0,
        protectedAmountInUSDC: 0,
        totalAmountInUSDC: 0,
        fixedInUSDC: 0,
        flexibleInUSDC: 0,
      },
    );
    const resultForLargeCard: LargeTotalCardType = {
      assetBalanceInUSDC:
        totalAmountsInUSDCForLargeCard.unprotectedAmountInUSDC.toString(),
      investedAmountInUSDC:
        totalAmountsInUSDCForLargeCard.protectedAmountInUSDC.toString(),
      totalAmountInUSDC:
        totalAmountsInUSDCForLargeCard.totalAmountInUSDC.toString(),
      fixedInUSDC: totalAmountsInUSDCForLargeCard.fixedInUSDC.toString(),
      flexibleInUSDC: totalAmountsInUSDCForLargeCard.flexibleInUSDC.toString(),
      positionsCount: tokensSymbols.reduce((prev, symbol) => {
        return (positions.get(symbol) || []).length + prev;
      }, 0),
    };
    return { result, resultForLargeCard };
  }

  public async getPositionsByDate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    start?: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    end?: number,
  ): Promise<PositionExtendedType<TakerPositionType>[]> {
    const result = await client.query<GetMarketTakerPositionsByDate>({
      query: GET_TAKER_POSITIONS_BY_DATE,
      variables: { limit: 1000 },
    });

    const positionsData = await Promise.all(
      result.data.marketTakerPositions.map(async (pos) => {
        const position = await this.getTakerPosition(
          +pos.positionId,
          pos.market,
        );
        const marketContract = await this.marketServices.getMarketContract(
          pos.market,
        );
        const tokenAddress = await marketContract.ASSET_ADDRESS();
        const tokenSymbol = await IERC20__factory.connect(
          tokenAddress,
          this.ethersServiceProvider
            .provider as ethers.providers.JsonRpcProvider,
        ).symbol();

        return {
          id: +pos.positionId,
          marketAddress: pos.market.address,
          tokenAddress: tokenAddress,
          tokenSymbol: tokenSymbol,
          boost: pos.boost,
          incentives: pos.incentives,
          ...position,
          premium: pos.premium,
          positionOpenTimestamp: +pos.positionOpenTimestamp,
        };
      }),
    );
    return positionsData;
  }

  private async getDefaultTotalData(
    tokensDetails: CoinDetailsReducerType,
  ): Promise<{
    result: TotalCardType[];
    resultForLargeCard: LargeTotalCardType;
  }> {
    const tokens = [ETH, ...marketsTokensConfig.map((data) => data.token)];
    const balanceInUSD = tokens
      .map((token) => tokensDetails[token.symbol].value)
      .reduce(
        (prev, value) => (parseFloat(value) + parseFloat(prev)).toString(),
        '0',
      );
    const resultForLargeCard: LargeTotalCardType = {
      assetBalanceInUSDC: balanceInUSD,
      totalAmountInUSDC: balanceInUSD,
      investedAmountInUSDC: '0',
      positionsCount: 0,
      fixedInUSDC: '0',
      flexibleInUSDC: '0',
    };
    return {
      result: [
        {
          // HUB to show ETH
          token: {
            name: tokensDetails.ETH.name,
            decimals: tokensDetails.WETH.decimals,
            symbol: tokensDetails.ETH.symbol,
          },
          totalAmount: tokensDetails[WETH.symbol].balanceDecimal.add(
            tokensDetails[ETH.symbol].balanceDecimal,
          ),
          totalAmountInUSDC: (
            parseFloat(tokensDetails.WETH.value) +
            parseFloat(tokensDetails.ETH.value)
          ).toString(),
          investedAmount: BigNumber.from(0),
          investedAmountInUSDC: '0.00',
          assetBalance: tokensDetails[WETH.symbol].balanceDecimal.add(
            tokensDetails[ETH.symbol].balanceDecimal,
          ),
          assetBalanceInUSDC: (
            parseFloat(tokensDetails.WETH.value) +
            parseFloat(tokensDetails.ETH.value)
          ).toString(),
          price: tokensDetails.WETH.price,
          average: '0.00',
          fixedInUSDC: '0.00',
          flexibleInUSDC: '0.00',
          totalYield: 0,
          totalYieldInUSD: 0,
        },
      ],
      resultForLargeCard,
    };
  }

  public async calculateTakerFee(
    amount: BigNumber,
    start: number,
    tokenSymbol: string,
  ): Promise<BigNumber> {
    const { CALCULATIONS } = await getContractsAddresses();
    const calcAddress =
      CALCULATIONS[tokenSymbol === ETH.symbol ? WETH.symbol : tokenSymbol];
    const calculationContract = Calculation__factory.connect(
      calcAddress,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
    return await calculationContract.calcTakerPositionFee(amount, start);
  }
}
