import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ConfirmDepositCard from './ConfirmDepositCard';
import { ConfirmDepositCardProps } from './types';

import { MakerPositionService } from '../../core/services/makerPositionService';
import { useAppSelector } from '../../core/state/hooks';

export const ConfirmDepositCardContainer: React.FC = () => {
  const locationState = useLocation().state as ConfirmDepositCardProps;

  const { chainId } = useWeb3React();

  const { TOKEN_DETAILS } = useAppSelector((state) => state.contractsAddresses);

  const makerPositionService = MakerPositionService.getInstance();

  useEffect(() => {
    makerPositionService
      .getIncentiveValus(
        chainId || 1,
        TOKEN_DETAILS[locationState.stableSymbol].address,
        ethers.utils.parseUnits(
          locationState.stableAmount,
          TOKEN_DETAILS[locationState.stableSymbol].decimal,
        ),
      )
      .then((incentive) => {
        locationState.incentiveNetwork = incentive.coordination;
        locationState.incentiveBoost = incentive.boost;
      });
  }, []);

  return <ConfirmDepositCard {...locationState} />;
};
