import { PositionInfo } from '@bumper-dao/ui-kit';
import React, { FC } from 'react';

import { FixedCardContent } from './FixedCardContent';
import { FixedCardHeader } from './FixedCardHeader';

import { secondsByDays, timeInMilliseconds } from '../../../core/utils/helpers';
import { FixedStakingCardProps } from '../types';

export const FixedStakingCard: FC<FixedStakingCardProps> = ({
  rewards,
  autorenew,
  stakeIndex,
  option,
  start,
  interval,
  withdrawWindow,
  amount,
  cardView,
}) => {
  const periods = Math.ceil(
    (Date.now() / 1000 - start) /
      (secondsByDays(interval) + (autorenew ? withdrawWindow : 0)),
  );

  const multiplier = autorenew ? periods : 1;
  const endDate = timeInMilliseconds(
    start +
      (autorenew && periods > 1 ? withdrawWindow * (periods - 1) : 0) +
      multiplier * secondsByDays(interval),
  );
  return (
    <PositionInfo
      cardView={cardView}
      header={
        <FixedCardHeader
          amount={amount}
          endDate={endDate}
          stakeIndex={stakeIndex}
          rewards={rewards}
          autorenew={autorenew}
          option={option}
          interval={interval}
        />
      }
      content={
        <FixedCardContent
          stakeIndex={stakeIndex}
          amount={amount}
          rewards={rewards}
          endDate={endDate}
        />
      }
    />
  );
};
