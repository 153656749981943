import { Vesting, Vesting__factory } from '@bumper-dao/contract-interfaces';
import { RSV } from 'eth-permit/dist/rpc';
import { BigNumber, ContractTransaction, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';
import { getContractsAddresses, tryPermit } from './servicesUtils';

import { BUMP } from '../config/tokenNames';
import { VestingMerkleType } from '../state/reducers/merkleTree';

export class VestingService {
  private static instance: VestingService;
  private ethersServiceProvider: EthersServiceProvider;
  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
  }
  public static getInstance(): VestingService {
    if (!VestingService.instance) {
      VestingService.instance = new VestingService();
    }
    return VestingService.instance;
  }
  private getVesting(contractAddress: string): Vesting {
    return Vesting__factory.connect(
      contractAddress,
      this.ethersServiceProvider.provider?.getSigner(
        0,
      ) as ethers.providers.JsonRpcSigner,
    );
  }

  private async getAccount(address?: string): Promise<string> {
    return address ?? (await this.ethersServiceProvider.getUserAddress());
  }

  public async claimedEvents() {
    const { CONTRACT_ADDRESS } = await getContractsAddresses();
    return await this.getVesting(CONTRACT_ADDRESS.Vesting).queryFilter(
      this.getVesting(CONTRACT_ADDRESS.Vesting).filters.Claimed(
        null,
        await this.getAccount(),
        null,
        null,
      ),
    );
  }

  public async claim(
    vestingData: VestingMerkleType,
  ): Promise<ContractTransaction> {
    if (!vestingData) throw new Error('Vesting data is null');
    const { CONTRACT_ADDRESS } = await getContractsAddresses();
    return await this.getVesting(CONTRACT_ADDRESS.Vesting).claim(
      vestingData.account,
      vestingData.info,
      vestingData.proof,
    );
  }

  public async claimToStakeWithPermit(
    vestingData: VestingMerkleType | null,
    stakingOption: number,
    autorenew: boolean,
    walletAmount: BigNumber,
    claimAmount: BigNumber,
    stakingAmount: BigNumber,
  ): Promise<ContractTransaction> {
    if (!vestingData) throw new Error('Vesting data is null');
    const { CONTRACT_ADDRESS, TOKEN_DETAILS } = await getContractsAddresses();

    let permit: ({ deadline: string | number } & RSV) | undefined = undefined;

    if (!walletAmount.isZero()) {
      permit = await tryPermit(
        this.ethersServiceProvider.provider,
        TOKEN_DETAILS[BUMP.symbol].address,
        await this.getAccount(),
        walletAmount,
        1,
        'BUMP',
        CONTRACT_ADDRESS.Vesting,
      );
    }

    if (permit) {
      return await this.getVesting(
        CONTRACT_ADDRESS.Vesting,
      ).stakeOwnAndVestedTokensWithPermit(
        vestingData.account,
        vestingData.info,
        stakingOption,
        autorenew,
        walletAmount,
        vestingData.proof,
        permit.deadline,
        permit.v,
        permit.r,
        permit.s,
      );
    } else if (!walletAmount.isZero()) {
      if (
        (
          await this.ethersServiceProvider.approveAmount(
            vestingData.account,
            this.getVesting(CONTRACT_ADDRESS.Vesting).address,
            TOKEN_DETAILS[BUMP.symbol].address,
          )
        ).lt(walletAmount)
      ) {
        const approveTx = await this.ethersServiceProvider.approveTokenAmount(
          walletAmount.toString(),
          this.getVesting(CONTRACT_ADDRESS.Vesting).address,
          TOKEN_DETAILS[BUMP.symbol].address,
        );
        await approveTx.wait();
      }

      return await this.getVesting(
        CONTRACT_ADDRESS.Vesting,
      ).stakeOwnAndVestedTokensWithApprove(
        vestingData.account,
        vestingData.info,
        stakingOption,
        autorenew,
        walletAmount,
        vestingData.proof,
      );
    } else {
      return await this.getVesting(CONTRACT_ADDRESS.Vesting).stakeVestedTokens(
        vestingData.account,
        vestingData.info,
        stakingOption,
        autorenew,
        claimAmount,
        stakingAmount,
        vestingData.proof,
      );
    }
  }

  public async getClaimableAmount(
    vestingData: VestingMerkleType,
  ): Promise<BigNumber> {
    if (!vestingData) throw new Error('Vesting data is null');
    const { CONTRACT_ADDRESS } = await getContractsAddresses();
    return await this.getVesting(
      CONTRACT_ADDRESS.Vesting,
    ).getClaimableAmountFor(vestingData.account, vestingData.info);
  }
  public async totalLockedOf(
    vestingData: VestingMerkleType,
  ): Promise<BigNumber> {
    if (!vestingData) throw new Error('Vesting data is null');
    const { CONTRACT_ADDRESS } = await getContractsAddresses();
    return await this.getVesting(CONTRACT_ADDRESS.Vesting).totalLockedOf(
      vestingData.info,
    );
  }

  public async totalClaimedOf(address: string): Promise<BigNumber> {
    const { CONTRACT_ADDRESS } = await getContractsAddresses();
    return await this.getVesting(CONTRACT_ADDRESS.Vesting).recipientClaimed(
      address,
    );
  }

  public async claimedFromNewVersionContract(
    address?: string,
  ): Promise<BigNumber> {
    const { CONTRACT_ADDRESS } = await getContractsAddresses();
    return await this.getVesting(CONTRACT_ADDRESS.Vesting).recipientClaimed(
      await this.getAccount(address),
    );
  }
}
