import { gql } from '@apollo/client';

const GET_WHOLE_HISTORY = gql`
  query GetWholeHistory($limit: Int = 1000, $offset: Int = 0) {
    generalHistories(skip: $offset, first: $limit) {
      txHash
      txTo
      txFrom
      amountEth
      eventType
      amountBump
    }
  }
`;

const GET_WHOLE_HISTORY_BY_ADDRESS = gql`
  query GetWholeHistoryByAddress($address: Bytes) {
    generalHistories(where: { txFrom: $address }) {
      txHash
      txTo
      txFrom
      amountEth
      amountAsset
      amountStable
      amountBump
      timestamp
      eventType
    }
  }
`;

const GET_VESTING_CLAIMS_BY_ADDRESS = gql`
  query GetVestingClaimsByAddress(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    vestingClaimHistories(
      skip: $offset
      first: $limit
      where: { account: $address }
    ) {
      txHash
      index
      account
      amount
      timestamp
    }
  }
`;

// const GET_ALL_VESTING_CLAIMS_BY_ADDRESS = gql`
//   query GetAllVestingClaimsByAddress($address: Bytes) {
//     vestingClaimHistories(where: { account: $address }) {
//       txHash
//       index
//       account
//       amount
//       timestamp
//     }
//   }
// `;

const GET_AIRDROP_CLAIMS_BY_ADDRESS = gql`
  query GetAirdropClaimsByAddress(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    airdropClaimHistories(
      skip: $offset
      first: $limit
      where: { account: $address }
    ) {
      txHash
      index
      account
      amountUSDC
      amountBUMP
      timestamp
    }
  }
`;

const GET_STAKING_CLAIMS_BY_ADDRESS = gql`
  query GetStakingClaimsByAddress(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    stakingClaimHistories(
      skip: $offset
      first: $limit
      where: { account: $address }
    ) {
      txHash
      account
      amount
      timestamp
      option
    }
  }
`;

const GET_STAKING_STAKES_BY_ADDRESS = gql`
  query GetStakingStakesByAddress(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    stakingStakeHistories(
      skip: $offset
      first: $limit
      where: { account: $address }
    ) {
      txHash
      account
      amount
      autorenew
      period
      timestamp
      option
    }
  }
`;
const GET_STAKING_WITHDRAW_BY_ADDRESS = gql`
  query GetStakingWithdrawsByAddress(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    stakingWithdrawHistories(
      skip: $offset
      first: $limit
      where: { account: $address }
    ) {
      txHash
      account
      amount
      timestamp
      option
      rewards
    }
  }
`;
const GET_STAKING_WITHDRAW_REQUESTS_BY_ADDRESS = gql`
  query GetStakingWithdrawRequestsByAddress(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    stakingRequestWithdrawHistories(
      skip: $offset
      first: $limit
      where: { account: $address }
    ) {
      txHash
      account
      amount
      timestamp
      option
    }
  }
`;
const GET_STAKING_GENERAL_HISTORY_BY_ADDRESS = gql`
  query GetStakingGeneralHistoryByAddress(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    stakingGeneralHistories(
      skip: $offset
      first: $limit
      where: { account: $address }
    ) {
      txHash
      account
      amountBUMP
      timestamp
      operation
    }
  }
`;

const GET_BUMP_TRANSFERS_BY_ADDRESS_TO = gql`
  query GetBumpTokenTransfersByTo(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    bumpTokenTransfers(skip: $offset, first: $limit, where: { to: $address }) {
      txHash
      from
      to
      amount
      timestamp
    }
  }
`;

const GET_BUMP_TRANSFERS_BY_ADDRESS_FROM = gql`
  query GetBumpTokenTransfersByFrom(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    bumpTokenTransfers(
      skip: $offset
      first: $limit
      where: { from: $address }
    ) {
      txHash
      from
      to
      amount
      timestamp
    }
  }
`;
const GET_BUMP_APPROVALS_BY_ADDRESS_OWNER = gql`
  query GetBumpTokenApprovalsByOwner(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    bumpTokenApprovals(
      skip: $offset
      first: $limit
      where: { owner: $address }
    ) {
      txHash
      owner
      spender
      amount
      timestamp
    }
  }
`;

const GET_BUMP_APPROVALS_BY_ADDRESS_SPENDER = gql`
  query GetBumpTokenApprovalsBySpender(
    $limit: Int = 1000
    $offset: Int = 0
    $address: Bytes
  ) {
    bumpTokenApprovals(
      skip: $offset
      first: $limit
      where: { spender: $address }
    ) {
      txHash
      owner
      spender
      amount
      timestamp
    }
  }
`;

const GET_MAKER_POSITIONS_BY_OWNER = gql`
  query GetMarketMakerPositionsByOwner($address: Bytes) {
    marketMakerPositions(where: { owner: $address }) {
      market
      owner
      positionOpenTimestamp
      positionId
      amount
      withdrawnAssetAmount
      withdrawnStableAmount
      reward
      tier
      term
      isOpened
      isCanceled
      boost
      incentives
    }
  }
`;

const GET_MAKER_POSITIONS_BY_DATE = gql`
  query GetMarketMakerPositionsByDate($limit: Int = 1000, $offset: Int = 0) {
    marketMakerPositions(skip: $offset, first: $limit) {
      market
      owner
      positionOpenTimestamp
      positionId
      amount
      withdrawnAssetAmount
      withdrawnStableAmount
      reward
      tier
      term
      isOpened
      isCanceled
      boost
      incentives
    }
  }
`;

const GET_TAKER_POSITIONS_BY_OWNER = gql`
  query GetMarketTakerPositionsByOwner($address: Bytes) {
    marketTakerPositions(where: { owner: $address }) {
      market
      owner
      positionOpenTimestamp
      positionId
      amount
      withdrawnAssetAmount
      withdrawnStableAmount
      floor
      risk
      term
      premium
      flags
      isOpened
      isCanceled
      boost
      incentives
    }
  }
`;

const GET_TAKER_POSITIONS_BY_DATE = gql`
  query GetMarketTakerPositionsByDate($limit: Int = 1000, $offset: Int = 0) {
    marketTakerPositions(skip: $offset, first: $limit) {
      market
      owner
      positionOpenTimestamp
      positionId
      amount
      withdrawnAssetAmount
      withdrawnStableAmount
      floor
      risk
      term
      premium
      flags
      isOpened
      isCanceled
      boost
      incentives
    }
  }
`;

const GET_CLOSED_TAKER_POSITION_IN_TIME_INTERVAL = gql`
  query GetClosedTakerPositionInTimeInterval(
    $timestampStart: BigInt
    $timestampEnd: BigInt
  ) {
    marketTakerPositions(
      where: {
        isOpened: false
        positionCloseTimestamp_gte: $timestampStart
        positionCloseTimestamp_lte: $timestampEnd
      }
    ) {
      premium
      amount
    }
  }
`;

const GET_AGGREGATED_DATA_BY_MARKET = gql`
  query GetAggregatedDataByMarket(
    $market: Bytes
    $timestampStart: BigInt
    $timestampEnd: BigInt
  ) {
    aggregatedMarketDatas(
      where: {
        market: $market
        timestamp_gte: $timestampStart
        timestamp_lt: $timestampEnd
      }
    ) {
      market
      timestamp
      assetPrice
      totalOpenedTakerPositions
      totalOpenedMakerPositions
      openedPositionsTierSum
      openedPositionsFloorSum
      ciMaker
      ciTakerAsset
      ciTakerStable
    }
    marketTakerPositions(
      where: {
        isOpened: false
        positionCloseTimestamp_gte: $timestampStart
        positionCloseTimestamp_lte: $timestampEnd
      }
    ) {
      premium
      amount
    }
  }
`;

export {
  GET_WHOLE_HISTORY,
  GET_WHOLE_HISTORY_BY_ADDRESS,
  GET_VESTING_CLAIMS_BY_ADDRESS,
  GET_AIRDROP_CLAIMS_BY_ADDRESS,
  GET_STAKING_CLAIMS_BY_ADDRESS,
  GET_STAKING_STAKES_BY_ADDRESS,
  GET_STAKING_WITHDRAW_BY_ADDRESS,
  GET_STAKING_WITHDRAW_REQUESTS_BY_ADDRESS,
  GET_STAKING_GENERAL_HISTORY_BY_ADDRESS,
  GET_BUMP_TRANSFERS_BY_ADDRESS_TO,
  GET_BUMP_TRANSFERS_BY_ADDRESS_FROM,
  GET_BUMP_APPROVALS_BY_ADDRESS_OWNER,
  GET_BUMP_APPROVALS_BY_ADDRESS_SPENDER,
  GET_MAKER_POSITIONS_BY_OWNER,
  GET_MAKER_POSITIONS_BY_DATE,
  GET_TAKER_POSITIONS_BY_OWNER,
  GET_TAKER_POSITIONS_BY_DATE,
  GET_CLOSED_TAKER_POSITION_IN_TIME_INTERVAL,
  GET_AGGREGATED_DATA_BY_MARKET,
};
