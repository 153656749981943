import {
  DarkInfoBox,
  FlowCard,
  Heading,
  IconSize,
  LargeText,
  lightColors,
  SmallText,
  Tooltip,
  Button,
  Select,
  OptionType,
  useMatchBreakpoints,
  MediumText,
  FlexWithGap,
} from '@bumper-dao/ui-kit';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BottomLeftCornerAsset from '../../assets/cardCorners/leftAsset.svg';
import BottomRightCornerAsset from '../../assets/cardCorners/rightAsset.svg';
import { Routes, subRoutes } from '../../core/config/routes';
import { USDCoin } from '../../core/config/tokenNames';
import { tokensIcons } from '../../core/config/tokensIcons';
import { useChainName } from '../../core/hooks/useChain';
import {
  CoinKey,
  ContentHeadingWrapper,
  ContentValueWrapper,
  ContentWrapper,
  InfoBoxContent,
} from '../StakingAssetPage/styles';

export const EarnAssetSummaryCard: FC<EarnProps> = ({
  total,
  averageRisk,
  idle,
  earning,
  positions,
}) => {
  const { isMobile } = useMatchBreakpoints();
  const [selectedCoin, setSelectedCoin] = useState<string | undefined>('');
  return (
    <FlowCard
      header={
        <Header
          disabled={parseFloat(idle.amount) === 0}
          onChangeCoin={setSelectedCoin}
        />
      }
      content={
        <Content
          total={total}
          averageRisk={averageRisk}
          idle={idle}
          earning={earning}
          positions={positions}
          inCoin={selectedCoin ?? ''}
        />
      }
      bottomLeftCorner={BottomLeftCornerAsset}
      bottomRightCorner={BottomRightCornerAsset}
      bottomLedgeHeight="38px"
      bottomLedgeCenterWidth={isMobile ? '281px' : '1030px'}
      radiatorWidth="36px"
      headerPadding={isMobile ? '12px 26px' : '12px 10% 12px 2%'}
      mt="2rem !important"
      mb="3rem"
    />
  );
};

interface EarnProps {
  total: {
    amount: string;
    amountInUSD: string;
  };
  averageRisk: {
    amount: string;
    percent: string;
  };
  idle: {
    amount: string;
    amountInUSD: string;
  };
  earning: {
    amount: string;
    amountInUSD: string;
  };
  positions: number;
  inCoin?: string;
}

const Content: FC<EarnProps> = ({
  total,
  averageRisk,
  idle,
  earning,
  positions,
  inCoin,
}) => {
  return (
    <ContentWrapper>
      <DarkInfoBox
        minWidth="150px"
        minHeight="90px"
        borderColor="primary2.darkGreen"
      >
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Total
          </Heading>
          <ContentValueWrapper>
            {parseFloat(total.amount) > 0 ? (
              <FlexWithGap gap="4px" alignItems="baseline">
                <LargeText
                  fontWeight={500}
                  color={lightColors.typography.input}
                >
                  {total.amount}
                </LargeText>
                <SmallText
                  color={lightColors.primary2.cambridgeBlue}
                  fontWeight={500}
                >
                  {inCoin}
                </SmallText>
              </FlexWithGap>
            ) : (
              <LargeText color={lightColors.secondary.white}>-</LargeText>
            )}
          </ContentValueWrapper>
          {parseFloat(total.amount) > 0 && (
            <SmallText color={lightColors.primary2.cambridgeBlue}>
              (${total.amountInUSD})
            </SmallText>
          )}
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        minHeight="90px"
        borderColor="primary2.darkGreen"
      >
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Average Risk
          </Heading>
          <ContentValueWrapper>
            {parseFloat(averageRisk.amount) > 0 ? (
              <LargeText fontWeight={500} color={lightColors.secondary.white}>
                {averageRisk.amount}
              </LargeText>
            ) : (
              <LargeText color={lightColors.secondary.white}>-</LargeText>
            )}
          </ContentValueWrapper>
          {parseFloat(averageRisk.amount) > 0 && (
            <SmallText color={lightColors.primary2.cambridgeBlue}>
              +{averageRisk.percent}% (24h)
            </SmallText>
          )}
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        minHeight="90px"
        borderColor="primary2.darkGreen"
      >
        <InfoBoxContent>
          <ContentHeadingWrapper>
            <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
              Idle
            </Heading>
            <Tooltip tooltipValue={'fdsfdsg'} place="bottom" />
          </ContentHeadingWrapper>
          <ContentValueWrapper>
            {parseFloat(idle.amount) > 0 ? (
              <FlexWithGap gap="4px" alignItems="baseline">
                <LargeText fontWeight={500} color={lightColors.secondary.red}>
                  {idle.amount}
                </LargeText>
                <SmallText
                  color={lightColors.primary2.cambridgeBlue}
                  fontWeight={500}
                >
                  {inCoin}
                </SmallText>
              </FlexWithGap>
            ) : (
              <LargeText fontWeight={500} color={lightColors.secondary.white}>
                -
              </LargeText>
            )}
          </ContentValueWrapper>
          {parseFloat(idle.amount) > 0 && (
            <SmallText color={lightColors.primary2.desaturatedCyan}>
              (${idle.amountInUSD})
            </SmallText>
          )}
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        minHeight="90px"
        borderColor="primary2.darkGreen"
      >
        <InfoBoxContent>
          <ContentHeadingWrapper>
            <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
              Earning
            </Heading>
            <Tooltip tooltipValue={'fdsfdsg'} place="bottom" />
          </ContentHeadingWrapper>
          <ContentValueWrapper>
            {parseFloat(earning.amount) > 0 ? (
              <>
                <LargeText
                  fontWeight={500}
                  color={lightColors.primary1.vividTangelo}
                >
                  {earning.amount}
                </LargeText>
                <CoinKey>{inCoin}</CoinKey>
              </>
            ) : (
              <LargeText fontWeight={500} color={lightColors.secondary.white}>
                -
              </LargeText>
            )}
          </ContentValueWrapper>
          {parseFloat(idle.amount) > 0 && (
            <SmallText color={lightColors.primary2.desaturatedCyan}>
              (${earning.amountInUSD})
            </SmallText>
          )}
        </InfoBoxContent>
      </DarkInfoBox>
      <DarkInfoBox
        minWidth="150px"
        minHeight="90px"
        borderColor="primary2.darkGreen"
      >
        <InfoBoxContent>
          <Heading scale="md" color={lightColors.primary2.desaturatedCyan}>
            Positions
          </Heading>
          <LargeText fontWeight={500} color={lightColors.secondary.white}>
            {positions}
          </LargeText>
        </InfoBoxContent>
      </DarkInfoBox>
    </ContentWrapper>
  );
};

const Header: React.FC<{
  onChangeCoin(coinSymb: string): void;
  disabled?: boolean;
}> = ({ onChangeCoin, disabled }) => {
  const navigate = useNavigate();

  const chainName = useChainName();

  const options = [
    {
      key: 'USDC',
      value: (
        <FlexWithGap gap="5px" alignItems="center">
          <Heading scale={'lg'} color="secondary.white">
            USDC
          </Heading>
          <MediumText
            fontWeight={500}
            fontSize="16px"
            color="primary2.cambridgeBlue"
          >
            <span className="cambridgeBlue">(USDC)</span>
          </MediumText>
          <SmallText fontWeight={500} color="secondary.white">
            1 USDC = $1.00
          </SmallText>
        </FlexWithGap>
      ),
      icon: tokensIcons[USDCoin.symbol],
    },
  ];

  const [firstValue, setFirstValue] = useState<OptionType>(options[0]);

  onChangeCoin(options[0].key);

  return (
    <ContentWrapper justifyContent="space-between !important" flex={1}>
      <Select
        value={firstValue}
        options={options}
        withIcon
        topValue="12px"
        onValueChanged={(value) => {
          setFirstValue(value);
          onChangeCoin(value.key);
        }}
      />
      <Button
        primary
        size={IconSize.L}
        padding="0.25rem 1rem"
        letterSpacing="0.03em"
        lineHeight="20px"
        minHeight="32px"
        disabled={disabled}
        onClick={() => {
          navigate(`${Routes.Earn}/${chainName}/${subRoutes.Select}`, {
            state: { token: USDCoin },
          });
        }}
      >
        Earn
      </Button>
    </ContentWrapper>
  );
};
