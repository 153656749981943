import { Flex, FlowHeader, StepStatus } from '@bumper-dao/ui-kit';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ClaimPage } from './ClaimPage';
import { ClaimStakeConfirmPage } from './ClaimStakeConfirmPage';
import { SummaryVestingClaimStake } from './SummaryVestingClaimStake';

import { Routes, subRoutes } from '../../core/config/routes';
import { useAppDispatch } from '../../core/state/hooks';
import { toggleToolbar } from '../../core/state/reducers/uiStateManagementReducer';
import { StakeDeposit } from '../Staking/StakeDeposit';
import { TransactionConfirm, TransactionInProgress } from '../Transaction';

export const stakeDepositFlowSteps = ['Details', 'Confirm'];

export const VestingClaimStakeFlowPage: React.FC = () => {
  const history = useNavigate();
  const { step } = useParams<{ step?: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleToolbar());

    return () => {
      dispatch(toggleToolbar());
    };
  }, []);

  const onCancel = () => {
    history(Routes.Dashboard);
  };

  const showSection = () => {
    switch (step) {
      case subRoutes.Claim: {
        return <ClaimPage />;
      }
      case subRoutes.Select: {
        return <StakeDeposit isVesting />;
      }
      case subRoutes.Confirm: {
        return <ClaimStakeConfirmPage />;
      }
      case subRoutes.Approve: {
        return <TransactionConfirm steps={2} />;
      }
      case subRoutes.Processing: {
        return <TransactionInProgress />;
      }
      case subRoutes.Summary: {
        return <SummaryVestingClaimStake />;
      }
      default: {
        return <ClaimPage />;
      }
    }
  };

  const getStatus = (): StepStatus[] => {
    switch (step) {
      case subRoutes.Claim: {
        return ['current', 'next', 'next'];
      }
      case subRoutes.Select: {
        return ['past', 'current', 'next'];
      }
      case subRoutes.Confirm: {
        return ['past', 'past', 'current'];
      }
      case subRoutes.Summary: {
        return ['past', 'past', 'current'];
      }
      default: {
        return ['current', 'next', 'next'];
      }
    }
  };

  return (
    <Flex flexDirection="column" flex={1}>
      <FlowHeader
        cancelClick={onCancel}
        logoClick={() => history(Routes.Dashboard)}
        steps={
          step === subRoutes.Processing || step == subRoutes.Approve
            ? []
            : step === subRoutes.Summary
            ? ['Claim', 'Stake', 'Summary']
            : ['Claim', 'Stake', 'Confirm']
        }
        stepsStatus={getStatus()}
      />
      <Flex
        py="40px"
        justifyContent="center"
        alignItems="center"
        flex={1}
        width="100%"
      >
        {showSection()}
      </Flex>
    </Flex>
  );
};
