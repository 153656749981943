import { getStorage, ref, getDownloadURL } from '@firebase/storage';
import axios from 'axios';

import { firebaseApp } from '../../firebase';
import {
  IEpoch,
  AirdropMerkleInfo,
  EpochData,
  EpochUserData,
  FirebaseDataType,
  StakingMerkleInfo,
  VestingMerkleInfo,
} from '../interfaces';

const storage = getStorage(firebaseApp);

export const getEpochResultsData = (): Promise<EpochData[]> => {
  return getFirebaseFileContent('epochs/epoch-results.json');
};

export const getEpochData = (epochNumber: number): Promise<EpochUserData[]> => {
  return getFirebaseFileContent(`epochs/epoch-${epochNumber}.json`);
};

export const getEpochSummaryData = (): Promise<IEpoch[]> => {
  return getFirebaseFileContent('epochs/epoch-summary.json');
};

export const getVestingUserData = (
  network: string,
  address: string,
): Promise<VestingMerkleInfo> => {
  return getFirebaseUserData(FirebaseDataType.Vesting, network, address);
};

export const getStakingUserData = (
  network: string,
  address: string,
): Promise<StakingMerkleInfo> => {
  return getFirebaseUserData(FirebaseDataType.Staking, network, address);
};

export const getAirdropUserData = (
  network: string,
  address: string,
): Promise<AirdropMerkleInfo[]> => {
  return getFirebaseUserData(FirebaseDataType.Airdrop, network, address);
};

const getFirebaseUserData = <TReturn = unknown>(
  dataType: FirebaseDataType,
  network: string,
  address: string,
): Promise<TReturn> => {
  try {
    return getFirebaseFileContent(
      `/userData/${network}/${dataType.toString()}/${address}.json`,
    );
  } catch (err) {
    console.error('File was not found or any fetching/parsing error occurred');
    throw err;
  }
};

const getFirebaseFileContent = async <TReturn = unknown>(
  filePath: string,
): Promise<TReturn> => {
  const downloadUrl = await getDownloadURL(ref(storage, filePath));
  return (await axios.get<TReturn>(downloadUrl, { withCredentials: false }))
    .data;
};
