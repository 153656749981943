import {
  IERC20__factory,
  Incentives__factory,
} from '@bumper-dao/contract-interfaces';
import { BigNumber, ContractTransaction, ethers } from 'ethers';

import { EthersServiceProvider } from './ethersServiceProvider';
import { MarketService } from './marketService';
import { ProtocolConfigService } from './protocolConfigService';
import { getContractsAddresses, tryPermit } from './servicesUtils';

import { marketsTokensConfig } from '../config/constants/tokens';
import { CONTRACTS } from '../config/contractAddresses';
import {
  DEFAULT_DECIMAL_VALUE,
  NULLABLE_ADDRESS,
} from '../config/formulaConstants';
import { BUMP, USDCoin, WETH } from '../config/tokenNames';
import {
  ICoin,
  MakerPositionType,
  PositionExtendedType,
  IPosition,
  LargeTotalCardType,
  TotalCardType,
} from '../interfaces';
import { client } from '../providers/graphql';
import { GetMarketMakerPositionsByDate } from '../providers/graphql/__generated__/GetMarketMakerPositionsByDate';
import { GetMarketMakerPositionsByOwner } from '../providers/graphql/__generated__/GetMarketMakerPositionsByOwner';
import {
  GET_MAKER_POSITIONS_BY_DATE,
  GET_MAKER_POSITIONS_BY_OWNER,
} from '../providers/graphql/queries';
import { CoinDetailsReducerType } from '../state/reducers/coinReducer';
import { getTokenPrice } from '../utils/helpers';

export class MakerPositionService {
  private static instance: MakerPositionService;
  private ethersServiceProvider: EthersServiceProvider;
  private protocolConfigServices: ProtocolConfigService;
  private marketServices: MarketService;

  private constructor() {
    this.ethersServiceProvider = EthersServiceProvider.getInstance();
    this.protocolConfigServices = ProtocolConfigService.getInstance();
    this.marketServices = MarketService.getInstance();
  }

  public static getInstance(): MakerPositionService {
    if (!MakerPositionService.instance) {
      MakerPositionService.instance = new MakerPositionService();
    }
    return MakerPositionService.instance;
  }

  private async getAccount(address?: string): Promise<string> {
    return address ?? (await this.ethersServiceProvider.getUserAddress());
  }

  public async getMakerPosition(
    makerPositionId: number,
    marketAddress: string,
  ): Promise<Omit<MakerPositionType, 'positionOpenTimestamp'>> {
    const position = await this.marketServices
      .getMarketContract(marketAddress)
      .getMakerPosition(makerPositionId);

    const AUTORENEW_TERMS_OFFSET = 1;
    const AUTORENEW_TERMS_MASK = 0xff << AUTORENEW_TERMS_OFFSET;
    const terms =
      (+position.flags & AUTORENEW_TERMS_MASK) >> AUTORENEW_TERMS_OFFSET;
    const pastTerms = terms > 0 ? terms : 1;

    const autorenew = position.flags.and(0x1).gt(0);

    let yieldOnWithdraw: BigNumber = ethers.constants.Zero;
    try {
      yieldOnWithdraw = await this.marketServices
        .getMarketContract(marketAddress)
        .yieldOnWithdraw(makerPositionId);
    } catch {
      yieldOnWithdraw = ethers.constants.Zero;
    }

    return {
      ...position,
      autorenew,
      pastTerms,
      yield: parseFloat(ethers.utils.formatUnits(yieldOnWithdraw, 18)),
      bumpBondAmount: position.bumpAmount,
    };
  }

  private async getLatestEvents() {
    const { ADAPTERS } = await getContractsAddresses();
    const latestBlock =
      (await this.ethersServiceProvider.provider?.getBlockNumber()) || 1000;
    const adaptersLibrary = marketsTokensConfig
      .map(({ token }) => {
        return ADAPTERS[token.symbol];
      })
      .filter((adapter) => !!adapter)
      .map((adapter) => Object.values(adapter))
      .flat();
    const filteredPositions = await Promise.all(
      adaptersLibrary.map(async (adapterAddress) => {
        try {
          const adapterContract = await this.marketServices.getAdapterContract(
            adapterAddress,
          );
          const tokenAddress = await adapterContract.ASSET_ADDRESS();
          const depositedEventsFilter = await adapterContract.filters.Deposit(
            await this.getAccount(),
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          );

          const depositedEvents = await adapterContract
            .queryFilter(depositedEventsFilter, latestBlock - 1000, latestBlock)
            .then((data) => {
              return Promise.all(
                data.map<Promise<IPosition>>(async (event) => ({
                  id: +event.args.id,
                  marketAddress: await adapterContract.market(),
                  tokenAddress,
                  positionOpenTimestamp: (await event.getBlock()).timestamp,
                  incentives: event.args.incentives,
                  boost: event.args.boost,
                })),
              );
            });

          return [...depositedEvents];
        } catch (e) {
          console.error(e);
          return [];
        }
      }),
    );

    return filteredPositions.flat();
  }

  public async getIncentiveValus(
    chainId: number,
    stableAddress: string,
    stableAmount: BigNumber,
  ): Promise<{ coordination: number; boost: number }> {
    const incentiveContract = await Incentives__factory.connect(
      CONTRACTS[chainId].CONTRACT_ADDRESS.Incentives,
      this.ethersServiceProvider.provider as ethers.providers.JsonRpcProvider,
    );

    try {
      const boost = await incentiveContract.boostForMaker(
        stableAddress,
        stableAmount,
      );
      const coordination = await incentiveContract.coordinationForMaker(
        stableAddress,
        stableAmount,
      );

      return {
        coordination: parseFloat(ethers.utils.formatUnits(coordination)),
        boost: parseFloat(ethers.utils.formatUnits(boost)),
      };
    } catch (e) {
      console.error('Incentives failed:', e);
      return {
        coordination: 0,
        boost: 0,
      };
    }
  }

  public async getYieldForWithdraw(
    makerPositionId: number,
    tokenAddress: string,
  ): Promise<BigNumber> {
    const marketAddress = await this.protocolConfigServices.getMarket(
      tokenAddress,
    );
    return await this.marketServices
      .getMarketContract(marketAddress)
      .yieldOnWithdraw(makerPositionId);
  }

  public async getYieldForAbandon(
    makerPositionId: number,
    tokenAddress: string,
  ): Promise<BigNumber> {
    const marketAddress = await this.protocolConfigServices.getMarket(
      tokenAddress,
    );
    return await this.marketServices
      .getMarketContract(marketAddress)
      .yieldOnAbandon(makerPositionId);
  }

  // open new maker position
  public async deposit(
    amount: string,
    bumpBondAmount: BigNumber,
    term: number,
    risk: number,
    autorenew: boolean,
    token: ICoin,
    tokenForGetMarket: ICoin,
  ): Promise<ContractTransaction> {
    const { TOKEN_DETAILS, ADAPTERS } = await getContractsAddresses();
    if (!tokenForGetMarket)
      throw new Error('INDX: token for getting market not found');
    const adapterAddress = ADAPTERS[tokenForGetMarket.symbol][token.symbol];
    const marketAddress = await this.protocolConfigServices.getMarket(
      TOKEN_DETAILS[tokenForGetMarket.symbol || ''].address || '',
    );
    const bondAddress = await this.marketServices
      .getMarketContract(marketAddress)
      .BOND_ADDRESS();

    const amountDec = ethers.utils.parseUnits(
      parseFloat(amount).toFixed(6),
      token.decimals,
    );

    if (
      (
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          adapterAddress,
          TOKEN_DETAILS[token.symbol].address,
        )
      ).gte(amountDec) &&
      (
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          bondAddress,
          TOKEN_DETAILS[BUMP.symbol].address,
        )
      ).gte(bumpBondAmount)
    ) {
      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .deposit(amountDec, risk, term, autorenew);
    }

    const permitToken = await tryPermit(
      this.ethersServiceProvider.provider,
      TOKEN_DETAILS[token.symbol].address,
      await this.getAccount(),
      amountDec,
      2,
      token.name,
      adapterAddress,
    );

    const permitBump = await tryPermit(
      this.ethersServiceProvider.provider,
      TOKEN_DETAILS[BUMP.symbol].address,
      await this.getAccount(),
      bumpBondAmount,
      1,
      'BUMP',
      bondAddress,
    );

    if (permitToken && permitBump) {
      const abiCoder = ethers.utils.defaultAbiCoder;
      const encodedPermitToken = abiCoder.encode(
        ['uint256', 'uint8', 'bytes32', 'bytes32'],
        [permitToken.deadline, permitToken.v, permitToken.r, permitToken.s],
      );
      const encodedPermitBump = abiCoder.encode(
        ['uint256', 'uint8', 'bytes32', 'bytes32'],
        [permitBump.deadline, permitBump.v, permitBump.r, permitBump.s],
      );
      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .depositWithPermitWithAutoBondingPermit(
          amountDec,
          risk,
          term,
          autorenew,
          bumpBondAmount,
          encodedPermitToken,
          encodedPermitBump,
        );
    } else if (permitToken && !permitBump) {
      if (
        !(
          await this.ethersServiceProvider.approveAmount(
            await this.getAccount(),
            bondAddress,
            TOKEN_DETAILS[BUMP.symbol].address,
          )
        ).gte(bumpBondAmount)
      ) {
        const approveBumpTx =
          await this.ethersServiceProvider.approveTokenAmount(
            bumpBondAmount.toHexString(),
            bondAddress,
            TOKEN_DETAILS[BUMP.symbol].address,
          );
        await approveBumpTx.wait();
      }

      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .depositWithPermit(
          amountDec,
          risk,
          term,
          autorenew,
          permitToken.deadline,
          permitToken.v,
          permitToken.r,
          permitToken.s,
        );
    } else if (permitBump && !permitToken) {
      if (
        !(
          await this.ethersServiceProvider.approveAmount(
            await this.getAccount(),
            adapterAddress,
            TOKEN_DETAILS[token.symbol].address,
          )
        ).gte(amountDec)
      ) {
        const approveTokenTx =
          await this.ethersServiceProvider.approveTokenAmount(
            amountDec.toHexString(),
            adapterAddress,
            TOKEN_DETAILS[token.symbol].address,
          );
        await approveTokenTx.wait();
      }
      return await this.marketServices
        .getAdapterContract(adapterAddress)
        .depositWithAutoBondingPermit(
          amountDec,
          risk,
          term,
          autorenew,
          bumpBondAmount,
          permitBump.deadline,
          permitBump.v,
          permitBump.r,
          permitBump.s,
        );
    }

    if (
      !(
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          bondAddress,
          TOKEN_DETAILS[BUMP.symbol].address,
        )
      ).gte(bumpBondAmount)
    ) {
      const approveBumpTx = await this.ethersServiceProvider.approveTokenAmount(
        bumpBondAmount.toHexString(),
        bondAddress,
        TOKEN_DETAILS[BUMP.symbol].address,
      );
      await approveBumpTx.wait();
    }

    if (
      !(
        await this.ethersServiceProvider.approveAmount(
          await this.getAccount(),
          adapterAddress,
          TOKEN_DETAILS[token.symbol].address,
        )
      ).gte(amountDec)
    ) {
      const approveTokenTx =
        await this.ethersServiceProvider.approveTokenAmount(
          amountDec.toHexString(),
          adapterAddress,
          TOKEN_DETAILS[token.symbol].address,
        );
      await approveTokenTx.wait();
    }

    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .deposit(amountDec, risk, term, autorenew);
  }

  //close maker position
  public async withdraw(
    makerPositionId: number,
    token: ICoin,
    tokenForGetMarket: ICoin,
    withdrawBond = true,
  ): Promise<ContractTransaction> {
    const { ADAPTERS } = await getContractsAddresses();
    if (!tokenForGetMarket)
      throw new Error('INDX: token for getting market not found');
    const adapterAddress = ADAPTERS[tokenForGetMarket.symbol][token.symbol];
    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .withdraw(makerPositionId, withdrawBond);
  }

  //emergency close maker position
  public async abandonMakerPosition(
    makerPositionId: number,
    token: ICoin,
    tokenForGetMarket: ICoin,
  ): Promise<ContractTransaction> {
    const { ADAPTERS } = await getContractsAddresses();
    if (!tokenForGetMarket)
      throw new Error('INDX: token for getting market not found');
    const adapterAddress = ADAPTERS[tokenForGetMarket.symbol][token.symbol];
    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .abandon(makerPositionId);
  }

  //emergency close maker position
  public async toggleAutoRenew(
    makerPositionId: number,
    token: ICoin,
    tokenForGetMarket: ICoin,
  ): Promise<ContractTransaction> {
    const { ADAPTERS } = await getContractsAddresses();
    if (!tokenForGetMarket)
      throw new Error('INDX: token for getting market not found');
    const adapterAddress = ADAPTERS[tokenForGetMarket.symbol][token.symbol];
    return await this.marketServices
      .getAdapterContract(adapterAddress)
      .toggleMakerAutorenew(makerPositionId);
  }

  public async getOpenPositions(
    account?: string,
  ): Promise<Map<string, PositionExtendedType<MakerPositionType>[]>> {
    const { TOKEN_DETAILS } = await getContractsAddresses();
    const marketsLibrary = (
      await Promise.all(
        marketsTokensConfig.map(({ token }) =>
          this.protocolConfigServices.getMarket(
            TOKEN_DETAILS[token.symbol].address,
          ),
        ),
      )
    ).map((address) => address.toLowerCase());

    const combinedData = new Map<
      string,
      PositionExtendedType<MakerPositionType>[]
    >();

    const result = await client.query<GetMarketMakerPositionsByOwner>({
      query: GET_MAKER_POSITIONS_BY_OWNER,
      variables: { address: await this.getAccount(account) },
    });

    const openPositions = await Promise.all(
      result.data.marketMakerPositions
        .filter(
          (pos) =>
            pos.isOpened && marketsLibrary.includes(pos.market.toLowerCase()),
        )
        .map(async (pos): Promise<IPosition> => {
          const marketContract = await this.marketServices.getMarketContract(
            pos.market,
          );
          const tokenAddress = await marketContract.ASSET_ADDRESS();
          return {
            id: +pos.positionId,
            marketAddress: pos.market,
            tokenAddress,
            positionOpenTimestamp: pos.positionOpenTimestamp,
            boost: pos.boost,
            incentives: pos.incentives,
          };
        }),
    );

    const positionsFromEvents = await this.getLatestEvents();

    const combinedPositions = [
      ...openPositions,
      ...positionsFromEvents.filter(
        (event) =>
          !openPositions.find(
            (pos) =>
              pos.id === event.id &&
              pos.marketAddress.toLowerCase() ===
                event.marketAddress.toLowerCase(),
          ),
      ),
    ];

    const positionsData: MakerPositionType[] = await Promise.all(
      combinedPositions.map(async (pos) => ({
        ...(await this.getMakerPosition(pos.id, pos.marketAddress)),
        positionOpenTimestamp: pos.positionOpenTimestamp,
      })),
    );

    const stableTokenAddress = TOKEN_DETAILS[USDCoin.symbol].address;
    const stableTokenSymbol = USDCoin.symbol;

    combinedPositions.forEach((pos, index) => {
      const newPosition: PositionExtendedType<MakerPositionType> = {
        id: pos.id,
        marketAddress: pos.marketAddress,
        tokenAddress: stableTokenAddress,
        tokenSymbol: stableTokenSymbol,
        boost: pos.boost,
        incentives: pos.incentives,
        ...positionsData[index],
      };
      const prev = combinedData.get(stableTokenSymbol);
      if (newPosition.owner !== NULLABLE_ADDRESS)
        combinedData.set(stableTokenSymbol, [...(prev || []), newPosition]);
    });

    return combinedData;
  }

  public async calculateMakerTotalPositionsData(
    tokensDetails: CoinDetailsReducerType,
  ): Promise<{
    result: TotalCardType[];
    resultForLargeCard: LargeTotalCardType;
  }> {
    const { TOKEN_DETAILS } = await getContractsAddresses();

    const positions = await this.getOpenPositions();

    const tokensSymbols = Array.from(positions.keys());

    const totalPositionsData = tokensSymbols.map((symbol) => {
      const positionsData = positions.get(symbol) || [];
      const idleAmount = ethers.utils.parseUnits(
        tokensDetails[symbol].balance,
        DEFAULT_DECIMAL_VALUE,
      );

      const depositedAmount = positionsData.reduce(
        (prev, pos) => prev.add(pos.stableAmount),
        ethers.constants.Zero,
      );

      const totalAmount = idleAmount.add(depositedAmount);
      const averageTier =
        positionsData.reduce((prev, curr) => prev + curr.tier, 0) /
        (positionsData.length > 0 ? positionsData.length : 1);

      const now = Date.now();

      const fixed = positionsData.reduce(
        (prev, curr) =>
          now < (curr.positionOpenTimestamp + curr.term * 86400) * 1000
            ? prev.add(curr.stableAmount)
            : prev,
        ethers.constants.Zero,
      );

      const flexible = positionsData.reduce(
        (prev, curr) =>
          now >= (curr.positionOpenTimestamp + curr.term * 86400) * 1000
            ? prev.add(curr.stableAmount)
            : prev,
        ethers.constants.Zero,
      );

      const totalYield = positionsData.reduce(
        (prev, curr) => prev + Math.abs(curr.yield),
        0,
      );

      return {
        idleAmount,
        depositedAmount,
        totalAmount,
        averageTier,
        fixed,
        flexible,
        totalYield,
        token: { ...tokensDetails[symbol], ...TOKEN_DETAILS[symbol] },
      };
    });

    if (totalPositionsData.length === 0)
      return await this.getDefaultTotalData(tokensDetails);

    const priceData = await Promise.all(
      totalPositionsData.map(async ({ totalAmount, token }) =>
        getTokenPrice(token.address, totalAmount),
      ),
    );

    const result = totalPositionsData.map<TotalCardType>(
      (
        {
          totalAmount,
          idleAmount,
          depositedAmount,
          token,
          averageTier,
          fixed,
          flexible,
          totalYield,
        },
        index,
      ) => ({
        totalAmount,
        assetBalance: idleAmount,
        investedAmount: depositedAmount,
        price: ethers.utils.formatUnits(
          priceData[index],
          TOKEN_DETAILS[USDCoin.symbol].decimal,
        ),
        totalAmountInUSDC: ethers.utils.formatUnits(
          totalAmount.gt(0)
            ? totalAmount.mul(priceData[index])
            : BigNumber.from(0),
          DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
        ),
        assetBalanceInUSDC: ethers.utils.formatUnits(
          idleAmount.gt(0)
            ? idleAmount.mul(priceData[index])
            : BigNumber.from(0),
          DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
        ),
        average: averageTier.toFixed(1),
        investedAmountInUSDC: ethers.utils.formatUnits(
          depositedAmount.gt(0)
            ? depositedAmount.mul(priceData[index])
            : BigNumber.from(0),
          DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
        ),
        fixedInUSDC: ethers.utils.formatUnits(
          fixed.gt(0) ? fixed.mul(priceData[index]) : BigNumber.from(0),
          DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
        ),
        flexibleInUSDC: ethers.utils.formatUnits(
          flexible.gt(0) ? flexible.mul(priceData[index]) : BigNumber.from(0),
          DEFAULT_DECIMAL_VALUE + TOKEN_DETAILS[USDCoin.symbol].decimal,
        ),
        totalYield,
        totalYieldInUSD: totalYield,
        token: {
          symbol: token.symbol === WETH.symbol ? 'ETH' : token.symbol,
          name: token.symbol === WETH.symbol ? 'Ether' : token.name,
          decimals: token.decimals,
        },
      }),
    );
    const totalAmountsInUSDCForLargeCard = result.reduce(
      (prev, data) => {
        return {
          idleAmountInUSDC:
            prev.idleAmountInUSDC + parseFloat(data.assetBalanceInUSDC),
          depositedAmountInUSDC:
            prev.depositedAmountInUSDC + parseFloat(data.investedAmountInUSDC),
          totalAmountInUSDC:
            prev.totalAmountInUSDC + parseFloat(data.totalAmountInUSDC),
          fixedInUSDC: prev.fixedInUSDC + parseFloat(data.fixedInUSDC),
          flexibleInUSDC: prev.flexibleInUSDC + parseFloat(data.flexibleInUSDC),
        };
      },
      {
        idleAmountInUSDC: 0,
        depositedAmountInUSDC: 0,
        totalAmountInUSDC: 0,
        fixedInUSDC: 0,
        flexibleInUSDC: 0,
      },
    );
    const resultForLargeCard: LargeTotalCardType = {
      assetBalanceInUSDC:
        totalAmountsInUSDCForLargeCard.idleAmountInUSDC.toString(),
      investedAmountInUSDC:
        totalAmountsInUSDCForLargeCard.depositedAmountInUSDC.toString(),
      totalAmountInUSDC:
        totalAmountsInUSDCForLargeCard.totalAmountInUSDC.toString(),
      fixedInUSDC: totalAmountsInUSDCForLargeCard.fixedInUSDC.toString(),
      flexibleInUSDC: totalAmountsInUSDCForLargeCard.flexibleInUSDC.toString(),
      positionsCount: tokensSymbols.reduce((prev, symbol) => {
        return (positions.get(symbol) || []).length + prev;
      }, 0),
    };
    return { result, resultForLargeCard };
  }

  public async getPositionsByDate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    start?: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    end?: number,
  ): Promise<PositionExtendedType<MakerPositionType>[]> {
    const result = await client.query<GetMarketMakerPositionsByDate>({
      query: GET_MAKER_POSITIONS_BY_DATE,
      variables: { limit: 1000 },
    });

    const positionsData = await Promise.all(
      result.data.marketMakerPositions.map(async (pos) => {
        const position = await this.getMakerPosition(
          +pos.positionId,
          pos.market,
        );
        const marketContract = await this.marketServices.getMarketContract(
          pos.market,
        );
        const stableTokenAddress = await marketContract.ASSET_ADDRESS();
        const stableTokenSymbol = await IERC20__factory.connect(
          stableTokenAddress,
          this.ethersServiceProvider
            .provider as ethers.providers.JsonRpcProvider,
        ).symbol();

        return {
          id: +pos.positionId,
          marketAddress: pos.market.address,
          tokenAddress: stableTokenAddress,
          tokenSymbol: stableTokenSymbol,
          boost: pos.boost,
          incentives: pos.incentives,
          ...position,
          yieldReward: pos.reward,
          positionOpenTimestamp: pos.positionOpenTimestamp,
        };
      }),
    );
    return positionsData;
  }

  private async getDefaultTotalData(
    tokensDetails: CoinDetailsReducerType,
  ): Promise<{
    result: TotalCardType[];
    resultForLargeCard: LargeTotalCardType;
  }> {
    const tokensSymbolsLibrary = new Set<string>();

    marketsTokensConfig.forEach((data) =>
      data.stable.forEach((token) => tokensSymbolsLibrary.add(token.symbol)),
    );

    const balanceInUSD = Array.from(tokensSymbolsLibrary)
      .map((symbol) => tokensDetails[symbol].value)
      .reduce(
        (prev, value) => (parseFloat(value) + parseFloat(prev)).toString(),
        '0',
      );

    const resultForLargeCard: LargeTotalCardType = {
      assetBalanceInUSDC: balanceInUSD,
      totalAmountInUSDC: balanceInUSD,
      investedAmountInUSDC: '0',
      positionsCount: 0,
      fixedInUSDC: '0',
      flexibleInUSDC: '0',
    };

    return {
      result: [
        {
          token: USDCoin,
          totalAmount: tokensDetails[USDCoin.symbol].balanceDecimal.mul(
            10 ** 12,
          ),
          totalAmountInUSDC: tokensDetails[USDCoin.symbol].value,
          investedAmount: BigNumber.from('0'),
          investedAmountInUSDC: '0.00',
          assetBalance: tokensDetails[USDCoin.symbol].balanceDecimal.mul(
            10 ** 12,
          ),
          assetBalanceInUSDC: tokensDetails.USDC.value,
          price: '1.00',
          average: '0.0',
          fixedInUSDC: '0.00',
          flexibleInUSDC: '0.00',
          totalYield: 0,
          totalYieldInUSD: 0,
        },
      ],
      resultForLargeCard,
    };
  }
}
