import {
  Button,
  Flex,
  Heading,
  IconSize,
  lightColors,
  MediumText,
  SmallText,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import React from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';

import { Routes, subRoutes } from '../../../core/config/routes';
import { DEFAULT_TOOLTIP } from '../../../core/config/tooltips';
import { useChain } from '../../../core/hooks/useChain';
import { formatStringifyNumberToDot } from '../../../core/utils/helpers';
import { ConfirmClaimRewardsState } from '../../../pages/Staking/types';
import { CountDownComponent } from '../../common/CountDown';
import {
  ContentProtectedWrapper,
  MobileFieldContainerWrapper,
  PositionContentWrapper,
  PositionInfoBlockInset,
  PositionInfoBlockLarge,
  PositionInfoBlockWrapper,
  PositionInfoWrapper,
} from '../styles';
import { ContentFixedProps } from '../types';

export const FixedCardContent: React.FC<ContentFixedProps> = ({
  rewards,
  amount,
  endDate,
  stakeIndex,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();
  const { nameForDisplay: chainName } = useChain();

  const claimClick = () => {
    const state: ConfirmClaimRewardsState = {
      stakeIndex,
      rewardsAmount: rewards.rewards,
    };
    navigate(
      `${Routes.ClaimRewardsStaking}/${chainName}/${subRoutes.Confirm}`,
      {
        replace: true,
        state,
      },
    );
  };

  return (
    <PositionContentWrapper>
      <ContentProtectedWrapper>
        {!isMobile && (
          <PositionInfoWrapper>
            <PositionInfoBlockWrapper>
              <Flex alignItems="center">
                <Heading
                  color={lightColors.primary2.cambridgeBlue}
                  lineHeight="16px"
                  fontWeight="500"
                  mr={'6.5px'}
                >
                  Staked
                </Heading>
                <Tooltip tooltipValue={DEFAULT_TOOLTIP} />
              </Flex>
              <Flex alignItems="center">
                <MediumText color={lightColors.typography.input} mr="4px">
                  {formatStringifyNumberToDot(amount + '')}
                </MediumText>
                <SmallText
                  color={lightColors.primary2.cambridgeBlue}
                  lineHeight="16px"
                  fontWeight="500"
                >
                  BUMP
                </SmallText>
              </Flex>
            </PositionInfoBlockWrapper>
            <PositionInfoBlockWrapper padd={isMobile ? '' : '14px 16px 10px'}>
              <Heading
                color={lightColors.primary2.cambridgeBlue}
                lineHeight="16px"
                fontWeight="500"
              >
                Period:Fixed
              </Heading>
              <Countdown
                date={endDate}
                renderer={(properties) => (
                  <CountDownComponent {...properties} />
                )}
              />
            </PositionInfoBlockWrapper>
            <PositionInfoBlockLarge>
              <PositionInfoBlockInset>
                <Flex alignItems="center">
                  <Heading
                    color={lightColors.primary2.cambridgeBlue}
                    lineHeight="16px"
                    fontWeight="500"
                    mr={'6.5px'}
                  >
                    Earned
                  </Heading>
                  <Tooltip tooltipValue={DEFAULT_TOOLTIP} />
                </Flex>
                <Flex alignItems="center">
                  <MediumText color={lightColors.typography.input} mr="4px">
                    {formatStringifyNumberToDot(rewards.rewards + '')}
                  </MediumText>
                  <SmallText
                    color={lightColors.primary2.cambridgeBlue}
                    lineHeight="16px"
                    fontWeight="500"
                  >
                    BUMP
                  </SmallText>
                </Flex>
              </PositionInfoBlockInset>
              <Button
                primary
                size={IconSize.L}
                padding="0.25rem 1rem"
                letterSpacing="0.03em"
                lineHeight="20px"
                minHeight="32px"
                disabled={rewards.rewards < 0.1}
                onClick={claimClick}
              >
                Claim
              </Button>
            </PositionInfoBlockLarge>
          </PositionInfoWrapper>
        )}
        {isMobile && (
          <PositionInfoWrapper>
            <MobileFieldContainerWrapper>
              <Flex alignItems="center">
                <Heading
                  color={lightColors.primary2.cambridgeBlue}
                  lineHeight="16px"
                  fontWeight="500"
                  mr={'6.5px'}
                >
                  Staked
                </Heading>
                <Tooltip tooltipValue={DEFAULT_TOOLTIP} />
              </Flex>
              <PositionInfoBlockWrapper>
                <Flex alignItems="center">
                  <MediumText color={lightColors.typography.input} mr="4px">
                    {formatStringifyNumberToDot(amount + '')}
                  </MediumText>
                  <SmallText
                    color={lightColors.primary2.cambridgeBlue}
                    lineHeight="16px"
                    fontWeight="500"
                  >
                    BUMP
                  </SmallText>
                </Flex>
              </PositionInfoBlockWrapper>
            </MobileFieldContainerWrapper>
            <MobileFieldContainerWrapper>
              <Heading
                color={lightColors.primary2.cambridgeBlue}
                lineHeight="16px"
                fontWeight="500"
              >
                Period:Fixed
              </Heading>
              <PositionInfoBlockWrapper>
                <Countdown
                  date={endDate}
                  renderer={(properties) => (
                    <CountDownComponent {...properties} />
                  )}
                />
              </PositionInfoBlockWrapper>
            </MobileFieldContainerWrapper>
            <MobileFieldContainerWrapper>
              <Flex alignItems="center">
                <Heading
                  color={lightColors.primary2.cambridgeBlue}
                  lineHeight="16px"
                  fontWeight="500"
                  mr={'6.5px'}
                >
                  Earned
                </Heading>
                <Tooltip tooltipValue={DEFAULT_TOOLTIP} />
              </Flex>
              <PositionInfoBlockWrapper>
                <Flex alignItems="center">
                  <MediumText color={lightColors.typography.input} mr="4px">
                    {formatStringifyNumberToDot(rewards.rewards + '')}
                  </MediumText>
                  <SmallText
                    color={lightColors.primary2.cambridgeBlue}
                    lineHeight="16px"
                    fontWeight="500"
                  >
                    BUMP
                  </SmallText>
                </Flex>
              </PositionInfoBlockWrapper>
              <Button
                primary
                size={IconSize.L}
                padding="0.25rem 1rem"
                letterSpacing="0.03em"
                lineHeight="20px"
                minHeight="32px"
                mt="20px"
                disabled={rewards.rewards < 0.1}
                onClick={claimClick}
              >
                Claim
              </Button>
            </MobileFieldContainerWrapper>
          </PositionInfoWrapper>
        )}
      </ContentProtectedWrapper>
    </PositionContentWrapper>
  );
};
