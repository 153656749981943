import {
  Button,
  Checkbox,
  ConfirmCard,
  Flex,
  Heading,
  IconSize,
  LargeText,
  SmallText,
  Link,
  MediumText,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { BigNumber, ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ConfirmProtectDepositProps } from './types';

import ProtectIco from '../../assets/protect-flow-ico.png';
import { MainContainer } from '../../components/common/MainContainers';
import { DEFAULT_DECIMAL_VALUE } from '../../core/config/formulaConstants';
import {
  disclaimerLink,
  privacyPolicyLink,
  termsAndConditionsLink,
} from '../../core/config/links';
import { Routes, subRoutes } from '../../core/config/routes';
import { ETH } from '../../core/config/tokenNames';
import { useChainName } from '../../core/hooks/useChain';
import { TakerPositionService } from '../../core/services/takerPositionService';
import { useAppSelector } from '../../core/state/hooks';
import { formatStringifyNumberToDot } from '../../core/utils/helpers';
import { ConfirmDepositInnerContainer } from '../EarnDepositFlow/styles';

export const ConfirmProtectDeposit = () => {
  const state = useLocation().state as ConfirmProtectDepositProps;
  const { isMobile } = useMatchBreakpoints();
  const [isActive, setIsActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const chainName = useChainName();
  const tokenDetails = useAppSelector(
    (stateRed) => stateRed.coin.coinDetails[state.token.symbol],
  );

  const takerService = TakerPositionService.getInstance();

  const [protocolFee, setProtocolFee] = useState<number>(0);

  useEffect(() => {
    takerService
      .calculateTakerFee(
        ethers.utils.parseUnits(state.protectAmount, DEFAULT_DECIMAL_VALUE),
        Math.ceil(Date.now() / 1000 - 86400 * state.protectTerm),
        state.token.symbol,
      )
      .then((fee) => {
        const numericFee = parseFloat(
          ethers.utils.formatUnits(fee, DEFAULT_DECIMAL_VALUE),
        );
        setProtocolFee(numericFee * parseFloat(tokenDetails.price));
      });
  }, []);

  const handleBack = async () => {
    navigate(`${Routes.Protect}/${chainName}/${subRoutes.Select}`, {
      replace: true,
      state,
    });
  };

  const onConfirmButtonClick = async () => {
    try {
      navigate(`${Routes.Protect}/${chainName}/${subRoutes.Approve}`, {
        replace: true,
      });

      const tx =
        state.token.symbol === ETH.symbol
          ? await takerService.protectNative(
              state.protectAmount,
              BigNumber.from(state.bondAmount.toTransfer),
              state.protectTerm,
              state.autoRenew,
              state.protectFloor * 100,
              state.token,
            )
          : await takerService.protect(
              state.protectAmount,
              BigNumber.from(state.bondAmount.toTransfer),
              state.protectTerm,
              state.protectFloor * 100,
              state.autoRenew,
              state.token,
            );

      navigate(`${Routes.Protect}/${chainName}/${subRoutes.Processing}`, {
        replace: true,
      });
      await tx.wait();

      navigate(`${Routes.Protect}/${chainName}/${subRoutes.Summary}`, {
        replace: true,
        state: {
          ...state,
          txHash: tx.hash,
          protocolFee,
        },
      });
    } catch (e) {
      console.error(e);
      navigate(`${Routes.Protect}/${chainName}`, {
        replace: true,
      });
    }
  };

  return (
    <MainContainer
      maxWidth={isMobile ? '100%' : '575px !important'}
      style={{ gap: '40px' }}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        mt={isMobile ? '0' : '25px'}
        mb="25px"
      >
        <Heading as="h1" scale="xxl" color="secondary.white">
          Confirmation
        </Heading>
      </Flex>

      <ConfirmCard
        iconComponent={
          <img
            src={ProtectIco}
            style={{
              position: 'absolute',
              left: '50%',
              top: '-50px',
              transform: 'translateX(-50%)',
            }}
            width="56px"
            height="48px"
            alt="Icon"
          />
        }
        amount={formatStringifyNumberToDot(state.protectAmount, 4)}
        subTitle={`${state.token.symbol}`}
        px={isMobile ? '8px' : ''}
      >
        <ConfirmDepositInnerContainer mb="20px" mx="4px">
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
            >
              Protect Term:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {state.protectTerm} Days
            </LargeText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
            >
              Protect Floor:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {state.protectFloor}%
            </LargeText>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
            >
              Auto-renew:
            </LargeText>
            <LargeText
              color="secondary.white"
              fontSize={isMobile ? '14px' : '16px'}
            >
              {state.autoRenew ? 'On' : 'Off'}
            </LargeText>
          </Flex>
        </ConfirmDepositInnerContainer>
        <Flex flexDirection="column" justifyContent="space-between">
          <Flex alignItems="start" justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
            >
              {state.token.symbol} Protection Amount:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatStringifyNumberToDot(state.protectAmount, 4)}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  {state.token.symbol}
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="Amount" />}
                <MediumText
                  ml="5px"
                  color="primary2.cambridgeBlue"
                  textAlign="end"
                  fontSize={'12px'}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  (Estimated $
                  {formatStringifyNumberToDot(
                    (
                      parseFloat(state.protectAmount) *
                      parseFloat(state.token.price)
                    ).toString(),
                    2,
                  )}
                  )
                </MediumText>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" mb="20px">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
            >
              Protocol Fee:
            </LargeText>
            <Flex justifyContent="end">
              <LargeText
                ml="5px"
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                ${formatStringifyNumberToDot(protocolFee.toString(), 2)}
              </LargeText>
            </Flex>
          </Flex>
          <Flex alignItems="start" justifyContent="space-between">
            <LargeText
              fontFamily="Roboto Mono"
              color="secondary.white"
              style={{ whiteSpace: 'nowrap' }}
              fontWeight={500}
              fontSize={isMobile ? '14px' : '16px'}
            >
              BUMP Bond:
            </LargeText>
            <Flex flexDirection="column" alignItems="flex-end">
              <LargeText
                color="secondary.white"
                fontSize={isMobile ? '14px' : '16px'}
              >
                {formatStringifyNumberToDot(
                  ethers.utils.formatUnits(state.bondAmount.toTransfer),
                  4,
                )}{' '}
                <SmallText color="secondary.white" display="inline-block">
                  BUMP
                </SmallText>
              </LargeText>
              <Flex>
                {!isMobile && <Tooltip tooltipValue="Bond" />}
                <MediumText
                  ml="5px"
                  color="primary2.cambridgeBlue"
                  style={{ whiteSpace: 'nowrap' }}
                  fontSize={'12px'}
                >
                  (Estimated ${state.bondAmountInUSD})
                </MediumText>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ConfirmCard>
      <Flex alignItems="center" width="100%">
        <Checkbox onClick={() => setIsActive(!isActive)} />
        <LargeText
          mt={isMobile ? '-10px' : ''}
          ml="20px"
          color="secondary.white"
          fontFamily="Roboto Mono"
        >
          By checking this box you acknowledge and agree to our <br />
          <Link color="secondary.green" href={termsAndConditionsLink}>
            <span style={{ fontFamily: 'Roboto Mono' }}>
              Terms and conditions
            </span>
          </Link>
          ,{' '}
          <Link color="secondary.green" href={privacyPolicyLink}>
            <span style={{ fontFamily: 'Roboto Mono' }}>Privacy Policy</span>
          </Link>
          , and{' '}
          <Link color="secondary.green" href={disclaimerLink}>
            <span style={{ fontFamily: 'Roboto Mono' }}>Disclaimer</span>
          </Link>
          .
        </LargeText>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Button
          secondary
          size={isMobile ? IconSize.L : IconSize.XL}
          onClick={handleBack}
        >
          Back
        </Button>

        <Button
          primary
          onClick={onConfirmButtonClick}
          size={isMobile ? IconSize.L : IconSize.XL}
          disabled={!isActive}
        >
          Confirm
        </Button>
      </Flex>
    </MainContainer>
  );
};
