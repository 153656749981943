import styled from 'styled-components';

export const SummaryInfoContainer = styled.div`
  display: flex;
  grid-template-columns: repeat(2 1fr);
  grid-template-rows: 1fr;
  width: 100%;
  gap: 52px;
  flex: 1;

  @media (max-width: 450px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 50px;
  }
`;
