import {
  AssetLabel,
  AssetLabelDark,
  Button,
  GreenLabel,
  Heading,
  IconSize,
  LargeText,
  lightColors,
  MediumText,
  PositionInfo,
  SmallText,
  Toggle,
  Tooltip,
  useMatchBreakpoints,
} from '@bumper-dao/ui-kit';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import React, { useMemo } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router';

import {
  CoinWrapper,
  CoinName,
  InfoPositionBalanceWrapper,
  InfoPositionLine,
  InfoPositionBalance,
  ContentWrap,
  DepositWrap,
  EarnedWrap,
  DetailsWrap,
  DepositDetailsWrap,
  BumpIcon,
  DetailsInfo,
  DetailsElement,
  BumpedCoinWrap,
  ToggleWrap,
  LineSeparator,
  CreatedDateText,
  DepositDetailsContainer,
  AssetLabelWrap,
  LeftBlockWrap,
  RightBlockWrap,
} from './styles';

import BumperIco from '../../assets/bumper-pixel-icon.svg';
import { DEFAULT_DECIMAL_VALUE } from '../../core/config/formulaConstants';
import { Routes, subRoutes } from '../../core/config/routes';
import { getNetworkConfigsByEnv } from '../../core/config/supportedChains';
import { BUMP, USDCoin } from '../../core/config/tokenNames';
import { tokensIcons } from '../../core/config/tokensIcons';
import { useChain } from '../../core/hooks/useChain';
import {
  ICoin,
  MakerPositionType,
  PositionExtendedType,
} from '../../core/interfaces';
import { useAppSelector } from '../../core/state/hooks';
import {
  convertBumpToDollars,
  dateFormatter,
  formatStringifyNumberToDot,
  formatWeiToNormalString,
  secondsByDays,
  timeInMilliseconds,
} from '../../core/utils/helpers';
import { ConfirmWithdrawPageProps } from '../../pages/EarnWithdrawFlow/types';
import { PositionHeaderWrapper } from '../StakingAssetPage/styles';

export interface ListViewProps {
  position: PositionExtendedType<MakerPositionType>;
  token?: ICoin;
  cardView?: boolean;
}

const ListViewBlock: React.FC<ListViewProps> = (props) => {
  return (
    <PositionInfo
      header={<Header {...props} />}
      content={<Content {...props} />}
      cardView={props.cardView}
    />
  );
};

export const Content: React.FC<ListViewProps> = ({ position }) => {
  const {
    bumpBondAmount,
    tier,
    yield: yieldReward,
    term,
    stableAmount,
    start,
  } = position;

  const { isMobile } = useMatchBreakpoints();
  return (
    <ContentWrap>
      <DepositWrap>
        <BumpedCoinWrap>
          <CoinWrapper>
            <img src={tokensIcons[USDCoin.symbol]} />
            <CoinName ml="0.5rem" mr="35px">
              <Heading
                scale="xl"
                lineHeight="150%"
                letterSpacing="0.03em"
                color={lightColors.secondary.white}
              >
                USDC
              </Heading>
              <SmallText color="rgba(255, 255, 255, 0.7)" fontWeight="500">
                USD Coin
              </SmallText>
            </CoinName>
          </CoinWrapper>
          <InfoPositionBalanceWrapper>
            <InfoPositionLine />
            <InfoPositionBalance>
              <MediumText
                color={lightColors.typography.placeholder}
                lineHeight="16px"
                fontWeight="500"
              >
                Position Balance
              </MediumText>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="16px"
                mt="0.25rem"
                className={(isMobile && 'word-breaker') || ''}
                maxWidth={isMobile ? '80px' : ''}
              >
                {formatStringifyNumberToDot(
                  ethers.utils.formatUnits(stableAmount, DEFAULT_DECIMAL_VALUE),
                  4,
                )}
              </MediumText>
            </InfoPositionBalance>
          </InfoPositionBalanceWrapper>
        </BumpedCoinWrap>
        <EarnedWrap>
          <MediumText
            color={lightColors.typography.placeholder}
            lineHeight="16px"
            fontWeight="500"
          >
            Yield earned
          </MediumText>
          <div>
            <AssetLabelWrap>
              <AssetLabel>
                <MediumText
                  color={lightColors.secondary.white}
                  lineHeight="20px"
                  fontWeight="400"
                >
                  {formatStringifyNumberToDot(yieldReward.toString(), 2)}
                </MediumText>
              </AssetLabel>
            </AssetLabelWrap>
          </div>
        </EarnedWrap>
      </DepositWrap>
      <DetailsWrap>
        <DepositDetailsWrap>
          <DepositDetailsContainer>
            <BumpIcon src={BumperIco} alt="bumper-logo" />
            <LargeText
              fontSize="24px"
              lineHeight="36px"
              letterSpacing="0.03em"
              fontWeight={400}
              color={lightColors.secondary.white}
              fontFamily="Share Tech Mono"
            >
              Deposit Details
            </LargeText>
          </DepositDetailsContainer>
          <CreatedDateText>Created: {dateFormatter(start)}</CreatedDateText>
        </DepositDetailsWrap>
        <DetailsInfo>
          <DetailsElement>
            <MediumText
              color={lightColors.typography.placeholder}
              lineHeight="16px"
              fontWeight="500"
            >
              Risk tier
            </MediumText>
            <AssetLabelDark>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
              >
                {tier}
              </MediumText>
            </AssetLabelDark>
          </DetailsElement>
          <DetailsElement>
            <MediumText
              color={lightColors.typography.placeholder}
              lineHeight="16px"
              fontWeight="500"
            >
              BUMP Bond
            </MediumText>
            <AssetLabelDark>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                {formatStringifyNumberToDot(
                  ethers.utils.formatUnits(
                    bumpBondAmount,
                    DEFAULT_DECIMAL_VALUE,
                  ),
                  4,
                )}
              </MediumText>
              <SmallText ml="4px" color={lightColors.typography.placeholder}>
                BUMP
              </SmallText>
            </AssetLabelDark>
          </DetailsElement>
          <DetailsElement>
            <MediumText
              color={lightColors.typography.placeholder}
              lineHeight="16px"
              fontWeight="500"
            >
              Deposit Term
            </MediumText>
            <AssetLabelDark>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                {term}
              </MediumText>
              <SmallText ml="4px" color={lightColors.typography.placeholder}>
                Days
              </SmallText>
            </AssetLabelDark>
          </DetailsElement>
          <DetailsElement>
            <MediumText
              color={lightColors.typography.placeholder}
              lineHeight="16px"
              fontWeight="500"
            >
              BUMP Incentive
            </MediumText>
            <AssetLabelDark>
              <MediumText
                color={lightColors.secondary.white}
                lineHeight="20px"
                fontWeight="400"
                marginRight="2px"
              >
                {/* todo: replace with incentives value when new contracts are deployed */}
                {0}
              </MediumText>
              <SmallText ml="4px" color={lightColors.typography.placeholder}>
                BUMP
              </SmallText>
            </AssetLabelDark>
          </DetailsElement>
        </DetailsInfo>
      </DetailsWrap>
    </ContentWrap>
  );
};

export const Header: React.FC<ListViewProps> = ({ position, token }) => {
  const { autorenew, stableAmount } = position;

  const navigate = useNavigate();

  const { chainId } = useWeb3React();
  const chainName = useMemo(() => {
    if (chainId) return getNetworkConfigsByEnv()[chainId ?? 1].nameForDisplay;
  }, [chainId]);

  const history = useNavigate();

  const chain = useChain();

  const coinDetails = useAppSelector((state) => state.coin.coinDetails);

  const periodsPast = Math.ceil(
    (Date.now() / 1000 - position.start) / secondsByDays(+position.term),
  );
  const liquidateDeadline = autorenew
    ? timeInMilliseconds(
        +position.positionOpenTimestamp +
          secondsByDays(+position.term) * periodsPast,
      )
    : timeInMilliseconds(
        +position.start + secondsByDays(+position.term) * position.pastTerms,
      );

  const onWithdrawClick = () => {
    convertBumpToDollars('1').then((bumpPrice) => {
      const locationState: ConfirmWithdrawPageProps = {
        token: coinDetails[position.tokenSymbol],
        positionId: position.id,
        withdrawAmount: formatWeiToNormalString(position.stableAmount, 18, 4),
        yieldAmount: formatStringifyNumberToDot(position.yield.toString(), 4),
        bondAmount: formatWeiToNormalString(
          position.bumpBondAmount,
          coinDetails[BUMP.symbol].decimals,
          4,
        ),
        bondInUsdAmount: formatStringifyNumberToDot(
          (
            parseFloat(bumpPrice) *
            parseFloat(ethers.utils.formatUnits(position.bumpBondAmount))
          ).toString(),
          2,
        ),
        bumpIncentiveAmount: formatStringifyNumberToDot(
          ethers.utils.formatUnits(
            BigNumber.from(position.incentives.toString()).add(position.boost),
            18,
          ),
          4,
        ),
        coordinationAmount: formatStringifyNumberToDot(
          ethers.utils.formatUnits(position.incentives, 18),
          4,
        ),
        boostAmount: formatStringifyNumberToDot(
          ethers.utils.formatUnits(position.boost, 18),
          4,
        ),
      };

      history(
        `${Routes.EarnAssetsDashboard}/${chain.nameForDisplay}/${subRoutes.Confirm}`,
        {
          state: locationState,
        },
      );
    });
  };

  const { isMobile } = useMatchBreakpoints();

  return (
    <PositionHeaderWrapper>
      <LeftBlockWrap>
        <BumpedCoinWrap>
          <CoinWrapper mr="10px">
            <img src={tokensIcons[USDCoin.symbol]} />
            <CoinName ml="0.5rem">
              <Heading
                scale="xl"
                lineHeight="150%"
                letterSpacing="0.03em"
                color={lightColors.secondary.white}
              >
                USDC
              </Heading>
              <SmallText
                color="rgba(255, 255, 255, 0.7)"
                fontWeight="500"
                style={{ whiteSpace: 'nowrap' }}
              >
                USD Coin
              </SmallText>
            </CoinName>
          </CoinWrapper>
          <InfoPositionBalanceWrapper>
            <InfoPositionLine />
            <InfoPositionBalance>
              <MediumText
                color={lightColors.typography.placeholder}
                fontSize="12px"
                lineHeight="16px"
                fontWeight="500"
                style={{ whiteSpace: 'nowrap' }}
              >
                Deposited Balance
              </MediumText>
              <MediumText
                color={lightColors.secondary.white}
                className={(isMobile && 'word-breaker') || ''}
                fontSize="12px"
                lineHeight="16px"
                mt="0.25rem"
                maxWidth={isMobile ? '134px' : ''}
              >
                {formatStringifyNumberToDot(
                  ethers.utils.formatUnits(stableAmount, DEFAULT_DECIMAL_VALUE),
                  4,
                )}
                {isMobile && (
                  <MediumText
                    color={lightColors.typography.placeholder}
                    className={(isMobile && 'word-breaker') || ''}
                    display="inline-block"
                    ml="4px"
                    fontSize="12px"
                    lineHeight="16px"
                  >
                    {token?.symbol}
                  </MediumText>
                )}
              </MediumText>
            </InfoPositionBalance>
          </InfoPositionBalanceWrapper>
        </BumpedCoinWrap>
        <GreenLabel style={{ backgroundColor: 'inherit' }}>Profit</GreenLabel>
      </LeftBlockWrap>
      <LineSeparator />
      <RightBlockWrap>
        {!(Date.now() >= liquidateDeadline) && (
          <ToggleWrap>
            <Tooltip
              tooltipValue="123"
              conntainerStyles={{
                zIndex: 9000,
              }}
            >
              <MediumText color="#99B3B5" lineHeight="16px">
                Auto-renew
              </MediumText>
            </Tooltip>
            <Toggle
              onToggle={(active) => {
                navigate(
                  `${Routes.Earn}/${chainName}${Routes.ConfirmAutoRenew}/${subRoutes.Confirm}`,
                  {
                    state: {
                      position,
                      token,
                      toggleState: active,
                    },
                  },
                );
              }}
              checked={autorenew}
            />
          </ToggleWrap>
        )}

        <Button
          primary
          size={IconSize.L}
          padding="0.25rem 1rem"
          letterSpacing="0.03em"
          lineHeight="20px"
          minHeight="32px"
          onClick={() => onWithdrawClick()}
          disabled={!(Date.now() >= liquidateDeadline)}
        >
          {!(Date.now() >= liquidateDeadline) && (
            <Countdown
              autoStart={true}
              date={liquidateDeadline}
              now={Date.now}
              renderer={({ formatted: cur }) => {
                return (
                  <span>
                    {cur.days}:{cur.hours}:{cur.minutes}:{cur.seconds}
                  </span>
                );
              }}
            ></Countdown>
          )}
          {Date.now() >= liquidateDeadline && 'Withdraw'}
        </Button>
      </RightBlockWrap>
    </PositionHeaderWrapper>
  );
};

export default ListViewBlock;
