import { ConnectWalletModal, IWallets } from '@bumper-dao/ui-kit';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import BinanceWalletLogo from '../assets/48px/wallets/icon-wallet-binance.svg';
import BrowserWalletLogo from '../assets/48px/wallets/icon-wallet-browser.svg';
import FrameWalletLogo from '../assets/48px/wallets/icon-wallet-frame.svg';
import WalletConnectLogo from '../assets/48px/wallets/icon-wallet-walletconnect.svg';
import WalletLinkLogo from '../assets/48px/wallets/icon-wallet-walletlink.svg';
import { Routes } from '../core/config/routes';
import {
  removeWeb3WalletType,
  setWeb3WalletType,
  Web3WalletType,
} from '../core/config/wallets';
import {
  useBsc,
  useInjected,
  useFrame,
  useWalletConnect,
  useWalletLink,
} from '../core/hooks/useWallet';

export interface ConnectWalletPropsType {
  setIsOpened: (data: boolean) => void;
}

export const ConnectWallet: React.FC<ConnectWalletPropsType> = ({
  setIsOpened,
}) => {
  const navigate = useNavigate();
  const {
    active: isConnectedInjected,
    tried: triedInjected,
    connectWallet: connectInjected,
  } = useInjected();
  const {
    active: isConnectedWalletConnect,
    tried: triedWalletConnect,
    connectWallet: connectWalletConnect,
  } = useWalletConnect();
  const {
    active: isConnectedBsc,
    tried: triedBsc,
    connectWallet: connectBsc,
  } = useBsc();
  const {
    active: isConnectedFrame,
    tried: triedFrame,
    connectWallet: connectFrame,
  } = useFrame();
  const {
    active: isConnectedWalletLink,
    tried: triedWalletLink,
    connectWallet: connectWalletLink,
  } = useWalletLink();

  const onConnectInjected = async () => {
    await connectInjected()
      .then(() => setWeb3WalletType(Web3WalletType.INJECTED))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectWalletConnect = async () => {
    await connectWalletConnect()
      .then(() => setWeb3WalletType(Web3WalletType.WALLET_CONNECT))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectBsc = async () => {
    await connectBsc()
      .then(() => setWeb3WalletType(Web3WalletType.BSC))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectFrame = async () => {
    await connectFrame()
      .then(() => setWeb3WalletType(Web3WalletType.FRAME))
      .catch(() => removeWeb3WalletType());
  };
  const onConnectWalletLink = async () => {
    await connectWalletLink()
      .then(() => setWeb3WalletType(Web3WalletType.WALLET_LINK))
      .catch(() => removeWeb3WalletType());
  };

  const walletsArr: IWallets[] = [
    {
      name: 'Browser Wallet',
      subtitle: '(MetaMask, TrustWallet)',
      logo: BrowserWalletLogo,
      disabled: false,
      onClick: () => onConnectInjected(),
    },
    {
      name: 'WalletConnect',
      logo: WalletConnectLogo,
      disabled: false,
      onClick: onConnectWalletConnect,
    },
    {
      name: 'WalletLink',
      logo: WalletLinkLogo,
      disabled: false,
      onClick: onConnectWalletLink,
    },
    {
      name: 'Binance',
      logo: BinanceWalletLogo,
      disabled: false,
      onClick: onConnectBsc,
    },
    {
      name: 'Frame',
      logo: FrameWalletLogo,
      disabled: false,
      onClick: onConnectFrame,
    },
  ];

  const isConnected =
    isConnectedInjected ||
    isConnectedWalletConnect ||
    isConnectedBsc ||
    isConnectedFrame ||
    isConnectedWalletLink;
  const tried =
    triedInjected ||
    triedWalletConnect ||
    triedBsc ||
    triedFrame ||
    triedWalletLink;

  const renderPage = () => {
    if (isConnected && tried) {
      return <Navigate to={Routes.Dashboard}></Navigate>;
    } else {
      return (
        <ConnectWalletModal
          wallets={walletsArr}
          withoutWalletClick={() => {
            setIsOpened(false);
            navigate(`${Routes.Dashboard}`, { replace: true });
          }}
          clickOutModal={() => {
            return;
          }}
        />
      );
    }
    return <></>;
  };

  return renderPage();
};
