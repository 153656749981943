import { Box } from '@bumper-dao/ui-kit';
import earnIcon from '@bumper-dao/ui-kit/dist/images/earn.png';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Routes, subRoutes } from '../../../core/config/routes';
import { USDCoin, WETH } from '../../../core/config/tokenNames';
import { tokensIcons } from '../../../core/config/tokensIcons';
import { getFlowAssetIcon } from '../../../core/funcs/getFlowAssetIcon';
import { useChain } from '../../../core/hooks/useChain';
import { MakerPositionService } from '../../../core/services/makerPositionService';
import { useAppSelector } from '../../../core/state/hooks';
import { ConfirmWithdrawPage } from '../Presenters/ConfirmWithdrawPage';
import { ConfirmWithdrawPageProps, PolicyWithdrawPageProps } from '../types';

export const ConfirmWithdrawPageContainer: React.FC = () => {
  const location = useLocation();
  const coinDetails = useAppSelector((state) => state.coin.coinDetails);
  const getLocationState: () => ConfirmWithdrawPageProps = useCallback(() => {
    if (!location.state) {
      return {
        amount: '0',
        token: coinDetails[USDCoin.symbol],
        positionId: 0,
        withdrawAmount: '',
        yieldAmount: '',
        bondAmount: '',
        bondInUsdAmount: '',
        bumpIncentiveAmount: '',
        coordinationAmount: '',
        boostAmount: '',
      };
    }

    return location.state as ConfirmWithdrawPageProps;
  }, [location.state]);

  const locationState = getLocationState();
  const history = useNavigate();
  const makerServices = MakerPositionService.getInstance();
  const chain = useChain();

  const onBackButtonPress = () => {
    history(`${Routes.EarnAssetsDashboard}/${chain.nameForDisplay}`);
  };
  const onConfirmWithdraw = async () => {
    try {
      history(
        `${Routes.EarnAssetsDashboard}/${chain.nameForDisplay}/${subRoutes.Approve}`,
      );

      const tx = await makerServices.withdraw(
        locationState.positionId,
        locationState.token,
        WETH,
      );

      history(
        `${Routes.EarnAssetsDashboard}/${chain.nameForDisplay}/${subRoutes.Processing}`,
      );
      await tx.wait();

      const policyData: PolicyWithdrawPageProps = {
        token: locationState.token,
        txHash: tx.hash,
        withdrawAmount: locationState.withdrawAmount,
        yieldEstimated: locationState.yieldAmount,
        bondAmount: locationState.bondAmount,
        bondAmountInUsd: locationState.bondInUsdAmount,
        bumpIncentivesAmount: locationState.bumpIncentiveAmount,
        bumpBoostAmount: locationState.boostAmount,
        bumpCoordinationAmount: locationState.coordinationAmount,
      };

      history(
        `${Routes.EarnAssetsDashboard}/${chain.nameForDisplay}/${subRoutes.Summary}`,
        {
          state: policyData,
        },
      );
    } catch (err) {
      console.error(err);
      history(`${Routes.EarnAssetsDashboard}/${chain.nameForDisplay}`, {
        replace: true,
      });
    }
  };

  return (
    <ConfirmWithdrawPage
      {...locationState}
      linkPath={''}
      iconComponent={
        <Box
          style={{
            position: 'absolute',
            top: '-50px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          {getFlowAssetIcon(earnIcon, tokensIcons[USDCoin.symbol])}
        </Box>
      }
      onBackClick={onBackButtonPress}
      onConfirmClick={onConfirmWithdraw}
    />
  );
};
