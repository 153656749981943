import { useWeb3React } from '@web3-react/core';
import { BigNumber, ethers } from 'ethers';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import AddLiqudityDeposit from './AddLiqudityDeposit';
import { ConfirmDepositCardProps, DepositLiqudityBalance } from './types';

import { termsAndConditionsLink } from '../../core/config/links';
import { Routes, subRoutes } from '../../core/config/routes';
import { BUMP, ETH, USDCoin } from '../../core/config/tokenNames';
import { tokensIcons } from '../../core/config/tokensIcons';
import { WalletContext } from '../../core/config/walletContext';
import { useChainName } from '../../core/hooks/useChain';
import {
  FixedProtectionPeriodType,
  FloorOptionType,
  IBondPositionData,
  ICoin,
} from '../../core/interfaces';
import { BondService } from '../../core/services/bondService';
import { MakerPositionService } from '../../core/services/makerPositionService';
import { MarketService } from '../../core/services/marketService';
import { ProtocolConfigService } from '../../core/services/protocolConfigService';
import { setIsOpenedWalletModal } from '../../core/state/actions/walletModalActions';
import { useAppSelector } from '../../core/state/hooks';
import {
  convertBumpToDollars,
  formatStringifyNumberToDot,
} from '../../core/utils/helpers';

export type ConfirmProtectLocationProps = {
  amount: string;
  term: number;
  token: ICoin;
  floor: number;
  premiumRate: number;
  autorenew: boolean;
  bondValueDollars: string;
  bondValueBumps: IBondPositionData;
  usdValue: string;
  shouldDisable?: boolean;
};

export const AddDepositLiqudityContainer: React.FC = () => {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isWalletConnected = useContext(WalletContext).isWalletConnected;
  const locationState = location.state as ConfirmDepositCardProps;

  const bondService = BondService.getInstance();
  const marketService = MarketService.getInstance();
  const makerService = MakerPositionService.getInstance();
  const protocolService = ProtocolConfigService.getInstance();

  const { chainId } = useWeb3React();
  const chainName = useChainName();

  const tokensDetails = useAppSelector((state) => state.coin.coinDetails);
  const { TOKEN_DETAILS } = useAppSelector((state) => state.contractsAddresses);

  const optionsConfig: DepositLiqudityBalance[] = [
    {
      key: USDCoin.symbol,
      token: USDCoin,
      value: `${USDCoin.symbol} ($${formatStringifyNumberToDot(
        tokensDetails[USDCoin.symbol].price,
        2,
      )})`,
      balance: tokensDetails[USDCoin.symbol].balance,
      balanceInNumber: parseFloat(tokensDetails[USDCoin.symbol].balance),
      icon: tokensIcons[USDCoin.symbol],
      price: tokensDetails[USDCoin.symbol].price,
    },
  ];

  const [ethMarketAddress, setEthMarketAddress] = useState<string>(
    ethers.constants.AddressZero,
  );

  useEffect(() => {
    protocolService
      .getMarket(TOKEN_DETAILS[ETH.symbol].address)
      .then((addr) => setEthMarketAddress(addr))
      .catch((e) => console.log('Market error:', e));
  }, []);

  const [option, setOption] = useState<DepositLiqudityBalance>(
    optionsConfig[0],
  );

  const [autoRenew, setAutoRenew] = useState<boolean>(
    !!locationState?.autoRenew,
  );
  const [notEnoughBump, setNotEnoughBump] = useState<boolean>(false);
  const [protectAmount, setProtectAmount] = useState<number>(
    parseFloat(locationState?.stableAmount || '0'),
  );

  const [activeDepositTierValue, setActiveDepositTierValue] = useState<number>(
    locationState?.activeRiskTierIndex || -1,
  );
  const [fixedDepositPeriodValue, setFixedDepositPeriodValue] =
    useState<number>(locationState?.activeTermIndex || -1);

  const [bumpCoordination, setBumpCoordination] = useState<number>(0);
  const [bumpBoost, setBumpBoost] = useState<number>(0);

  const [depositTiersValuesOptions, setDepositTiersValuesOptions] = useState<
    FloorOptionType[]
  >([]);
  const [fixedDepositPeriodOptions, setFixedDepositPeriodOptions] = useState<
    FixedProtectionPeriodType[]
  >([]);

  const [bumpBondValue, setBumpBondValue] = useState<IBondPositionData>({
    toLock: ethers.constants.Zero,
    toTransfer: ethers.constants.Zero,
    toReduce: ethers.constants.Zero,
  });
  const [bumpBondInDollars, setBumpBondInDollars] = useState<string>('0.00');

  const onCancel = () => {
    history(Routes.Earn);
  };

  const onNext = () => {
    const state: ConfirmDepositCardProps = {
      token: option.token,
      icon: option.icon ?? tokensIcons[USDCoin.symbol],
      stableAmount: protectAmount.toString(),
      stableSymbol: option.token.symbol,
      earningPeriod:
        fixedDepositPeriodValue >= 0
          ? fixedDepositPeriodOptions[fixedDepositPeriodValue]
              .fixedProtectionPeriodValue
          : 0,
      activeTermIndex: fixedDepositPeriodValue,
      riskTier:
        activeDepositTierValue >= 0
          ? depositTiersValuesOptions[activeDepositTierValue].floorValue
          : 0,
      activeRiskTierIndex: activeDepositTierValue,
      autoRenew: autoRenew,
      bondAmount: bumpBondValue,
      bondAmountInUSD: bumpBondInDollars,
      incentiveBoost: bumpCoordination,
      incentiveNetwork: bumpBoost,
      linkPath: termsAndConditionsLink,
    };

    return history(`${Routes.Earn}/${chainName}/${subRoutes.Confirm}`, {
      state,
      replace: true,
    });
  };

  useEffect(() => {
    Promise.all([
      marketService.getDepositTierOptions(),
      marketService.getFixedEarningPeriodOptions(),
    ]).then(([depositTierOptions, fixedProtectionPeriodOptions]) => {
      setDepositTiersValuesOptions(depositTierOptions);
      setFixedDepositPeriodOptions(fixedProtectionPeriodOptions);
    });
  }, []);

  const debouncedBondValue = debounce(async () => {
    const risk =
      activeDepositTierValue - 1 >= 0 && depositTiersValuesOptions.length !== 0
        ? depositTiersValuesOptions[activeDepositTierValue - 1].floorValue
        : 0;
    const term =
      fixedDepositPeriodValue - 1 >= 0 && fixedDepositPeriodOptions.length !== 0
        ? fixedDepositPeriodOptions[fixedDepositPeriodValue - 1]
            .fixedProtectionPeriodValue
        : 0;
    const bondValue = await bondService.calcBondSizeForMakerPosition(
      option.token,
      protectAmount.toFixed(6).toString(),
      risk,
      term,
    );
    setBumpBondValue(bondValue);
    const inDollars = await convertBumpToDollars('1');
    setBumpBondInDollars(
      formatStringifyNumberToDot(
        (
          parseFloat(inDollars) *
          parseFloat(ethers.utils.formatUnits(bondValue.toLock))
        ).toString(),
        2,
      ),
    );
    setNotEnoughBump(
      parseFloat(tokensDetails[BUMP.symbol].balance) <
        parseFloat(ethers.utils.formatUnits(bondValue.toLock)),
    );
  }, 700);
  useEffect(() => {
    debouncedBondValue();
    makerService
      .getIncentiveValus(
        chainId ?? 1,
        TOKEN_DETAILS[option.token.symbol].address,
        BigNumber.from(ethers.utils.parseUnits(protectAmount.toString())),
      )
      .then(({ coordination, boost }) => {
        setBumpBoost(boost);
        setBumpCoordination(coordination);
      });
  }, [protectAmount, activeDepositTierValue, fixedDepositPeriodValue]);

  return (
    <AddLiqudityDeposit
      earningPeriods={fixedDepositPeriodOptions.map(
        (v) => v.fixedProtectionPeriodValue,
      )}
      activeEarningPeriodIndex={fixedDepositPeriodValue}
      requiredBumpAmount={formatStringifyNumberToDot(
        ethers.utils.formatUnits(bumpBondValue.toLock),
        4,
      )}
      requiredBumpAmountInUsd={bumpBondInDollars}
      balanceBlock={{
        balances: optionsConfig,
        activeOption: option,
        onValueChange: (newValue) => {
          setOption(newValue);
        },
        onWalletConnectClick: () => {
          dispatch(setIsOpenedWalletModal(true));
        },
        isActive: isWalletConnected,
      }}
      onNextClick={onNext}
      onCancelClick={onCancel}
      bumpIncentives={bumpBoost + bumpCoordination}
      outValue={{
        depositAmount: protectAmount,
        riskTier: activeDepositTierValue,
        earningPeriod: fixedDepositPeriodValue,
        autoRenew,
      }}
      setProtectAmount={setProtectAmount}
      setActiveDepositTierValue={setActiveDepositTierValue}
      setFixedDepositPeriodValue={setFixedDepositPeriodValue}
      setAutoRenew={setAutoRenew}
      marketAddress={ethMarketAddress}
      notEnoughBump={notEnoughBump}
    />
  );
};
