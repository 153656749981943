import { VestingInfo } from './IVestingServices';

export enum FirebaseDataType {
  Vesting = 'vesting',
  Staking = 'staking',
  Airdrop = 'airdrop',
}

export interface StakingMerkleInfo {
  amount: string;
  amountWithoutStaking: string;
  amountWithRewards: string;
}

export interface AirdropMerkleInfo {
  index: number;
  bump: string;
  usdc: string;
  proof: string[];
}

export type VestingMerkleInfo = VestingInfo & { proof: string[] };

export interface EpochData {
  wallet_address: string;
  usdc_yield_per_user: number;
  bump_rewards: number;
  bump_value_usdc: number;
  total_rewards_usdc_value: number;
}

export type EpochUserData = EpochData & { apr: string };
