import { PositionInfo } from '@bumper-dao/ui-kit';
import React, { FC } from 'react';

import { FlexibleCardContent } from './FlexibleCardContent';
import { FlexibleCardHeader } from './FlexibleCardHeader';

import { timeInMilliseconds } from '../../../core/utils/helpers';
import { FlexibleStakingCardProps } from '../types';

export const FlexibleStakingCard: FC<FlexibleStakingCardProps> = ({
  rewards,
  stakeIndex,
  amount,
  endWithdrawWindow,
  cooldownPeriod,
  unstakeRequestedAt,
  cardView,
}) => {
  const endRequestTimestamp = timeInMilliseconds(
    unstakeRequestedAt + cooldownPeriod,
  );
  return (
    <PositionInfo
      cardView={cardView}
      header={
        <FlexibleCardHeader
          amount={amount}
          stakeIndex={stakeIndex}
          rewards={rewards}
          endRequestTimestamp={endRequestTimestamp}
          endWithdrawWindow={endWithdrawWindow}
        />
      }
      content={
        <FlexibleCardContent
          stakeIndex={stakeIndex}
          amount={amount}
          rewards={rewards}
        />
      }
    />
  );
};
